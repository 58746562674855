/*** Inclusion des partials **********************************************************************/
@import "partials/reset";
@import "partials/library";
@import "partials/functions";
@import "partials/variables";

/*** Styles Généraux *****************************************************************************/
/*Lisser la police	*/
html, body {
  //TODO : Trouver pourquoi ça overflow sur l'accueil (écran un peu plus mince que 1920) et corriger
  //overflow-x: hidden;
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
.padding-0 {
    padding: 0 !important;
}
.wd-14 {
    width: 14em !important;
}
body {
  font-family: $fontBase;
  font-weight: 400;
  font-size: 18px;
  color: $black;
  padding: 0;
  line-height: 1.3;
  //min-width: 320px;

  .turquoise {
    color: $turquoise !important;

    * {
      color: $turquoise !important;
    }
  }

  .mauve {
    color: $mauve !important;

    * {
      color: $mauve !important;
    }
  }

  .noir {
    color: $black !important;

    * {
      color: $black !important;
    }
  }

  .gras {
    font-weight: bold !important;

    * {
      font-weight: bold !important;
    }
  }

  .underline {
    text-decoration: underline;
  }
}

em {
  font-style: italic;
}

#site {
  margin: 0 auto;
  position: relative;
}

.wrap {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}

//Pour détecter les trucs pas finis
/* a[href="#"], a[href=""], form[action=""], form[action="#"] {
	//outline: 1px dotted red !important;
}

* {
	 //outline: 1px dotted red !important;
} */

/*** Styles globaux **************************************************/
.hide {
  display: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hidden-textarea{
    height: 0 !important;
    width: 0 !important;
    min-height: 0 !important;
    min-width: 0 !important;
    border: 0 !important;
    opacity: 0;
}

input.button, a.button, button, input[type="submit"] {
  background-color: $turquoise;
  padding: 0.9em 1.1em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  border: 0 none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;

  &.needUpload {
    display: none;

    &.uploadFinished {
      display: inline-block;
    }
  }

  &:hover, &:active {
    background-color: $darkturquoise;
  }

  &[disabled] {
    background-color: darken($lightgray, 10%) !important;

    &:hover, &:active {
      background-color: darken($lightgray, 10%) !important;
    }

    &.borderButton, &.outline {
      background-color: transparent !important;

      &:hover, &:active {
        background-color: transparent !important;
      }
    }
  }
}

/*button.stripe-button-el {
	background-image: none;
	-webkit-font-smoothing: inherit;
	appearance: none !important;
	padding: 0;
	text-decoration: none;
	border-radius: 0;
	box-shadow: none;

	span {
		@extend button;
		position: relative;
		height: auto;
		line-height: 1;
		background: $turquoise;
		background-image: none;
		font-size: inherit;
		font-family: inherit;
		text-shadow: none;
		box-shadow: none;
	}
}*/

input.buttonLink, a.buttonLink, button.buttonLink {
  background-color: transparent;
  font-weight: 400;
  font-size: 0.9em;
  font-weight: bold;
  color: $turquoise;
  text-decoration: none;
  text-transform: none;
  border-radius: 0;
  padding: 0.9em 0;
  display: inline-block;
  cursor: pointer;
  outline: none;

  &:hover, &:active {
    text-decoration: underline;
    background-color: white;
  }
}

input.buttonMauve, a.buttonMauve, button.buttonMauve {
  background-color: $mauve;
  padding: 0.9em 1.1em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  border: 0 none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: darken($mauve, 15%);
  }
}

input.buttonAlt, a.buttonAlt, button.buttonAlt {
  background-color: $black;
  padding: 0.9em 1.1em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  border: 0 none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: lighten($black, 10%);
  }
}

input.buttonSmall, a.buttonSmall, button.buttonSmall {
  border-radius: 0;
  padding: 0.3em 0.6em;
  font-size: 0.9em;
  font-weight: 400;
  text-transform: none;
}

input.borderButton, button.borderButton, a.borderButton,
.button.outline, button.outline, input.outline {
  background-color: transparent;
  padding: 0.7em 1.4em;
  border: 3px solid $turquoise;
  color: $black;
  transition: color 0.2s ease, background-color 0.2s ease;

  &:hover, &:active {
    background-color: $turquoise;
    color: $white;
  }
}

a[disabled], button[disabled], input[type="button"][disabled], input[type="submit"][disabled] {
  &:hover {
    cursor: default;
  }
}


button.btnWithIcon, .button.btnWithIcon, input.btnWithIcon {
  display: inline-block;
  padding-left: 0.6em;
  padding-right: 0.6em;

  &.bigger {
    font-size: 1.2em;
  }

  &.thin {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
  }

  &.gradientOutline {
    position: relative;
    padding: 5px;
    color: $turquoise;

    //Fake border
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 0.5em;
      background-image: linear-gradient(115deg, $turquoise 0%, $turquoise 40%, $green 80%, $green 100%);
    }

    &:hover, &:active {
      &::before, .icon {
        filter: brightness(90%);
      }

      .linkText {
        color: darken($turquoise, 5%);
      }
    }

    &::before, .icon {
      transition: filter 0.2s ease;
    }

    .linkText {
      transition: color 0.2s ease;
    }

    //Fond blanc pour cacher en partie le fake border
    .buttonInner {
      position: relative;
      z-index: 10;
      padding: 0.9em 0.6em;
      background-color: $white;
      border-radius: 0.5em;
    }

    .icon {
      background-position: 0 -2100px;
    }
  }

  &.outline {
    &:hover, &:active {
      .icon {
        filter: brightness(1000%);
      }
    }

    .icon {
      background-position: -55px -1300px;
    }
  }

  .buttonInner {
    display: flex;
    align-items: center;
    text-align: left;
  }

  .icon {
    display: inline-block;
    width: 47px;
    height: 47px;
    background-image: url('/images/sprite_icon.png');
    background-position: 0 -1300px;
    background-repeat: no-repeat;
    transition: filter 0.2s ease;

    &.arrowUp {
      width: 38px;
      height: 47px;
      background-position: 0 -4271px;
      filter: brightness(1000%);
    }

    &.timePlus {
      width: 47px;
      height: 47px;
      background-position: 0 -4371px;
      filter: brightness(1000%);
    }
  }

  .linkText {
    flex-grow: 1;
    display: block;
    padding-left: 0.6em;
    line-height: 1.2em;

    span {
      display: block;
      white-space: nowrap;
    }

    .italic {
      text-transform: none;
      font-style: italic;
      font-weight: normal;
    }
  }
}

.btnCloseRound {
  display: block;
  vertical-align: top;
  background-color: $green;
  padding: 9px 10px;
  border-radius: 100%;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: border-color 0.2s linear;

  &:hover, &:active {
    border-color: $white;
    background-color: $green;
  }

  &.larger {
    padding: 9px 10px;
    border-width: 3px;

    .icon {
      width: 10px;
      height: 12px;
      background-position: 0 -609px;
    }
  }

  .icon {
    display: block;
    vertical-align: top;
    width: 7px;
    height: 9px;
    background-image: url('/images/sprite_user.png');
    background-repeat: no-repeat;
    background-position: 0 -509px;
    text-indent: -99999px;
    overflow: hidden;
  }
}

//Points d'interrogation encerclés pour tooltips/popup
.roundTrigger {
  position: relative;
  padding: 0;
  border-radius: 100%;
  width: 1.6em;
  background-color: $turquoise;
  transition: background-color 0.2s ease;

  &:hover, &:active {
    background-color: $darkturquoise;
    cursor: pointer;
  }

  //Ratio d'aspect 1:1
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
  }
}

//Points d'interrogation encerclés pour le worktype (inscription retraité étape 1)
.roundTrigger.smallipop {
  display: inline-block;
  color: #fff;
  top: 8px;
  margin-left: 5px;
}

// Laisser de l'espace à droite pour le "(?)" des worktype (première étape d'inscription retraité)
div.formRow label.triggerLabel {
  max-width: 215px;
}

.mceBigger {
  font-size: 1.2em;
}

.mceAqua {
  color: $turquoise;
}

.progressbar {
  background-color: white;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  padding: 2px;

  .progress {
    background-color: $black;
    height: 6px;
    border-radius: 3px 0 0 3px;

    &.progressComplete {
      border-radius: 3px;
    }
  }
}

.outerVideo {
  width: 100%;
  max-width: 1280px;
  max-height: 720px;
  margin: 0 auto;
}

.wrapVideo {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.largeTitle, .site #main .largeTitle, .account .largeTitle, #main .largeTitle {
  display: inline-block;

  &::after {
    content: '';
    display: block;
    width: 100%;
    max-width: 100px;
    margin-top: 2.2em;
  }

  h1, h2, h3 {
    margin: 0;
    font-size: 2.6em;
    font-weight: 400;
    letter-spacing: -0.05em;
    color: $black;
    line-height: 1.2em;
  }

  .leftLinks, .rightLinks {
    margin-top: -0.2em;

    a {
      // font-weight: bold;
      text-decoration: none;
      transition: color 0.2s ease;

      &, &:link, &:visited {
        color: $turquoise;
      }

      &:hover, &:active {
        color: $darkturquoise;
      }

      &:not(:last-child) {
        margin-right: 1em;
      }


    }

    .button {
      margin-top: 0.6em;
      font-size: 0.8em;
      text-transform: none;
      font-weight: 400;
      padding: 0.6em 1em;
      border-radius: 0.5em;
      transition: background-color 0.2s ease;

      &, &:link, &:visited {
        color: $white;
      }

      &:hover, &:active {
        color: $white;
        background-color: $darkturquoise;
      }
    }
  }

  .rightLinks {
    text-align: right;
  }

  .leftLinks {
    text-align: left;
  }

  .label {
    font-weight: 700;
    text-transform: uppercase;
    color: lighten($midgray, 30%);

    & + h1, & + h2, & + h3 {
      margin-top: -0.2em;
    }
  }
}

/*** Styles d'accessibilité **********************************/
.skip-link {
  position: absolute;
  background: #f3f4f6;
  color:  #374151;
  padding: 1rem 0.5rem;
  top: 10px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  text-decoration: none;

  transform: translateY(-1000%);
}

.skip-link:focus-within {
  transform: translateY(0%);
}

a:focus, button:focus, input:focus, .owl-prev:focus, .owl-next:focus {
  outline: 2px solid #007BFF;
  outline-offset: 2px;
}

#newsletter input:focus {
  outline: 2px solid white !important;
  outline-offset: 2px;
}

//Carousel
.owl-nav button {
  background: transparent;
}


/*** Style des popups custom (Sweet Alert) *******************/

@keyframes pulseWarning {
  0% {
    border-color: lighten($green, 10%);
  }

  100% {
    border-color: $green;
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: lighten($green, 10%);
  }

  100% {
    background-color: $green;
  }
}

.sweet-alert {
  p {
    a {
      color: $turquoise;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .extraButtons {
    margin-top: 1.4em;
  }

  button, .button {
    padding: 0.6em 2em;
    margin-top: 0.4em;
    font-weight: 400;
    border-radius: 0.3em;
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.extraButton {
      background-color: $gray;

      &:hover, &:active {
        background-color: $midgray;
      }
    }
  }

  button.cancel {
    background-color: $gray;

    &:hover, &:active {
      background-color: $midgray;
    }
  }

  button.confirm {
    background-color: $turquoise !important;
    box-shadow: none !important;

    &:hover, &:active {
      background-color: darken($turquoise, 10%) !important;
    }
  }

  fieldset {
    padding: 0;
  }

  .sa-icon {
    &.sa-warning {
      border-color: $green;

      .sa-body, .sa-dot {
        background-color: $green;
      }
    }

    &.sa-success {
      border-color: $green;

      .sa-line {
        background-color: $green;
      }
    }
  }
}

/*** HEADER **************************************************/
.home header#header {
  background-color: rgba($white, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 60;
}

.site header#header {
  padding-bottom: 4px;
  background: $green;
  background: linear-gradient(to right, $lightgreen, $green);

  .inner {
    background-color: white;
  }
}

header#header {
  box-shadow: 0 0 10px 10px rgba($black, 0.2);
}

#header-wrapper {
  display: flex;
  justify-content: space-between;
}

#main > header {
  background: {
    position: center top;
    repeat: no-repeat;
    size: cover;
  }

  h1 {
    color: white;
    margin: 0;
    letter-spacing: -0.04em;
    line-height: 1;
  }
}

.page #main {
  //Header page / pageHeader
  & > header {
    margin-bottom: 50px;

    &.gradient {
      .wrap {
        .inner {
          height: 190px;
          display: flex;
          align-items: center;
        }
      }

      .headerText {
        position: static;
        flex-basis: 100%;
        margin-bottom: 0;
        text-align: center;
      }

      h1 {
        font-weight: 800;
        font-style: italic;
        font-size: 1.8em;
        text-shadow: none;
      }
    }

    .wrap {
      .inner {
        position: relative;
        height: 110px;
      }
    }

    .headerText {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: 2px;
    }

    h1 {
      font-size: 2em;
      text-shadow: 3px 6px 1px rgba(black, 0.5);
      line-height: 1;
    }
  }
}

#main {
  .center  {
    text-align: center;
  }

  .left {
    text-align: left;
  }

  .right  {
    text-align: right;
  }

  &.hasSidebar {
    .innerMain {
      display: flex;
      flex-wrap: wrap;

      & > * {
        flex-basis: 100%;
        max-width: 100%;
      }

      //Sidebar principale / Main sidebar / #mainsidebar
      & > aside {
        width: 100%;
        margin-top: 2em;
        padding: 0;

        &.blogSidebar {
          .search, .categories {
            display: none;
          }
        }

        .sidebarInner {
          padding-bottom: 2em;

          & > * {
            margin: auto;

            &:not(:last-of-type) {
              margin-bottom: 2em;

              &::after {
                content: '';
                display: block;
                margin-top: 2em;
                width: 100%;
                max-width: 67px;
                border-bottom: 2px solid $turquoise;
              }
            }

            &.bup {
              & > a {
                display: block;
                width: 100%;
                max-width: 300px;
                margin: 0;

                & > img {
                  display: block;
                  width: 100%;
                  height: auto;
                }
              }
            }

            &.sidebarVideo {
              .outerVideo {
                max-width: 400px;
                margin: 0 0;
                border: 3px solid $black;
              }
            }

            &.form {
              .entryContent, .entry-content {
                margin-bottom: 1em;
              }

              form {
                display: flex;

                input {
                  margin-right: 0.5em;
                  max-width: 350px;
                }

                button {
                  position: relative;
                  display: flex;
                  align-items: center;
                  padding: 0.2em 0.4em;
                  background-color: transparent;
                  border-radius: 0;

                  &:hover, &:active {
                    .icon {
                      &:not(.hover) {
                        opacity: 0;
                      }

                      &.hover {
                        opacity: 1;
                      }
                    }
                  }

                  .icon {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    text-indent: -99999px;
                    background-image: url('/images/sprite_icon.png');
                    background-position: 0 -2650px;
                    background-repeat: no-repeat;
                    transition: opacity 0.2s ease;

                    &.hover {
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                      opacity: 0;
                      background-position: 0 -2700px;
                    }
                  }
                }
              }
            }
          }

          h4 {
            font-size: 1.45em;
            font-weight: 400;
            letter-spacing: -0.04em;
            color: $black;
          }
        }
      }
    }
  }
}

//Header formulaire
.containForm #main > header {
  .wrap {
    text-align: center;
    margin-bottom: 50px;
    padding: 15px 20px;
  }

  h1, h2 {
    margin: 0;
    color: white !important;
    line-height: 1;
  }

  h1 {
    font-weight: 400;
    //font-style: italic;
    font-size: 1.8em;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
    line-height: 1;
  }

  h2 {
    font-weight: 800;
    font-style: italic;
    font-size: 2em;
    line-height: 1.2em;

    strong {
      color: $white;
    }
  }

  .wrapProgress {
    width: 50%;
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 1em;
  }

  .progressbar {
    flex-grow: 100;

    .progress {
    }
  }

  .wrapPercent {
    flex-grow: 0;
    margin-left: 0.5em;
  }

  .percentCompletion {
    color: $white;

    .number {
      font-weight: 700;
    }

    .symbol {
      font-size: 0.8em;
    }
  }
}

header#header {
  width: 100%;
  position: relative;
  z-index: 60;

  .wrap {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  #logo {
    margin: 0;
    max-width: 82%;

    a {
      display: inline-block;
    }
  }

  #menus {
    //float: right;
    margin-top: 20px;
  }

  .top {
    position: absolute;
    z-index: 10;
    top: 39px;
    right: 37px;

    .lang {
      display: block;
      float: right;
      margin-left: 10px;

      ul {
        li {
          display: block;
          font-size: 0.7em;
          text-transform: uppercase;

          a {
            color: $black;
            text-decoration: none;
            transition: color 0.2s ease;

            &:hover {
              color: $turquoise;
            }
          }
          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  .signed-in .lang {
    float: left;
  }

  .bottom {
    z-index: 5;
    position: relative;
  }

  .userinfos {
    display: none;
    float: right;
    margin-right: 1em;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: top;
      margin: 0 0.4em 0 0.6em;
      height: 26px;
      border-right: 2px solid $lightgray;
    }

    .trigger {
      //background: url(/images/user_noimage_mini.png) right 0 no-repeat;
      min-height: 26px;
      line-height: 26px;
      padding-right: 40px;
      padding-bottom: 18px;
      display: inline-block;
      font-size: 0.75em;
      font-weight: 400;
      color: $black;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.2s ease;
      position: relative;

      img {
        position: absolute;
        top: 0;
        right: 0;
        border: 2px solid $turquoise;
        border-radius: 100%;
        overflow: hidden;

        &.noborder {
          border: 0 none;
          border-radius: 0;
        }
      }

      &:hover, &:active {
        color: $turquoise;
      }
    }

    .infos {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: -3px;
      right: 8px;
      max-width: 280px;
      font-size: 0.8em;
      background-color: rgba($white, 0.9);
      filter: drop-shadow(0px 0px 2px rgba($black, 0.5));
      opacity: 0;
      transition: opacity 0.2s ease;

      &.visible {
        display: block;
      }

      &.fadeIn {
        opacity: 1;
      }

      &::before {
        content: "";
        position: absolute;
        right: 15px;
        top: -13px;
        border-style: solid;
        border-color: transparent transparent white transparent;
        border-width: 0 8px 13px 8px;
      }

      .inner {
        padding: 1em 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .avatar {
        float: left;
        width: 70px;
        height: 70px;
        margin-right: 10px;
        overflow: hidden;
        box-sizing: border-box;

        img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 35px;
          border: 2px solid $turquoise;
        }
      }

      ul {
        width: 210px;
        text-align: left;
        margin: 0;
        padding: 0;
      }

      li {
        margin: 5px 0;
        // word-break: break-all;
        // max-width: 130px;
      }

      a.link {
        color: $turquoise;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

    }
  }

  #mainmenu, #topmenu {
    text-align: left;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      list-style-type: none;
      display: inline-block;

      &.hide {
        display: none;
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
      //transition: all 0.2s ease;

      /*&:hover, &:focus, &.active {
				background-color: $turquoise;
				color: $white;
			}*/
    }
  }

  #mainmenu {
    display: inline-block;

    //Pour compenser pour le padding du trigger du .userinfos
    margin-top: -18px;

    // Cacher l'inscription express temporairement
    /* ul>li:nth-child(3) ul>li:nth-child(3){
			display: none;
		} */

    & > ul > li {
      &.parent {
        position: relative;
        transition: background-color 100ms ease;

        &:hover, &:active {
          & > a {
            color: $white;
            background-color: rgba($turquoise, 0.9);
          }
        }

        ul {
          display: none;
          background-color: rgba($turquoise, 0.9);
          //background-image: linear-gradient(to bottom, rgba($turquoise, 0) 0em, rgba($turquoise, 0.9) 1.6em);
          min-width: 200px;
          padding: 1em 0;
          position: absolute;
          top: 100%;
          left: 0;
          text-align: left;
          border-radius: 0 0 10px 10px;

          &.visible {
            display: block;
          }

          &.fadeIn {
            opacity: 1;
            transform: translateY(0);

            & > li {
              transform: translateY(0);
            }
          }

          & > li {
            padding: 0;
            display: block;
            //transform: translateY(-12px);
            transition: transform 0.2s ease;

            &.active {
              font-weight: bold;
            }

            &.hide {
              display: none;
            }
          }

          a {
            display: block;
            padding: 0.4em 1.25em;
            font-size: 0.8em;
            color: white;

            &:hover, &:active {
              background-color: rgba($white, 0.1);
            }
          }
        }
      }

      &:not(.highlight) {
        &.parent {
          & > a {
            &:hover, &:active {
              color: $white;
            }
          }
        }

        & > a {
          &:hover, &:active {
            color: $turquoise;
          }
        }

      }

      &.highlight {
        & > a {
          font-weight: 700;
          padding-top: 12px;
          padding-bottom: 12px;
          border: 2px solid $turquoise;
          color: $turquoise;
          border-radius: 3px;

          &:hover, &:active {
            background-color: $turquoise;
            color: white;
          }
        }

        & + li {
          margin-left: 14px;
        }
      }

      &.active > a {
        font-weight: 700;
      }

      & > a {
        display: inline-block;
        padding: 14px 14px;
        font-size: 0.8em;
        white-space: nowrap;
        color: $black;
        border-radius: 10px 10px 0 0;
        transition: background-color 0.2s ease, color 0.2s ease;
      }
    }
  }

  #topmenu {
    margin: 0 0 7px;
    display: none;
    float: left;

    li {
      padding-bottom: 18px;

      &:not(:last-of-type) {
        margin-right: 1em;
      }
    }

    a {
      font-size: 0.6em;
      color: $black;
      text-transform: uppercase;
      transition: color 0.2s ease;

      &:hover {
        color: $turquoise;
      }
    }

    .active a {
      font-weight: 700;
    }
  }

  #moreContainer {
    display: inline-block;
    position: relative;
    z-index: 800;
    float: right;
    //Pour compenser pour le padding du trigger du .userinfos
    margin-top: -10px;

    &.hide {
      display: none;
    }

    &.menuOpen {
      & > .moreBtn {
        background-color: rgba($turquoise, 0.9);

        &:hover, &:active {
          background-color: rgba($turquoise, 0.8);

          .bar {
            box-shadow: 0 1px 0 rgba($black, 0);
          }
        }

        .bar {
          background-color: $white;
        }
      }
    }

    & > .moreBtn {
      display: inline-block;
      padding: 0.7em 14px;
      margin-left: 10px;
      line-height: 1.125em;
      background-color: transparent;
      border-radius: 10px 10px 0 0;
      transition: background-color 0.2s ease;

      &:hover, &:active {
        .bar {
          box-shadow: 0 1px 0 rgba($black, 0.25);
        }
      }

      .icon {
        display: inline-block;
      }

      .bar {
        background-color: $turquoise;
        display: block;
        width: 20px;
        height: 2px;
        @include rounded(1px);
        transition: box-shadow 0.2s ease;
        box-shadow: 0 1px 0 rgba($black, 0);
        transition: background-color 0.2s ease, box-shadow 0.2s ease;

        &:not(:first-child) {
          margin-top: 3px;
        }
      }
    }

    .moreSubmenu > ul {
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 60;
      display: none;
      width: 200px;
      margin: 0;
      padding: 0.6em 0;
      text-align: right;
      border-radius: 0 0 10px 10px;
      background-color: rgba($turquoise, 0.9);
      opacity: 0;
      //transform: translateY(-4px);
      transition: opacity 0.2s ease, transform 0.2s ease;

      &.visible {
        display: block;
      }

      &.fadeIn {
        opacity: 1;
        transform: translateY(0);

        & > li {
          transform: translateY(0);
        }
      }

      & > li {
        list-style-type: none;
        //transform: translateY(-12px);
        transition: transform 0.2s ease;

        &:not(:last-of-type) {
          &::after {
            content: '';
            display: block;
            margin: 0.4em 1.5em 0.4em auto;
            width: 1em;
            border-bottom: 1px solid $white;
          }
        }

        & > a {
          white-space: nowrap;
          font-weight: bold;
        }

        ul {
          margin: 0;

          li {
            font-size: 0.85em;
          }
        }
      }

      a {
        display: block;
        padding: 0.6em 1.8em;
        text-decoration: none;
        font-size: 0.8em;
        color: $white !important;
        //transition: all 0.2s ease;

        span {
          color: $white !important;
        }

        &:hover, &:active {
          background-color: rgba($white, 0.1);
        }
      }
    }
  }
}

#entete {
  position: relative;

  .inner {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: top;
    position: relative;
    z-index: 1;
  }

  h1 {
    font-weight: 800;
    font-style: italic;
  }
}

/*** MAIN **************************************************/
.site #main {
  background-color: $white;
  color: $black;
  font-weight: 400;
  line-height: 1.5;
  padding: 0 0 50px;

  h2, h3, h4, h5, h6 {
    color: $turquoise;
    line-height: 1;
  }

  h2 {
    font-weight: 700;

    strong {
      color: $black;
      font-weight: 700;
    }
  }

  h3 {
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

/* Styles de texte généraux  */

p {
  margin-bottom: 0;
}

.entryContent, .entry-content, .formFooter, label, dd, #main .entryContent, #main .entry-content {
  img.lazy {
    display: none;
  }

  article {
    margin-bottom: 1em;
  }

  table {
    max-width: 100%;
    border-spacing: collapse;
    margin-bottom: 15px;
    border: 2px solid $turquoise;
    font-size: 0.8em;

    thead {
      tr {
        border-bottom: 2px solid $turquoise;
      }
    }

    tr {
      th {
        text-align: center;
        padding: 10px 10px;
        text-transform: uppercase;
        border: 1px solid $white;
        color: $white;
        background-color: $turquoise;
      }

      td {
        text-align: left;
        padding: 8px 10px;
        border: 1px solid $turquoise;
      }
    }
  }

  ul, ol {
    list-style-position: outside;
    padding-left: 0.67em;

    li {
      list-style-type: none;
      margin: 0 0 0.6em 0em;
      padding-left: 0.67em;
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 0.5em;

      &::before {
        content: "-";
        position: absolute;
        left: 0;
        top: 0;
        // margin-right: 0.5em;
        font-weight: bold;
        color: $turquoise;
      }
    }
  }

  ol {
    counter-reset: item;

    li {
      &::before {
        content: counter(item) ".";
        counter-increment: item;
        color: $turquoise;
        margin-right: 0.5em;
        font-weight: bold;
      }
    }
  }

  table.alternance {
    tr:nth-child(even) td {
      background-color: $lightgray;
    }

    tr td, tr th {
      border-bottom: 1px solid $gray;
    }
  }

  form {
    ul, ol {
      margin-left: 0;

      li {
        list-style-type: none;
      }
    }
  }

  p, table, ul, ol {
    margin: 0 0 0.67em 0;
  }

  img.noborder {
    border: 0 none !important;
  }

  /*
	.tooltip {
		position: absolute;
		right: 4px;
		top: -2px;
		color: red;
		font-size: 0.8em;
	}
	*/

  dl {
    dt {
      font-weight: 500;
      padding-bottom: 5px;
      float: left;
      padding-right: 5px;
    }

    dd {
      padding-bottom: 15px;
      margin-left: 0;
    }
  }

  .alignleft {
    display: inline-block;
    float: none;
    margin: 0 1em 0.67em 0;
    width: 260px;
    height: auto;

    &.wp-caption .wp-caption-text {
      text-align: center;
      font-size: 0.9em;
    }
  }

  .alignright {
    float: right;
    margin: 0 0 0.4em 1em;
  }

  .aligncenter {
    text-align: center;
  }

  h2 {
    font-size: 2.2em;
    font-style: italic;
    font-weight: bold;
    margin: 0.67em 0 0.335em 0;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 1.7em;
    font-style: italic;
    font-weight: bold;
    text-transform: none;
    margin: 0 0 0.335em 0;
  }

  h4 {
    margin: 0 0 0.5em 0;
    font-size: 1.4em;
    font-weight: bold;
    color: $black;
  }

  h5 {
    margin: 0 0 0.67em 0;
    font-size: 1.3em;
    font-weight: bold;
    color: $black;
  }

  h6 {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    color: $black;
  }
}

.entryContent, .entry-content, .formFooter, label, dd, fieldset, .formRow:not(.buttons) {
  a:not(.button) {
    font-weight: 700;
    color: $turquoise;

    &:hover {
      text-decoration: none;
    }

  }

  a.suppress {
    font-weight: initial;
    color: $red;
    font-size: 0.75em;

    &:hover {
      text-decoration: none;
    }
  }
}

/*** FOOTER **************************************************/
div#newsletter {
  background-color: $turquoise;
  color: $white;

  .inner {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h3 {
    font-size: 1.4em;
    font-style: italic;
    font-weight: 400;
    padding: 0;
    margin: 0;

    strong {
      font-weight: 800;
      font-style: italic;
      font-size: 1.1em;
    }
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-style: italic;
    font-weight: 600;
  }

  form {
    white-space: nowrap;
  }

  input[type="text"], input[type="email"], input[type="password"] {
    background-color: $white;
    border: 0 none;
    border-radius: 9px 0 0 9px;
    padding: 15px 20px;
    font-family: $fontBase;
    font-weight: 400;
    color: $black;
    font-size: 0.9em;
    margin-right: 2px;
    display: inline-block;
    max-width: 55%;
    float: left;
  }

  input[type="submit"], input[type="button"], button {
    background-color: $darkturquoise;
    border: 0 none;
    border-radius: 0 9px 9px 0;
    padding: 15px 20px;
    font-family: $fontBase;
    font-weight: 700;
    color: $white;
    font-size: 0.9em;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: darken($turquoise, 25%);
    }
  }
}

footer#footer {
  padding: 30px 0;
  background-color: $white;

  &:not(.afterNewsletter) {
    box-shadow: 0 0 10px 10px rgba($black, 0.2);
  }

  .logo {
    margin-bottom: 20px;
  }

  #footermenu, #legalmenu {
    text-align: left;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      list-style-type: none;
      margin: 0.5em 0;
    }

    a {
      display: inline-block;
      text-decoration: none;
      transition: font-weight 0.2s ease;
    }

    .active a {
      font-weight: 700;
    }
  }

  #footermenu {
    font-size: 0.8em;

    a {
      color: $black;
      transition: color 0.2s ease;

      &:hover, &:active {
        color: $turquoise;
      }
    }
  }

  #legalmenu {
    margin: 22px 0 7px;
    font-size: 0.7em;

    a {
      color: $darkturquoise;
      text-transform: uppercase;
      transition: color 0.2s ease;

      &:hover, &:active {
        color: $black;
      }
    }
  }

  .social {
    p {
      margin-bottom: 0.6em;
    }

    strong {
      text-transform: uppercase;
    }

    ul {
      margin-bottom: 0.6em;
      list-style-type: none;

      li {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 15px;
        }

        a {
          background: {
            image: url('/images/sprite_social.png');
            repeat: no-repeat;
          };
          width: 48px;
          height: 48px;
          display: block;
          white-space: nowrap;
          text-indent: 48px;
          overflow: hidden;
          transition: filter 0.2s ease;

          &:hover, &:active {
            filter: brightness(90%);
          }
        }

        &.linkedin a {
          background-position: 0 0;
        }

        &.facebook a {
          background-position: 0 -78px;
        }
      }
    }
  }

  .copyright, .conception {
    font-size: 0.7em;
  }

  .copyright {
    margin-bottom: 0.6em;
  }

  .conception a {
    font-weight: bold;
    color: $turquoise;
    transition: color 0.2s ease;

    &:hover, &:active {
      color: $darkturquoise;
    }
  }
}

/*** Styles de la page d'accueil **************************************************/
.home {
  #main {
    position: relative;
    background-image: url('/images/bg_small.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding-top: 220px;

    .inner {
      //padding: 0 50px;
    }

    .title {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.4em;
      font-weight: 400;
      letter-spacing: -0.05em;
      color: $black;

      & > div {
        flex-basis: 100%;
        max-width: 100%;
      }

      .number {
        display: inline;
        font-size: 1.6em;
      }

      .lines {
        display: inline;

        p {
          margin-bottom: 0.5em;

          &:first-of-type {
            display: inline;
          }
        }
      }
    }

    .typeuser {
      padding-bottom: 240px;
      position: relative;
      z-index: 5;

      & > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .bubble {
        display: inline-block;
        flex-basis: 96%;
        max-width: 96%;
        flex-grow: 0;
        position: relative;
        margin-top: 1em;
        z-index: 10;
        &.black {
          .content {
            background-color: rgba($black, 0.8);

            &:hover {
              background-color: $black;
            }
          }
        }

        &.aqua {
          .content {
            background-color: rgba($turquoise, 0.8);

            &:hover {
              background-color: $turquoise;
            }
          }
        }

        &.purple {
          .content {
            background-color: rgba($mauve, 0.8);

            &:hover {
              background-color: $mauve;
            }
          }
        }

        &.green {
          .content {
            background-color: rgba($green, 0.8);

            &:hover {
              background-color: $green;
            }
          }
        }

        //Donne le ratio d'aspect 1:1
        &::before {
          content: "";
          display: none;
          margin-top: 100%;
        }

        &.disabled {
          opacity: 0.3;
        }

        .icon {
          display: none;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 10%;
          width: 72px;
          height: 59px;
          background-image: url('/images/sprite_icon.png');
          background-position: 0 -4771px;
        }

        .content {
          border: 6px solid $white;
          text-align: center;
          border-radius: 1em;
          transition: background-color 0.2s ease;

          span.noa, a {
            display: block;
            color: white;

            &, &:link, &:visited {
              text-decoration: none;
            }

            .aContent {
              display: block;
              padding: 1.4em 8% 3.2em 8%;

              & > span {
                display: block;
              }
            }
          }

          //La flèche pour inciter à cliquer
          .clickHere {
            text-decoration: underline;
            display: block;
            position: absolute;
            width: 125px;
            bottom: 15px;
            left: 50%;
            margin-left: -62.5px;
            text-align: center;
            line-height: 1.2;
            font-size: 1em;
            font-weight: bold;
            color: #ffffff;
          }

          .typeTitle {
            line-height: 1.4em;

            .titleLine {
              display: block;
              white-space: nowrap;
            }

            .fragment {
              &.xlarge {
                font-size: 2em;
                font-weight: 700;
              }

              &.large {
                font-size: 1.4em;
                font-weight: 700;
              }

              &.small {
                font-size: 0.8em;
              }
            }

          }

          .typeSubtitle {
            //margin-top: -1em;
            line-height: 1.2em;
            font-size: 1.4em;
            font-style: italic;
          }
        }
      }
    }

    .linkBar {
      position: absolute;
      z-index: 50;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $black;
      opacity: 0.9;

      .inner {
        padding: 0.7em 0;
        margin: 0 auto;
        width: 100%;
        max-width: 1300px;
        display: flex;
        flex-wrap: wrap;
      }

      .text {
        display: block;
        width: 100%;
        text-align: center;
        padding: 5px 20px;

        h3 {
          color: $white;
          margin: 0;
          font-size: 1em;
        }
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
        max-width: 100%;

        &:first-of-type {
          position: relative;
          padding-bottom: 1em;

          a {
            &::after {
              content: '';
              display: block;
              position: absolute;
              left: 50%;
              bottom: -1.5px; //La moitié de la largeur de la bordure, pour être bien centré
              width: 3em;
              max-width: 90%;
              height: 0;
              border-top: 3px solid $white;
              transform: translateX(-50%);
            }
          }
        }

        &:not(:first-of-type) {
          margin-top: 1em;
        }

        a {
          display: inline-block;
          padding: 0.6em 1.6em;
          border: 1px solid $white;
          border-radius: 0.5em;
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;
          text-align: center;
          transition: background-color 0.2s ease, color 0.2s ease;

          &, &:link, &:visited {
            color: $white;
          }

          &:hover, &:active {
            color: $black;
            background-color: $white;
          }
        }
      }
    }
  }

  #videoBlock {
    padding: 9em 0 7em;
    overflow: hidden;
    background-color: $lightgray;

    .wrap {
      position: relative;
      z-index: 10;
      display: flex;
      flex-wrap: wrap;

      //Faux background blanc
      &::after {
        content: '';
        position: absolute;
        z-index: -10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $white;
        transform: translate(0, -1.5em);
      }
    }

    .videoCol {
      flex-basis: 100%;
      max-width: 100%;
      flex-grow: 1;
      align-items: top;

      .outerVideo {
        max-width: 600px;
      }
    }

    .videoDescription {
      flex-basis: 100%;
      max-width: 100%;
      padding-left: 4%;
      padding-top: 1em;

      h2 {
        font-size: 1.8em;

        & > span {
          display: block;
          line-height: 1em;
        }

        .mainTitle {
          font-size: 1.4em;
          font-weight: 800;
          font-style: italic;
          color: $turquoise;
        }

        .subtitle {
          font-style: italic;
        }
      }

      .entryContent, .entry-content {
        padding-right: 2em;
        line-height: 1.4em;
        margin-bottom: 1em;
      }
    }

    .joinButton {
    }
  }

  #howitwork {
    padding: 5em 0;

    a {
      color: $turquoise;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    header {
      display: flex;
      justify-content: center; // align horizontal
      align-items: center; // align vertical
    }

    h2 {
      font-size: 2.2em;
      font-weight: 800;
      font-style: italic;
      line-height: 1.3;
      display: inline-block;
      padding-right: 4px;
      margin: 0;
      padding-bottom: 0.3em;
      position: relative;
      //-webkit-text-fill-color: rgba(0, 0, 0, 0);
      & > span {
        display: block;
        padding-right: 0.2em;
        //background-color: transparent;
        color: $turquoise;
        //background: -webkit-linear-gradient(right, $green, $turquoise); //Background Webkit seulement, car seul lui supporte background-clip: text;
        //background: -o-linear-gradient(transparent, transparent); //Empêcher Opera de prendre le background de Webkit en ne supportant pas background-clip: text;
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
      }

      .indent {
        text-indent: 1em;
        margin-top: -0.9em;
      }

      strong {
        font-size: 1.4em;
      }
    }

    .intro {
      //width: 80%;
      text-align: justify;
      margin-bottom: 1.5em;
      margin: 2em auto 0;

      h3 {
        font-size: 1.5em;
      }

      p {
        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
    }

    .steps {
      .step {
        position: relative;
        margin-bottom: 25px;

        .image {
          width: 100%;

          img {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: auto;
          }
        }

        .content {
          //width: 30%;
          //padding-top: 1.5em;
          //position: relative;
        }

        .title {
          line-height: 1;

          hr {
            max-width: 5em;
            border-width: 0 0 2px 0;
            border-style: solid;
            border-color: $lightgray;
            text-align: left;
            margin: 1em 0;
          }

          .thestep {
            font-size: 1.4em;
            font-weight: 400;
            font-style: italic;
          }

          .no {
            font-size: 4em;
            font-weight: 800;
            display: block;
          }
        }

        .candidate, .employer {
          font-size: 0.9em;
          font-weight: 700;

          strong {
            font-size: 1.4em;
            font-weight: 800;
            text-transform: uppercase;
            display: block;
          }
        }

        .candidate strong {
          color: $turquoise;
        }

        .image, .content {
          float: left;
        }

        .content {
          padding-right: 1em;

          .title .no {
            color: $darkgreen;
          }
        }
      }
    }
  }

  #blog {
    background-color: $black;
    color: white;
    padding: 3em 0;

    .wrap > header {
      line-height: 1;
      margin-bottom: 3em;

      hr {
        max-width: 5em;
        border-width: 0 0 6px 0;
        border-style: solid;
        border-color: $green;
        text-align: left;
        margin: 1em 0;
      }
    }

    h2 {
      font-size: 3em;
      font-weight: 800;
      font-style: italic;
      color: $white;
      line-height: 1.3;
      display: inline-block;
      padding-right: 0.2em;
      margin: 0;
    }

    .blogpost {
      &:not(:first-child) {
        margin-top: 2em;
      }

      //Retraités
      &.postCategory-269 {
        .content {
          h4 {
            color: $turquoise;

            a {
              &, &:link, &:visited {
                color: $turquoise;
              }

              &:hover, &:active {
                color: lighten($turquoise, 10%);
              }
            }
          }

          .entryContent .excerpt-read-more, .entry-content .excerpt-more {
            &, &:link, &:visited {
              color: $turquoise;
            }

            &:hover, &:active {
              color: lighten($turquoise, 10%);
            }
          }
        }
      }

      //Employeurs
      &.postCategory-270 {
        .content {
          h4 {
            color: $mauve;

            a {
              &, &:link, &:visited {
                color: $mauve;
              }

              &:hover, &:active {
                color: lighten($mauve, 10%);
              }
            }
          }

          .entryContent .excerpt-read-more, .entry-content .excerpt-read-more {
            &, &:link, &:visited {
              color: $mauve;
            }

            &:hover, &:active {
              color: lighten($mauve, 10%);
            }
          }
        }
      }

      .content {
        .entryContent .excerpt-read-more, .entry-content .excerpt-read-more {
          &, &:link, &:visited {
            color: rgba($white, 0.6);
          }

          &:hover, &:active {
            color: $white;
          }
        }
      }
    }


    .postCategories li {
      &.category-269 {
        a {
          &, &:link, &:visited {
            color: $white;
            background-color: $mauve;
          }

          &:hover, &:active {
            background-color: lighten($mauve, 10%);
          }
        }
      }

      &.category-270 {
        a {
          &, &:link, &:visited {
            color: $white;
            background-color: $mauve;
          }

          &:hover, &:active {
            background-color: lighten($mauve, 10%);
          }
        }
      }

      a {
        transition: background-color 0.2s ease, color 0.2s ease;

        &, &:link, &:visited {
          background-color: $white;
          color: $midgray;
        }

        &:hover, &:active {
          background-color: rgba($white, 0.9);
          color: $black;
        }
      }
    }

    .image {
      display: block;
      margin-right: 0;
      margin-bottom: 1em;

      img {
        border: 1px solid lighten($black, 17%);
        max-width: 480px;
      }
    }

    time {
      font-size: 1.1em;
      font-weight: 700;
      font-style: italic;
      color: $white;
      line-height: 1;
    }

    h4 {
      font-size: 1.6em;
      font-weight: 700;
      font-style: italic;
      line-height: 1;
      margin: 0;
      margin-bottom: 0.2em;

      a {
        color: rgba($white, 0.6);
        hyphens: auto;
        transition: color 0.2s ease;

        &:hover, &:active {
          color: $white;
        }
      }
    }

    .excerpt-read-more {
      text-decoration: none;

      &:hover, &:active {
        text-decoration: underline;
      }
    }

    .allposts {
      margin-top: 2em;
    }
  }

  #infos {
    padding: 5em 0;

    ul.infos {
      display: flex;
      justify-content: space-around; // align horizontal
      align-items: flex-start; // align vertical
      flex-wrap: wrap;

      li {
        //width: 30%;
        font-weight: 700;
        text-align: center;
        margin-bottom: 2em;

        &:nth-child(odd) {
          h5 {
            color: $turquoise;
          }
        }

        &:nth-child(even) {
          h5 {
            color: $darkturquoise;
          }
        }

        a {
          text-decoration: none;
          color: $mauve;
          font-style: italic;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .icon {
      height: 130px;
      margin-bottom: 2em;

      .theIcon {
        display: inline-block;
        height: 130px;
        width: 100%;
        background-image: url('/images/sprite_icon.png');
        background-repeat: no-repeat;
      }

      &.communaute .theIcon {
        background-position: 0 0;
        width: 126px;
        height: 116px;
      }

      &.securite .theIcon {
        background-position: 0 -431px;
        width: 98px;
        height: 126px;
      }

      &.partout .theIcon {
        background-position: 0 -882px;
        width: 74px;
        height: 126px;
      }
    }

    h5 {
      font-size: 1.4em;
      font-weight: 800;
      line-height: 1;
      //margin: 0;
      text-transform: uppercase;
    }
  }
}

.emploiretraite-view-jobs-button {
  margin-top: 1em;
  text-align: center;
}

/*** Styles des formulaires **************************************************/
.msg {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.2em;
  padding: 1em 1.5em 1.3em;
  font-size: 0.8em;
  font-weight: 700;
  border: 1px solid $black;
  border-radius: 0.3em;
  width: 100%;

  &.msgBig {
    font-size: 1em;
    font-weight: 400;
  }

  &.msg-error {
    border-color: $bad;
    color: $bad;

    & > .icon {
      background-position: 0 -806px;
    }

    a {
      transition: color 0.2s ease;

      &, &:link, &:visited {
        color: $bad;
      }

      &:hover, &:active {
        color: $black;
      }
    }
  }

  &.msg-notice {
    border-color: $orange;
    color: darken($orange, 10%);

    & > .icon {
      width: 32px;
      height: 28px;
      //Ajustement pour avoir la même largeur que les autres icônes
      border-right: 4px solid transparent;
      background-position: 0 -706px;
    }

    a {
      transition: color 0.2s ease;

      &, &:link, &:visited {
        color: $orange;
      }

      &:hover, &:active {
        color: $black;
      }
    }
  }

  &.msg-success {
    border-color: $correct;
    color: darken($correct, 10%);

    & > .icon {
      background-position: 0 -406px;
    }

    a {
      transition: color 0.2s ease;

      &, &:link, &:visited {
        color: $correct;
      }

      &:hover, &:active {
        color: $black;
      }
    }
  }

  & > .icon {
    display: inline-block;
    margin-right: 1em;
    width: 28px;
    height: 28px;
    background-image: url('/images/sprite_form.png');
    background-repeat: no-repeat;
  }

  ul {
    margin: 0;
    margin-top: 0.2em;
    padding: 0;
    list-style-type: disc;
  }

  li {
    margin: 0 0 0 0;
    padding: 0;
    margin-left: 1em;
    list-style-type: disc;
    line-height: 1.4em;

    &:only-child {
      margin-left: 0;
      list-style-type: none;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.4em;
    }
  }
}

#main, .mfp-wrap {
  li.formRow {
    list-style-type: none;
  }

  .validForm {
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px $white inset;
    }

    .required:not(select) {
      background: url(/images/sprite_form.png) right -989px no-repeat;
      padding-right: 45px !important;
    }

    select.required {
      background: url(/images/sprite_form.png) calc(100% - 29px) -989px no-repeat, url(/images/sprite_form.png) right -84px no-repeat;
      padding-right: 80px;
    }

    select.badSelect {
      border: 1px solid $red !important;
      background: url(/images/sprite_form.png) calc(100% - 29px) -1430px no-repeat, url(/images/sprite_form.png) right -84px no-repeat;
    }

    select.correctSelect {
      background: url(/images/sprite_form.png) calc(100% - 29px) -1664px no-repeat, url(/images/sprite_form.png) right -84px no-repeat;
    }

    .bad {
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px lighten($red, 60%) inset;
      }

      .label, {
        color: $red !important;
      }

      input, textarea {
        border: 1px solid $red !important;
      }

      .required:not(select) {
        background: url(/images/sprite_form.png) right -1430px no-repeat;
      }
    }

    .correct {
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px lighten($green, 20%) inset;
      }

      .required:not(select) {
        background: url(/images/sprite_form.png) right -1664px no-repeat;
      }
    }
  }

  .formRow {
    margin-bottom: 0.8em;

    .radio {
      display: inline-block;

      //Tous les radios sauf le premier
      & ~ .radio {
        margin-left: 0.8em;
      }
    }

    div.passwordStrength {
      position: relative;

      a.button_strength {
        display: none;
      }

      div.strength_meter {
        position: absolute;
        top: 28px;
        right: 50px;
        color: $turquoise;

        .veryweak {
          color: $red;
          font-weight: normal;
        }

        .weak {
          color: $red;
          font-weight: bold;
        }

        .medium {
          color: $green;
          font-weight: normal;
        }

        .strong {
          color: $green;
          font-weight: bold;
        }
      }
    }
  }

  .multiRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    &.threeRows {
      & > select, & > input {
        &:not(:last-child) {
          margin-bottom: 0.8em;
        }
      }

      .separator {
        display: none;
      }
    }

    &.fakeRowEnd {
      &::after {
        content: '';
        display: block;
        flex-basis: 50%;
        max-width: 50%;
        align-self: stretch;
      }

      &.threeRows {
        &::after {
          flex-basis: 33.333%;
          max-width: 33.333%;
        }
      }
    }

    &.fakeRowStart {
      &::before {
        content: '';
        display: block;
        flex-basis: 50%;
        max-width: 50%;
        align-self: stretch;
      }

      &.threeRows {
        &::after {
          flex-basis: 33.333%;
          max-width: 33.333%;
        }
      }
    }

    & > * {
      flex-basis: 100%;
      max-width: 100%;

      &:not(:last-child) {
        //margin-right: 0.8em;
      }
    }

    .separator {
      flex-shrink: 99;
      align-self: center;

      span {
        display: inline-block;
        transform: scale(1.6);
      }
    }

    //multiRow dans un multiRow
    .multiRow {
      flex-wrap: nowrap;

      & > * {
        flex-basis: 50%;
        max-width: 50%;

        &:not(:last-child) {
          margin-right: 0.8em;
        }
      }
    }
  }

  .formRowParent {
    padding: 6px 30px 10px;
    border: 1px solid $turquoise;
    border-radius: 5px;
    margin-bottom: 4px;

    &.checkbox > input[type="checkbox"] + label {
      display: block;
    }
  }

  .twoCols {
    .rows, fieldset {
      display: flex;
      justify-content: space-between; // align horizontal
      align-items: flex-start; // align vertical
      flex-wrap: wrap;

      .formRowParent {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }

  .formRowChild {
    padding-left: 2.3em;

    .formRow {
      margin-bottom: 0;
    }
  }

  .title {
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 1em;
    margin-bottom: 1em;
  }

  p.notice {
    margin-bottom: 1em;
  }

  .label {
    display: block;
    margin: 0;
    font-size: 0.9em;
    font-weight: 700;
    color: $turquoise;
    //text-transform: uppercase;
  }

  .label-inline {
    display: inline-block;
    margin-right: 1em;
  }

  .label-inline + input {
    max-width: 80% !important;
  }

  // Style des bande ET / OU
  .or, .formRow .or {
    margin: 2em 0;
    border-width: 4px 0 0;
    border-style: solid;
    border-color: $green;
    height: 0;
    max-width: 80%;
    color: $turquoise;
    text-transform: uppercase;

    strong {
      background-color: white;
      display: inline-block;
      padding: 0 1em;
      font-size: 1.4em;
      font-weight: 800;
      font-style: italic;
      top: -0.9em;
      position: relative;
      margin-left: 1.5em;
    }
  }

  .instruction {
    font-size: 0.9em;
    font-weight: 400;
    font-style: italic;
  }

  small {
    font-size: 0.7em;
    font-weight: 400;
    font-style: italic;
    display: block;
    text-transform: none;
  }

  .fhp {
    display: none;
  }

  input:not([type="submit"]):not([type="reset"]):not([type="button"]), textarea, select {
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  input:not([type="submit"]):not([type="reset"]):not([type="button"]), textarea {
    &:read-only {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]), textarea, select {
    border: 1px solid $turquoise;
    font-family: $fontBase;
    font-size: 1em;
    font-weight: 400;
    color: $black;
    padding: 5px 15px;
    border-radius: 0;
    width: 100%;
    box-sizing: border-box;
    outline: none;
  }

  input[type=file], select, textarea {
    width: 100%;
  }

  textarea {
    min-height: 10em;
  }

  .formRowError {
    //background-color: red;
    input, select, textarea, .checkbox label::before, .radio label::before {
      border: 1px solid $red !important;
    }

    label, .label {
      color: $red;
    }
  }

  /* Style des compteurs de caractères des textarea */

  .wrapCharCounter {
    display: flex;
    justify-content: space-between;

    &.tooLong {
      .curChars {
        color: $red;
      }
    }

    .separator, .maxChars {
      color: $turquoise;
    }

    .curChars {
      margin-right: 0.2em;
      font-size: 1.2em;
      color: $midgray;
    }
  }

  /* Style des liens spéciaux de formulaire (Ajouter une expérience, supprimer une expérience) */

  .addXP, .remove a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover, &:active {
      color: $darkturquoise;
    }

    .plus, .x {
      font-size: 2em;
      margin-right: 0.2em;
    }
  }

  .remove {
    display: block;
    text-align: right;

    a {
      &, &:link, &:visited {
        color: $red;
      }

      &:hover, &:active {
        color: darken($red, 15%);
      }
    }
  }

  /* Style des fieldset */
  fieldset {
    width: 100%;
    border: 0 none;
    padding: 15px 0;
    margin-bottom: 1.5em;

    legend {
      font-size: 1.1em;
      font-weight: 700;
      color: $black;
      text-transform: uppercase;
    }
  }

  /* Style des select */
  select:not(.picker__select--year):not(.picker__select--month) {
    background: url(/images/sprite_form.png) right -84px no-repeat;
    border-radius: 0;
    min-height: 34px;
    appearance: none;
    padding: 4px 42px 4px 4px;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }

    option {
      line-height: 1.4em;
    }
  }

  .buttons {
    margin-top: 1.5em;
    margin-bottom: 3em;
    padding-top: 1.5em;
    border-top: 2px solid $lightgray;
    text-align: right;

    &.buttonsLeft {
      text-align: left;
    }

    .button, .buttonLink, .buttonAlt {
      &:not(:first-child) {
        margin-left: 1.5em;
      }
    }

    .buttonLine {
      &:not(:last-of-type) {
        margin-bottom: 1em;
      }

      .connexionDroite {
        display: inline-block;
        margin-left: 1.5em;
        position: relative;
        top: 8px;

        .buttonLink {
          display: block;
          padding: 0;
          margin: 0;
          line-height: 1.2;
        }
      }
    }

    &.formTop {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      border-top: none;
      border-bottom: 2px solid $lightgray;
    }
  }

  .buttonSave {
    background: {
      image: url(/images/sprite_form.png);
      position: 17px -156px;
      repeat: no-repeat;
    };
    padding-left: 58px;
    margin-top: 8px;
  }

  /* Styles des checkbox */
  .checkbox {
    &.withOtherField {
      .otherField {
        margin-left: 20px;
        padding-left: 0.5em;
      }
    }

    & > input[type=checkbox] {
      display: none;
    }

    & > input[type=checkbox] + label {
      display: inline-block;

      &:not(.long) {
        line-height: 40px;
      }

      &.long {
      }

      &:hover {
        cursor: pointer;
      }
    }

    & > input[type=checkbox] + label::before {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid $black;
      border-radius: 2px;
      box-shadow: 1px 1px 1px 0px rgba($black, 0.5);
      display: inline-block;
      margin-right: 0.5em;
      position: relative;
      top: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    & > input[type=checkbox]:checked + label::before {
      content: "";
      background: url(/images/sprite_form.png) 1px 1px no-repeat;
    }
  }

  /* Styles des radios */
  .radio {
    & > input[type="radio"] {
      display: none;
    }

    & > input[type="radio"] + label {
      display: inline-block;
      line-height: 40px;

      &:hover {
        cursor: pointer;
      }
    }

    & > input[type="radio"] + label::before {
      content: "";
      width: 18px;
      height: 18px;
      border: 1px solid $black;
      border-radius: 50%;
      box-shadow: 1px 1px 1px 0px rgba($black, 0.5);
      display: inline-block;
      margin-right: 0.5em;
      position: relative;
      top: 4px;

      &:hover {
        cursor: pointer;
      }
    }

    & > input[type="radio"]:checked + label::before {
      content: "";
      background: url(/images/sprite_form.png) 2px -33px no-repeat;
    }
  }

  /* Styles des checkbox "Secteurs" */
  .sectors {
    display: flex;
    justify-content: flex-start; // align horizontal
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .sectorCheckbox, .categoryCheckbox {
    flex-basis: 100%;
    max-width: 100%;

    > input[type="checkbox"] {
      display: none;
    }

    & > input[type="checkbox"] + label,
    & > input[type="checkbox"] + .label,
    & > label,
    & > .label {
      display: inline-block;
      border: 4px solid white;
      position: relative;
      cursor: pointer;
      transition: border-color 0.2s ease;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &:hover, &:active {
        border-color: rgba($turquoise, 0.7);
      }
    }

    label, .label {
      position: relative;
      width: 100%;

      span {
        background-color: rgba($white, 0.7);
        width: 100%;
        min-height: 4.6em;
        display: block;
        padding: 0.8em 5px;
        font-size: 0.85em;
        font-weight: 700;
        color: $black;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center; // align horizontal
        align-items: center; // align vertical
        transition: background-color 0.2s ease, color 0.2s ease;
      }
    }


    & > input[type="checkbox"]:checked {
      & + label, & + .label {
        border-color: $turquoise;

        span {
          background-color: rgba($turquoise, 0.7);
          color: white;
        }

        .visual-checkbox {
          .checkmark {
            opacity: 1;
          }
        }
      }
    }

    .visual-checkbox {
      display: inline-block;
      vertical-align: top;
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 1px;
      border: 1px solid $black;
      border-radius: 2px;
      box-shadow: 1px 1px 1px 0px rgba($black, 0.5);
      background-color: $white;

      .checkmark {
        position: relative;
        z-index: 10;
        display: block;
        opacity: 0;
        vertical-align: top;
        width: 18px;
        height: 18px;
        background-image: url('/images/sprite_form.png');
        background-repeat: no-repeat;
        background-position: 0 -1206px;
        transition: opacity 0.2s ease;
      }
    }
  }

  .notice {
    font-size: 0.9em;
    color: $turquoise;
    font-style: italic;
  }

  /*.single-dropzone {
		.dz-image-preview, .dz-file-preview {
			display: none;
		}
	}*/

  .fbconnect {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1em;
    padding: 0.8em 1.2em;
    text-align: left;
    font-size: 1em;
    font-weight: 700;
    text-decoration: none;
    border: 1px solid #254892;
    border-radius: 0.5em;
    color: white;
    background-color: #3d5a97;

    &:hover, &:active {
      background-color: #254892;
      cursor: pointer;
    }

    .inner {
      display: inline-flex;
    }

    .fbIcon {
      margin-right: 1em;

      .icon {
        display: block;
        width: 21px;
        height: 21px;
        text-indent: -99999px;
        background-image: url(/images/sprite_social.png);
        background-position: 0 -372px;
        background-repeat: no-repeat;
      }
    }


    .text {
      line-height: 1.2em;
    }
  }

  .security {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 3em;
    // margin-bottom: 3em;
    font-size: 0.9em;
    font-weight: 700;

    & > * {
      flex-basis: 100%;
      max-width: 100%;
    }

    .padlock {
      margin-bottom: 1.2em;

      .icon {
        display: block;
        width: 98px;
        height: 126px;
        margin: auto;
        background-image: url(/images/sprite_icon.png);
        background-position: left -431px;
        background-repeat: no-repeat;
      }
    }

    h5 {
      margin-bottom: 0.2em;
      font-size: 1.2em;
      //text-align: center;
      font-style: italic;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }


    a {
      font-style: italic;
      color: $turquoise;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

body:not(.account) {
  .leftCentered {
    margin: 0 auto;
    width: 100%;
    max-width: 575px;

    &.thin {
      max-width: 325px;
    }

    &.mWide {
      max-width: 600px;
    }

    &.wide {
      max-width: 700px;
    }

    &.xWide {
      max-width: 800px;
    }

    .or {
      max-width: 100% !important;
    }
  }
}

/*** Styles spécifiques à certaines pages, templates / modèles de page ou étapes du formulaire d'inscription */

.register.step1, .register.step4b_2 {
  #main .inner {
    max-width: 900px;
  }
}

.register.step2 {
  #main .inner {
    max-width: 1000px;
  }
}

.register.step3 {
  #main {
    .sectors {
      .visual-checkbox {
        display: none;
      }

      .formRow.sectorCheckbox {
        flex-basis: 49%;
        max-width: 49%;
        margin-bottom: 0;

        label {
          cursor: auto;
          transition: none;

          &:hover {
            border-color: $white;
          }
        }

        span {
          font-size: 0.55em;
        }
      }
    }
  }
}

.register.step3, .register.step6 {
  #main .inner {
    max-width: 900px;
  }
}

.register.step5 {
  #main .inner {
    max-width: 800px;
  }
}

.register.step5_2, .register.step5_3, .register.step7 {
  #main .inner {
    max-width: 800px;
  }
}

.register.summary {
  .entryContent, .entry-content {
    strong {
      font-style: italic;
      font-weight: 700;
      //color: $turquoise;
    }
  }
}

.publishOffer.stepOffer {
  #main .inner {
    max-width: 900px;
  }
}

.publishOffer.stepAccount {
  #main .inner {
    max-width: 900px;
  }
}

/*** Styles des pages du template pricing (tarification / forfaits) **************/
/*
#main {
	.packages {
		.packageGroup {
			&:not(:last-of-type) {
				margin-bottom: 3em;
			}

			table {
				width: 100%;
				border-collapse: separate;

				&, thead, tbody, tr, td, th {
					display: block;
				}

				.extraInfo {
					display: inline-block;
					vertical-align: middle;

					.trigger {
						background-color: transparent;
						padding: 0;
						text-transform: none;
						border-radius: 0;

						&, * {
							display: inline-block;
							vertical-align: top;
						}

						&:hover, &:active {
							.icon {
								background-color: $darkturquoise;
							}
						}

						.icon {
							position: relative;
							width: 1.6em;
							padding-bottom: 100%;
							border-radius: 100%;
							color: $white;
							background-color: $turquoise;
							transition: background-color 0.2s ease;

							.btnContent {
								position: absolute;
								left: 0;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								text-align: center;
								//font-size: 0.9em;
								font-weight: 700;
							}
						}
					}
				}

				h2, h3 {
					display: inline-block;
					margin-right: 0.5em;
					vertical-align: middle;
				}

				thead {
				}

				th {
					padding: 0 0.4em;
					border-bottom: 2px solid $turquoise;
					font-size: 1em;
					text-align: left;

					&.title {
						padding-bottom: 0.2em;
						background-color: transparent;

						& > h2 {
							margin-bottom: 0.1em;
							margin-top: 0.2em;
							font-size: 2.2em;
							font-weight: 800;
						}

						.shortDescription {
							margin: 0;

							p {
								margin-bottom: 0;
								font-weight: 400;
							}
						}
					}

					&:not(.title) {
						position: relative;
						display: none;
						//De la place pour la ligne qui est un ::after
						padding-bottom: 1px;
						text-align: center;
						background-color: $turquoise;
						color: $white;

						&::after {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							display: block;
							border-top: 1px solid $white;
						}

						&::before {
							//Fix pour la border-right qui marche semi
							content: '';
							display: block;
							position: absolute;
							left: -2px;
							top: 0;
							bottom: -2px;
							border-right: 2px solid $white;
						}

						&:not(:last-of-type) {
							border-right: 2px solid $white;
						}

						.icon {
							display: inline-block;
							vertical-align: middle;
							width: 44px;
							height: 44px;
							margin-right: 0.5em;
							background-image: url('/images/sprite_icon.png');
						}

						.text {
							display: inline-block;
							vertical-align: middle;
							font-size: 1.4em;
						}
					}

					&.duration {
						.icon {
							background-position: 0 -1700px;
						}
					}

					&.cost {
						.icon {
							background-position: 0 -1500px;
						}
					}
				}

				tbody {
					tr {
						padding: 0.4em 0;
						background-color: $lightgray;

						&:nth-of-type(even) {
							background-color: darken($lightgray, 7%);
						}

						&:not(:last-of-type) {
							border-bottom: 2px solid $white;
						}
					}

					td {
						padding: 0 0.4em;

						&:not(:last-of-type) {
							margin-bottom: 0.2em;
						}

						&.title {
							font-size: 1em;
						}

						.mobileTitle {
							margin-bottom: 0;
							margin-right: 0.5em;

							&, & ~ * {
								display: inline-block;
							}

							&::after {
								content: ':';
								display: inline;

							}
						}

						&.cost {
							.mobileTitle {
								margin-right: 1em;
							}

							.content {
								font-size: 1.2em;
								font-weight: 800;
								letter-spacing: 0.02em;
								color: $turquoise;
							}

							.perUnit {
								font-size: 0.7em;
								letter-spacing: 0.04em;
							}
						}

						h3 {
							font-size: 1em;
							font-weight: bold;
							text-transform: none;
							color: $black;
						}
					}
				}
			}

			.buttons {
				margin: 0;
				padding: 0;
				border: 0;
				margin-top: 1em;
			}
		}
	}
}

.pageTemplate-pricing .innerPopup .entryContent, .pageTemplate-pricing .innerPopup .entry-content {
	h2, h3 {
		display: inline-block;
	}

	h2 {
		font-weight: 400;
		margin-right: 0.5em;
	}

	h3 {
		font-size: 1.4em;
		color: $turquoise;
	}
}
*/

/*** Styles des listings de forfaits (Tarification -> Entreprises/Particuliers) (Page de la comparaison des avantages) ************/

body.pricePlans #main {
  .inner {
    & > div {
      &:not(:last-child) {
        margin-bottom: 3em;

        &.intro {
          margin-bottom: 2em;
        }
      }
    }

    div.yourPlan {
      margin-bottom: 1em;
    }

    .planGroup {
      &.monthlyPlans {
        table {

          thead {
            tr:last-of-type {
              th {
                vertical-align: bottom;
              }
            }
          }
        }
      }

      &.aLaCartePlans {
        table {
          thead {
            display: none;
          }

          td, th {
            padding: 0;
            padding-bottom: 1em;
            vertical-align: middle;

            &, &:not(:first-child) {
              border: none;
            }

            &:not(:last-child) {
              padding-right: 0.5em;
            }
          }

          h3 {
            font-size: 1.4em;
          }

          .subtitle {
            font-style: italic;
            font-weight: 700;
            font-size: 0.9em;
          }

          .price {
            font-size: 1.6em;
            font-weight: 700;

            .number, .symbol {
              // font-weight: 700;
            }

            .suffix {
              font-size: 0.6em;
              color: $turquoise;
            }
          }

          .description {
            font-size: 0.9em;
          }

          tbody {
            &:not(:last-of-type) {
              border-bottom: 2px solid darken($lightgray, 15%);

              .button {
                td {
                  padding-bottom: 2em;
                }
              }
            }

            &:not(:first-of-type) {
              tr:first-of-type {
                td {
                  padding-top: 2em;
                }
              }
            }
          }

        }
      }

      .mobileAdvantages {
        margin-bottom: 2em;

        .plan {
          &:not(:last-of-type) {
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: 2px solid darken($lightgray, 15%);
          }

          & > header {
            h3 {
              margin-bottom: 0.6em;
            }

            .subtitle {
              p {
                margin-bottom: 0;
              }

              strong {
                font-size: 1.4em;

                sub {
                  position: static;
                  display: inline-block;
                  font-size: 0.7em;
                  vertical-align: baseline;
                }
              }
            }

            .extraInfo {
              margin-top: 0.4em;
            }
          }

          .advantages {
            margin-top: 0.6em;
          }

          .advantage {
            display: flex;
            margin-bottom: 0.6em;

            .advantageCheckmark {
              flex-grow: 0;
              padding-right: 0.5em;

              .checkmark {
                display: inline-block;
                width: 28px;
                height: 28px;
                text-indent: -99999px;
                background-image: url('/images/sprite_icon.png');
                background-repeat: no-repeat;

                &.checked {
                  background-position: 0 -4571px;
                }

                &.notChecked {
                  background-position: 0 -4671px;
                }
              }
            }

            .advantageText {
              padding-top: 2px;
              font-size: 0.95em;
            }
          }
        }
      }

      .fullAdvantages {
        display: none;
      }


      .buttonWrapper.free {
        display: none;
      }

      .intro {
        margin-bottom: 2em;
      }

      .planTable {
      }

      table {
        position: relative;
        z-index: 10;

        th, td {
          vertical-align: top;
          padding-bottom: 1em;
          padding-left: 0.5em;
          padding-right: 0.5em;

          &:not(:first-child) {
            border-left: 2px solid $turquoise;
          }

          &.highlighted {
            position: relative;
            color: $white;
            background-color: $turquoise;
            border-bottom: none;
            border-left-color: $turquoise;
            background-clip: border-box;

            h3 {
              color: $white;
            }

            button {
              border-color: $white;
              color: $white;

              &:hover, &:active {
                background-color: $white;
                color: $turquoise;
              }
            }

            .roundTrigger {
              background-color: $white;
              color: $turquoise;
            }

            .extraInfo {
              display: inline-block;
              margin-top: 0.75em;

              &:hover, &:active {
                cursor: pointer;

                .roundTrigger {
                  background-color: darken($lightgray, 15%);
                }
              }
            }
          }

          &.plan {
            min-width: 20%;
          }

          &.featureText {
            font-weight: bold;
          }

          .cellInner {
            display: block;
          }
        }

        tr {
        }

        thead {
          tr {
            border-bottom: 0;

            &:first-of-type {
              td, th {
                padding-bottom: 0;
                padding-top: 0.5em;
                border-top: 35px solid transparent;

                &.highlighted {
                  // margin-top: -35px;
                  border-top-color: $turquoise;
                }

                &:not(.highlighted) {
                  position: relative;

                  &::before {
                    // Cacher la bordure qui dépasse
                    content: '';
                    display: block;
                    position: absolute;
                    left: -5px;
                    top: -50px;
                    width: 10px;
                    height: 36px;
                    background-color: $white;
                  }
                }
              }
            }

            &:last-of-type {
              th {
                border-bottom: 2px solid $turquoise;

                &.highlighted {
                  border-bottom-color: $white;
                }
              }
            }
          }
        }

        tbody {

          td {
            border-bottom: 2px solid darken($lightgray, 15%);

            &.plan {
              text-align: center;
              vertical-align: middle;

              .icon {
                display: inline-block;
                margin-top: 0.2em;
                text-indent: -99999px;
                width: 35px;
                height: 25px;
                background-image: url('/images/sprite_icon.png');
                background-repeat: no-repeat;
                background-position: 0 -4471px;
              }
            }

            &.highlighted {
              &.plan {
                .icon {
                  background-position: 0 -4521px;
                }
              }

              &::after {
                content: '';
                position: absolute;
                top: -2px;
                left: -3px;
                right: 0;
                z-index: 30;
                height: 2px;
                background-color: $white;
              }
            }
          }
        }

        tfoot {
          tr {
            &:last-of-type {
              td {
                padding-bottom: 0.5em;
                border-bottom: 35px solid transparent;

                &.highlighted {
                  border-bottom-color: $turquoise;
                }

                &:not(.highlighted) {
                  &::before {
                    // Cacher la bordure qui dépasse
                    content: '';
                    display: block;
                    position: absolute;
                    left: -5px;
                    right: 0;
                    bottom: -36px;
                    top: 100%;
                    background-color: $white;
                  }
                }
              }
            }
          }

          td {
            padding-left: 0.5em;
            padding-right: 0.5em;
            text-align: center;

            &.highlighted {
              &::after {
                content: '';
                position: absolute;
                top: -1px;
                left: -3px;
                right: 0;
                z-index: 30;
                height: 2px;
                background-color: $white;
              }
            }
          }

          /* button {
						text-transform: none;
						background-color: transparent;
						color: $black;
						text-align: center;
						border: 3px solid $turquoise;
						transition: all 0.2s ease;

						&:hover, &:active {
							border-color: $turquoise;
							background-color: $turquoise;
							color: $white;
						}

						.textNonBold, strong {
							display: block;
						}

						.textNonBold {
							font-weight: 400;
							font-style: italic;
						}

						strong {
							text-transform: uppercase;
							line-height: 1.2em;
						}
					} */
        }

        th {
          h3 {
            line-height: 1.1em;
          }

          .subtitle {
            margin-top: 0.4em;
            line-height: 1.3em;
            font-weight: 400;

            p {
              margin-bottom: 0;
            }

            strong {
              font-size: 1.2em;
              line-height: 1.6em;

              sub {
                position: static;
                display: inline-block;
                font-size: 0.7em;
                vertical-align: baseline;
              }
            }
          }

          .extraInfo {
            margin-top: 0.8em;
          }
        }

        td {
          padding-top: 1em;
        }
      }
    }
  }
}

//Tableaux de sélection de durée de forfait

.durationSelect, #main .durationSelect, .durationTable {
  &.durationTable {
    margin-top: 2em;

    .footNotes {
      padding-bottom: 2em;
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: rgba($black, 0.1);
        }
      }
    }
  }

  &.durationSelect {
    table {
      tbody tr {
        cursor: pointer;
      }
    }
  }

  table {
    width: 100%;
    // font-size: 1.2em;

    &:not(:last-child) {
      margin-bottom: 2em;
    }

    thead {
      display: none;
    }

    &, tbody, tfoot, tr, td, th {
      display: block;
    }

    tr {
      position: relative;
    }

    td.radio {
      position: absolute;
      display: inline-block;
      padding: 0;
      left: 0;
      top: 2px;
    }

    td, th {
      vertical-align: middle;
      padding: 0;

      &:not(:last-child):not(.radio) {
        margin-bottom: 1em;
      }
    }

    tr {
      padding: 0.5em 0;

      &:not(:last-child) {
        margin-bottom: 2em;
      }
    }

    th, .mobileTitle {
      text-align: left;
      font-size: 1.2em;
      font-weight: 400;
      color: $turquoise;
      text-transform: uppercase;

      a {
        transition: none;

        &, &:link, &:visited {
          color: $midgray;
        }

        &:hover, &:active {
          color: $black;
          text-decoration: underline;
        }
      }
    }

    .mobileTitle {
      margin-bottom: 0.2em;
    }

    a {
      font-weight: 700;
      transition: color 0.2s ease;
      text-decoration: none;

      &, &:link, &:visited {
        color: $turquoise;
      }

      &:hover, &:active {
        color: $darkturquoise;
      }
    }

    .bigLabel {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;

      &.above {
        z-index: 30;

        &:hover, &:active {
          cursor: pointer;

          & + .bigLabel {
            background-color: rgba($black, 0.1);
          }
        }
      }
    }

    .textLabel {
      position: relative;
      z-index: 20;
    }

    label.textLabel {
      &:hover {
        cursor: pointer;
      }
    }

    .radio {
      width: 1px;

      input[type="radio"]:checked {
        & ~ .bigLabel.below {
          background-color: rgba($turquoise, 0.2);
        }
      }

      input[type="radio"] + label {
        margin-right: 0;

        &::before {
          top: 3px;
          margin-right: 0;
        }
      }
    }

    .duration {
      .textLabel {
        font-size: 1.1em;
      }

      .subtitle {
        display: block;
        font-size: 0.9em;
        font-style: italic;
        color: $turquoise;
      }
    }

    .price {
      .number, .symbol {
        font-size: 1.4em;
        font-weight: bold;
      }

      .suffix {
        font-size: 0.9em;
      }
    }

    .savings {
      &:not(.savings-0) {
        .number {
          color: $turquoise;
        }
      }

      .textLabel {
        font-size: 1.4em;
      }
    }

  }

  .footNotes {
    .footNote {
      display: flex;
    }

    .theStar {
      position: relative;
      bottom: 0.1em;
      margin-right: 0.3em;
      font-size: 1.5em;
      font-weight: bold;
      color: $turquoise;
    }

    p {
      flex-grow: 100;
      font-size: 0.9em;
      font-style: italic;
    }
  }

  .buttons {
    & > button, & > input, & > a {
      &:not(:first-child) {
        margin-left: 0.7em;
      }
    }
  }
}

/*** Tableau de confirmation du prix lors de la commande d'un forfait **************/

.confirmTable, #main .confirmTable, .confirmTable {

  table {

    tbody {
      tr {
        cursor: auto;

        &:hover, &:active {
          background-color: inherit;
        }

        &:nth-of-type(even) {
          background-color: transparent;
        }

        &:nth-of-type(odd) {
          background-color: rgba($black, 0.1);
        }
      }
    }

    tfoot {
      tr {
        &:nth-of-type(even) {
          background-color: rgba($black, 0.1);
        }

        &.lastLine {
          td {
            padding-bottom: 2em;
          }
        }

        &.subtotal {
          font-weight: bold;
        }

        &.total {
          text-transform: uppercase;
          font-weight: bold;
          border-top: 2px solid $turquoise;
          background-color: transparent;

          td {
            padding-top: 1em;
          }
        }
      }
    }
  }
}

/*** Styles de la page Thanks **************************************/

.thanks .entryContent, .thanks .entry-content {
  time {
    font-weight: bold;
  }
}


/*** Styles du template linkHub (pour Publier une offre) ***********/

.pageTemplate-linkHub #main {
  .inner > .entryContent, .inner > .entry-content {
    margin-bottom: 2em;

    h2 {
      text-align: left;
      font-weight: 800;
      font-style: italic;
      color: $turquoise;
    }
  }

  .pageSection {
    margin-bottom: 3em;

    &:not(:last-of-type) {
      &::after {
        content: '';
        display: block;
        border-bottom: 1px solid darken($lightgray, 20%);
        margin-top: 2em;
        margin-bottom: 2em;
      }
    }

    &.hasBubble {
      .mainText {
        order: 2;
      }

      .bubble {
        order: 1;
      }
    }

    &.hasBubble:nth-of-type(odd) {
      .bubble {
      }

      .mainText {
        padding-right: 0;
      }
    }

    .sectionInner {
      display: flex;
      flex-wrap: wrap;
    }

    .mainText {
      flex-basis: 100%;
      max-width: 100%;
      flex-grow: 99;
      margin-bottom: 1em;
      //padding-right: 1em;
      text-align: center;

      & > h2 {
        text-align: center;
        font-weight: 800;
        font-style: italic;
        color: $black;

        a {
          text-decoration: none;

          &, &:link, &:visited {
            color: inherit;
          }

          &:hover, &:active {
            text-decoration: underline;
          }
        }
      }

      .entryContent, .entry-content {
        ul {
          padding-top: 0;
          padding-bottom: 0;

          li {
            padding-top: 0;
            padding-bottom: 0;

            &:before {
              color: $black;
            }
          }
        }

        h3 {
          text-transform: none;
          font-weight: 700;

          &:not(:only-child) {
            margin-bottom: 0.6em;
          }

          &:only-child {
            margin-bottom: 0;
          }

          a {
            text-decoration: none;

            &:hover, &:active {
              text-decoration: underline;
            }
          }
        }

        .extraInfoBubble {
          display: inline-block;

          button {
            padding: 0.5em 0.9em;
          }
        }
      }
    }
  }

  div.bubblesContainer:not(:last-child) {
    margin-bottom: 3em;
  }

  .bubble {
    flex-basis: 260px;
    max-width: 260px;
    flex-shrink: 0;
    display: block;
    margin: 0 auto 1em;
    max-width: 100%;
    color: $white;

    &.transparent {
      a, .noa {
        border: none;
        box-shadow: none;
        border-radius: 0;
        color: $black;

        &, &:link, &:visited {
          color: $black;
        }

        &:not(.noa) {
          &:active {
            top: 0;
            box-shadow: none;
          }
        }


        &::after {
          display: none;
        }

        .aContent {
          position: static;
          transform: translateY(0);
          padding: 0;
        }
      }
    }

    &.black {
      a, .noa {
        background-color: rgba($black, 0.9);

        &:not(.noa) {
          &:hover, &:active {
            background-color: $black;
          }
        }
      }
    }

    &.purple {
      a, .noa {
        background-color: rgba($mauve, 0.9);

        &:not(.noa) {
          &:hover, &:active {
            background-color: $mauve;
          }
        }
      }
    }

    &.green {
      a, .noa {
        background-color: rgba($green, 0.9);

        &:not(.noa) {
          &:hover, &:active {
            background-color: $green;
          }
        }
      }
    }

    &.aqua {
      a, .noa {
        background-color: rgba($turquoise, 0.9);

        &:not(.noa) {
          &:hover, &:active {
            background-color: $turquoise;
          }
        }
      }
    }

    &.textXlarge {
      .titleLine {
        &:not(.titleLine-bigger) {
          font-size: 1.8em;
        }

        &.titleLine-bigger {
          font-size: 4em;
        }
      }
    }

    a, .noa {
      position: relative;
      top: 0;
      display: block;
      text-decoration: none;
      border-radius: 100%;
      border: 6px solid $white;
      box-shadow: 0px 2px 5px 5px rgba($black, 0.2);
      transition: all 0.2s ease;

      &, &:link, &:visited {
        color: $white;
      }

      &:not(.noa) {
        &:active {
          top: 2px;
          box-shadow: 0px 0px 5px 5px rgba($black, 0.2);
        }
      }


      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }

    .aContent {
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding: 0 20px;

      img {
        display: block;
        width: 100%;
        max-width: 500px;
        height: auto;
      }
    }

    .titleLine {
      display: block;
      text-align: center;
      line-height: 1em;

      &:not(.titleLine-bigger) {
        font-style: italic;
        font-size: 1.2em;
      }

      &.titleLine-bigger {
        font-size: 2.2em;
      }
    }

    .titleLine-1 {
    }

    .titleLine-2 {
      margin-top: 0.1em;
    }
  }
}

/*** Styles des pubs **************************************************/
form {

  .formContent {
    display: block;
  }

  .rows {
    flex-grow: 1;
  }

  .wrapBup {
    flex-basis: 100%;
    max-width: 100%;
    margin: auto;
    text-align: center;

    .bup {
      width: 100%;
      text-align: center;

      img {
        display: inline-block;
        vertical-align: top;
        max-width: 300px;
        width: 100%;
        height: auto;
      }
    }
  }
}

.step1 {
  form {
    .wrapBup {
      text-align: left;

      .bup {
        text-align: left;
      }
    }
  }
}

/*** Styles de la page de connexion / login ****************************************/

.login #main, .passwordReset #main {
  input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]) {
    width: 15em;
    max-width: 100%;
  }
}

//Mot de passe oublié

.passwordReset #main {
  .intro {
    max-width: 900px;
  }
}


/*** Styles du compte utilisateur **************************************************/
.account {
  &.handyTools {
    .introText {
      margin-top: 1em;
    }

    .blogposts {
      margin-top: 2em;
      padding-bottom: 2em;

      .blogpostsTitle {
        margin-bottom: 2em;
      }
    }
  }

  #main {
    width: 100%;
    padding: 4.6em 5% 1em;
    flex-grow: 1;

    & > .wrap {
      padding: 0;
    }

    h1 {
      font-size: 2.6em;
      line-height: 1.05em;
      font-style: italic;
      margin-bottom: 0.2em;

      &.withComma::after {
        content: ',';
      }
    }

    p {
      margin-bottom: 0.8em;
    }

    .inner {
      .headline {
        font-size: 1.3em;
      }

      /* a{
				color: $turquoise;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			} */
    }
  }

  .headerText {
    .headerText-inner {
      max-width: 100%;
    }

    .external {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      color: $turquoise;
    }
  }

  #content {
    position: relative;
    display: flex;
    min-height: 1085px;
    justify-content: space-between;
    align-items: stretch;
  }
        .cvs-display{
		flex-direction: column;

		.stats-applied-by-link{
			b{
				color: #339a99;
				font-size: 24px;
			}
		}
	}

  .headline {
    margin-bottom: 0;
  }

  .articles, .inner.entryContent, .inner.entry-content {
    margin-top: 1.4em;
  }

  &:not(.history) {
    .jobOffers .jobOffer.applied .articleInner {
      opacity: 0.5;
    }
  }

  &.history {
    .jobOffers {
      .jobOffer {
        padding-left: 40px;
      }
    }
  }


}

.emploiretraite-alerte-emploi {
  float: right;

  .linkList {
    a {
      border-width: 0;
      background-color: #4caf50;
    }
  }
}

#main .entryContent .emploiretraite-alerte-emploi ul li::before {
  content: '';
}

.jobOffers {
  margin-bottom: 2em;

  .jobOffer {
    display: block;
    position: relative;
    justify-content: space-between;


    &:not(:last-of-type) {

      margin-bottom: 1em;
    }

    /*&.nbApply-0 {
            .wrapButton {
                .link .button {
                    color: $gray;
                    border-color: $gray;

                    &:hover, &:active {
                        background-color: transparent;
                        color: $gray;
                    }
                }
            }
        }*/

    .link .button[disabled] {
      color: $gray;
      border-color: $gray;

      &:hover, &:active {
        background-color: transparent;
        color: $gray;
      }
    }

    .state {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -17.5px;
      margin-right: 5px;

      .icon {
        display: none;
        margin: 0 auto;
        text-indent: -99999px;
        background-image: url('/images/sprite_user.png');
        background-repeat: no-repeat;
      }

      &.state-trashed, &.state-trash {
        .icon {
          display: block;
          width: 30px;
          height: 36px;
          background-position: 0 -751px;
        }
      }

      &.state-expired {
        .icon {
          display: block;
          width: 35px;
          height: 35px;
          background-position: 0 -955px;
        }
      }

      &.state-applied {
        .icon {
          display: block;
          width: 30px;
          height: 31px;
          background-position: 0 -1080px;
        }
      }

      &.state-future {
        .icon {
          display: block;
          width: 35px;
          height: 34px;
          background-position: 0 -1881px;
        }
      }

      &.state-draft, &.state-auto-draft, &.state-pending {
        .icon {
          display: block;
          width: 35px;
          height: 45px;
          background-position: 0 -2081px;
        }
      }

      &.state-private {
        &, & + div {
          display: none;
        }
      }

      .tooltip {
        display: none;
      }
    }

    .articleInner {
      flex-grow: 1;
      border-left: 7px solid $turquoise;
    }

    &.company {
      .articleInner {
        border-left: 7px solid $black;

        h3 {
          color: $black;
        }
      }
    }

    &.private {
      .articleInner {
        border-left: 7px solid $mauve;

        h3 {
          color: $mauve;
        }
      }
    }

    .articleInner-l2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0.5em 0 0.5em 0.7em;
      border-bottom: 2px solid $lightgray;
    }

    .cieLogo {
      flex-basis: 45px;
      max-width: 45px;
      flex-shrink: 0;
      padding-top: 0.3em;
      display: flex;
      align-items: flex-start;
      margin-right: 0.5em;

      img {
        display: block;
        width: 100%;
        height: auto;
        border: 2px solid $turquoise;
        border-radius: 100%;
        overflow: hidden;
      }
    }

    .jobInfo {
      flex-basis: 72%;
      max-width: 72%;
      display: flex;

      h3 {
        margin-bottom: 0.1em;
        font-weight: 700;
        color: $turquoise;

        a {
          color: $turquoise;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .regions {
        font-weight: 700;
      }

      .sectors {
        font-size: 0.85em;
        font-style: italic;
        color: $midgray;
      }

      .dates {
        margin-top: 0.4em;
        font-size: 0.75em;

        time {
          font-size: 1.1em;
          color: $turquoise;
        }
      }

      .managementLinks {
        margin-top: 0.4em;

        a {
          margin-right: 0.5em;
          text-decoration: underline;
          transition: opacity 0.2s ease;
          white-space: nowrap;
          text-decoration: none;
          opacity: 0.6;

          &, &:link, &:visited {
            color: $black;
          }

          &:hover, &:hover {
            opacity: 1;
          }
        }
      }
    }

    .wrapButton {
      display: flex;
      margin-top: 1em;
      text-align: left;
      white-space: nowrap;

      .wrapButton-inner {
        display: flex;
        flex-wrap: wrap;
      }

      .cieLogoSpace {
        flex-basis: 45px;
        max-width: 45px;
        flex-shrink: 0;
        margin-right: 0.5em;
      }

      .link {
        margin-right: 1em;

        a {
          text-align: center;
          white-space: nowrap;
        }
      }

      .wrapIndicators {
        margin-top: 0.5em;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
      }

      .indicator {
        border-radius: 0.5em;

        &:not(:last-of-type) {
          margin-bottom: 0;
          margin-right: 1em;
        }

        &.nbRetired {
          .wrapIcon {
            padding-top: 3px;
          }

          .icon {
            width: 30px;
            height: 31px;
            background-position: 0 -1080px;
          }
        }

        &.nbApply {
          .icon {
            width: 30px;
            height: 22px;
            background-position: 0 -1279px;
          }
        }

        .indicatorInner {
          &, & > a {
            display: flex;
            align-items: center;
          }

          & > a {
            text-decoration: none;
            opacity: 0.9;
            transition: opacity 0.2s ease;

            &, &:link, &:visited {
              color: $turquoise;
            }

            &:hover, &:active {
              opacity: 1;
            }
          }
        }

        .wrapIcon {
          margin-right: 0.6em;
        }

        .icon {
          display: block;
          font-size: 0;
          background-image: url('/images/sprite_user.png');
          background-repeat: no-repeat;
        }

        .above {
          display: block;
          padding-top: 0.2em;
          padding-bottom: 0.5em;
          line-height: 0;
          text-align: center;
          font-style: italic;
          font-weight: 700;
          text-transform: lowercase;
          color: $turquoise;
        }

        .number {
          display: block;
          flex-grow: 1;
          font-size: 1.4em;
          text-align: left;
          color: $turquoise;
        }
      }

      .tooltip {
        display: none;
      }
    }
  }
}

/*** Styles des modules du tableau de bord / dashboard de l'accueil du compte utilisateur ***********************/

#main .dashboardModules {
  margin: 0 0 2em;

  .gutterSizer {
    width: 4%;
  }

  .modulesInner {
  }

  .module {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 3em;
    }

    /* Couleurs des modules */
    //Par défaut c'est gris

    //Turquoise

    &.moduleColor-aqua {
      &.threeCols .col {
        .number {
          &, a, a:link, a:visited {
            color: $turquoise;
          }

          a:hover, a:active {
            color: $darkturquoise;
          }
        }

        .text a {
          text-decoration: none;

          &, &:link, &:visited {
            color: $turquoise;
          }

          &:hover, &:active {
            color: $darkturquoise;
          }
        }
      }

      &.offerList {
        .offer {
          h4 {
            &, a, a:link, a:visited {
              color: $turquoise;
            }

            a:hover, a:visited {
              color: $darkturquoise;
            }
          }
        }
      }

      &.largeButton {
        .button {
          border-color: $turquoise;

          &:hover {
            text-decoration: none;
          }
        }

        .wrapText {
          color: white !important;
        }
      }

      &.oneStat {
        .theStat .statNumber {
          color: $turquoise;
        }

        .links {
          a, a:link, a:visited {
            color: $turquoise;
          }

          a:hover, a:active {
            color: $darkturquoise;
          }
        }
      }

      &.textContent {
        a {
          &, &:link, &:visited {
            color: $turquoise;
          }

          &:hover, &:active {
            color: $darkturquoise;
          }
        }
      }

      &.message {

      }

      &.planInfo {
        .infoLine {
          .theText {
            h5 {
              color: $turquoise;
            }
          }
        }

        .promoLinks {
          a {
            &, &:link, &:visited {
              color: $turquoise;
            }

            &:hover, &:active {
              color: $darkturquoise;
            }
          }
        }
      }

      & > header {
        background-color: $turquoise;

        .wrapIcon {
          background-color: $turquoise;
        }
      }

      .button, button, input[type="button"], input[type="submit"] {
        background-color: $turquoise;

        &:hover, &:active {
          background-color: $darkturquoise;
        }

        &.outline {
          border-color: $turquoise;

          &:hover, &:active {
            border-color: $darkturquoise;
            background-color: $darkturquoise;
          }
        }
      }

      .buttonLink {
        color: $turquoise;

        &:hover, &:active {
          color: $darkturquoise;
        }
      }
    }

    //Vert

    &.moduleColor-green {
      &.threeCols .col {
        .number {
          &, a, a:link, a:visited {
            color: $green;
          }

          a:hover, a:active {
            color: $darkgreen;
          }
        }

        .text a {
          text-decoration: none;

          &, &:link, &:visited {
            color: $green;
          }

          &:hover, &:active {
            color: $darkgreen;
          }
        }
      }

      &.offerList {
        .offer {
          h4 {
            &, a, a:link, a:visited {
              color: $green;
            }

            a:hover, a:visited {
              color: $darkgreen;
            }
          }
        }
      }

      &.largeButton {
        .button {
          border-color: $green;
        }
      }

      &.oneStat {
        .theStat .statNumber {
          color: $green;
        }

        .links {
          a, a:link, a:visited {
            color: $green;
          }

          a:hover, a:active {
            color: $darkgreen;
          }
        }
      }

      &.textContent {
        a {
          &, &:link, &:visited {
            color: $green;
          }

          &:hover, &:active {
            color: $darkgreen;
          }
        }
      }

      &.planInfo {
        .infoLine {
          .theText {
            h5 {
              color: $green;
            }
          }
        }

        .promoLinks {
          a {
            &, &:link, &:visited {
              color: $green;
            }

            &:hover, &:active {
              color: $darkgreen;
            }
          }
        }
      }

      & > header {
        background-color: $green;

        .wrapIcon {
          background-color: $green;
        }
      }

      .button, button, input[type="button"], input[type="submit"] {
        background-color: $green;

        &:hover, &:active {
          background-color: $darkgreen;
        }

        &.outline {
          border-color: $green;

          &:hover, &:active {
            border-color: $darkgreen;
            background-color: $darkgreen;
          }
        }
      }

      .buttonLink {
        color: $green;

        &:hover, &:active {
          color: $darkgreen;
        }
      }
    }

    //Violet / mauve

    &.moduleColor-purple {
      &.threeCols .col {
        .number {
          &, a, a:link, a:visited {
            color: $mauve;
          }

          a:hover, a:active {
            color: $darkmauve;
          }
        }

        .text a {
          text-decoration: none;

          &, &:link, &:visited {
            color: $mauve;
          }

          &:hover, &:active {
            color: $darkmauve;
          }
        }
      }

      &.offerList {
        .offer {
          h4 {
            &, a, a:link, a:visited {
              color: $mauve;
            }

            a:hover, a:visited {
              color: $darkmauve;
            }
          }
        }
      }

      &.largeButton {
        .button {
          border-color: $mauve;
        }
      }

      &.oneStat {
        .theStat .statNumber {
          color: $mauve;
        }

        .links {
          a, a:link, a:visited {
            color: $mauve;
          }

          a:hover, a:active {
            color: $darkmauve;
          }
        }
      }

      &.textContent {
        a {
          &, &:link, &:visited {
            color: $mauve;
          }

          &:hover, &:active {
            color: $darkmauve;
          }
        }
      }

      &.planInfo {
        .infoLine {
          .theText {
            h5 {
              color: $mauve;
            }
          }
        }

        .promoLinks {
          a {
            &, &:link, &:visited {
              color: $mauve;
            }

            &:hover, &:active {
              color: $darkmauve;
            }
          }
        }
      }

      & > header {
        background-color: $mauve;

        .wrapIcon {
          background-color: $mauve;
        }
      }

      .button, button, input[type="button"], input[type="submit"] {
        background-color: $mauve;

        &:hover, &:active {
          background-color: $darkmauve;
        }

        &.outline {
          border-color: $mauve;

          &:hover, &:active {
            border-color: $darkmauve;
            background-color: $darkmauve;
          }
        }
      }

      .buttonLink {
        color: $mauve;

        &:hover, &:active {
          color: $darkmauve;
        }
      }
    }

    &.moduleIcon-pieChart {
      & > header .wrapIcon .icon {
        width: 40px;
        height: 40px;
        background-position: 0 -3320px;
      }
    }

    &.moduleIcon-medalFirst {
      & > header .wrapIcon .icon {
        width: 28px;
        height: 36px;
        background-position: 0 -3420px;
      }
    }

    &.moduleIcon-sheetCheck {
      & > header .wrapIcon .icon {
        //Pour centrer sur la feuille, pas l'icône entière
        margin-left: 6px;
        width: 35px;
        height: 37px;
        background-position: 0 -3520px;
      }
    }

    &.moduleIcon-eye {
      & > header .wrapIcon .icon {
        width: 35px;
        height: 21px;
        background-position: 0 -3620px;
      }
    }

    &.moduleIcon-tag {
      & > header .wrapIcon .icon {
        width: 32px;
        height: 25px;
        background-position: 0 -4171px;
      }
    }

    &.moduleIcon-wrenchScrewdriver {
      & > header .wrapIcon .icon {
        width: 32px;
        height: 32px;
        background-position: 0 -3720px;
      }
    }

    &.padded {
      .contentInner {
        padding: 1.2em 1.4em;
      }
    }

    //Statistiques
    &.threeCols {
      .contentInner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .col {
        flex-basis: 100%;
        max-width: 100%;
        flex-grow: 10;
        padding: 0.8em 1.4em;

        &:not(:last-of-type) {
          border-bottom: 2px solid darken($lightgray, 2%);
        }

        &:nth-of-type(odd) {
          background-color: lighten($lightgray, 2%);
        }

        & > .number {
          font-size: 3em;
          font-weight: 700;
          color: lighten($sidebargray, 15%);

          .small {
            font-size: 0.5em;
          }

          a {
            text-decoration: none;
            transition: color 0.2s ease;

            &, &:link, &:visited {
              color: lighten($sidebargray, 15%);
            }

            &:hover, &:active {
              color: $sidebargray;
            }
          }
        }

        & > .text {
          font-size: 0.9em;
          font-weight: 700;

          a {
            transition: color 0.2s ease;

            &, &:link, &:visited {
              color: lighten($sidebargray, 15%);
            }

            &:hover, &:active {
              color: $sidebargray;
            }
          }
        }
      }
    }

    //Offre en vedette et Dernière offre
    &.offerList {
      //Dernière offre (avec indicateurs et bouton)
      &.withIndicators {
        .offer {
          flex-wrap: wrap;
        }
      }

      .offer {
        margin-bottom: 1.4em;

        h4 {
          margin: 0;
          font-weight: 700;
          color: lighten($midgray, 15%);

          a {
            text-decoration: none;
            transition: color 0.2s ease;

            &, &:link, &:visited {
              color: lighten($midgray, 15%);
            }

            &:hover, &:active {
              color: $midgray;
            }
          }
        }

        .region {
          margin-bottom: 0.2em;
          font-size: 0.9em;
          font-weight: 700;
          color: $black;
        }

        .sector {
          font-size: 0.8em;
          color: darken($lightgray, 25%);
        }
      }

      .buttons {
        margin: 0;
        padding: 0;
        border-top: none;
        text-align: left;

        .wrapButton {
          display: block;
          text-align: center;
        }

        .button, button, input[type="button"], input[type="submit"], .buttonLink {
          &:not(:first-child) {
            margin-left: 0;
          }
        }
      }
    }

    //Demander une recommandation
    &.largeButton {
      &.moduleIcon-rankStars {
        .icon {
          width: 64px;
          height: 61px;
          background-position: 0 -3871px;
        }
      }

      &.moduleIcon-ribbonSeal {
        .icon {
          width: 49px;
          height: 63px;
          background-position: 0 -4071px;
        }
      }

      .button {
        position: relative;
        display: block;
        // max-width: 600px;
        padding: 1.6em 2em;
        border: 2px solid $midgray;
        background-color: $white;
        overflow: hidden;

        &:hover, &:active {
          &::before {
            opacity: 1;
          }
        }

        //Faux background
        &::before {
          content: '';
          position: absolute;
          z-index: 5;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-image: linear-gradient(115deg, $turquoise 0%, $turquoise 20%, $green 60%, $green 100%);
          opacity: 0.9;
          transition: opacity 0.2s linear;
        }

        .buttonContent {
          position: relative;
          z-index: 10;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .wrapIcon {
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 0.5em;
            text-align: center;

            .icon {
              display: inline-block;
              background-image: url('/images/sprite_icon.png');
              background-repeat: no-repeat;
            }
          }

          .wrapText {
            text-align: center;

            & > span {
              display: block;
              line-height: 1.2em;
            }

            .firstLine {
              font-size: 1.2em;
              text-transform: none;
              font-style: italic;
              font-weight: 400;
            }

            .emphasis {
              font-size: 1.4em;
              letter-spacing: 0.02em;
            }
          }
        }
      }
    }

    //Curriculum vitae
    &.buttonList {
      .wrapButton {
        position: relative;

        button, .button, input[type="button"], input[type="submit"] {
          margin: 0 0 0.4em 37px;
        }

        .linkTrigger {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -18px;

          button {
            margin-bottom: 0;
            margin: 0;
          }
        }
      }
    }

    //Complétion
    &.oneStat {
      .theStat {

        .statNumber {
          margin-right: 0.4em;
          color: lighten($midgray, 15%);
        }

        .number {
          font-size: 3em;
          line-height: 1em;
          font-weight: 700;
        }

        .symbol {
          font-size: 1.7em;
          line-height: 1em;
        }

        .label {
          display: inline;
          font-weight: 700;
          color: $black;
        }
      }

      .links {
        margin-top: 0.6em;

        a {
          font-style: italic;
          text-decoration: none;
          transition: color 0.2s ease;

          &, &:link, &:visited {
            color: lighten($midgray, 15%);
          }

          &:hover, &:active {
            color: $midgray;
          }
        }
      }
    }

    //Outils pratiques
    &.textContent {
      a {
        text-decoration: none;
        font-weight: 700;
        transition: color 0.2s ease;

        &, &:link, &:visited {
          color: lighten($midgray, 15%);
        }

        &:hover, &:active {
          color: $midgray;
        }
      }

      .textLinkList {
        &:not(:last-child) {
          margin-bottom: 1em;
        }

        ul {
          padding-left: 1.6em;
          font-size: 0.9em;
          list-style-type: disc;
        }

        li {
          &:not(:last-of-type) {
            margin-bottom: 0.4em;
          }
        }

        .theLink {
          a {
            font-size: 0.9em;
            text-decoration: none;
            font-style: italic;
          }
        }
      }
    }

    //Votre forfait
    &.planInfo {
      .contentInner {
        & > div {
          &:not(:last-child) {
            margin-bottom: 1em;
          }
        }
      }

      .infoLine {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        align-items: center;

        .theText {
          margin-right: 2em;

          h5 {
            margin-bottom: 0;
            font-size: 0.7em;
            text-transform: uppercase;
            color: lighten($midgray, 30%);
          }

          .mainText {
            display: block;
            font-size: 1.8em;
            margin-bottom: 0.4em;
            line-height: 1.2em;
            color: $midgray;
          }
        }

        .buttons {
          margin: 0;
          padding: 0;
          border: 0;
        }
      }

      .promoLinks {
        margin-top: 2em;

        & > div {
          &:not(:last-child) {
            margin-bottom: 0.6em;
          }
        }

        a {
          text-decoration: none;
          font-style: italic;
          transition: color 0.2s ease;

          &, &:link, &:visited {
            color: lighten($midgray, 15%);
          }

          &:hover, &:active {
            color: $midgray;
          }
        }
      }
    }

    & > header {
      position: relative;
      padding: 0.6em 1.4em;
      //De la place pour l'icône + 0.4em
      padding-right: (1.2em + 1.2em + 1.4em + 1.4em + 0.4em);
      text-transform: uppercase;
      background-color: lighten($sidebargray, 15%);
      color: $white;
      border-radius: 0.3em 0.3em 0 0;

      h3 {
        margin: 0;
        font-size: 1.05em;
        line-height: 1.2em;
        font-weight: 800;
        letter-spacing: 0.02em;
      }

      .wrapIcon {
        position: absolute;
        right: 1.4em;
        top: -0.7em;
        height: (1.2em + 1.2em + 1.4em);
        width: (1.2em + 1.2em + 1.4em); // (hauteur texte) + (2 x padding 0.6em) + (top + bottom de cet élément)
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: lighten($sidebargray, 15%);

        .icon {
          display: inline-block;
          text-indent: -99999px;
          background-image: url('/images/sprite_icon.png');
          background-repeat: no-repeat;
        }
      }
    }

    .contentInner {
      border: 2px solid darken($lightgray, 2%);
      border-top: none;
    }

    button, input[type="button"], input[type="submit"], .button {
      background-color: lighten($midgray, 15%);
      transition: all 0.2s ease;

      &:hover, &:active {
        background-color: $midgray;
      }

      &.outline {
        border: 3px solid lighten($midgray, 15%);
        background-color: transparent;
        color: $black;

        &:hover, &:active {
          border-color: $midgray;
          background-color: $midgray;
          color: $white;
        }
      }
    }

    .buttonLink {
      color: lighten($midgray, 15%);

      &:hover, &:active {
        color: $midgray;
      }
    }
  }
}


/*** Styles des tooltips Smallipop ****************************************/
//Utiliser 'theme': 'white emploiretraite' dans le JS.

.pageTemplate-pricing div.smallipop-theme-emploiretraite {
  max-width: 200px;
}

div.smallipop-theme-emploiretraite {
  background-color: $white;
  color: $black;
  border-color: $turquoise;
  font-size: 0.9em;
  border-width: 3px;
  border-radius: 0.5em;
  text-shadow: 0;
  letter-spacing: -0.03em;
  z-index: 11000;
  //Ombre incluant la petite flèche en triangle
  filter: drop-shadow(1px 3px 3px rgba($black, 0.7));

  &, &.smallipop-top {
    &.smallipop-align-left {
      &::before {
        transform: translate(6px, 12px);
      }
    }

    &::before {
      border-width: 16px;
      transform: translate(-6px, 12px);
      border-color: $turquoise transparent transparent transparent;
    }


    &::after {
      border-color: $white transparent transparent transparent;
    }
  }


  &.smallipop-right {
    &::before {
      border-color: transparent $turquoise transparent transparent;
    }

    &::after {
      border-color: transparent $white transparent transparent;
    }
  }

  &.smallipop-bottom {
    &.smallipop-align-left {
      &::before {
        transform: translate(6px, -12px);
      }
    }

    &::before {
      transform: translate(-6px, -12px);
      border-color: transparent transparent $turquoise transparent;
    }

    &::after {
      border-color: transparent transparent $white transparent;
    }
  }

  &.smallipop-left {
    &::before {
      border-color: transparent transparent transparent $turquoise;
    }

    &::after {
      border-color: transparent transparent transparent $white;
    }
  }

  p, ul, ol, dl, h1, h2, h3, h4, h5, h6 {
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    white-space: nowrap;
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0;
    color: $turquoise;
  }

  .number {
    font-weight: 700;
    color: $turquoise;
  }

  //Pour contrer le display: none !important; de Magnific Popup.
  .mfp-hide {
    display: block !important;
  }
}

/*** Popup AJAX : Popup emploi, popup liste des CV, popup CV **************/
/*** Single emploi, liste de CV *********************************/
.mfp-inline-holder, .mfp-ajax-holder {
  .mfp-content {
    height: 100%;
    width: 100%;
    max-width: 1050px;
    background-color: transparent;

    .innerPopup {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &.extraBubble {
        p {
          margin: 5px 0;
        }

        ol {
          margin: 5px 0;
          padding-left: 30px;
          list-style-type: disc;

          li {
            max-width: 95%;
          }
        }
      }
    }

    .innerPopup-l2 {
      position: absolute;
      top: 5%;
      right: 2%;
      bottom: 5%;
      left: 2%;
      padding: 0.5em 0.5em 0 0.5em;
      background-color: $white;
      border: 4px solid $turquoise;
      overflow-y: scroll;
    }

    #content {
      padding-bottom: 0.5em;
    }

    .closePopup {
      display: block;
      position: absolute;
      z-index: 2000;
      right: 2%;
      top: 5%;
      margin-right: -13px;
      margin-top: -13px;
      background-color: $turquoise;
      border-color: $white;
      transition: transform 0.1s linear;

      &:hover, &:active {
        transform: scale(1.1);
      }
    }
  }
}

#singleHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;
  position: relative;

  .cieLogo {
    margin-right: 1em;
    flex-basis: 70px;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: center;

    img {
      border: 4px solid $turquoise;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  .headerText {
    flex-basis: 100%;
    max-width: 100%;
    max-width: calc(100% - 230px);
    word-wrap: break-word;
  }

  .info {
    flex-basis: 300px;
    flex-shrink: 100;
    margin-top: 0.5em;
    font-weight: 700;
    font-style: italic;
    color: $turquoise;
  }

  form {
    justify-content: flex-end;
  }

  h1 {
    margin-bottom: 0.2em;
    font-size: 2em;
    font-style: normal;
    color: $turquoise;
  }

  .regions {
    margin-bottom: 0.2em;
    font-size: 1.2em;
    font-weight: 700;
  }

  .sectors {
    margin-bottom: 0;
    font-style: italic;
    color: $midgray;
  }

  form, .contactCandidates {
    margin-top: 1em;

    .button {
      margin-bottom: 0.6em;
    }
  }

  & + .introText {
    margin-top: -1em;
    margin-bottom: 2em;
  }
}


.mainContent {
  width: 100%;

  &.jobEntreprise {
    .fieldset.accordion {
      &.open {
        & > header {
          background-color: darken($turquoise, 5%);
        }
      }

      & > header {
        background-color: $turquoise;

        &:hover, &:active {
          background-color: darken($turquoise, 5%);
        }
      }
    }
  }

  &.jobParticulier {
    .fieldset.accordion {
      &.open {
        & > header {
          background-color: darken($mauve, 5%);
        }
      }

      & > header {
        background-color: $mauve;

        &:hover, &:active {
          background-color: darken($mauve, 5%);
        }
      }
    }
  }

  .closePopup {
    display: none;
  }
}

#sidebar {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 375px;
  min-height: 100%;
  padding-bottom: 1em;
  border-right: 6px solid $turquoise;
  background-color: $sidebargray;
  color: $white;
  transform: translateX(-100%);
  transition: transform 0.3s ease;

  &.open {
    transform: translateX(0);
    overflow: hidden;
  }

  #btnOpenSidebar {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    right: -225px;
    top: 14px;
    padding: 10px 10px;
    border-radius: 0 10px 10px 0;
    background-color: $mauve;

    //Coins ronds "inset" reliant le bouton à la barre latérale
    .roundCorner {
      display: inline-block;
      position: absolute;
      left: 0;
      width: 10px;
      height: 10px;

      &::after, &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: 20px 20px;
        transition: opacity 0.2s ease;
      }

      //Coin rond du haut
      &:nth-of-type(1) {
        top: -10px;

        &::before, &::after {
          background-position: bottom left;
        }
      }

      //Coin rond du bas
      &:nth-of-type(2) {
        bottom: -10px;
      }

      //Le non hover
      &::before {
        background-image: radial-gradient(circle farthest-side at center, rgba($mauve, 0) 0%, rgba($mauve, 0) 99.9%, $mauve 100%);
      }

      //Le hover pour transitionner
      &::after {
        background-image: radial-gradient(circle farthest-side at center, rgba($darkmauve, 0) 0%, rgba($darkmauve, 0) 99.9%, $darkmauve 100%);
        opacity: 0;
      }
    }

    &:hover, &:active {
      background-color: $darkmauve;

      .roundCorner {
        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 200px;
      height: 36px;
      background-image: url(/images/sprite_user.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      text-indent: 42px;
      white-space: nowrap;
      padding-top: 7px;
      color: $white;
    }
  }

  #btnCloseSidebar {
    position: absolute;
    top: 0.6em;
    left: 0.6em;
    display: inline-block;
    padding: 0;
    height: 30px;
    width: 30px;
    border-radius: 0;
    background-color: transparent;
    transition: transform 0.15s linear;

    &:hover, &:active {
      transform: scale(1.4);
    }

    span {
      display: inline-block;
    }

    .btnName {
      position: absolute;
      overflow: hidden;
      text-indent: -99999px;
    }

    .bar {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 3px;
      width: 100%;
      transform: translate(-50%, -50%);
      background-color: $white;

      &:nth-of-type(1) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:nth-of-type(2) {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  .profileCompletion {
    display: flex;
    align-items: center;
    //background-color: $turquoise;
    background-image: linear-gradient(to left, $turquoise, $green);
    padding: 2.8em 0.4em 1em 0.6em;

    & > div {
      padding: 0 0.8em;
    }

    & + .userinfos {
      padding-top: 1.6em;
    }

    .wrapProgress {
      flex-basis: 75%;
      max-width: 75%;
      font-size: 0.85em;
      font-weight: 700;

      p {
        margin-top: 0.5em;
        margin-bottom: 0;

        span {
          vertical-align: middle;
        }

        .completionNumber, .percentSign {
          font-size: 1.4em;
          font-weight: 800;
        }
      }

    }

    .wrapButton {

    }

    .button {
      display: block;
      padding: 0.9em 1em;
      border: 2px solid $white;
      border-radius: 0;
      background-color: transparent;
      font-size: 0.8em;
      text-transform: none;
      transition: background-color 0.2s ease, color 0.2s ease;

      &:hover, &:active {
        background-color: $white;
        color: $turquoise;
      }
    }
  }

  .userinfos {
    padding: 3em 0 1.6em;
    text-align: center;

    .avatar {
      display: inline-block;
      border: 3px solid $white;
      border-radius: 100%;
      overflow: hidden;
    }

    .name {
      margin-bottom: 0.4em;
      font-size: 1.4em;
      font-style: italic;
    }

    .button {
      padding: 0.4em 1em 0.5em;
      font-weight: bold;

      &:hover, &:active {
        background-color: lighten($turquoise, 3%);
      }
    }
  }

  .account-menu {
    background-color: $sidebardarkgray;

    > ul {
      & > li {
        border-top: 1px solid $darkblack;
        border-right: 1px solid $darkblack;

        &:last-of-type {
          border-bottom: 1px solid $darkblack;
        }

        &.active {
          > a {
            background-color: $turquoise;
            border-right: none;

            &:hover {
              background-color: rgba($white, 0.06);
            }

            &:active {
              background-color: rgba($white, 0.12);
            }
          }

          ul {
            display: block;
          }
        }

        &.dashboard > a::before {
          background-position: 0 -360px;
        }

        &.statistics > a::before {
          background-position: 0 -50px;
        }

        &.profil > a::before {
          background-position: 0 0;
        }

        &.emplois > a::before {
          background-position: 0 -103px;
        }

        &.historique > a::before {
          background-position: 0 -203px;
        }

        &.outils > a::before {
          background-position: 0 -308px;
        }

        &.communaute > a::before {
          background-position: 0 -409px;
        }

        &.deconnexion > a::before {
          background-position: 0 -2281px;
        }

        &.publish > a::before {
          background-position: 0 -255px;
        }

        &.forfait > a::before {
          background-image: url(/images/sprite_icon.png);
          background-position: 0 -4165px;
        }

        & > a {
          display: block;
          padding: 0.4em 0 0.4em 10%;
          border-left: 5px solid $white;
          transition: background-color 0.2s ease;

          &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 36px;
            height: 36px;
            background-image: url('/images/sprite_user.png');
            background-repeat: no-repeat;
          }

          &, &:link, &:visited {
            text-decoration: none;
            color: $white;
          }

          &:hover {
            background-color: rgba($white, 0.03);
          }

          &:active {
            background-color: rgba($white, 0.06);
          }

          span {
            display: inline-block;
            vertical-align: middle;
            margin-left: 5%;
          }
        }
      }

      ul {
        background-color: lighten($sidebardarkgray, 5%);
        padding: 0.4em 0.4em 0.4em 20%;
        display: none;

        li {
          list-style-type: disc;
          color: $white;
        }

        li.active a {
          font-weight: 700;
        }

        a {
          font-size: 0.8em;
          color: $white;
          text-decoration: none;

          &:hover {
            color: $turquoise;
          }
        }
      }
    }
  }

  .bup {
    padding: 0 10%;
    margin-top: 2em;
    text-align: center;

    a {
      display: block;
    }

    img {
      display: inline-block;
      width: 100%;
      max-width: 298px;
      height: auto;
    }
  }

  .social {
    margin-top: 1.4em;
    padding: 0 10%;
    font-size: 0.9em;
    line-height: 1.2em;
    text-transform: uppercase;

    p {
      margin-bottom: 0.6em;
    }

    ul {
      .icon {
        display: inline-block;
        width: 44px;
        height: 44px;
        text-indent: -99999px;
        overflow: hidden;
        background-image: url('/images/sprite_social.png');
        background-repeat: no-repeat;
      }

      li {
        display: inline-block;
        vertical-align: top;

        a {
          display: block;
        }

        &:not(:first-of-type) {
          margin-left: 0.6em;
        }

        &.facebook {
          .icon {
            background-position: 0 -182px;
          }
        }

        &.linkedin {
          .icon {
            background-position: 0 -472px;
          }
        }

        &.youtube {
          .icon {
            background-position: 0 -290px;
          }
        }
      }

    }
  }
}

/* Menu en onglets (n'apparaît pas en onglets en mobile */

//Version 2 (avec flèches)

/*#sidebar .userinfos .avatar {
	img { width: 100px; height: 100px; }
	.dz-preview { display: none; }
}
.dropzone-module {
	.single-dropzone {
		margin-top: 1em;
		padding: 2em;
		border: 5px dashed $turquoise;
		cursor: pointer;

		.text {
			font-size: 1.4em;
			font-weight: 700;
			color: $turquoise;
		}

		&.dz-drag-hover { border-color: $green;
			.text { color: $green; }
		}
	}

	.avatar {
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 0.5em;
		display: none;

		.dz-error-message, .dz-success-mark, .dz-error-mark { display: none; }

		img {
			max-width: 250px;
			height: auto;
			border-radius: 100%;
			border: 3px solid $turquoise;
		}
	}
}*/

#avatarUpload {
  margin: 1em 0;
  width: 200px;
  height: 200px;
  position: relative; /* or fixed or absolute */

  .avatarUpload_imgUploadForm {
    height: 0;
    overflow: hidden;
  }

  img {
    border: 2px solid $turquoise;
    border-radius: 100%;

    &.notUploaded {
      width: 100%;
      height: auto;
    }
  }

  .cropControls {
    right: -45px;

    &.cropControlsUpload {
      display: none;
    }
  }
}

/* Croppic */
.cropImgWrapper, #croppicModal {
  img {
    max-width: none !important;
  }
}

.cropImgWrapper {
  cursor: -webkit-grab;
  cursor: grab;

  &:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

.cropImgUpload {
  z-index: 2;
  position: absolute;
  height: 28px;
  display: block;
  top: -30px;
  right: -2px;
  font-family: sans-serif;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #FFF;
}

.cropControls {
  z-index: 2;
  position: absolute;
  height: 30px;
  display: block;
  /* top: -31px; */
  top: -1px;
  //right: -45px;
  font-family: sans-serif;
  background-color: rgba(0, 0, 0, 0.35);

  i {
    display: block;
    float: left;
    margin: 0;
    cursor: pointer;
    background-image: url('/images/cropperIcons.png');
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 20px;
    color: #FFF;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;

    .cropTips {
      position: absolute;
      top: -22px;
      left: 0;
      opacity: 0;
      font-size: 1.2em;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);

      .cropTips {
        opacity: 1;
      }
    }

    &.cropControlZoomMuchIn {
      background-position: 0px 0px;
    }

    &.cropControlZoomIn {
      background-position: -30px 0px;
    }

    &.cropControlZoomOut {
      background-position: -60px 0px;
    }

    &.cropControlZoomMuchOut {
      background-position: -90px 0px;
    }

    &.cropControlRotateLeft {
      background-position: -210px 0px;
    }

    &.cropControlRotateRight {
      background-position: -240px 0px;
    }

    &.cropControlCrop {
      background-position: -120px 0px;
    }

    &.cropControlUpload {
      background-position: -150px 0px;
    }

    &.cropControlReset {
      background-position: -180px 0px;
    }

    &.cropControlRemoveCroppedImage {
      background-position: -180px 0px;
    }

    &::last-child {
      margin-right: none;
    }
  }
}

#croppicModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;

  .cropImgWrapper {
    border-radius: 100%;
  }

  .cropControls {
    top: -30px;
    right: 8px;
  }
}


/*
*		PRELOADER
*		With courtesy of : http://cssload.net/
*/

.bubblingG {
  text-align: center;
  width: 80px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px auto auto -40px;
  z-index: 2;

  span {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin: 25px auto;
    background: #FFF;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-border-radius: 50px;
    -moz-animation: bubblingG 1s infinite alternate;
    -webkit-border-radius: 50px;
    -webkit-animation: bubblingG 1s infinite alternate;
    -ms-border-radius: 50px;
    -ms-animation: bubblingG 1s infinite alternate;
    -o-border-radius: 50px;
    -o-animation: bubblingG 1s infinite alternate;
    border-radius: 50px;
    animation: bubblingG 1s infinite alternate;
  }
}

#bubblingG_1 {
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

#bubblingG_2 {
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

#bubblingG_3 {
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-moz-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -moz-transform: translateY(0);
  }

  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -moz-transform: translateY(-21px);
  }

}

@-webkit-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -webkit-transform: translateY(0);
  }

  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -webkit-transform: translateY(-21px);
  }

}

@-ms-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -ms-transform: translateY(0);
  }

  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -ms-transform: translateY(-21px);
  }

}

@-o-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -o-transform: translateY(0);
  }

  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -o-transform: translateY(-21px);
  }

}

@keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    transform: translateY(0);
  }

  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    transform: translateY(-21px);
  }

}

ul.links {
  list-style-type: disc;
  padding-left: 20px;

  .tab {
    margin-bottom: 10px;

    a {
      color: $turquoise;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tabs {
  margin-top: 15px;
}

.tabMenu {
  display: inline-block;
  position: relative;
  margin-top: 1em;
  margin-bottom: 2em;

  .menuTitle {
    font-style: italic;
    color: $turquoise;
    background-color: $white;

    & > span {
      font-size: 1.4em;
    }
  }

  ul {
  }

  li {
    &:not(:last-of-type) {
      border-bottom: 1px solid $white;
    }

    &:first-of-type {
      &, a {
        border-radius: 0.5em 0 0 0;
      }
    }

    &:last-of-type {
      &, a {
        border-radius: 0 0 0 0.5em;
      }
    }

    &.active {
      a {
        &, &:link, &:visited {
          background-color: $turquoise;
          color: $white;

          &::after {
            border-left-color: $turquoise;
          }
        }

        &:hover, &:active {
          background-color: darken($turquoise, 5%);

          &::after {
            border-left-color: darken($turquoise, 5%);
          }
        }
      }
    }

    a {
      position: relative;
      display: block;
      text-decoration: none;
      padding: 0.6em 1em 0.7em;
      background-color: $lightgray;
      transition: background-color 0.2s ease;

      &, &:link, &:visited {
        color: $black;
      }

      &:hover, &:active {
        background-color: darken($lightgray, 5%);

        &::after {
          border-left-color: darken($lightgray, 5%);
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        height: 0;
        width: 0;
        border: 1.26em solid transparent;
        border-left-color: $lightgray;
        transition: border-left-color 0.2s ease;
        pointer-events: none;
      }

      & > span {
        font-size: 0.9em;
      }

      .full {
        display: block;
      }

      .short {
        display: none;
      }
    }
  }
}

//Version 1 (plain)
/*
.tabMenu {
	display: inline-block;
	position: relative;
	margin-top: 1em;
	margin-bottom: 2em;
	padding: 1em;

	.menuTitle {
		font-style: italic;
		color: $turquoise;
		background-color: $white;

		&>span {
			font-size: 1.4em;
		}
	}

	ul {
		background-color: $sidebargray;
		border-radius: 0.5em;
	}

	li {
		&:not(:last-of-type) {
			border-bottom: 1px solid $darkblack;
		}

		a {
			display: block;
			text-decoration: none;
			padding: 0.6em 1.6em 0.7em;
			transition: color 0.2s ease;

			&, &:link, &:visited {
				color: $white;
			}

			&:hover, &:active {
				background-color: rgba($white, 0.03);
			}

			.full {
				display: block;
			}

			.short {
				display: none;
			}
		}
	}
}
*/


/* Styles des listes d'affichage d'informations (incluant accordéons / accordeons / accordions) */

.site #main .fieldset > header * {
  color: $white;
}

.site #main .fieldset.lightTheme > header {
  &:hover, &:active {
    * {
      color: $trueblack;
    }
  }

  * {
    color: $black;
  }

  h2, h3, h4 {
    font-weight: 400;
  }
}


.fieldset {
  position: relative;
  margin-bottom: 0.2em;
  display: flex;
  flex-wrap: wrap;

  //Thème pâle (comme la FAQ)
  &.lightTheme {
    margin-bottom: 0;

    &.accordion {
      border-bottom: 1px solid $turquoise;

      &:nth-of-type(2n) {
        background-color: $lightgray;
      }

      &.open {
        & > header {
          background-color: transparent;
        }
      }

      & > header {
        &:hover, &:active {
          border-bottom-color: darken($lightgray, 15%);
          background-color: transparent;
        }

        .btnRollUnroll {
          display: inline-block;
        }
      }
    }

    & > header {
      background-color: transparent;
      padding-left: 0.8em;
      transition: background-color 0.2s ease, border-bottom-color 0.2s ease;

      &:hover, &:active {
        border-bottom-color: darken($lightgray, 20%);
        background-color: transparent;

        .btnRollUnroll {
          &::after {
            color: $darkturquoise;
          }
        }
      }

      &, * {
        color: $black;
      }

      .headerText {
        //padding: 0.5em 0;
      }

      h2, h3, h4 {
        font-size: 1.1em;
        line-height: 1.2em;
        font-weight: 400;
        color: $turquoise;
        transition: color 0.2s ease;
      }

      .btnRollUnroll {
        &::after {
          color: $turquoise;
          font-weight: 400;
          font-size: 1.1em;
          transition: transform 0.2s linear, color 0.2s ease;
        }
      }
    }

    .accordionContent {
      border-left-width: 0.8em;
      border-right-width: 0.8em;
    }
  }

  div.formRow.checkbox {
    flex-basis: 16%;
    max-width: 16%;
    align-items: stretch;
    background-color: $gray;
    display: flex;
    align-items: center;
    float: left;
    padding: 0.5em;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;

    label {
      width: 23px;
      height: 23px;
      margin: auto;

      &::before {
        background-color: $white;
        border: 1px solid $white !important;
        top: -4px;
        margin-right: 50px;
      }
    }

    & + header {
      flex-basis: 84%;
      max-width: 84%;
    }
  }

  & > header {
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 0.8em;
    background-color: $sidebargray;
    color: $white;
    user-select: none;
    transition: background-color 0.2s ease;

    &.hasDetails {
      h2, h3, h4 {
        font-size: 1em;
        margin-bottom: 0.4em;
      }

      .extraDetails {
        font-size: 0.85em;
      }
    }

    h2, h3, h4 {
      margin-bottom: 0;
      font-size: 0.8em;
    }

    .btnRollUnroll {
      align-self: stretch;
      padding: 0 0.5em;
      //padding: 0 1.2em;
      background-color: transparent;
      border-radius: 0;

      &::after {
        content: '+';
        display: block;
        font-weight: 700;
        font-size: 1.3em;
        transition: transform 0.2s linear, color 0.2s ease;
      }
    }

    .headerText {
      padding: 1em;
    }

    .extraDetails {
      margin-top: 0.2em;
      opacity: 0.9;

      & > div {
        display: inline-flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 0.2em;
          margin-right: 1em;
        }

        .wrapIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 20px;
          margin-right: 0.4em;
          text-align: center;
        }

        .icon {
          display: inline-block;
          text-indent: -99999px;
          background-image: url('/images/sprite_user.png');
          background-repeat: no-repeat;
        }
      }
    }

    .mainDiploma {
      .icon {
        width: 17px;
        height: 17px;
        background-position: 0 -1680px;
      }

      .domain {
        //font-weight: 700;
      }
    }

    .lastEmployer {
      .icon {
        width: 20px;
        height: 17px;
        background-position: 0 -1480px;
      }
    }

    .testimonials {
      .icon {
        width: 19px;
        height: 19px;
        background-position: 0 -1576px;
      }
    }
  }

  &.accordion {
    & > header {
      &:hover {
        cursor: pointer;
      }

      &:hover, &:active {
        background-color: $sidebardarkgray;
      }

      .btnRollUnroll {
        display: inline-block;
      }
    }

    &.open {
      & > header {
        background-color: $sidebardarkgray;

        .btnRollUnroll {
          min-width: 53px;

          &::after {
            content: "-";
            position: relative;
            top: -2px;
            font-size: 1.5em;
            left: -2px;
          }
        }
      }

      .accordionContent {
        opacity: 1;
        overflow: visible;
      }
    }

    .accordionContent {
      width: 100%;
      overflow: hidden;
      display: none;
      opacity: 0;
      transition: opacity 0.3s linear;
    }
  }

  &.pointList {

  }

  &.twoCol {
    .accordionContent {
      & > dl {
        display: flex;

        .col {

        }

        .colLeft {
          flex-basis: 30%;
          max-width: 30%;

          img {
            display: block;
            width: 100%;
            max-width: 300px;
            height: auto;
          }
        }

        .colRight {
          flex-grow: 1;
          padding-left: 1em;
        }
      }
    }
  }

  &.longText {
    .text {
      & > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &:not(:last-of-type) {
    .accordionContent {
      margin-bottom: 2em;
    }
  }

  .accordionContent {
    padding-top: 1em;
    //Faux padding (pour éviter l'overflow des colonnes lors du slideDown/Up)
    border-right: 0.8em solid transparent;
    border-left: 0.8em solid transparent;

    .xps {
      padding-top: 1em;

      &, & > .xp {
        list-style-type: none;
      }

      & > .xp {
        margin-left: 0;
        text-indent: 0;

        &:not(:last-of-type) {
          padding-bottom: 2em;
          margin-bottom: 2em;
          border-bottom: 1px solid $gray;
        }

        dl {
          & > div:last-of-type dd,
          & > dd:last-of-type {
            padding-bottom: 0;
          }
        }

        .dates {
          margin-bottom: 1em;
          font-size: 0.8em;
          text-transform: uppercase;
          font-weight: 700;
          color: darken($gray, 20%);
        }
      }
    }

    & > dl {
      margin: 0;

      dt {
        line-height: 1.1;
      }

      & > div:not(.col),
      .col > div {
        &:not(:last-of-type) {
          margin-bottom: 1em;
        }
      }

      //Qu'il y ait un wrapper div ou pas
      &, & > div {
        //Premier niveau de dt/dd
        & > dt {
          font-size: 1.2em;
          font-style: italic;
          font-weight: bold;
          color: $turquoise;
          opacity: 0.7;
          border-bottom: 1px solid $turquoise;
        }

        & > dd {
          padding: 0.4em 0.4em 0 0.4em;

          ul {
            margin: 0;
            padding-left: 0;
          }

          dl {
            &, & > div {
              //dt/dd qui sont dans un dd (peu importe combien de couches)
              & > dt {
                padding: 0;
                //font-size: 1.1em;
                //line-height: 0.9em;
                font-weight: bold;
                font-style: italic;

                &::after {
                  content: ':';
                  margin: 0 0.2em;
                }
              }

              & > dd {

              }
            }
          }
        }
      }
    }
  }
}

/*** Styles des sliders / carousels / carrousels de logos ****************************************/

.logoSlider {
  padding-top: 2em;
  padding-bottom: 2em;

  .sliderTitle {
    margin-bottom: 1.4em;
    text-align: center;
    text-transform: uppercase;
    color: $black;

    &.sliderTitle-aqua {
      color: $turquoise;
    }

    &.sliderTitle-green {
      color: $green;
    }

    &.sliderTitle-purple {
      color: $mauve;
    }

    &::after {
      content: '';
      display: block;
      margin: auto;
      margin-top: 0.4em;
      width: 100%;
      max-width: 4em;
      border-bottom: 2px solid darken($lightgray, 10%);
    }

    h3 {
      font-weight: 800;
    }
  }

  .owl-carousel .owl-stage {
    display: flex;
  }

  .owl-item {
    float: none;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elementsOuter {
    padding: 0 5%;
  }

  .sliderElements {
  }

  .sliderElement {
    text-align: center;

    img {
      width: auto;
      height: auto;
      max-height: 100px;
      max-width: 230px;
    }

    a {
      &, &:link, &:visited {
        color: $black;
        text-decoration: none;
      }
    }

    a, .noa {
      color: $black;
      opacity: 0.9;
      transition: opacity 0.2s ease;

      &:hover, &:active {
        opacity: 1;
      }
    }

    .noImg {
      font-size: 1.6em;
      line-height: 1em;
      font-weight: 800;
      font-style: italic;
      color: $turquoise;
    }
  }

  .owl-prev, .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.556%;
    font-size: 3.4em;
    color: $black;
    transition: color 0.2s ease;

    &:hover, &:active {
      color: $turquoise;
    }

    span {
      transform: scaleX(0.5);
    }
  }

  .owl-prev {
    right: 103.5%;
  }

  .owl-next {
    left: 103.5%;
  }
}

/*** Styles des alertes **************************************************************************/
body.home #site, body.home .mfp-wrap {
  .wrapAlerts > .inner {
    width: 90%;
  }
}

#site, .mfp-wrap {
  .wrapAlerts {
    margin: 2em 0;

    & > .inner {
      margin: 0 auto;
      width: 96%;
      max-width: 900px;
    }
  }

  .alerts {
    font-size: 0.9em;

    .alertInner {
      text-align: center;
    }
  }

  .alert {
    //Les styles de base de l'alerte correspondent au niveau d'alerte Info.
    position: relative;
    margin-bottom: 1em;
    border: 2px solid $green;
    color: $white;
    opacity: 1;
    transition: opacity 0.2s ease-in;

    &.vanishing {
      opacity: 0;
    }

    &.alertImage-background {
      .alertInner {
        background-color: rgba($turquoise, 0.8);
      }
    }

    &.alertImage-top {
      .alertThumbnail {
        margin-bottom: 0.6em;
      }
    }

    &.alertImage-bottom {
      .alertThumbnail {
        margin-top: 0.6em;
      }
    }

    &.alertImage-left {
      .alertThumbnail {
        margin-right: 0.6em;
      }
    }

    &.alertImage-right {
      .alertThumbnail {
        margin-left: 0.6em;
      }
    }

    &.alertImage-top, &.alertImage-bottom, &.alertImage-left, &.alertImage-right {
      .alertThumbnail {
        padding-left: 0.2em;

        img {
          width: auto;
          height: auto;
          max-height: 50px;
        }
      }
    }

    &.buttonDisplay-horizontal_bottom, &.buttonDisplay-horizontal_top {
      .alertInner {
        flex-wrap: wrap;
      }

      .wrapContent, .wrapButtons {
        flex-basis: 100%;
        max-width: 100%;
      }

      .wrapButtons {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
      }

      .wrapButton {
        &:not(:last-of-type) {
          margin-bottom: 0.6em;
          margin-right: 0.6em;
        }
      }
    }

    &.buttonDisplay-horizontal_bottom {
      .wrapButtons {
        margin-top: 0.6em;
      }
    }

    &.buttonDisplay-horizontal_top {
      .wrapButtons {
        margin-bottom: 0.6em;
      }
    }

    &.buttonDisplay-vertical_right, &.buttonDisplay-vertical_left {
      .alertInner {
        flex-wrap: wrap;
      }

      .wrapContent, .wrapButtons {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    &.buttonDisplay-vertical_right {
      .wrapButtons {
        margin-top: 0.6em;
      }
    }

    &.buttonDisplay-vertical_left {
      .wrapButtons {
        margin-bottom: 0.6em;
      }
    }

    .wrapContent {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
    }

    .contentInner, .alertThumbnail {
      flex-basis: 100%;
      max-width: 100%;
    }

    .alertThumbnail {
      img {
        &[src$=".jpg"] {
          padding: 2px;
          border: 2px solid $white;
          background-clip: padding-box;
          border-radius: 100%;
        }
      }
    }

    .fakeBackgroundImage {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      filter: grayscale(100%);
      opacity: 0.9;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .dismissAlert {
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 20;
      transition: border-color 0.2s linear, transform 0.05s linear, opacity 0.05s linear;

      &:hover, &:active {
      }

      &:active {
        transform: scale(1.1);
      }

      &.vanishing {
        transform: scale(0);
        opacity: 0;
      }

      .icon {
        transition: opacity 0.2s linear;

        &:not(:first-of-type) {
          position: absolute;
          left: 10px;
          top: 9px;
          opacity: 0;
        }
      }
    }

    .alertInner {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: flex-start;
      padding: 0.6em 1.2em;
      border: 1px solid $white;
      background-color: $turquoise;
    }

    h4 {
      color: $white;
      display: inline;
      text-transform: uppercase;

      &::after {
        content: ' -- ';
      }
    }

    .entryContent, .entry-content {
      display: inline;

      p {
        margin-bottom: 0;

        &:first-of-type {
          display: inline;
        }

        &:not(:first-of-type) {
          margin-top: 1em;
        }
      }

      a:not(.button) {
        &, &:link, &:visited {
          color: inherit;
        }
      }
    }

    .wrapButtons {
      white-space: nowrap;
    }

    .wrapButton {
      &:not(:last-of-type) {
        margin-bottom: 0.4em;
      }

      &.buttonStyle-link .button {
        color: $white;
        background-color: transparent;

        &:hover, &:active {
          border-color: transparent;
          outline-color: transparent;
          text-decoration: underline;
        }
      }

      &.buttonStyle-outline .button {
        background-clip: padding-box;
        background-color: transparent;
        //border-width: 2px;
        border-color: $white;
        color: $white;

        &:hover, &:active {
          border-color: transparent;
          background-color: $white;
          color: $black;
        }
      }
    }

    .button {
      padding: 0.6em 1.2em;
      border-radius: 0;
      text-transform: none;
      background-color: $white;
      background-clip: padding-box;
      color: $black;
      border: 1px solid $white;
      outline: 2px solid transparent;
      transition: all 0.2s ease;

      &:hover, &:active {
        border-color: transparent;
        outline-color: $white;
      }
    }
  }
}

// "Thèmes" d'alertes (niveaux d'alerte)

#site .alert {
  //Information système
  &.alertLevel-notice {
    border: 1px dashed $turquoise;
    color: $black;

    &.alertImage-background {
      .alertInner {
        background-color: rgba($white, 0.8);
      }
    }

    .alertThumbnail {
      img {
        &[src$=".jpg"] {
          border-color: $turquoise;
        }
      }
    }

    .dismissAlert {
      background-color: $white;
      border-color: $green;

      &:hover, &:active {
        border-color: $turquoise;

        .icon {
          &:first-of-type {
            opacity: 0;
          }

          &:not(:first-of-type) {
            opacity: 1;
          }
        }
      }

      .icon {
        &:first-of-type {
          background-position: 0 -2561px;
        }

        &:not(:first-of-type) {
          background-position: 0 -2481px;
        }
      }
    }

    .alertInner {
      background-color: $white;
    }

    .wrapButton {
      &.buttonStyle-outline .button {
        border-color: $turquoise;
        color: $turquoise;

        &:hover, &:active {
          color: $white;
          background-color: $turquoise;
          border-color: transparent;
          outline-color: $turquoise;
        }
      }

      &.buttonStyle-link .button {
        color: $turquoise;
      }
    }

    .button {
      color: $white;
      border-color: $turquoise;
      background-color: $turquoise;

      &:hover, &:active {
        border-color: transparent;
        outline-color: $turquoise;
      }
    }

    h4 {
      color: $turquoise;
    }
  }

  &.alertLevel-success {
    border-color: $black;

    &.alertImage-background {
      .alertInner {
        background-color: rgba($green, 0.8);
      }
    }

    .alertInner {
      background-color: $green;
    }

    .dismissAlert {
      background-color: $black;
    }
  }

  &.alertLevel-warning {
    color: $black;
    border-color: $black;

    &.alertImage-background {
      .alertInner {
        background-color: rgba($orange, 0.8);
      }
    }

    .alertInner {
      background-color: $orange;
    }

    .dismissAlert {
      background-color: $black;
    }

    h4 {
      color: $black;
    }

    .wrapButton {
      &.buttonStyle-outline .button {
        border-color: $black;
        color: $black;

        &:hover, &:active {
          color: $white;
          background-color: $black;
          border-color: transparent;
          outline-color: $black;
        }
      }

      &.buttonStyle-link .button {
        color: $black;
      }
    }

    .button {
      color: $white;
      background-color: $black;
      border-color: $black;

      &:hover, &:active {
        outline-color: $black;
        border-color: transparent;
      }
    }
  }

  &.alertLevel-error {
    border-color: $black;

    &.alertImage-background {
      .alertInner {
        background-color: rgba($red, 0.8);
      }
    }

    .alertInner {
      background-color: $red;
    }

    .dismissAlert {
      background-color: $black;
    }
  }
}


/*** Styles de la liste des billets de blogue/posts/articles (archive) **************************/

.blogposts {
  .blogpost {

    &:not(:last-of-type) {
      margin-bottom: 2em;
    }

    //Retraités
    &.postCategory-269 {
      .content {
        h4 {
          color: $turquoise;

          a {
            &, &:link, &:visited {
              color: $turquoise;
            }

            &:hover, &:active {
              color: $darkturquoise;
            }
          }
        }

        .entryContent .excerpt-read-more, .entry-content .excerpt-read-more {
          &, &:link, &:visited {
            color: $turquoise;
          }

          &:hover, &:active {
            color: $darkturquoise;
          }
        }
      }
    }

    //Employeurs
    &.postCategory-270 {
      .content {
        h4 {
          color: $mauve;

          a {
            &, &:link, &:visited {
              color: $mauve;
            }

            &:hover, &:active {
              color: $darkmauve;
            }
          }
        }

        .entryContent .excerpt-read-more, .entry-content .excerpt-read-more {
          &, &:link, &:visited {
            color: $mauve;
          }

          &:hover, &:active {
            color: $darkmauve;
          }
        }
      }
    }

    .image {
      flex-grow: 1;

      a {
        display: block;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 275px;
      }
    }

    .content {
      flex-shrink: 99;
      flex-grow: 1;

      & > header {
        margin-top: 0.6em;

        .postCategories {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.6em;
          margin-bottom: 0;

          li {
            &:not(:last-of-type) {
              margin-right: 0.4em;
              margin-bottom: 0.2em;
            }

            &.category-269 {
              a {
                &, &:link, &:visited {
                  background-color: $turquoise;
                }

                &:hover, &:active {
                  background-color: $darkturquoise;
                }
              }
            }

            &.category-270 {
              a {
                &, &:link, &:visited {
                  background-color: $mauve;
                }

                &:hover, &:active {
                  background-color: $darkmauve;
                }
              }
            }

            a {
              position: relative;
              bottom: 0.2em;
              display: block;
              padding: 0.2em 0.6em;
              text-decoration: none;
              transition: background-color 0.2s ease;

              &, &:link, &:visited {
                font-weight: 700;
                background-color: $midgray;
                color: $white;
              }

              &:hover, &:active {
                background-color: $black;
              }

              span {
                font-size: 0.8em;
              }
            }
          }
        }

        time {
          margin-right: 0.8em;
          font-size: 1.1em;
          font-weight: 800;
          font-style: italic;
          color: $midgray;
        }

        h4 {
          margin-bottom: 0.2em;
          font-size: 1.7em;
          font-weight: 700;
          font-style: italic;
          color: $midgray;

          a {
            text-decoration: none;
            transition: color 0.2s ease;

            &, &:link, &:visited {
              color: $midgray;
            }

            &:hover, &:active {
              color: $black;
            }
          }
        }
      }

      .entryContent, .entry-content {
        font-size: 0.9em;

        .excerpt-read-more {
          text-decoration: none;

          &, &:link, &:visited {
            color: $midgray;
          }

          &:hover, &:active {
            text-decoration: underline;
            color: $black;
          }
        }
      }
    }
  }
}

//Contrôles du blogue / Menu mobile du blogue

.blogControls {
  margin-bottom: 2em;

  & > div {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  .linkList li {
    display: inline-block;
    font-size: 0.9em;
  }
}


/*** Styles du single (un seul article de blogue) *****************************************/

#main .blogSingle {
  .singleHeader {
    margin-bottom: 2em;

    &::after {
      content: '';
      display: block;
      margin-top: 2em;
      width: 100%;
      max-width: 160px;
      border-bottom: 2px solid $turquoise;
    }

    .innerHeader {
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex-basis: 100%;
        max-width: 100%;
      }
    }


    .postInfo {
      display: inline-flex;
      align-items: center;
      margin-bottom: 1em;

      time {
        display: flex;
        align-items: baseline;
        margin-right: 1em;
        font-style: italic;
        color: $midgray;

        .date {
          font-size: 1.1em;
          margin-right: 0.5em;
        }
      }

      .comments {

        &, a {
          display: inline-flex;
          align-items: center;
        }

        a {
          text-decoration: none;

          &, &:link, &:visited {
            color: $black;
          }

          &:hover, &:active {
            .icon {
              filter: none;
            }

            .number {
              color: $turquoise;
            }
          }
        }

        .icon {
          display: inline-block;
          width: 21px;
          height: 21px;
          margin-right: 0.2em;
          background-image: url('/images/sprite_icon.png');
          background-repeat: no-repeat;
          background-position: 0 -3070px;
          text-indent: -99999px;
          overflow: hidden;
          filter: grayscale(100%) brightness(140%);
          transition: filter 0.2s ease;
        }

        .number {
          color: darken($gray, 7%);
          transition: color 0.2s ease;
        }
      }
    }

    .share {
      & > .button {
        padding: 0.5em;
        border-radius: 0.25em;

        .buttonInner {
          display: flex;
          align-items: center;
        }

        .icon {
          display: inline-block;
          width: 21px;
          height: 21px;
          margin-right: 0.5em;
          background-image: url('/images/sprite_icon.png');
          background-repeat: no-repeat;
          background-position: 0 -3120px;
        }

        .label {
          font-weight: 400;
          text-transform: none;
          color: $white;
        }
      }
    }
  }

  .singleFooter {
    margin-top: 2em;
    margin-bottom: 2em;

    &::after {
      content: '';
      display: block;
      margin-top: 2em;
      width: 100%;
      max-width: 67px;
      border-bottom: 2px solid $turquoise;
    }
  }

  .singleComments {
    & > header {
      margin-bottom: 2em;
      /*
                        &::after {
                            content: '';
                            display: block;
                            margin-top: 1em;
                            width: 100%;
                            max-width: 220px;
                            border-bottom: 2px solid darken($lightgray, 10%);
                        }
             */
      .number {
        &:not(.comments-0) {
          // font-weight: 400;
          // font-size: 1.4em;
        }
      }

      .label {
        display: inline;
      }
    }

    .newComment, .replyToComment {
      margin-bottom: 2em;

      form {
        display: flex;

        .avatar {
          flex-grow: 0;
          flex-shrink: 0;
          margin-right: 0.5em;

          img {
            width: 40px;
            height: auto;
            border-radius: 100%;
            border: 3px solid $turquoise;
          }
        }

        .theForm, .notLoggedIn {
          flex-grow: 1;
        }

        .notLoggedIn, .theForm {
          p {
            margin-bottom: 0.4em;
            font-size: 1.05em;
            color: lighten($black, 20%);
          }

          footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .replyTo {
            a {
              display: flex;
              align-items: center;
              text-decoration: none;
              transition: color 0.2s ease;

              &, &:link, &:visited {
                color: lighten($black, 10%);
              }

              &:hover, &:active {
                color: $black;

                .icon {
                  filter: brightness(90%);
                }

                .username {
                  color: $darkturquoise;
                }
              }
            }

            .icon {
              display: inline-block;
              width: 20px;
              height: 18px;
              margin-right: 0.5em;
              background-image: url('/images/sprite_icon.png');
              background-repeat: no-repeat;
              background-position: 0 -3170px;
              transition: filter 0.2s ease;
            }

            .label {
              word-break: break-all;
              color: inherit;
              font-weight: 400;
            }

            .username {
              font-weight: 700;
              color: $turquoise;
              transition: color 0.2s ease;
            }
          }

          .buttons {
            margin: 0;
            padding: 0;
            border: 0;
            text-align: left;
          }

          button, .button {
            padding: 0.3em 0.8em;
            font-size: 0.9em;
            font-weight: 400;
            text-transform: none;
            border-radius: 0.25em;
            border: 2px solid $turquoise;
            transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;

            &:hover, &:active {
              border-color: $darkturquoise;
            }

            &:not(:first-child) {
              margin-left: 0;
            }

            &:not(:last-child) {
              margin-right: 1em;
              margin-bottom: 0.5em;
            }

            &.buttonLink {
              border-color: transparent;

              &:hover, &:active {
                border-color: transparent;
              }
            }

            &.register {
              color: $turquoise;
              background-color: transparent;

              &:hover, &:active {
                border-color: $darkturquoise;
                color: $darkturquoise;
              }
            }
          }
        }

        .theForm {
          textarea {
            min-height: 3em;
            width: 100%;
            resize: vertical;
            //border: 3px solid $turquoise;
          }

          .buttons {
            margin-top: 0.5em;
            text-align: right;

            button, .button {
              font-size: 1em;
            }
          }
        }
      }
    }

    .commentList {
      .comment {
        margin-bottom: 2em;

        &.child {
          margin-left: 1em;
        }

        .commentInner {
          display: flex;
        }

        .avatar {
          flex-shrink: 0;
          margin-right: 1em;

          img {
            display: block;
            width: 40px;
            height: auto;
            border-radius: 100%;
            border: 3px solid $turquoise;
          }
        }

        .theComment {
          flex-grow: 99;

          header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .isReplyTo {
              display: inline-block;
              margin-right: 0.5em;

              a {
                display: flex;
                align-items: center;
                text-decoration: none;

                &:hover, &:active {
                  .icon {
                    filter: brightness(90%);
                  }
                }
              }

              .icon {
                display: inline-block;
                width: 20px;
                height: 18px;
                background-image: url('/images/sprite_icon.png');
                background-repeat: no-repeat;
                background-position: 0 -3170px;
                text-indent: -99999px;
                transition: filter 0.2s ease;
              }
            }

            .authorName {
              margin-right: 0.3em;
              color: $turquoise;
              font-weight: 700;
            }

            .separator, time {
              color: darken($lightgray, 30%);
            }

            time .time {
              font-size: 0.9em;
            }

            .separator {
              display: none;
              margin-right: 0.3em;
            }
          }

          .commentBody {
            margin-bottom: 0.2em;
          }

          footer {
            .links {
              display: flex;
              align-items: baseline;

              &::before {
                content: '';
                display: inline-block;
                align-self: center;
                width: 1.6em;
                margin-right: 0.4em;
                border-bottom: 2px solid darken($lightgray, 20%);
              }

              ///Liens Répondre et Permalien

              a {
                font-size: 0.9em;
                font-style: italic;
                text-decoration: none;
                transition: color 0.2s ease;

                &, &:link, &:visited {
                  color: darken($lightgray, 30%);
                }

                &:hover, &:active {
                  color: $black;
                }

                &:not(:last-child) {
                  margin-right: 0.5em;
                }
              }
            }
          }
        }

        //Formulaire de réponse à un commentaire

        .replyContainer {
          display: none;
          opacity: 0;
          margin-left: 1em;
          margin-top: 1em;
          transition: opacity 0.2s ease;

          &.appearing {
            opacity: 1;
          }

          form .theForm {
            footer {
              flex-wrap: wrap;

              .replyTo {
                flex-basis: 100%;
                max-width: 100%;
                flex-grow: 1;
                margin-top: 0.4em;
              }
            }

            .buttons {
              .button, button {
                font-size: 0.9em;

                &:not(:last-child) {
                  margin-right: 0.5em;
                }
              }
            }
          }
        }
      }

      .tmpReplyContainer {
        display: none;
      }
    }
  }
}


//Formulaire de recherche - Affecte celui de la sidebar et celui "mobile" au-dessus du blogue

.search.form,
#main.hasSidebar .innerMain > aside .sidebarInner > .search {
  form {
    display: flex;

    input {
      margin-right: 0.5em;
    }

    button {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0.2em 0.4em;
      background-color: transparent;
      border-radius: 0;

      &:hover, &:active {
        .icon {
          &:not(.hover) {
            opacity: 0;
          }

          &.hover {
            opacity: 1;
          }
        }
      }

      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        text-indent: -99999px;
        background-image: url('/images/sprite_icon.png');
        background-repeat: no-repeat;
        background-position: 0 -2500px;
        transition: opacity 0.2s linear;

        &.hover {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          background-position: 0 -2550px;
          opacity: 0;
        }
      }
    }
  }
}

//Liste de liens en "blocs" - Affecte la sidebar et l'espèce de menu mobile au-dessus du blogue

.linkList {
  &.categories {
    li {
      &.active {
        &.category-269 {
          a {
            background-color: transparent;

            &, &:link, &:visited {
              color: $turquoise;
            }

            &:hover, &:active {
              background-color: transparent;
              color: $darkturquoise;
              border-color: $darkturquoise;
            }
          }
        }

        &.category-270 {
          a {
            background-color: transparent;

            &, &:link, &:visited {
              color: $mauve;
            }

            &:hover, &:active {
              background-color: transparent;
              color: $darkmauve;
              border-color: $darkmauve;
            }
          }
        }

        a {
          background-color: transparent;

          &, &:link, &:visited {
            color: $midgray;
          }

          &:hover, &:active {
            background-color: transparent;
            color: $black;
            border-color: $black;
          }
        }
      }

      //Général
      &.category-1 {

      }

      //Retraités
      &.category-269 {
        a {
          background-color: $turquoise;
          border-color: $turquoise;

          &:hover, &:active {
            border-color: $darkturquoise;
            background-color: $darkturquoise;
          }
        }
      }

      //Employeurs
      &.category-270 {
        a {
          background-color: $mauve;
          border-color: $mauve;

          &:hover, &:active {
            border-color: $darkmauve;
            background-color: $darkmauve;
          }
        }
      }

      a {
        background-color: $midgray;
        border-color: $midgray;

        &:hover, &:active {
          border-color: $black;
          background-color: $black;
        }
      }
    }
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 0.4em;
    }
  }

  a {
    display: inline-block;
    padding: 0.4em 1em;
    font-weight: 700;
    text-decoration: none;
    border: 3px solid $midgray;
    background-color: $midgray;
    transition: all 0.2s ease;

    &, &:link, &:visited {
      color: $white;
    }

    &:hover, &:active {
      background-color: $black;
      border-color: $black;
    }
  }
}


.pagination {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    font-size: 0.9em;

    & > li {
      &:not(:last-of-type) {
        margin-right: 0.5em;
      }

      &.ellipsis {
        span {
          position: relative;
          bottom: 0.15em;
          line-height: 0;
          font-size: 1.4em;
          letter-spacing: 0.04em;
          color: rgba($black, 0.3);
        }
      }

      &.button a {
        padding: 0 0.2em;
      }

      &.first, &.last {
        a {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
      }

      &.first {
        margin-right: 0.9em;

        .icon {
          width: 20px;
          height: 13px;
          background-position: 0 -2920px;
        }
      }

      &.last {
        margin-left: 0.4em;

        .icon {
          width: 20px;
          height: 13px;
          background-position: 0 -2820px;
        }
      }

      &.previous {
        margin-right: 2.1em;

        .icon {
          width: 28px;
          height: 28px;
          background-position: 0 -3020px;
        }
      }

      &.next {
        margin-left: 1.6em;

        .icon {
          width: 28px;
          height: 28px;
          background-position: 0 -2770px;
        }
      }

      &.number {
        a {
          min-width: 2.6em;
          padding-left: 0.6em;
          padding-right: 0.6em;
          text-align: center;
          background-color: rgba($black, 0.15);

          &, &:link, &:visited {
            color: $black;
          }

          &:hover, &:active {
            color: $black;
            background-color: rgba($black, 0.4);
          }

          padding: 0.4em;
        }
      }

      /* 			&.number.active {
                            a {
                                position: relative;
                                display: flex;
                                align-items: center;
                                height: 0;
                                width: 2.4em;
                                padding-top: 0;
                                //Ratio d'aspect 1:1
                                padding-bottom: 100%;
                                background-color: $turquoise;
                                border-radius: 100%;

                                &, &:link, &:visited {
                                    color: $white;
                                }

                                &:hover, &:active {
                                    color: $white;
                                    background-color: $darkturquoise;
                                }

                                span {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    font-size: 1.1em;
                                }
                            }
                        } */

      &.number.active {
        a {
          background-color: $turquoise;

          &, &:link, &:visited {
            color: $white;
          }

          &:hover, &:active {
            color: $white;
            background-color: $darkturquoise;
          }

          span {
            //font-size: 1.4em;
            //font-weight: 700;
          }
        }
      }

      a {
        position: relative;
        display: block;
        text-decoration: underline;
        transition: color 0.2s ease, background-color 0.2s ease;

        &, &:link, &:visited {
          color: $black;
        }

        &:hover, &:active {
          color: $turquoise;

          .icon {
            filter: brightness(85%);
          }
        }

      }


      .icon {
        display: block;
        text-indent: -99999px;
        overflow: hidden;
        background-image: url('/images/sprite_icon.png');
        background-repeat: no-repeat;
        transition: filter 0.2s ease;
      }
    }
  }

}

//Single de blogue (un seul article)
.single {
  .headerText .postCategories {
    font-size: 0.8em;
  }
}

.cvRegister {
  position: absolute;
  right: 10px;
  top: 10px;
  max-width: calc(100% - 198px);
}

#home-search {
  border-radius: 10px;
  background-color: rgba($turquoise, 0.8);
  width: 95%;
  margin: auto;
  padding: 1em;

  button {
    background-color: rgba($mauve, 0.8);
  }

  label {
    color: #fff !important;
  }

  input, select {
    width: 100%;
  }

  ul {
    position: static !important;
  }
}

#home-search-simplified {
  border-radius: 10px;
  width: 28%;
  margin: auto;
  padding: 1em;
  text-align: center;
  z-index: 10;

  button {
    background-color: rgba($mauve, 0.8);
  }

  label {
    color: #6a6a6a !important;
    font-weight: bold;
    font-size: 1.4rem;
  }

  input, select {
    width: 100%;
  }

  ul {
    position: static !important;
  }

  .animated-arrow {
    position: absolute;
    top: 90%;
    right: 37%;
    width: 100px;
    height: 80px;
    background-image: url('/images/arrow-homepage.svg');
    background-size: contain;
    background-repeat: no-repeat;
    animation: arrowAnimation 3000ms forwards;
    opacity: 0;
    transition: opacity 100ms ease;
  }

  @keyframes arrowAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

#searchfilters {
  min-width: 100px;
  max-width: 300px !important;

  form#filter {
    .filter-container {
      margin: auto;
    }
  }
}

span.select2-selection__arrow {
  background-color: rgba($mauve, 0.8);

  b {
    border-color: white transparent transparent transparent !important;
  }
}

body #main .select2 input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]) {
  border: 0;
}

body #main .select2-container {
  width: 100% !important;
}

.select2-selection__arrow {
  display: none !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border-color: #479a99;
}

.select2-container .select2-selection--single {
  height: 36px;
  padding-top: 4px;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 400px;
}

#triCityDiv > div > span {
  border: 0;
}

#filter > div.formRow.filter-container {
  > div:nth-child(1) > div > span {
    border: 0;
  }

  > div:nth-child(2) > div > span {
    border: 0;
  }

  > div:nth-child(3) > div > span {
    border: 0;
  }

  > div:nth-child(4) > div > span {
    border: 0;
  }

  > div:nth-child(5) > div > span {
    border: 0;
  }
}

/*** Importation du partial pour les librairies **************************************************/
@import "partials/manager";

//@import "partials/library";
@import "partials/magnific-popup";

/*** Importation du partial pour le responsive ***************************************************/
@import "partials/responsive";

// I can't confirm if using laravel-elixir instead of codekitapp would break something so
// I'm not going to use this for now.
// TODO: use me, merge `feature/use-laravel-elixir` and delete `public/announcement.css`.
//@import "partials/announcement";

.smallipop-hint {
  display: none;
}

.smallipop-instance {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  background-color: #314b64;
  border: 1px solid #0f161e;
  color: #d2dfe7;
  z-index: 9999;
  max-width: 400px;
}
.smallipop-instance font {
  size: 11px;
  family: arial;
}
.smallipop-instance a {
  color: #98cbea;
}
.smallipop-instance:before, .smallipop-instance:after {
  content: '';
  position: absolute;
  left: 50%;
  height: 0;
  width: 0;
  pointer-events: none;
}
.smallipop-instance:before {
  bottom: -20px;
  margin-left: -10px;
  border: 10px solid transparent;
}
.smallipop-instance:after {
  bottom: -24px;
  margin-left: -12px;
  border: 12px solid transparent;
}

.smallipop-align-left:before, .smallipop-align-left:after {
  margin-left: 0;
  left: auto;
  right: 20px;
}
.smallipop-align-left:after {
  right: 18px;
}

.smallipop-align-right:before, .smallipop-align-right:after {
  margin-left: 0;
  left: 20px;
  right: auto;
}
.smallipop-align-right:after {
  left: 18px;
}

.smallipop-bottom:before, .smallipop-bottom:after {
  bottom: auto;
  top: -20px;
}
.smallipop-bottom:after {
  top: -24px;
}

.smallipop-left:before, .smallipop-left:after,
.smallipop-right:before,
.smallipop-right:after {
  right: -16px;
  left: auto;
  top: 50%;
  bottom: auto;
  border-width: 8px;
  margin: -8px 0 0;
}
.smallipop-left:after,
.smallipop-right:after {
  right: -20px;
  border-width: 10px;
  margin: -10px 0 0;
}

.smallipop-right:before, .smallipop-right:after {
  left: -16px;
  right: auto;
}
.smallipop-right:after {
  left: -20px;
}

.smallipop-content {
  padding: 10px;
}

#smallipop-tour-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.smallipop-tour-content {
  padding: 5px 0;
  min-width: 150px;
}

.smallipop-tour-footer {
  padding-top: 5px;
  position: relative;
  overflow: hidden;
  *zoom: 1;
}

.smallipop-tour-progress {
  color: #bbb;
  text-align: center;
  position: absolute;
  left: 50%;
  width: 80px;
  margin-left: -40px;
  top: 8px;
}

.smallipop-tour-close-icon {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  padding-top: 0px;
  font-size: 11px;
  background: #555;
  color: #ccc;
  text-align: center;
  text-shadow: 0 -1px 1px #666;
  text-decoration: none;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.smallipop-tour-close-icon:hover {
  text-decoration: none;
  background: #666;
  color: #fff;
}

.smallipop-tour-prev,
.smallipop-tour-next,
.smallipop-tour-close {
  color: #ccc;
  display: block;
  padding: 3px 4px 2px;
  line-height: 1em;
  float: left;
  background: #203142;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.smallipop-tour-prev:hover,
.smallipop-tour-next:hover,
.smallipop-tour-close:hover {
  color: #fff;
  background: #293e53;
  text-decoration: none;
}

.smallipop-tour-next,
.smallipop-tour-close {
  float: right;
}

/* default theme */
.smallipop-theme-default {
  text-shadow: 0 -1px 1px #0f161e;
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMxNGI2NCIgc3RvcC1vcGFjaXR5PSIwLjkiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYTI2MzQiIHN0b3Atb3BhY2l0eT0iMC45Ii8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(49, 75, 100, 0.9)), color-stop(100%, rgba(26, 38, 52, 0.9)));
  background: -moz-linear-gradient(rgba(49, 75, 100, 0.9), rgba(26, 38, 52, 0.9));
  background: -webkit-linear-gradient(rgba(49, 75, 100, 0.9), rgba(26, 38, 52, 0.9));
  background: linear-gradient(rgba(49, 75, 100, 0.9), rgba(26, 38, 52, 0.9));
  /* Fallback for opera */
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9Ii0xMDBweCIgcj0iMTUwJSI+PHN0b3Agb2Zmc2V0PSI2Ni42NjY2NyUiIHN0b3AtY29sb3I9IiMzMTRiNjQiIHN0b3Atb3BhY2l0eT0iMC45Ii8+PHN0b3Agb2Zmc2V0PSI4Ni42NjY2NyUiIHN0b3AtY29sb3I9IiMyMTMyNDIiIHN0b3Atb3BhY2l0eT0iMC45Ii8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMWEyNjM0IiBzdG9wLW9wYWNpdHk9IjAuOSIvPjwvcmFkaWFsR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-radial-gradient(50% -100px, circle contain, rgba(49, 75, 100, 0.9) 100px, rgba(33, 50, 66, 0.9) 130px, rgba(26, 38, 52, 0.9) 150px);
  background: -webkit-radial-gradient(50% -100px, circle contain, rgba(49, 75, 100, 0.9) 100px, rgba(33, 50, 66, 0.9) 130px, rgba(26, 38, 52, 0.9) 150px);
  background: radial-gradient(circle contain at 50% -100px, rgba(49, 75, 100, 0.9) 100px, rgba(33, 50, 66, 0.9) 130px, rgba(26, 38, 52, 0.9) 150px);
}
.smallipop-theme-default a {
  text-shadow: 0 -1px 1px #0f161e;
}
.smallipop-theme-default .smallipop-content {
  border-top: 1px solid #586d82;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.smallipop-theme-default:before {
  border-color: #1a2634 transparent transparent transparent;
}
.smallipop-theme-default:after {
  border-color: #0f161e transparent transparent transparent;
}
.smallipop-theme-default.smallipop-bottom:before {
  border-color: transparent transparent #1a2634 transparent;
}
.smallipop-theme-default.smallipop-bottom:after {
  border-color: transparent transparent #0f161e transparent;
}
.smallipop-theme-default.smallipop-left:before {
  border-color: transparent transparent transparent #1a2634;
}
.smallipop-theme-default.smallipop-left:after {
  border-color: transparent transparent transparent #0f161e;
}
.smallipop-theme-default.smallipop-right:before {
  border-color: transparent #1a2634 transparent transparent;
}
.smallipop-theme-default.smallipop-right:after {
  border-color: transparent #0f161e transparent transparent;
}

.cssgradients.rgba .smallipop-theme-default {
  background-color: transparent;
}

/* blue theme */
.smallipop-theme-blue {
  background: transparent;
  color: #111;
  border: 10px solid rgba(0, 100, 180, 0.9);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
.smallipop-theme-blue a {
  color: #2276aa;
}
.smallipop-theme-blue:after {
  bottom: -34px;
  border-color: rgba(0, 100, 180, 0.9) transparent transparent transparent;
}
.smallipop-theme-blue:before {
  display: none;
}
.smallipop-theme-blue.smallipop-bottom:after {
  top: -34px;
  border-color: transparent transparent rgba(0, 100, 180, 0.9) transparent;
}
.smallipop-theme-blue.smallipop-left {
  right: -26px;
}
.smallipop-theme-blue.smallipop-left:after {
  border-color: transparent transparent transparent rgba(0, 100, 180, 0.9);
}
.smallipop-theme-blue.smallipop-right {
  left: -26px;
}
.smallipop-theme-blue.smallipop-right:after {
  border-color: transparent rgba(0, 100, 180, 0.9) transparent transparent;
}
.smallipop-theme-blue .smallipop-content {
  border: 0;
  background: #fcfcfc;
}
.smallipop-theme-blue .smallipop-tour-progress {
  color: #777;
}
.smallipop-theme-blue .smallipop-tour-prev,
.smallipop-theme-blue .smallipop-tour-next,
.smallipop-theme-blue .smallipop-tour-close {
  color: #222;
  background: #efefef;
}
.smallipop-theme-blue .smallipop-tour-prev:hover,
.smallipop-theme-blue .smallipop-tour-next:hover,
.smallipop-theme-blue .smallipop-tour-close:hover {
  color: #111;
  background: #f7f7f7;
}

/* black theme */
.smallipop-theme-black {
  background-color: #222;
  border-color: #111;
  text-shadow: 0 -1px 1px #111;
  color: #f5f5f5;
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzIyMjIyMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #333333), color-stop(100%, #222222));
  background: -moz-linear-gradient(#333333, #222222);
  background: -webkit-linear-gradient(#333333, #222222);
  background: linear-gradient(#333333, #222222);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.smallipop-theme-black a {
  color: #eef8ff;
  text-shadow: 0 -1px 1px #111;
}
.smallipop-theme-black:before {
  border-color: #222 transparent transparent transparent;
}
.smallipop-theme-black:after {
  border-color: #111 transparent transparent transparent;
}
.smallipop-theme-black.smallipop-bottom:before {
  border-color: transparent transparent #222 transparent;
}
.smallipop-theme-black.smallipop-bottom:after {
  border-color: transparent transparent #111 transparent;
}
.smallipop-theme-black.smallipop-left:before {
  border-color: transparent transparent transparent #222;
}
.smallipop-theme-black.smallipop-left:after {
  border-color: transparent transparent transparent #111;
}
.smallipop-theme-black.smallipop-right:before {
  border-color: transparent #222 transparent transparent;
}
.smallipop-theme-black.smallipop-right:after {
  border-color: transparent #111 transparent transparent;
}
.smallipop-theme-black .smallipop-content {
  border-top: 1px solid #666;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.smallipop-theme-black .smallipop-tour-progress {
  color: #888;
}
.smallipop-theme-black .smallipop-tour-prev,
.smallipop-theme-black .smallipop-tour-next,
.smallipop-theme-black .smallipop-tour-close {
  color: #ccc;
  background: #333;
}
.smallipop-theme-black .smallipop-tour-prev:hover,
.smallipop-theme-black .smallipop-tour-next:hover,
.smallipop-theme-black .smallipop-tour-close:hover {
  color: #fff;
  background: #3a3a3a;
}

.cssgradients .smallipop-theme-black {
  background-color: transparent;
}

/* orange theme */
.smallipop-theme-orange {
  background-color: #f9aa18;
  border-color: #e17500;
  text-shadow: 0 1px 1px #fff24d;
  color: #714900;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZjI0ZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y5YWExOCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fff24d), color-stop(100%, #f9aa18));
  background: -moz-linear-gradient(#fff24d, #f9aa18);
  background: -webkit-linear-gradient(#fff24d, #f9aa18);
  background: linear-gradient(#fff24d, #f9aa18);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.smallipop-theme-orange a {
  color: #145d95;
  text-shadow: 0 1px 1px #fff24d;
}
.smallipop-theme-orange:before {
  border-color: #f9aa18 transparent transparent transparent;
}
.smallipop-theme-orange:after {
  border-color: #e17500 transparent transparent transparent;
}
.smallipop-theme-orange.smallipop-bottom:before {
  border-color: transparent transparent #f9aa18 transparent;
}
.smallipop-theme-orange.smallipop-bottom:after {
  border-color: transparent transparent #e17500 transparent;
}
.smallipop-theme-orange.smallipop-left:before {
  border-color: transparent transparent transparent #f9aa18;
}
.smallipop-theme-orange.smallipop-left:after {
  border-color: transparent transparent transparent #e17500;
}
.smallipop-theme-orange.smallipop-right:before {
  border-color: transparent #f9aa18 transparent transparent;
}
.smallipop-theme-orange.smallipop-right:after {
  border-color: transparent #e17500 transparent transparent;
}
.smallipop-theme-orange .smallipop-content {
  border-top: 1px solid #fffabc;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.smallipop-theme-orange .smallipop-tour-progress {
  color: #444;
}
.smallipop-theme-orange .smallipop-tour-prev,
.smallipop-theme-orange .smallipop-tour-next,
.smallipop-theme-orange .smallipop-tour-close {
  color: #444;
  background: #f19f06;
}
.smallipop-theme-orange .smallipop-tour-prev:hover,
.smallipop-theme-orange .smallipop-tour-next:hover,
.smallipop-theme-orange .smallipop-tour-close:hover {
  color: #333;
  background: #f9a509;
}

/* white theme */
.smallipop-theme-white {
  background-color: #fcfcfc;
  border-color: #ccc;
  text-shadow: 0 1px 1px #eee;
  color: #444;
  width: 200px;
  max-width: 200px;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.smallipop-theme-white:before {
  border-color: #fcfcfc transparent transparent transparent;
}
.smallipop-theme-white:after {
  border-color: #ccc transparent transparent transparent;
}
.smallipop-theme-white.smallipop-bottom:before {
  border-color: transparent transparent #fcfcfc transparent;
}
.smallipop-theme-white.smallipop-bottom:after {
  border-color: transparent transparent #ccc transparent;
}
.smallipop-theme-white.smallipop-left:before {
  border-color: transparent transparent transparent #fcfcfc;
}
.smallipop-theme-white.smallipop-left:after {
  border-color: transparent transparent transparent #ccc;
}
.smallipop-theme-white.smallipop-right:before {
  border-color: transparent #fcfcfc transparent transparent;
}
.smallipop-theme-white.smallipop-right:after {
  border-color: transparent #ccc transparent transparent;
}
.smallipop-theme-white .smallipop-content {
  text-align: center;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.smallipop-theme-white .smallipop-tour-progress {
  color: #777;
}
.smallipop-theme-white .smallipop-tour-close-icon {
  background: #fafafa;
  color: #555;
  text-shadow: 0 1px 1px #fff;
}
.smallipop-theme-white .smallipop-tour-close-icon:hover {
  background: #f5f5f5;
  color: #222;
}
.smallipop-theme-white .smallipop-tour-prev,
.smallipop-theme-white .smallipop-tour-next,
.smallipop-theme-white .smallipop-tour-close {
  color: #666;
  background: #f0f0f0;
}
.smallipop-theme-white .smallipop-tour-prev:hover,
.smallipop-theme-white .smallipop-tour-next:hover,
.smallipop-theme-white .smallipop-tour-close:hover {
  color: #333;
  background: #f4f4f4;
}

/* white theme extended, requires rgba support */
.smallipop-theme-white-transparent {
  background-color: rgba(255, 255, 255, 0.8);
}
.smallipop-theme-white-transparent:before {
  bottom: -21px;
  border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
}
.smallipop-theme-white-transparent:after {
  border-color: transparent;
}
.smallipop-theme-white-transparent.sipAlignBottom:before {
  top: -21px;
  border-color: transparent transparent rgba(255, 255, 255, 0.8) transparent;
}
.smallipop-theme-white-transparent.sipPositionedLeft:before {
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.8);
}
.smallipop-theme-white-transparent.sipPositionedRight:before {
  border-color: transparent rgba(255, 255, 255, 0.8) transparent transparent;
}

/* fat shadow extension theme */
.smallipop-instance.smallipop-theme-fat-shadow {
  -moz-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
}

/* wide content extension theme */
.smallipop-instance.smallipop-theme-wide {
  max-width: 600px;
}

/*# sourceMappingURL=jquery.smallipop.css.map */

@charset "UTF-8";
/*** Inclusion des partials **********************************************************************/
/* normalize.css 2012-07-07T09:50 UTC - http://github.com/necolas/normalize.css */
/* ==========================================================================
	 HTML5 display definitions
	 ========================================================================== */
/**
 * Correct `block` display not defined in IE 8/9.
 */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic,800,800italic);
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

/**
 * Correct `inline-block` display not defined in IE 8/9.
 */
audio, canvas, video {
  display: inline-block; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9.
 * Hide the `template` element in IE, Safari, and Firefox < 22.
 */
[hidden], template {
  display: none; }

/* ==========================================================================
	 Base
	 ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* ==========================================================================
	 Links
	 ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background: transparent; }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active, a:hover {
  outline: 0; }

/* ==========================================================================
	 Typography
	 ========================================================================== */
/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari 5, and Chrome.
 */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.67em; }

/**
 * Address styling not present in IE 8/9, Safari 5, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
 */
b, strong, .strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari 5 and Chrome.
 */
dfn, em, .em {
  font-style: italic; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/*
 * proper formatting (http://blog.fontdeck.com/post/9037028497/hyphens)
*/
p {
  -webkit-hyphens: auto;
  -epub-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  margin: 0 0 1em; }

/*
 * Addresses margins set differently in IE6/7.
 */
pre {
  margin: 0; }

/**
 * Correct font family set oddly in Safari 5 and Chrome.
 */
code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre-wrap; }

/**
 * Set consistent quote types.
 */
q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
q:before, q:after {
  content: '';
  content: none; }

small, .small {
  font-size: 75%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* ==========================================================================
	Lists
========================================================================== */
/*
 * Addresses margins set differently in IE6/7.
 */
dl, menu, ol, ul {
  margin: 0; }

.entry-content dl, .entry-content menu, .entry-content ol, .entry-content ul, article dl, article menu, article ol, article ul {
  margin: 1em 0; }

dd {
  margin: 0; }

/*
 * Addresses paddings set differently in IE6/7.
 */
menu {
  padding: 0 0 0 40px; }

ol, ul {
  padding: 0;
  list-style-type: none; }

/*
 * Corrects list images handled incorrectly in IE7.
 */
nav ul, nav ol {
  list-style: none;
  list-style-image: none; }

/* ==========================================================================
	Embedded content
========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9.
 */
img {
  border: 0;
  vertical-align: top; }

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden; }

/* ==========================================================================
	 Figures
	 ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari 5.
 */
figure {
  margin: 0; }

/* ==========================================================================
	 Forms
	 ========================================================================== */
/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Correct font family not being inherited in all browsers.
 * 2. Correct font size not being inherited in all browsers.
 * 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 */
button, input, select, textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button, input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button, select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled], html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* ==========================================================================
	 Tables
	 ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.image-replacement, .ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.clearfix, .cf {
  zoom: 1; }
  .clearfix:before, .clearfix:after, .cf:before, .cf:after {
    content: "";
    display: table; }
  .clearfix:after, .cf:after {
    clear: both; }

/*
use the best ampersand
http://simplebits.com/notebook/2008/08/14/ampersands-2/
*/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: italic; }

img, embed, video, embed {
  max-width: 100%; }

body.stop-scrolling {
  height: 100%;
  overflow: hidden; }

.sweet-overlay {
  background-color: black;
  /* IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  /* IE8 */
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 10000; }

.sweet-alert {
  background-color: white;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 478px;
  padding: 17px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -256px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 99999; }

@media all and (max-width: 540px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 15px;
    right: 15px; } }

.sweet-alert h2 {
  color: #575757;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 25px 0;
  padding: 0;
  line-height: 40px;
  display: block; }

.sweet-alert p {
  color: #797979;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  position: relative;
  text-align: inherit;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal; }

.sweet-alert fieldset {
  border: none;
  position: relative; }

.sweet-alert .sa-error-container {
  background-color: #f1f1f1;
  margin-left: -17px;
  margin-right: -17px;
  overflow: hidden;
  padding: 0 10px;
  max-height: 0;
  webkit-transition: padding 0.15s, max-height 0.15s;
  transition: padding 0.15s, max-height 0.15s; }

.sweet-alert .sa-error-container.show {
  padding: 10px 0;
  max-height: 100px;
  webkit-transition: padding 0.2s, max-height 0.2s;
  transition: padding 0.25s, max-height 0.25s; }

.sweet-alert .sa-error-container .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ea7d7d;
  color: white;
  line-height: 24px;
  text-align: center;
  margin-right: 3px; }

.sweet-alert .sa-error-container p {
  display: inline-block; }

.sweet-alert .sa-input-error {
  position: absolute;
  top: 29px;
  right: 26px;
  width: 20px;
  height: 20px;
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.1s;
  transition: all 0.1s; }

.sweet-alert .sa-input-error::before, .sweet-alert .sa-input-error::after {
  content: "";
  width: 20px;
  height: 6px;
  background-color: #f06e57;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  left: 50%;
  margin-left: -9px; }

.sweet-alert .sa-input-error::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sweet-alert .sa-input-error::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sweet-alert .sa-input-error.show {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.sweet-alert input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #d7d7d7;
  height: 43px;
  margin-top: 10px;
  margin-bottom: 17px;
  font-size: 18px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 0 12px;
  display: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.sweet-alert input:focus {
  outline: none;
  box-shadow: 0px 0px 3px #c4e6f5;
  border: 1px solid #b4dbed; }

.sweet-alert input:focus::-moz-placeholder {
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.5; }

.sweet-alert input:focus:-ms-input-placeholder {
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.5; }

.sweet-alert input:focus::-webkit-input-placeholder {
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.5; }

.sweet-alert input::-moz-placeholder {
  color: #bdbdbd; }

.sweet-alert input:-ms-input-placeholder {
  color: #bdbdbd; }

.sweet-alert input::-webkit-input-placeholder {
  color: #bdbdbd; }

.sweet-alert.show-input input {
  display: block; }

.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative; }

.sweet-alert .la-ball-fall {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -27px;
  margin-top: 4px;
  opacity: 0;
  visibility: hidden; }

.sweet-alert button {
  background-color: #8CD4F5;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  -webkit-border-radius: 4px;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer; }

.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05); }

.sweet-alert button:hover {
  background-color: #7ecff4; }

.sweet-alert button:active {
  background-color: #5dc2f1; }

.sweet-alert button.cancel {
  background-color: #C1C1C1; }

.sweet-alert button.cancel:hover {
  background-color: #b9b9b9; }

.sweet-alert button.cancel:active {
  background-color: #a8a8a8; }

.sweet-alert button.cancel:focus {
  box-shadow: rgba(197, 205, 211, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset !important; }

.sweet-alert button[disabled] {
  opacity: .6;
  cursor: default; }

.sweet-alert button.confirm[disabled] {
  color: transparent; }

.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s; }

.sweet-alert button::-moz-focus-inner {
  border: 0; }

.sweet-alert[data-has-cancel-button=false] button {
  box-shadow: none !important; }

.sweet-alert[data-has-confirm-button=false][data-has-cancel-button=false] {
  padding-bottom: 40px; }

.sweet-alert .sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box; }

.sweet-alert .sa-icon.sa-error {
  border-color: #F27474; }

.sweet-alert .sa-icon.sa-error .sa-x-mark {
  position: relative;
  display: block; }

.sweet-alert .sa-icon.sa-error .sa-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F27474;
  display: block;
  top: 37px;
  border-radius: 2px; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px; }

.sweet-alert .sa-icon.sa-warning {
  border-color: #F8BB86; }

.sweet-alert .sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #F8BB86; }

.sweet-alert .sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #F8BB86; }

.sweet-alert .sa-icon.sa-info {
  border-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-info::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-info::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-success {
  border-color: #A5DC86; }

.sweet-alert .sa-icon.sa-success::before, .sweet-alert .sa-icon.sa-success::after {
  content: '';
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sweet-alert .sa-icon.sa-success::before {
  -webkit-border-radius: 120px 0 0 120px;
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px; }

.sweet-alert .sa-icon.sa-success::after {
  -webkit-border-radius: 0 120px 120px 0;
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0px 60px;
  transform-origin: 0px 60px; }

.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2; }

.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: white;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #A5DC86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sweet-alert .sa-icon.sa-custom {
  background-size: contain;
  border-radius: 0;
  border: none;
  background-position: center center;
  background-repeat: no-repeat; }

/*
 * Animations
 */
@-webkit-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7); }
  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }
  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7); }
  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }
  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@-webkit-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); } }

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); } }

@-webkit-keyframes slideFromTop {
  0% {
    top: 0%; }
  100% {
    top: 50%; } }

@keyframes slideFromTop {
  0% {
    top: 0%; }
  100% {
    top: 50%; } }

@-webkit-keyframes slideToTop {
  0% {
    top: 50%; }
  100% {
    top: 0%; } }

@keyframes slideToTop {
  0% {
    top: 50%; }
  100% {
    top: 0%; } }

@-webkit-keyframes slideFromBottom {
  0% {
    top: 70%; }
  100% {
    top: 50%; } }

@keyframes slideFromBottom {
  0% {
    top: 70%; }
  100% {
    top: 50%; } }

@-webkit-keyframes slideToBottom {
  0% {
    top: 50%; }
  100% {
    top: 70%; } }

@keyframes slideToBottom {
  0% {
    top: 50%; }
  100% {
    top: 70%; } }

.showSweetAlert[data-animation=pop] {
  -webkit-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s; }

.showSweetAlert[data-animation=none] {
  -webkit-animation: none;
  animation: none; }

.showSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideFromTop 0.3s;
  animation: slideFromTop 0.3s; }

.showSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideFromBottom 0.3s;
  animation: slideFromBottom 0.3s; }

.hideSweetAlert[data-animation=pop] {
  -webkit-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s; }

.hideSweetAlert[data-animation=none] {
  -webkit-animation: none;
  animation: none; }

.hideSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideToTop 0.4s;
  animation: slideToTop 0.4s; }

.hideSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideToBottom 0.3s;
  animation: slideToBottom 0.3s; }

@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s; }

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s; }

.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in; }

@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1; } }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1; } }

.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s; }

@-webkit-keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px; }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px; }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s; }

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #F8D486; }
  100% {
    border-color: #F8BB86; } }

@keyframes pulseWarning {
  0% {
    border-color: #F8D486; }
  100% {
    border-color: #F8BB86; } }

.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate; }

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }

.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate; }

@-webkit-keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Internet Explorer 9 has some special quirks that are fixed here */
/* The icons are not animated. */
/* This file is automatically merged into sweet-alert.min.js through Gulp */
/* Error icon */
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg) \9; }

/* Success icon */
.sweet-alert .sa-icon.sa-success {
  border-color: transparent\9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9; }

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-fall,
.la-ball-fall > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff; }

.la-ball-fall.la-dark {
  color: #333; }

.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor; }

.la-ball-fall {
  width: 54px;
  height: 18px; }

.la-ball-fall > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -moz-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite; }

.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms; }

.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms; }

.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms; }

.la-ball-fall.la-sm {
  width: 26px;
  height: 8px; }

.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px; }

.la-ball-fall.la-2x {
  width: 108px;
  height: 36px; }

.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px; }

.la-ball-fall.la-3x {
  width: 162px;
  height: 54px; }

.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px; }

/*
 * Animation
 */
@-webkit-keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    transform: translateY(145%); } }

@-moz-keyframes ball-fall {
  0% {
    opacity: 0;
    -moz-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -moz-transform: translateY(145%);
    transform: translateY(145%); } }

@-o-keyframes ball-fall {
  0% {
    opacity: 0;
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -o-transform: translateY(145%);
    transform: translateY(145%); } }

@keyframes ball-fall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-145%);
    -moz-transform: translateY(-145%);
    -o-transform: translateY(-145%);
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(145%);
    -moz-transform: translateY(145%);
    -o-transform: translateY(145%);
    transform: translateY(145%); } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.smallipop-hint {
  display: none; }

.smallipop-instance {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  background-color: #314b64;
  border: 1px solid #0f161e;
  color: #d2dfe7;
  z-index: 9999;
  max-width: 400px; }

.smallipop-instance font {
  size: 11px;
  family: arial; }

.smallipop-instance a {
  color: #98cbea; }

.smallipop-instance:before, .smallipop-instance:after {
  content: '';
  position: absolute;
  left: 50%;
  height: 0;
  width: 0;
  pointer-events: none; }

.smallipop-instance:before {
  bottom: -20px;
  margin-left: -10px;
  border: 10px solid transparent; }

.smallipop-instance:after {
  bottom: -24px;
  margin-left: -12px;
  border: 12px solid transparent; }

.smallipop-align-left:before, .smallipop-align-left:after {
  margin-left: 0;
  left: auto;
  right: 20px; }

.smallipop-align-left:after {
  right: 18px; }

.smallipop-align-right:before, .smallipop-align-right:after {
  margin-left: 0;
  left: 20px;
  right: auto; }

.smallipop-align-right:after {
  left: 18px; }

.smallipop-bottom:before, .smallipop-bottom:after {
  bottom: auto;
  top: -20px; }

.smallipop-bottom:after {
  top: -24px; }

.smallipop-left:before, .smallipop-left:after,
.smallipop-right:before,
.smallipop-right:after {
  right: -16px;
  left: auto;
  top: 50%;
  bottom: auto;
  border-width: 8px;
  margin: -8px 0 0; }

.smallipop-left:after,
.smallipop-right:after {
  right: -20px;
  border-width: 10px;
  margin: -10px 0 0; }

.smallipop-right:before, .smallipop-right:after {
  left: -16px;
  right: auto; }

.smallipop-right:after {
  left: -20px; }

.smallipop-content {
  padding: 10px; }

#smallipop-tour-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0; }

.smallipop-tour-content {
  padding: 5px 0;
  min-width: 150px; }

.smallipop-tour-footer {
  padding-top: 5px;
  position: relative;
  overflow: hidden;
  *zoom: 1; }

.smallipop-tour-progress {
  color: #bbb;
  text-align: center;
  position: absolute;
  left: 50%;
  width: 80px;
  margin-left: -40px;
  top: 8px; }

.smallipop-tour-close-icon {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  padding-top: 0px;
  font-size: 11px;
  background: #555;
  color: #ccc;
  text-align: center;
  text-shadow: 0 -1px 1px #666;
  text-decoration: none;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); }

.smallipop-tour-close-icon:hover {
  text-decoration: none;
  background: #666;
  color: #fff; }

.smallipop-tour-prev,
.smallipop-tour-next,
.smallipop-tour-close {
  color: #ccc;
  display: block;
  padding: 3px 4px 2px;
  line-height: 1em;
  float: left;
  background: #203142;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); }

.smallipop-tour-prev:hover,
.smallipop-tour-next:hover,
.smallipop-tour-close:hover {
  color: #fff;
  background: #293e53;
  text-decoration: none; }

.smallipop-tour-next,
.smallipop-tour-close {
  float: right; }

/* default theme */
.smallipop-theme-default {
  text-shadow: 0 -1px 1px #0f161e;
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMxNGI2NCIgc3RvcC1vcGFjaXR5PSIwLjkiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYTI2MzQiIHN0b3Atb3BhY2l0eT0iMC45Ii8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(49, 75, 100, 0.9)), color-stop(100%, rgba(26, 38, 52, 0.9)));
  background: -moz-linear-gradient(rgba(49, 75, 100, 0.9), rgba(26, 38, 52, 0.9));
  background: -webkit-linear-gradient(rgba(49, 75, 100, 0.9), rgba(26, 38, 52, 0.9));
  background: linear-gradient(rgba(49, 75, 100, 0.9), rgba(26, 38, 52, 0.9));
  /* Fallback for opera */
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9Ii0xMDBweCIgcj0iMTUwJSI+PHN0b3Agb2Zmc2V0PSI2Ni42NjY2NyUiIHN0b3AtY29sb3I9IiMzMTRiNjQiIHN0b3Atb3BhY2l0eT0iMC45Ii8+PHN0b3Agb2Zmc2V0PSI4Ni42NjY2NyUiIHN0b3AtY29sb3I9IiMyMTMyNDIiIHN0b3Atb3BhY2l0eT0iMC45Ii8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMWEyNjM0IiBzdG9wLW9wYWNpdHk9IjAuOSIvPjwvcmFkaWFsR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background: -moz-radial-gradient(50% -100px, circle contain, rgba(49, 75, 100, 0.9) 100px, rgba(33, 50, 66, 0.9) 130px, rgba(26, 38, 52, 0.9) 150px);
  background: -webkit-radial-gradient(50% -100px, circle contain, rgba(49, 75, 100, 0.9) 100px, rgba(33, 50, 66, 0.9) 130px, rgba(26, 38, 52, 0.9) 150px);
  background: radial-gradient(circle contain at 50% -100px, rgba(49, 75, 100, 0.9) 100px, rgba(33, 50, 66, 0.9) 130px, rgba(26, 38, 52, 0.9) 150px); }

.smallipop-theme-default a {
  text-shadow: 0 -1px 1px #0f161e; }

.smallipop-theme-default .smallipop-content {
  border-top: 1px solid #586d82;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }

.smallipop-theme-default:before {
  border-color: #1a2634 transparent transparent transparent; }

.smallipop-theme-default:after {
  border-color: #0f161e transparent transparent transparent; }

.smallipop-theme-default.smallipop-bottom:before {
  border-color: transparent transparent #1a2634 transparent; }

.smallipop-theme-default.smallipop-bottom:after {
  border-color: transparent transparent #0f161e transparent; }

.smallipop-theme-default.smallipop-left:before {
  border-color: transparent transparent transparent #1a2634; }

.smallipop-theme-default.smallipop-left:after {
  border-color: transparent transparent transparent #0f161e; }

.smallipop-theme-default.smallipop-right:before {
  border-color: transparent #1a2634 transparent transparent; }

.smallipop-theme-default.smallipop-right:after {
  border-color: transparent #0f161e transparent transparent; }

.cssgradients.rgba .smallipop-theme-default {
  background-color: transparent; }

/* blue theme */
.smallipop-theme-blue {
  background: transparent;
  color: #111;
  border: 10px solid rgba(0, 100, 180, 0.9);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px; }

.smallipop-theme-blue a {
  color: #2276aa; }

.smallipop-theme-blue:after {
  bottom: -34px;
  border-color: rgba(0, 100, 180, 0.9) transparent transparent transparent; }

.smallipop-theme-blue:before {
  display: none; }

.smallipop-theme-blue.smallipop-bottom:after {
  top: -34px;
  border-color: transparent transparent rgba(0, 100, 180, 0.9) transparent; }

.smallipop-theme-blue.smallipop-left {
  right: -26px; }

.smallipop-theme-blue.smallipop-left:after {
  border-color: transparent transparent transparent rgba(0, 100, 180, 0.9); }

.smallipop-theme-blue.smallipop-right {
  left: -26px; }

.smallipop-theme-blue.smallipop-right:after {
  border-color: transparent rgba(0, 100, 180, 0.9) transparent transparent; }

.smallipop-theme-blue .smallipop-content {
  border: 0;
  background: #fcfcfc; }

.smallipop-theme-blue .smallipop-tour-progress {
  color: #777; }

.smallipop-theme-blue .smallipop-tour-prev,
.smallipop-theme-blue .smallipop-tour-next,
.smallipop-theme-blue .smallipop-tour-close {
  color: #222;
  background: #efefef; }

.smallipop-theme-blue .smallipop-tour-prev:hover,
.smallipop-theme-blue .smallipop-tour-next:hover,
.smallipop-theme-blue .smallipop-tour-close:hover {
  color: #111;
  background: #f7f7f7; }

/* black theme */
.smallipop-theme-black {
  background-color: #222;
  border-color: #111;
  text-shadow: 0 -1px 1px #111;
  color: #f5f5f5;
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzIyMjIyMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #333333), color-stop(100%, #222222));
  background: -moz-linear-gradient(#333333, #222222);
  background: -webkit-linear-gradient(#333333, #222222);
  background: linear-gradient(#333333, #222222);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }

.smallipop-theme-black a {
  color: #eef8ff;
  text-shadow: 0 -1px 1px #111; }

.smallipop-theme-black:before {
  border-color: #222 transparent transparent transparent; }

.smallipop-theme-black:after {
  border-color: #111 transparent transparent transparent; }

.smallipop-theme-black.smallipop-bottom:before {
  border-color: transparent transparent #222 transparent; }

.smallipop-theme-black.smallipop-bottom:after {
  border-color: transparent transparent #111 transparent; }

.smallipop-theme-black.smallipop-left:before {
  border-color: transparent transparent transparent #222; }

.smallipop-theme-black.smallipop-left:after {
  border-color: transparent transparent transparent #111; }

.smallipop-theme-black.smallipop-right:before {
  border-color: transparent #222 transparent transparent; }

.smallipop-theme-black.smallipop-right:after {
  border-color: transparent #111 transparent transparent; }

.smallipop-theme-black .smallipop-content {
  border-top: 1px solid #666;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }

.smallipop-theme-black .smallipop-tour-progress {
  color: #888; }

.smallipop-theme-black .smallipop-tour-prev,
.smallipop-theme-black .smallipop-tour-next,
.smallipop-theme-black .smallipop-tour-close {
  color: #ccc;
  background: #333; }

.smallipop-theme-black .smallipop-tour-prev:hover,
.smallipop-theme-black .smallipop-tour-next:hover,
.smallipop-theme-black .smallipop-tour-close:hover {
  color: #fff;
  background: #3a3a3a; }

.cssgradients .smallipop-theme-black {
  background-color: transparent; }

/* orange theme */
.smallipop-theme-orange {
  background-color: #f9aa18;
  border-color: #e17500;
  text-shadow: 0 1px 1px #fff24d;
  color: #714900;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZjI0ZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y5YWExOCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fff24d), color-stop(100%, #f9aa18));
  background: -moz-linear-gradient(#fff24d, #f9aa18);
  background: -webkit-linear-gradient(#fff24d, #f9aa18);
  background: linear-gradient(#fff24d, #f9aa18);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; }

.smallipop-theme-orange a {
  color: #145d95;
  text-shadow: 0 1px 1px #fff24d; }

.smallipop-theme-orange:before {
  border-color: #f9aa18 transparent transparent transparent; }

.smallipop-theme-orange:after {
  border-color: #e17500 transparent transparent transparent; }

.smallipop-theme-orange.smallipop-bottom:before {
  border-color: transparent transparent #f9aa18 transparent; }

.smallipop-theme-orange.smallipop-bottom:after {
  border-color: transparent transparent #e17500 transparent; }

.smallipop-theme-orange.smallipop-left:before {
  border-color: transparent transparent transparent #f9aa18; }

.smallipop-theme-orange.smallipop-left:after {
  border-color: transparent transparent transparent #e17500; }

.smallipop-theme-orange.smallipop-right:before {
  border-color: transparent #f9aa18 transparent transparent; }

.smallipop-theme-orange.smallipop-right:after {
  border-color: transparent #e17500 transparent transparent; }

.smallipop-theme-orange .smallipop-content {
  border-top: 1px solid #fffabc;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; }

.smallipop-theme-orange .smallipop-tour-progress {
  color: #444; }

.smallipop-theme-orange .smallipop-tour-prev,
.smallipop-theme-orange .smallipop-tour-next,
.smallipop-theme-orange .smallipop-tour-close {
  color: #444;
  background: #f19f06; }

.smallipop-theme-orange .smallipop-tour-prev:hover,
.smallipop-theme-orange .smallipop-tour-next:hover,
.smallipop-theme-orange .smallipop-tour-close:hover {
  color: #333;
  background: #f9a509; }

/* white theme */
.smallipop-theme-white {
  background-color: #fcfcfc;
  border-color: #ccc;
  text-shadow: 0 1px 1px #eee;
  color: #444;
  width: 200px;
  max-width: 200px;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px; }

.smallipop-theme-white:before {
  border-color: #fcfcfc transparent transparent transparent; }

.smallipop-theme-white:after {
  border-color: #ccc transparent transparent transparent; }

.smallipop-theme-white.smallipop-bottom:before {
  border-color: transparent transparent #fcfcfc transparent; }

.smallipop-theme-white.smallipop-bottom:after {
  border-color: transparent transparent #ccc transparent; }

.smallipop-theme-white.smallipop-left:before {
  border-color: transparent transparent transparent #fcfcfc; }

.smallipop-theme-white.smallipop-left:after {
  border-color: transparent transparent transparent #ccc; }

.smallipop-theme-white.smallipop-right:before {
  border-color: transparent #fcfcfc transparent transparent; }

.smallipop-theme-white.smallipop-right:after {
  border-color: transparent #ccc transparent transparent; }

.smallipop-theme-white .smallipop-content {
  text-align: center;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px; }

.smallipop-theme-white .smallipop-tour-progress {
  color: #777; }

.smallipop-theme-white .smallipop-tour-close-icon {
  background: #fafafa;
  color: #555;
  text-shadow: 0 1px 1px #fff; }

.smallipop-theme-white .smallipop-tour-close-icon:hover {
  background: #f5f5f5;
  color: #222; }

.smallipop-theme-white .smallipop-tour-prev,
.smallipop-theme-white .smallipop-tour-next,
.smallipop-theme-white .smallipop-tour-close {
  color: #666;
  background: #f0f0f0; }

.smallipop-theme-white .smallipop-tour-prev:hover,
.smallipop-theme-white .smallipop-tour-next:hover,
.smallipop-theme-white .smallipop-tour-close:hover {
  color: #333;
  background: #f4f4f4; }

/* white theme extended, requires rgba support */
.smallipop-theme-white-transparent {
  background-color: rgba(255, 255, 255, 0.8); }

.smallipop-theme-white-transparent:before {
  bottom: -21px;
  border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent; }

.smallipop-theme-white-transparent:after {
  border-color: transparent; }

.smallipop-theme-white-transparent.sipAlignBottom:before {
  top: -21px;
  border-color: transparent transparent rgba(255, 255, 255, 0.8) transparent; }

.smallipop-theme-white-transparent.sipPositionedLeft:before {
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.8); }

.smallipop-theme-white-transparent.sipPositionedRight:before {
  border-color: transparent rgba(255, 255, 255, 0.8) transparent transparent; }

/* fat shadow extension theme */
.smallipop-instance.smallipop-theme-fat-shadow {
  -moz-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8); }

/* wide content extension theme */
.smallipop-instance.smallipop-theme-wide {
  max-width: 600px; }

/*# sourceMappingURL=jquery.smallipop.css.map */
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel .owl-refresh .owl-item {
  display: none; }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; }

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1; }

/* ==========================================================================
	$BASE-PICKER
========================================================================== */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/**
 * The picker input element.
 */
.picker__input {
  cursor: default; }

/**
 * When the picker is opened, the input element is “activated”.
 */
.picker__input.picker__input--active {
  border-color: #0089ec; }

/**
 * The holder is the only “scrollable” top-level container element.
 */
.picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/*!
 * Default mobile-first, responsive styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
/**
 * Make the holder and frame fullscreen.
 */
.picker__holder,
.picker__frame {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%); }

/**
 * The holder should overlay the entire screen.
 */
.picker__holder {
  position: fixed;
  transition: background 0.15s ease-out, -webkit-transform 0s 0.15s;
  transition: background 0.15s ease-out, transform 0s 0.15s;
  -webkit-backface-visibility: hidden; }

/**
 * The frame that bounds the box contents of the picker.
 */
.picker__frame {
  position: absolute;
  margin: 0 auto;
  min-width: 256px;
  max-width: 666px;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  transition: all 0.15s ease-out; }

@media (min-height: 33.875em) {
  .picker__frame {
    overflow: visible;
    top: auto;
    bottom: -100%;
    max-height: 80%; } }

@media (min-height: 40.125em) {
  .picker__frame {
    margin-bottom: 7.5%; } }

/**
 * The wrapper sets the stage to vertically align the box contents.
 */
.picker__wrap {
  display: table;
  width: 100%;
  height: 100%; }

@media (min-height: 33.875em) {
  .picker__wrap {
    display: block; } }

/**
 * The box contains all the picker contents.
 */
.picker__box {
  background: #ffffff;
  display: table-cell;
  vertical-align: middle; }

@media (min-height: 26.5em) {
  .picker__box {
    font-size: 1.25em; } }

@media (min-height: 33.875em) {
  .picker__box {
    display: block;
    font-size: 1.33em;
    border: 1px solid #777777;
    border-top-color: #898989;
    border-bottom-width: 0;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24); } }

@media (min-height: 40.125em) {
  .picker__box {
    font-size: 1.5em;
    border-bottom-width: 1px;
    border-radius: 5px; } }

/**
 * When the picker opens...
 */
.picker--opened .picker__holder {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  background: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)";
  zoom: 1;
  background: rgba(0, 0, 0, 0.32);
  transition: background 0.15s ease-out; }

.picker--opened .picker__frame {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1; }

@media (min-height: 33.875em) {
  .picker--opened .picker__frame {
    top: auto;
    bottom: 0; } }

/* ==========================================================================
	$BASE-DATE-PICKER
========================================================================== */
/**
 * The picker box.
 */
.picker__box {
  padding: 0 1em; }

/**
 * The header containing the month and year stuff.
 */
.picker__header {
  text-align: center;
  position: relative;
  margin-top: .75em; }

/**
 * The month and year labels.
 */
.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em; }

.picker__year {
  color: #999999;
  font-size: .8em;
  font-style: italic; }

/**
 * The month and year selectors.
 */
.picker__select--month,
.picker__select--year {
  border: 1px solid #b7b7b7;
  height: 2em;
  padding: .5em;
  margin-left: .25em;
  margin-right: .25em; }

@media (min-width: 24.5em) {
  .picker__select--month,
  .picker__select--year {
    margin-top: -0.5em; } }

.picker__select--month {
  width: 35% !important; }

.picker__select--year {
  width: 22.5% !important; }

.picker__select--month:focus,
.picker__select--year:focus {
  border-color: #0089ec; }

/**
 * The month navigation buttons.
 */
.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: .5em 1.25em;
  width: 1em;
  height: 1em;
  box-sizing: content-box;
  top: -0.25em; }

@media (min-width: 24.5em) {
  .picker__nav--prev,
  .picker__nav--next {
    top: -0.33em; } }

.picker__nav--prev {
  left: -1em;
  padding-right: 1.25em; }

@media (min-width: 24.5em) {
  .picker__nav--prev {
    padding-right: 1.5em; } }

.picker__nav--next {
  right: -1em;
  padding-left: 1.25em; }

@media (min-width: 24.5em) {
  .picker__nav--next {
    padding-left: 1.5em; } }

.picker__nav--prev:before,
.picker__nav--next:before {
  content: " ";
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-right: 0.75em solid #000000;
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto; }

.picker__nav--next:before {
  border-right: 0;
  border-left: 0.75em solid #000000; }

.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb; }

.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5; }

/**
 * The calendar table of dates
 */
.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em; }

@media (min-height: 33.875em) {
  .picker__table {
    margin-bottom: .75em; } }

.picker__table td {
  margin: 0;
  padding: 0; }

/**
 * The weekday labels
 */
.picker__weekday {
  width: 14.285714286%;
  font-size: .75em;
  padding-bottom: .25em;
  color: #999999;
  font-weight: 500;
  /* Increase the spacing a tad */ }

@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em; } }

/**
 * The days on the calendar
 */
.picker__day {
  padding: .3125em 0;
  font-weight: 200;
  border: 1px solid transparent; }

.picker__day--today {
  position: relative; }

.picker__day--today:before {
  content: " ";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 0.5em solid #0059bc;
  border-left: .5em solid transparent; }

.picker__day--disabled:before {
  border-top-color: #aaaaaa; }

.picker__day--outfocus {
  color: #dddddd; }

.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb; }

.picker__day--highlighted {
  border-color: #0089ec; }

.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb; }

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background: #0089ec;
  color: #ffffff; }

.picker__day--disabled,
.picker__day--disabled:hover,
.picker--focused .picker__day--disabled {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default; }

.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: #bbbbbb; }

/**
 * The footer containing the "today", "clear", and "close" buttons.
 */
.picker__footer {
  text-align: center; }

.picker__button--today,
.picker__button--clear,
.picker__button--close {
  border: 1px solid #ffffff;
  background: #ffffff;
  font-size: .8em;
  padding: .66em 0;
  font-weight: bold;
  width: 33%;
  display: inline-block;
  vertical-align: bottom; }

.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb; }

.picker__button--today:focus,
.picker__button--clear:focus,
.picker__button--close:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: none; }

.picker__button--today:before,
.picker__button--clear:before,
.picker__button--close:before {
  position: relative;
  display: inline-block;
  height: 0; }

.picker__button--today:before,
.picker__button--clear:before {
  content: " ";
  margin-right: .45em; }

.picker__button--today:before {
  top: -0.05em;
  width: 0;
  border-top: 0.66em solid #0059bc;
  border-left: .66em solid transparent; }

.picker__button--clear:before {
  top: -0.25em;
  width: .66em;
  border-top: 3px solid #ee2200; }

.picker__button--close:before {
  content: "\D7";
  top: -0.1em;
  vertical-align: top;
  font-size: 1.1em;
  margin-right: .35em;
  color: #777777; }

.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default; }

.picker__button--today[disabled]:before {
  border-top-color: #aaaaaa; }

/* ==========================================================================
	$DEFAULT-DATE-PICKER
========================================================================== */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/**********************************************************************************
Fonts Stack
**********************************************************************************/
/* Times New Roman-based stack */
/* Modern Georgia-based serif stack */
/* Traditional Garamond-based serif stack */
/* Helvetica/Arial-based sans serif stack */
/* Verdana-based sans serif stack */
/* Trebuchet-based sans serif stack */
/* Impact-based sans serif stack */
/* Monospace stack */
/**********************************************************************************
Mixin tableReset
DESC : Remet à un <table> et ses enfants les propriétés display appropriées, lorsque le tableau a été mis en display: block; pour le mobile.
$img : Mettre le nom de l'image (ex: bg.png)
$pos : Permet de positionner le background ( défaut: left top )
$repeat : Défini la répétition du bacground ( défaut : no-repeat )
$color : Définir la couleur de background ( défaut : transparent )
**********************************************************************************/
/**********************************************************************************
Fonction bg
DESC : Fait l'appel d'un background plus rapidement
$img : Mettre le nom de l'image (ex: bg.png)
$pos : Permet de positionner le background ( défaut: left top )
$repeat : Défini la répétition du bacground ( défaut : no-repeat )
$color : Définir la couleur de background ( défaut : transparent )
**********************************************************************************/
/**********************************************************************************
Fonction transition
DESC : Faire la transition entre 2 états
$delai : Mettre le délai de la transition
**********************************************************************************/
/**********************************************************************************
Fonction rounded
DESC : Permet d'ajouter des coin rond à un élément
$radius : Définir le rayon des coins ronds ( défaut : 10px )
**********************************************************************************/
/**********************************************************************************
Fonction absBox
DESC : Permet de mettre un élément en absolue
$top : Définir la distance par rapport au haut
$left : Définir la distance par rapport à la gauche
**********************************************************************************/
/**********************************************************************************
Fonction init_font
DESC : Permet d'initier une nouvelle police
$fontName : Nom de la police servant servant aux appel
$fontFileName : Nom du fichier de la police
**********************************************************************************/
/**********************************************************************************
Fonction textoverflow
DESC : Permet de contrôler la coupure des mots
$value : Valeur à donner à la propriété (default: ellipsis)
**********************************************************************************/
/**********************************************************************************
Fonction cesure
DESC : Permet de contrôler la césure des mots
$value : Valeur à donner à la propriété (default: auto)
**********************************************************************************/
/*** Initialisation des polices ******************************************************************/
/*** Initialisation des variables ****************************************************************/
/* Polices (Voir dans _function.scss pour les fallback) */
/* Couleurs du site */
/* Couleurs des élément standard */
/*$menu: white;
$black: #05172b;*/
/* Couleurs qui reviennent toujours */
/* Slicknav */
/*$slickButton: $blue;
$slickButtonText: white;
$slickMenuBackground: $blue;
$slickMenuText: white;*/
/* Formulaire */
/*** Styles Généraux *****************************************************************************/
/*Lisser la police	*/
html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px; }

.padding-0 {
  padding: 0 !important; }

.wd-14 {
  width: 14em !important; }

body {
  font-family: "Open Sans", Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #333535;
  padding: 0;
  line-height: 1.3; }
  body .turquoise {
    color: #339a99 !important; }
    body .turquoise * {
      color: #339a99 !important; }
  body .mauve {
    color: #6a5ac9 !important; }
    body .mauve * {
      color: #6a5ac9 !important; }
  body .noir {
    color: #333535 !important; }
    body .noir * {
      color: #333535 !important; }
  body .gras {
    font-weight: bold !important; }
    body .gras * {
      font-weight: bold !important; }
  body .underline {
    text-decoration: underline; }

em {
  font-style: italic; }

#site {
  margin: 0 auto;
  position: relative; }

.wrap {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px; }

/* a[href="#"], a[href=""], form[action=""], form[action="#"] {
	//outline: 1px dotted red !important;
}

* {
	 //outline: 1px dotted red !important;
} */
/*** Styles globaux **************************************************/
.hide {
  display: none; }

.left {
  float: left; }

.right {
  float: right; }

.hidden-textarea {
  height: 0 !important;
  width: 0 !important;
  min-height: 0 !important;
  min-width: 0 !important;
  border: 0 !important;
  opacity: 0; }

input.button, a.button, button, input[type="submit"] {
  background-color: #339a99;
  padding: 0.9em 1.1em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  border: 0 none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease; }
  input.button.needUpload, a.button.needUpload, button.needUpload, input[type="submit"].needUpload {
    display: none; }
    input.button.needUpload.uploadFinished, a.button.needUpload.uploadFinished, button.needUpload.uploadFinished, input[type="submit"].needUpload.uploadFinished {
      display: inline-block; }
  input.button:hover, input.button:active, a.button:hover, a.button:active, button:hover, button:active, input[type="submit"]:hover, input[type="submit"]:active {
    background-color: #206160; }
  input.button[disabled], a.button[disabled], button[disabled], input[type="submit"][disabled] {
    background-color: #d2d2d2 !important; }
    input.button[disabled]:hover, input.button[disabled]:active, a.button[disabled]:hover, a.button[disabled]:active, button[disabled]:hover, button[disabled]:active, input[type="submit"][disabled]:hover, input[type="submit"][disabled]:active {
      background-color: #d2d2d2 !important; }
    input.button[disabled].borderButton, input.button[disabled].outline, a.button[disabled].borderButton, a.button[disabled].outline, button[disabled].borderButton, button[disabled].outline, input[type="submit"][disabled].borderButton, input[type="submit"][disabled].outline {
      background-color: transparent !important; }
      input.button[disabled].borderButton:hover, input.button[disabled].borderButton:active, input.button[disabled].outline:hover, input.button[disabled].outline:active, a.button[disabled].borderButton:hover, a.button[disabled].borderButton:active, a.button[disabled].outline:hover, a.button[disabled].outline:active, button[disabled].borderButton:hover, button[disabled].borderButton:active, button[disabled].outline:hover, button[disabled].outline:active, input[type="submit"][disabled].borderButton:hover, input[type="submit"][disabled].borderButton:active, input[type="submit"][disabled].outline:hover, input[type="submit"][disabled].outline:active {
        background-color: transparent !important; }

/*button.stripe-button-el {
	background-image: none;
	-webkit-font-smoothing: inherit;
	appearance: none !important;
	padding: 0;
	text-decoration: none;
	border-radius: 0;
	box-shadow: none;

	span {
		@extend button;
		position: relative;
		height: auto;
		line-height: 1;
		background: $turquoise;
		background-image: none;
		font-size: inherit;
		font-family: inherit;
		text-shadow: none;
		box-shadow: none;
	}
}*/
input.buttonLink, a.buttonLink, button.buttonLink {
  background-color: transparent;
  font-weight: 400;
  font-size: 0.9em;
  font-weight: bold;
  color: #339a99;
  text-decoration: none;
  text-transform: none;
  border-radius: 0;
  padding: 0.9em 0;
  display: inline-block;
  cursor: pointer;
  outline: none; }
  input.buttonLink:hover, input.buttonLink:active, a.buttonLink:hover, a.buttonLink:active, button.buttonLink:hover, button.buttonLink:active {
    text-decoration: underline;
    background-color: white; }

input.buttonMauve, a.buttonMauve, button.buttonMauve {
  background-color: #6a5ac9;
  padding: 0.9em 1.1em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  border: 0 none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease; }
  input.buttonMauve:hover, a.buttonMauve:hover, button.buttonMauve:hover {
    background-color: #4535a2; }

input.buttonAlt, a.buttonAlt, button.buttonAlt {
  background-color: #333535;
  padding: 0.9em 1.1em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  border: 0 none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease; }
  input.buttonAlt:hover, a.buttonAlt:hover, button.buttonAlt:hover {
    background-color: #4c4f4f; }

input.buttonSmall, a.buttonSmall, button.buttonSmall {
  border-radius: 0;
  padding: 0.3em 0.6em;
  font-size: 0.9em;
  font-weight: 400;
  text-transform: none; }

input.borderButton, button.borderButton, a.borderButton,
.button.outline, button.outline, input.outline {
  background-color: transparent;
  padding: 0.7em 1.4em;
  border: 3px solid #339a99;
  color: #333535;
  transition: color 0.2s ease, background-color 0.2s ease; }
  input.borderButton:hover, input.borderButton:active, button.borderButton:hover, button.borderButton:active, a.borderButton:hover, a.borderButton:active,
  .button.outline:hover,
  .button.outline:active, button.outline:hover, button.outline:active, input.outline:hover, input.outline:active {
    background-color: #339a99;
    color: #fff; }

a[disabled]:hover, button[disabled]:hover, input[type="button"][disabled]:hover, input[type="submit"][disabled]:hover {
  cursor: default; }

button.btnWithIcon, .button.btnWithIcon, input.btnWithIcon {
  display: inline-block;
  padding-left: 0.6em;
  padding-right: 0.6em; }
  button.btnWithIcon.bigger, .button.btnWithIcon.bigger, input.btnWithIcon.bigger {
    font-size: 1.2em; }
  button.btnWithIcon.thin, .button.btnWithIcon.thin, input.btnWithIcon.thin {
    padding-top: 0.6em;
    padding-bottom: 0.6em; }
  button.btnWithIcon.gradientOutline, .button.btnWithIcon.gradientOutline, input.btnWithIcon.gradientOutline {
    position: relative;
    padding: 5px;
    color: #339a99; }
    button.btnWithIcon.gradientOutline::before, .button.btnWithIcon.gradientOutline::before, input.btnWithIcon.gradientOutline::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 0.5em;
      background-image: linear-gradient(115deg, #339a99 0%, #339a99 40%, #5ac994 80%, #5ac994 100%); }
    button.btnWithIcon.gradientOutline:hover::before, button.btnWithIcon.gradientOutline:hover .icon, button.btnWithIcon.gradientOutline:active::before, button.btnWithIcon.gradientOutline:active .icon, .button.btnWithIcon.gradientOutline:hover::before, .button.btnWithIcon.gradientOutline:hover .icon, .button.btnWithIcon.gradientOutline:active::before, .button.btnWithIcon.gradientOutline:active .icon, input.btnWithIcon.gradientOutline:hover::before, input.btnWithIcon.gradientOutline:hover .icon, input.btnWithIcon.gradientOutline:active::before, input.btnWithIcon.gradientOutline:active .icon {
      filter: brightness(90%); }
    button.btnWithIcon.gradientOutline:hover .linkText, button.btnWithIcon.gradientOutline:active .linkText, .button.btnWithIcon.gradientOutline:hover .linkText, .button.btnWithIcon.gradientOutline:active .linkText, input.btnWithIcon.gradientOutline:hover .linkText, input.btnWithIcon.gradientOutline:active .linkText {
      color: #2d8786; }
    button.btnWithIcon.gradientOutline::before, button.btnWithIcon.gradientOutline .icon, .button.btnWithIcon.gradientOutline::before, .button.btnWithIcon.gradientOutline .icon, input.btnWithIcon.gradientOutline::before, input.btnWithIcon.gradientOutline .icon {
      transition: filter 0.2s ease; }
    button.btnWithIcon.gradientOutline .linkText, .button.btnWithIcon.gradientOutline .linkText, input.btnWithIcon.gradientOutline .linkText {
      transition: color 0.2s ease; }
    button.btnWithIcon.gradientOutline .buttonInner, .button.btnWithIcon.gradientOutline .buttonInner, input.btnWithIcon.gradientOutline .buttonInner {
      position: relative;
      z-index: 10;
      padding: 0.9em 0.6em;
      background-color: #fff;
      border-radius: 0.5em; }
    button.btnWithIcon.gradientOutline .icon, .button.btnWithIcon.gradientOutline .icon, input.btnWithIcon.gradientOutline .icon {
      background-position: 0 -2100px; }
  button.btnWithIcon.outline:hover .icon, button.btnWithIcon.outline:active .icon, .button.btnWithIcon.outline:hover .icon, .button.btnWithIcon.outline:active .icon, input.btnWithIcon.outline:hover .icon, input.btnWithIcon.outline:active .icon {
    filter: brightness(1000%); }
  button.btnWithIcon.outline .icon, .button.btnWithIcon.outline .icon, input.btnWithIcon.outline .icon {
    background-position: -55px -1300px; }
  button.btnWithIcon .buttonInner, .button.btnWithIcon .buttonInner, input.btnWithIcon .buttonInner {
    display: flex;
    align-items: center;
    text-align: left; }
  button.btnWithIcon .icon, .button.btnWithIcon .icon, input.btnWithIcon .icon {
    display: inline-block;
    width: 47px;
    height: 47px;
    background-image: url("/images/sprite_icon.png");
    background-position: 0 -1300px;
    background-repeat: no-repeat;
    transition: filter 0.2s ease; }
    button.btnWithIcon .icon.arrowUp, .button.btnWithIcon .icon.arrowUp, input.btnWithIcon .icon.arrowUp {
      width: 38px;
      height: 47px;
      background-position: 0 -4271px;
      filter: brightness(1000%); }
    button.btnWithIcon .icon.timePlus, .button.btnWithIcon .icon.timePlus, input.btnWithIcon .icon.timePlus {
      width: 47px;
      height: 47px;
      background-position: 0 -4371px;
      filter: brightness(1000%); }
  button.btnWithIcon .linkText, .button.btnWithIcon .linkText, input.btnWithIcon .linkText {
    flex-grow: 1;
    display: block;
    padding-left: 0.6em;
    line-height: 1.2em; }
    button.btnWithIcon .linkText span, .button.btnWithIcon .linkText span, input.btnWithIcon .linkText span {
      display: block;
      white-space: nowrap; }
    button.btnWithIcon .linkText .italic, .button.btnWithIcon .linkText .italic, input.btnWithIcon .linkText .italic {
      text-transform: none;
      font-style: italic;
      font-weight: normal; }

.btnCloseRound {
  display: block;
  vertical-align: top;
  background-color: #5ac994;
  padding: 9px 10px;
  border-radius: 100%;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: border-color 0.2s linear; }
  .btnCloseRound:hover, .btnCloseRound:active {
    border-color: #fff;
    background-color: #5ac994; }
  .btnCloseRound.larger {
    padding: 9px 10px;
    border-width: 3px; }
    .btnCloseRound.larger .icon {
      width: 10px;
      height: 12px;
      background-position: 0 -609px; }
  .btnCloseRound .icon {
    display: block;
    vertical-align: top;
    width: 7px;
    height: 9px;
    background-image: url("/images/sprite_user.png");
    background-repeat: no-repeat;
    background-position: 0 -509px;
    text-indent: -99999px;
    overflow: hidden; }

.roundTrigger {
  position: relative;
  padding: 0;
  border-radius: 100%;
  width: 1.6em;
  background-color: #339a99;
  transition: background-color 0.2s ease; }
  .roundTrigger:hover, .roundTrigger:active {
    background-color: #206160;
    cursor: pointer; }
  .roundTrigger::after {
    content: '';
    display: block;
    padding-bottom: 100%; }
  .roundTrigger .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700; }

.roundTrigger.smallipop {
  display: inline-block;
  color: #fff;
  top: 8px;
  margin-left: 5px; }

div.formRow label.triggerLabel {
  max-width: 215px; }

.mceBigger {
  font-size: 1.2em; }

.mceAqua {
  color: #339a99; }

.progressbar {
  background-color: white;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  padding: 2px; }
  .progressbar .progress {
    background-color: #333535;
    height: 6px;
    border-radius: 3px 0 0 3px; }
    .progressbar .progress.progressComplete {
      border-radius: 3px; }

.outerVideo {
  width: 100%;
  max-width: 1280px;
  max-height: 720px;
  margin: 0 auto; }

.wrapVideo {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* 16:9 */ }
  .wrapVideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.largeTitle, .site #main .largeTitle, .account .largeTitle, #main .largeTitle {
  display: inline-block; }
  .largeTitle::after, .site #main .largeTitle::after, .account .largeTitle::after, #main .largeTitle::after {
    content: '';
    display: block;
    width: 100%;
    max-width: 100px;
    margin-top: 2.2em; }
  .largeTitle h1, .largeTitle h2, .largeTitle h3, .site #main .largeTitle h1, .site #main .largeTitle h2, .site #main .largeTitle h3, .account .largeTitle h1, .account .largeTitle h2, .account .largeTitle h3, #main .largeTitle h1, #main .largeTitle h2, #main .largeTitle h3 {
    margin: 0;
    font-size: 2.6em;
    font-weight: 400;
    letter-spacing: -0.05em;
    color: #333535;
    line-height: 1.2em; }
  .largeTitle .leftLinks, .largeTitle .rightLinks, .site #main .largeTitle .leftLinks, .site #main .largeTitle .rightLinks, .account .largeTitle .leftLinks, .account .largeTitle .rightLinks, #main .largeTitle .leftLinks, #main .largeTitle .rightLinks {
    margin-top: -0.2em; }
    .largeTitle .leftLinks a, .largeTitle .rightLinks a, .site #main .largeTitle .leftLinks a, .site #main .largeTitle .rightLinks a, .account .largeTitle .leftLinks a, .account .largeTitle .rightLinks a, #main .largeTitle .leftLinks a, #main .largeTitle .rightLinks a {
      text-decoration: none;
      transition: color 0.2s ease; }
      .largeTitle .leftLinks a, .largeTitle .leftLinks a:link, .largeTitle .leftLinks a:visited, .largeTitle .rightLinks a, .largeTitle .rightLinks a:link, .largeTitle .rightLinks a:visited, .site #main .largeTitle .leftLinks a, .site #main .largeTitle .leftLinks a:link, .site #main .largeTitle .leftLinks a:visited, .site #main .largeTitle .rightLinks a, .site #main .largeTitle .rightLinks a:link, .site #main .largeTitle .rightLinks a:visited, .account .largeTitle .leftLinks a, .account .largeTitle .leftLinks a:link, .account .largeTitle .leftLinks a:visited, .account .largeTitle .rightLinks a, .account .largeTitle .rightLinks a:link, .account .largeTitle .rightLinks a:visited, #main .largeTitle .leftLinks a, #main .largeTitle .leftLinks a:link, #main .largeTitle .leftLinks a:visited, #main .largeTitle .rightLinks a, #main .largeTitle .rightLinks a:link, #main .largeTitle .rightLinks a:visited {
        color: #339a99; }
      .largeTitle .leftLinks a:hover, .largeTitle .leftLinks a:active, .largeTitle .rightLinks a:hover, .largeTitle .rightLinks a:active, .site #main .largeTitle .leftLinks a:hover, .site #main .largeTitle .leftLinks a:active, .site #main .largeTitle .rightLinks a:hover, .site #main .largeTitle .rightLinks a:active, .account .largeTitle .leftLinks a:hover, .account .largeTitle .leftLinks a:active, .account .largeTitle .rightLinks a:hover, .account .largeTitle .rightLinks a:active, #main .largeTitle .leftLinks a:hover, #main .largeTitle .leftLinks a:active, #main .largeTitle .rightLinks a:hover, #main .largeTitle .rightLinks a:active {
        color: #206160; }
      .largeTitle .leftLinks a:not(:last-child), .largeTitle .rightLinks a:not(:last-child), .site #main .largeTitle .leftLinks a:not(:last-child), .site #main .largeTitle .rightLinks a:not(:last-child), .account .largeTitle .leftLinks a:not(:last-child), .account .largeTitle .rightLinks a:not(:last-child), #main .largeTitle .leftLinks a:not(:last-child), #main .largeTitle .rightLinks a:not(:last-child) {
        margin-right: 1em; }
    .largeTitle .leftLinks .button, .largeTitle .rightLinks .button, .site #main .largeTitle .leftLinks .button, .site #main .largeTitle .rightLinks .button, .account .largeTitle .leftLinks .button, .account .largeTitle .rightLinks .button, #main .largeTitle .leftLinks .button, #main .largeTitle .rightLinks .button {
      margin-top: 0.6em;
      font-size: 0.8em;
      text-transform: none;
      font-weight: 400;
      padding: 0.6em 1em;
      border-radius: 0.5em;
      transition: background-color 0.2s ease; }
      .largeTitle .leftLinks .button, .largeTitle .leftLinks .button:link, .largeTitle .leftLinks .button:visited, .largeTitle .rightLinks .button, .largeTitle .rightLinks .button:link, .largeTitle .rightLinks .button:visited, .site #main .largeTitle .leftLinks .button, .site #main .largeTitle .leftLinks .button:link, .site #main .largeTitle .leftLinks .button:visited, .site #main .largeTitle .rightLinks .button, .site #main .largeTitle .rightLinks .button:link, .site #main .largeTitle .rightLinks .button:visited, .account .largeTitle .leftLinks .button, .account .largeTitle .leftLinks .button:link, .account .largeTitle .leftLinks .button:visited, .account .largeTitle .rightLinks .button, .account .largeTitle .rightLinks .button:link, .account .largeTitle .rightLinks .button:visited, #main .largeTitle .leftLinks .button, #main .largeTitle .leftLinks .button:link, #main .largeTitle .leftLinks .button:visited, #main .largeTitle .rightLinks .button, #main .largeTitle .rightLinks .button:link, #main .largeTitle .rightLinks .button:visited {
        color: #fff; }
      .largeTitle .leftLinks .button:hover, .largeTitle .leftLinks .button:active, .largeTitle .rightLinks .button:hover, .largeTitle .rightLinks .button:active, .site #main .largeTitle .leftLinks .button:hover, .site #main .largeTitle .leftLinks .button:active, .site #main .largeTitle .rightLinks .button:hover, .site #main .largeTitle .rightLinks .button:active, .account .largeTitle .leftLinks .button:hover, .account .largeTitle .leftLinks .button:active, .account .largeTitle .rightLinks .button:hover, .account .largeTitle .rightLinks .button:active, #main .largeTitle .leftLinks .button:hover, #main .largeTitle .leftLinks .button:active, #main .largeTitle .rightLinks .button:hover, #main .largeTitle .rightLinks .button:active {
        color: #fff;
        background-color: #206160; }
  .largeTitle .rightLinks, .site #main .largeTitle .rightLinks, .account .largeTitle .rightLinks, #main .largeTitle .rightLinks {
    text-align: right; }
  .largeTitle .leftLinks, .site #main .largeTitle .leftLinks, .account .largeTitle .leftLinks, #main .largeTitle .leftLinks {
    text-align: left; }
  .largeTitle .label, .site #main .largeTitle .label, .account .largeTitle .label, #main .largeTitle .label {
    font-weight: 700;
    text-transform: uppercase;
    color: #989898; }
    .largeTitle .label + h1, .largeTitle .label + h2, .largeTitle .label + h3, .site #main .largeTitle .label + h1, .site #main .largeTitle .label + h2, .site #main .largeTitle .label + h3, .account .largeTitle .label + h1, .account .largeTitle .label + h2, .account .largeTitle .label + h3, #main .largeTitle .label + h1, #main .largeTitle .label + h2, #main .largeTitle .label + h3 {
      margin-top: -0.2em; }

/*** Styles d'accessibilité **********************************/
.skip-link {
  position: absolute;
  background: #f3f4f6;
  color: #374151;
  padding: 1rem 0.5rem;
  top: 10px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transform: translateY(-1000%); }

.skip-link:focus-within {
  transform: translateY(0%); }

a:focus, button:focus, input:focus, .owl-prev:focus, .owl-next:focus {
  outline: 2px solid #007BFF;
  outline-offset: 2px; }

#newsletter input:focus {
  outline: 2px solid white !important;
  outline-offset: 2px; }

.owl-nav button {
  background: transparent; }

/*** Style des popups custom (Sweet Alert) *******************/
@keyframes pulseWarning {
  0% {
    border-color: #80d6ad; }
  100% {
    border-color: #5ac994; } }

@keyframes pulseWarningIns {
  0% {
    background-color: #80d6ad; }
  100% {
    background-color: #5ac994; } }

.sweet-alert p a {
  color: #339a99;
  text-decoration: none; }
  .sweet-alert p a:hover {
    text-decoration: underline; }

.sweet-alert .extraButtons {
  margin-top: 1.4em; }

.sweet-alert button, .sweet-alert .button {
  padding: 0.6em 2em;
  margin-top: 0.4em;
  font-weight: 400;
  border-radius: 0.3em;
  color: white;
  text-decoration: none; }
  .sweet-alert button:hover, .sweet-alert .button:hover {
    text-decoration: none; }
  .sweet-alert button.extraButton, .sweet-alert .button.extraButton {
    background-color: #bcbcbc; }
    .sweet-alert button.extraButton:hover, .sweet-alert button.extraButton:active, .sweet-alert .button.extraButton:hover, .sweet-alert .button.extraButton:active {
      background-color: #4b4b4b; }

.sweet-alert button.cancel {
  background-color: #bcbcbc; }
  .sweet-alert button.cancel:hover, .sweet-alert button.cancel:active {
    background-color: #4b4b4b; }

.sweet-alert button.confirm {
  background-color: #339a99 !important;
  box-shadow: none !important; }
  .sweet-alert button.confirm:hover, .sweet-alert button.confirm:active {
    background-color: #267473 !important; }

.sweet-alert fieldset {
  padding: 0; }

.sweet-alert .sa-icon.sa-warning {
  border-color: #5ac994; }
  .sweet-alert .sa-icon.sa-warning .sa-body, .sweet-alert .sa-icon.sa-warning .sa-dot {
    background-color: #5ac994; }

.sweet-alert .sa-icon.sa-success {
  border-color: #5ac994; }
  .sweet-alert .sa-icon.sa-success .sa-line {
    background-color: #5ac994; }

/*** HEADER **************************************************/
.home header#header {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 60; }

.site header#header {
  padding-bottom: 4px;
  background: #5ac994;
  background: linear-gradient(to right, #5ac993, #5ac994); }
  .site header#header .inner {
    background-color: white; }

header#header {
  box-shadow: 0 0 10px 10px rgba(51, 53, 53, 0.2); }

#header-wrapper {
  display: flex;
  justify-content: space-between; }

#main > header {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }
  #main > header h1 {
    color: white;
    margin: 0;
    letter-spacing: -0.04em;
    line-height: 1; }

.page #main > header {
  margin-bottom: 50px; }
  .page #main > header.gradient .wrap .inner {
    height: 190px;
    display: flex;
    align-items: center; }
  .page #main > header.gradient .headerText {
    position: static;
    flex-basis: 100%;
    margin-bottom: 0;
    text-align: center; }
  .page #main > header.gradient h1 {
    font-weight: 800;
    font-style: italic;
    font-size: 1.8em;
    text-shadow: none; }
  .page #main > header .wrap .inner {
    position: relative;
    height: 110px; }
  .page #main > header .headerText {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 2px; }
  .page #main > header h1 {
    font-size: 2em;
    text-shadow: 3px 6px 1px rgba(0, 0, 0, 0.5);
    line-height: 1; }

#main .center  {
  text-align: center; }

#main .left {
  text-align: left; }

#main .right  {
  text-align: right; }

#main.hasSidebar .innerMain {
  display: flex;
  flex-wrap: wrap; }
  #main.hasSidebar .innerMain > * {
    flex-basis: 100%;
    max-width: 100%; }
  #main.hasSidebar .innerMain > aside {
    width: 100%;
    margin-top: 2em;
    padding: 0; }
    #main.hasSidebar .innerMain > aside.blogSidebar .search, #main.hasSidebar .innerMain > aside.blogSidebar .categories {
      display: none; }
    #main.hasSidebar .innerMain > aside .sidebarInner {
      padding-bottom: 2em; }
      #main.hasSidebar .innerMain > aside .sidebarInner > * {
        margin: auto; }
        #main.hasSidebar .innerMain > aside .sidebarInner > *:not(:last-of-type) {
          margin-bottom: 2em; }
          #main.hasSidebar .innerMain > aside .sidebarInner > *:not(:last-of-type)::after {
            content: '';
            display: block;
            margin-top: 2em;
            width: 100%;
            max-width: 67px;
            border-bottom: 2px solid #339a99; }
        #main.hasSidebar .innerMain > aside .sidebarInner > *.bup > a {
          display: block;
          width: 100%;
          max-width: 300px;
          margin: 0; }
          #main.hasSidebar .innerMain > aside .sidebarInner > *.bup > a > img {
            display: block;
            width: 100%;
            height: auto; }
        #main.hasSidebar .innerMain > aside .sidebarInner > *.sidebarVideo .outerVideo {
          max-width: 400px;
          margin: 0 0;
          border: 3px solid #333535; }
        #main.hasSidebar .innerMain > aside .sidebarInner > *.form .entryContent, #main.hasSidebar .innerMain > aside .sidebarInner > *.form .entry-content {
          margin-bottom: 1em; }
        #main.hasSidebar .innerMain > aside .sidebarInner > *.form form {
          display: flex; }
          #main.hasSidebar .innerMain > aside .sidebarInner > *.form form input {
            margin-right: 0.5em;
            max-width: 350px; }
          #main.hasSidebar .innerMain > aside .sidebarInner > *.form form button {
            position: relative;
            display: flex;
            align-items: center;
            padding: 0.2em 0.4em;
            background-color: transparent;
            border-radius: 0; }
            #main.hasSidebar .innerMain > aside .sidebarInner > *.form form button:hover .icon:not(.hover), #main.hasSidebar .innerMain > aside .sidebarInner > *.form form button:active .icon:not(.hover) {
              opacity: 0; }
            #main.hasSidebar .innerMain > aside .sidebarInner > *.form form button:hover .icon.hover, #main.hasSidebar .innerMain > aside .sidebarInner > *.form form button:active .icon.hover {
              opacity: 1; }
            #main.hasSidebar .innerMain > aside .sidebarInner > *.form form button .icon {
              display: inline-block;
              width: 20px;
              height: 20px;
              text-indent: -99999px;
              background-image: url("/images/sprite_icon.png");
              background-position: 0 -2650px;
              background-repeat: no-repeat;
              transition: opacity 0.2s ease; }
              #main.hasSidebar .innerMain > aside .sidebarInner > *.form form button .icon.hover {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                background-position: 0 -2700px; }
      #main.hasSidebar .innerMain > aside .sidebarInner h4 {
        font-size: 1.45em;
        font-weight: 400;
        letter-spacing: -0.04em;
        color: #333535; }

.containForm #main > header .wrap {
  text-align: center;
  margin-bottom: 50px;
  padding: 15px 20px; }

.containForm #main > header h1, .containForm #main > header h2 {
  margin: 0;
  color: white !important;
  line-height: 1; }

.containForm #main > header h1 {
  font-weight: 400;
  font-size: 1.8em;
  margin-top: 0.5em;
  margin-bottom: 0.2em;
  line-height: 1; }

.containForm #main > header h2 {
  font-weight: 800;
  font-style: italic;
  font-size: 2em;
  line-height: 1.2em; }
  .containForm #main > header h2 strong {
    color: #fff; }

.containForm #main > header .wrapProgress {
  width: 50%;
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 1em; }

.containForm #main > header .progressbar {
  flex-grow: 100; }

.containForm #main > header .wrapPercent {
  flex-grow: 0;
  margin-left: 0.5em; }

.containForm #main > header .percentCompletion {
  color: #fff; }
  .containForm #main > header .percentCompletion .number {
    font-weight: 700; }
  .containForm #main > header .percentCompletion .symbol {
    font-size: 0.8em; }

header#header {
  width: 100%;
  position: relative;
  z-index: 60; }
  header#header .wrap {
    padding-top: 30px;
    padding-bottom: 10px; }
  header#header #logo {
    margin: 0;
    max-width: 82%; }
    header#header #logo a {
      display: inline-block; }
  header#header #menus {
    margin-top: 20px; }
  header#header .top {
    position: absolute;
    z-index: 10;
    top: 39px;
    right: 37px; }
    header#header .top .lang {
      display: block;
      float: right;
      margin-left: 10px; }
      header#header .top .lang ul li {
        display: block;
        font-size: 0.7em;
        text-transform: uppercase; }
        header#header .top .lang ul li a {
          color: #333535;
          text-decoration: none;
          transition: color 0.2s ease; }
          header#header .top .lang ul li a:hover {
            color: #339a99; }
        header#header .top .lang ul li span {
          font-weight: 600; }
  header#header .signed-in .lang {
    float: left; }
  header#header .bottom {
    z-index: 5;
    position: relative; }
  header#header .userinfos {
    display: none;
    float: right;
    margin-right: 1em;
    position: relative; }
    header#header .userinfos::before {
      content: '';
      display: inline-block;
      vertical-align: top;
      margin: 0 0.4em 0 0.6em;
      height: 26px;
      border-right: 2px solid #EBEBEB; }
    header#header .userinfos .trigger {
      min-height: 26px;
      line-height: 26px;
      padding-right: 40px;
      padding-bottom: 18px;
      display: inline-block;
      font-size: 0.75em;
      font-weight: 400;
      color: #333535;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.2s ease;
      position: relative; }
      header#header .userinfos .trigger img {
        position: absolute;
        top: 0;
        right: 0;
        border: 2px solid #339a99;
        border-radius: 100%;
        overflow: hidden; }
        header#header .userinfos .trigger img.noborder {
          border: 0 none;
          border-radius: 0; }
      header#header .userinfos .trigger:hover, header#header .userinfos .trigger:active {
        color: #339a99; }
    header#header .userinfos .infos {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: -3px;
      right: 8px;
      max-width: 280px;
      font-size: 0.8em;
      background-color: rgba(255, 255, 255, 0.9);
      filter: drop-shadow(0px 0px 2px rgba(51, 53, 53, 0.5));
      opacity: 0;
      transition: opacity 0.2s ease; }
      header#header .userinfos .infos.visible {
        display: block; }
      header#header .userinfos .infos.fadeIn {
        opacity: 1; }
      header#header .userinfos .infos::before {
        content: "";
        position: absolute;
        right: 15px;
        top: -13px;
        border-style: solid;
        border-color: transparent transparent white transparent;
        border-width: 0 8px 13px 8px; }
      header#header .userinfos .infos .inner {
        padding: 1em 2em;
        display: flex;
        align-items: center;
        justify-content: space-between; }
      header#header .userinfos .infos .avatar {
        float: left;
        width: 70px;
        height: 70px;
        margin-right: 10px;
        overflow: hidden;
        box-sizing: border-box; }
        header#header .userinfos .infos .avatar img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 35px;
          border: 2px solid #339a99; }
      header#header .userinfos .infos ul {
        width: 210px;
        text-align: left;
        margin: 0;
        padding: 0; }
      header#header .userinfos .infos li {
        margin: 5px 0; }
      header#header .userinfos .infos a.link {
        color: #339a99;
        text-decoration: none;
        cursor: pointer; }
        header#header .userinfos .infos a.link:hover {
          text-decoration: underline; }
  header#header #mainmenu, header#header #topmenu {
    text-align: left; }
    header#header #mainmenu ul, header#header #topmenu ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
    header#header #mainmenu li, header#header #topmenu li {
      list-style-type: none;
      display: inline-block; }
      header#header #mainmenu li.hide, header#header #topmenu li.hide {
        display: none; }
    header#header #mainmenu a, header#header #topmenu a {
      display: inline-block;
      text-decoration: none;
      /*&:hover, &:focus, &.active {
				background-color: $turquoise;
				color: $white;
			}*/ }
  header#header #mainmenu {
    display: inline-block;
    margin-top: -18px;
    /* ul>li:nth-child(3) ul>li:nth-child(3){
			display: none;
		} */ }
    header#header #mainmenu > ul > li.parent {
      position: relative;
      transition: background-color 100ms ease; }
      header#header #mainmenu > ul > li.parent:hover > a, header#header #mainmenu > ul > li.parent:active > a {
        color: #fff;
        background-color: rgba(51, 154, 153, 0.9); }
      header#header #mainmenu > ul > li.parent ul {
        display: none;
        background-color: rgba(51, 154, 153, 0.9);
        min-width: 200px;
        padding: 1em 0;
        position: absolute;
        top: 100%;
        left: 0;
        text-align: left;
        border-radius: 0 0 10px 10px; }
        header#header #mainmenu > ul > li.parent ul.visible {
          display: block; }
        header#header #mainmenu > ul > li.parent ul.fadeIn {
          opacity: 1;
          transform: translateY(0); }
          header#header #mainmenu > ul > li.parent ul.fadeIn > li {
            transform: translateY(0); }
        header#header #mainmenu > ul > li.parent ul > li {
          padding: 0;
          display: block;
          transition: transform 0.2s ease; }
          header#header #mainmenu > ul > li.parent ul > li.active {
            font-weight: bold; }
          header#header #mainmenu > ul > li.parent ul > li.hide {
            display: none; }
        header#header #mainmenu > ul > li.parent ul a {
          display: block;
          padding: 0.4em 1.25em;
          font-size: 0.8em;
          color: white; }
          header#header #mainmenu > ul > li.parent ul a:hover, header#header #mainmenu > ul > li.parent ul a:active {
            background-color: rgba(255, 255, 255, 0.1); }
    header#header #mainmenu > ul > li:not(.highlight).parent > a:hover, header#header #mainmenu > ul > li:not(.highlight).parent > a:active {
      color: #fff; }
    header#header #mainmenu > ul > li:not(.highlight) > a:hover, header#header #mainmenu > ul > li:not(.highlight) > a:active {
      color: #339a99; }
    header#header #mainmenu > ul > li.highlight > a {
      font-weight: 700;
      padding-top: 12px;
      padding-bottom: 12px;
      border: 2px solid #339a99;
      color: #339a99;
      border-radius: 3px; }
      header#header #mainmenu > ul > li.highlight > a:hover, header#header #mainmenu > ul > li.highlight > a:active {
        background-color: #339a99;
        color: white; }
    header#header #mainmenu > ul > li.highlight + li {
      margin-left: 14px; }
    header#header #mainmenu > ul > li.active > a {
      font-weight: 700; }
    header#header #mainmenu > ul > li > a {
      display: inline-block;
      padding: 14px 14px;
      font-size: 0.8em;
      white-space: nowrap;
      color: #333535;
      border-radius: 10px 10px 0 0;
      transition: background-color 0.2s ease, color 0.2s ease; }
  header#header #topmenu {
    margin: 0 0 7px;
    display: none;
    float: left; }
    header#header #topmenu li {
      padding-bottom: 18px; }
      header#header #topmenu li:not(:last-of-type) {
        margin-right: 1em; }
    header#header #topmenu a {
      font-size: 0.6em;
      color: #333535;
      text-transform: uppercase;
      transition: color 0.2s ease; }
      header#header #topmenu a:hover {
        color: #339a99; }
    header#header #topmenu .active a {
      font-weight: 700; }
  header#header #moreContainer {
    display: inline-block;
    position: relative;
    z-index: 800;
    float: right;
    margin-top: -10px; }
    header#header #moreContainer.hide {
      display: none; }
    header#header #moreContainer.menuOpen > .moreBtn {
      background-color: rgba(51, 154, 153, 0.9); }
      header#header #moreContainer.menuOpen > .moreBtn:hover, header#header #moreContainer.menuOpen > .moreBtn:active {
        background-color: rgba(51, 154, 153, 0.8); }
        header#header #moreContainer.menuOpen > .moreBtn:hover .bar, header#header #moreContainer.menuOpen > .moreBtn:active .bar {
          box-shadow: 0 1px 0 rgba(51, 53, 53, 0); }
      header#header #moreContainer.menuOpen > .moreBtn .bar {
        background-color: #fff; }
    header#header #moreContainer > .moreBtn {
      display: inline-block;
      padding: 0.7em 14px;
      margin-left: 10px;
      line-height: 1.125em;
      background-color: transparent;
      border-radius: 10px 10px 0 0;
      transition: background-color 0.2s ease; }
      header#header #moreContainer > .moreBtn:hover .bar, header#header #moreContainer > .moreBtn:active .bar {
        box-shadow: 0 1px 0 rgba(51, 53, 53, 0.25); }
      header#header #moreContainer > .moreBtn .icon {
        display: inline-block; }
      header#header #moreContainer > .moreBtn .bar {
        background-color: #339a99;
        display: block;
        width: 20px;
        height: 2px;
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
        transition: box-shadow 0.2s ease;
        box-shadow: 0 1px 0 rgba(51, 53, 53, 0);
        transition: background-color 0.2s ease, box-shadow 0.2s ease; }
        header#header #moreContainer > .moreBtn .bar:not(:first-child) {
          margin-top: 3px; }
    header#header #moreContainer .moreSubmenu > ul {
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 60;
      display: none;
      width: 200px;
      margin: 0;
      padding: 0.6em 0;
      text-align: right;
      border-radius: 0 0 10px 10px;
      background-color: rgba(51, 154, 153, 0.9);
      opacity: 0;
      transition: opacity 0.2s ease, transform 0.2s ease; }
      header#header #moreContainer .moreSubmenu > ul.visible {
        display: block; }
      header#header #moreContainer .moreSubmenu > ul.fadeIn {
        opacity: 1;
        transform: translateY(0); }
        header#header #moreContainer .moreSubmenu > ul.fadeIn > li {
          transform: translateY(0); }
      header#header #moreContainer .moreSubmenu > ul > li {
        list-style-type: none;
        transition: transform 0.2s ease; }
        header#header #moreContainer .moreSubmenu > ul > li:not(:last-of-type)::after {
          content: '';
          display: block;
          margin: 0.4em 1.5em 0.4em auto;
          width: 1em;
          border-bottom: 1px solid #fff; }
        header#header #moreContainer .moreSubmenu > ul > li > a {
          white-space: nowrap;
          font-weight: bold; }
        header#header #moreContainer .moreSubmenu > ul > li ul {
          margin: 0; }
          header#header #moreContainer .moreSubmenu > ul > li ul li {
            font-size: 0.85em; }
      header#header #moreContainer .moreSubmenu > ul a {
        display: block;
        padding: 0.6em 1.8em;
        text-decoration: none;
        font-size: 0.8em;
        color: #fff !important; }
        header#header #moreContainer .moreSubmenu > ul a span {
          color: #fff !important; }
        header#header #moreContainer .moreSubmenu > ul a:hover, header#header #moreContainer .moreSubmenu > ul a:active {
          background-color: rgba(255, 255, 255, 0.1); }

#entete {
  position: relative; }
  #entete .inner {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2; }
  #entete img {
    width: 100%;
    height: auto;
    vertical-align: top;
    position: relative;
    z-index: 1; }
  #entete h1 {
    font-weight: 800;
    font-style: italic; }

/*** MAIN **************************************************/
.site #main {
  background-color: #fff;
  color: #333535;
  font-weight: 400;
  line-height: 1.5;
  padding: 0 0 50px; }
  .site #main h2, .site #main h3, .site #main h4, .site #main h5, .site #main h6 {
    color: #339a99;
    line-height: 1; }
  .site #main h2 {
    font-weight: 700; }
    .site #main h2 strong {
      color: #333535;
      font-weight: 700; }
  .site #main h3 {
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0; }

/* Styles de texte généraux  */
p {
  margin-bottom: 0; }

.entryContent, .entry-content, .formFooter, label, dd, #main .entryContent, #main .entry-content {
  /*
	.tooltip {
		position: absolute;
		right: 4px;
		top: -2px;
		color: red;
		font-size: 0.8em;
	}
	*/ }
  .entryContent img.lazy, .entry-content img.lazy, .formFooter img.lazy, label img.lazy, dd img.lazy, #main .entryContent img.lazy, #main .entry-content img.lazy {
    display: none; }
  .entryContent article, .entry-content article, .formFooter article, label article, dd article, #main .entryContent article, #main .entry-content article {
    margin-bottom: 1em; }
  .entryContent table, .entry-content table, .formFooter table, label table, dd table, #main .entryContent table, #main .entry-content table {
    max-width: 100%;
    border-spacing: collapse;
    margin-bottom: 15px;
    border: 2px solid #339a99;
    font-size: 0.8em; }
    .entryContent table thead tr, .entry-content table thead tr, .formFooter table thead tr, label table thead tr, dd table thead tr, #main .entryContent table thead tr, #main .entry-content table thead tr {
      border-bottom: 2px solid #339a99; }
    .entryContent table tr th, .entry-content table tr th, .formFooter table tr th, label table tr th, dd table tr th, #main .entryContent table tr th, #main .entry-content table tr th {
      text-align: center;
      padding: 10px 10px;
      text-transform: uppercase;
      border: 1px solid #fff;
      color: #fff;
      background-color: #339a99; }
    .entryContent table tr td, .entry-content table tr td, .formFooter table tr td, label table tr td, dd table tr td, #main .entryContent table tr td, #main .entry-content table tr td {
      text-align: left;
      padding: 8px 10px;
      border: 1px solid #339a99; }
  .entryContent ul, .entryContent ol, .entry-content ul, .entry-content ol, .formFooter ul, .formFooter ol, label ul, label ol, dd ul, dd ol, #main .entryContent ul, #main .entryContent ol, #main .entry-content ul, #main .entry-content ol {
    list-style-position: outside;
    padding-left: 0.67em; }
    .entryContent ul li, .entryContent ol li, .entry-content ul li, .entry-content ol li, .formFooter ul li, .formFooter ol li, label ul li, label ol li, dd ul li, dd ol li, #main .entryContent ul li, #main .entryContent ol li, #main .entry-content ul li, #main .entry-content ol li {
      list-style-type: none;
      margin: 0 0 0.6em 0em;
      padding-left: 0.67em; }
  .entryContent ul li, .entry-content ul li, .formFooter ul li, label ul li, dd ul li, #main .entryContent ul li, #main .entry-content ul li {
    position: relative;
    padding-left: 0.5em; }
    .entryContent ul li::before, .entry-content ul li::before, .formFooter ul li::before, label ul li::before, dd ul li::before, #main .entryContent ul li::before, #main .entry-content ul li::before {
      content: "-";
      position: absolute;
      left: 0;
      top: 0;
      font-weight: bold;
      color: #339a99; }
  .entryContent ol, .entry-content ol, .formFooter ol, label ol, dd ol, #main .entryContent ol, #main .entry-content ol {
    counter-reset: item; }
    .entryContent ol li::before, .entry-content ol li::before, .formFooter ol li::before, label ol li::before, dd ol li::before, #main .entryContent ol li::before, #main .entry-content ol li::before {
      content: counter(item) ".";
      counter-increment: item;
      color: #339a99;
      margin-right: 0.5em;
      font-weight: bold; }
  .entryContent table.alternance tr:nth-child(even) td, .entry-content table.alternance tr:nth-child(even) td, .formFooter table.alternance tr:nth-child(even) td, label table.alternance tr:nth-child(even) td, dd table.alternance tr:nth-child(even) td, #main .entryContent table.alternance tr:nth-child(even) td, #main .entry-content table.alternance tr:nth-child(even) td {
    background-color: #EBEBEB; }
  .entryContent table.alternance tr td, .entryContent table.alternance tr th, .entry-content table.alternance tr td, .entry-content table.alternance tr th, .formFooter table.alternance tr td, .formFooter table.alternance tr th, label table.alternance tr td, label table.alternance tr th, dd table.alternance tr td, dd table.alternance tr th, #main .entryContent table.alternance tr td, #main .entryContent table.alternance tr th, #main .entry-content table.alternance tr td, #main .entry-content table.alternance tr th {
    border-bottom: 1px solid #bcbcbc; }
  .entryContent form ul, .entryContent form ol, .entry-content form ul, .entry-content form ol, .formFooter form ul, .formFooter form ol, label form ul, label form ol, dd form ul, dd form ol, #main .entryContent form ul, #main .entryContent form ol, #main .entry-content form ul, #main .entry-content form ol {
    margin-left: 0; }
    .entryContent form ul li, .entryContent form ol li, .entry-content form ul li, .entry-content form ol li, .formFooter form ul li, .formFooter form ol li, label form ul li, label form ol li, dd form ul li, dd form ol li, #main .entryContent form ul li, #main .entryContent form ol li, #main .entry-content form ul li, #main .entry-content form ol li {
      list-style-type: none; }
  .entryContent p, .entryContent table, .entryContent ul, .entryContent ol, .entry-content p, .entry-content table, .entry-content ul, .entry-content ol, .formFooter p, .formFooter table, .formFooter ul, .formFooter ol, label p, label table, label ul, label ol, dd p, dd table, dd ul, dd ol, #main .entryContent p, #main .entryContent table, #main .entryContent ul, #main .entryContent ol, #main .entry-content p, #main .entry-content table, #main .entry-content ul, #main .entry-content ol {
    margin: 0 0 0.67em 0; }
  .entryContent img.noborder, .entry-content img.noborder, .formFooter img.noborder, label img.noborder, dd img.noborder, #main .entryContent img.noborder, #main .entry-content img.noborder {
    border: 0 none !important; }
  .entryContent dl dt, .entry-content dl dt, .formFooter dl dt, label dl dt, dd dl dt, #main .entryContent dl dt, #main .entry-content dl dt {
    font-weight: 500;
    padding-bottom: 5px;
    float: left;
    padding-right: 5px; }
  .entryContent dl dd, .entry-content dl dd, .formFooter dl dd, label dl dd, dd dl dd, #main .entryContent dl dd, #main .entry-content dl dd {
    padding-bottom: 15px;
    margin-left: 0; }
  .entryContent .alignleft, .entry-content .alignleft, .formFooter .alignleft, label .alignleft, dd .alignleft, #main .entryContent .alignleft, #main .entry-content .alignleft {
    display: inline-block;
    float: none;
    margin: 0 1em 0.67em 0;
    width: 260px;
    height: auto; }
    .entryContent .alignleft.wp-caption .wp-caption-text, .entry-content .alignleft.wp-caption .wp-caption-text, .formFooter .alignleft.wp-caption .wp-caption-text, label .alignleft.wp-caption .wp-caption-text, dd .alignleft.wp-caption .wp-caption-text, #main .entryContent .alignleft.wp-caption .wp-caption-text, #main .entry-content .alignleft.wp-caption .wp-caption-text {
      text-align: center;
      font-size: 0.9em; }
  .entryContent .alignright, .entry-content .alignright, .formFooter .alignright, label .alignright, dd .alignright, #main .entryContent .alignright, #main .entry-content .alignright {
    float: right;
    margin: 0 0 0.4em 1em; }
  .entryContent .aligncenter, .entry-content .aligncenter, .formFooter .aligncenter, label .aligncenter, dd .aligncenter, #main .entryContent .aligncenter, #main .entry-content .aligncenter {
    text-align: center; }
  .entryContent h2, .entry-content h2, .formFooter h2, label h2, dd h2, #main .entryContent h2, #main .entry-content h2 {
    font-size: 2.2em;
    font-style: italic;
    font-weight: bold;
    margin: 0.67em 0 0.335em 0; }
    .entryContent h2:first-child, .entry-content h2:first-child, .formFooter h2:first-child, label h2:first-child, dd h2:first-child, #main .entryContent h2:first-child, #main .entry-content h2:first-child {
      margin-top: 0; }
  .entryContent h3, .entry-content h3, .formFooter h3, label h3, dd h3, #main .entryContent h3, #main .entry-content h3 {
    font-size: 1.7em;
    font-style: italic;
    font-weight: bold;
    text-transform: none;
    margin: 0 0 0.335em 0; }
  .entryContent h4, .entry-content h4, .formFooter h4, label h4, dd h4, #main .entryContent h4, #main .entry-content h4 {
    margin: 0 0 0.5em 0;
    font-size: 1.4em;
    font-weight: bold;
    color: #333535; }
  .entryContent h5, .entry-content h5, .formFooter h5, label h5, dd h5, #main .entryContent h5, #main .entry-content h5 {
    margin: 0 0 0.67em 0;
    font-size: 1.3em;
    font-weight: bold;
    color: #333535; }
  .entryContent h6, .entry-content h6, .formFooter h6, label h6, dd h6, #main .entryContent h6, #main .entry-content h6 {
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    color: #333535; }

.entryContent a:not(.button), .entry-content a:not(.button), .formFooter a:not(.button), label a:not(.button), dd a:not(.button), fieldset a:not(.button), .formRow:not(.buttons) a:not(.button) {
  font-weight: 700;
  color: #339a99; }
  .entryContent a:not(.button):hover, .entry-content a:not(.button):hover, .formFooter a:not(.button):hover, label a:not(.button):hover, dd a:not(.button):hover, fieldset a:not(.button):hover, .formRow:not(.buttons) a:not(.button):hover {
    text-decoration: none; }

.entryContent a.suppress, .entry-content a.suppress, .formFooter a.suppress, label a.suppress, dd a.suppress, fieldset a.suppress, .formRow:not(.buttons) a.suppress {
  font-weight: initial;
  color: #9a0000;
  font-size: 0.75em; }
  .entryContent a.suppress:hover, .entry-content a.suppress:hover, .formFooter a.suppress:hover, label a.suppress:hover, dd a.suppress:hover, fieldset a.suppress:hover, .formRow:not(.buttons) a.suppress:hover {
    text-decoration: none; }

/*** FOOTER **************************************************/
div#newsletter {
  background-color: #339a99;
  color: #fff; }
  div#newsletter .inner {
    padding-top: 40px;
    padding-bottom: 40px; }
  div#newsletter h3 {
    font-size: 1.4em;
    font-style: italic;
    font-weight: 400;
    padding: 0;
    margin: 0; }
    div#newsletter h3 strong {
      font-weight: 800;
      font-style: italic;
      font-size: 1.1em; }
  div#newsletter p {
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-style: italic;
    font-weight: 600; }
  div#newsletter form {
    white-space: nowrap; }
  div#newsletter input[type="text"], div#newsletter input[type="email"], div#newsletter input[type="password"] {
    background-color: #fff;
    border: 0 none;
    border-radius: 9px 0 0 9px;
    padding: 15px 20px;
    font-family: "Open Sans", Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #333535;
    font-size: 0.9em;
    margin-right: 2px;
    display: inline-block;
    max-width: 55%;
    float: left; }
  div#newsletter input[type="submit"], div#newsletter input[type="button"], div#newsletter button {
    background-color: #206160;
    border: 0 none;
    border-radius: 0 9px 9px 0;
    padding: 15px 20px;
    font-family: "Open Sans", Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 0.9em;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.2s ease; }
    div#newsletter input[type="submit"]:hover, div#newsletter input[type="button"]:hover, div#newsletter button:hover {
      background-color: #133a3a; }

footer#footer {
  padding: 30px 0;
  background-color: #fff; }
  footer#footer:not(.afterNewsletter) {
    box-shadow: 0 0 10px 10px rgba(51, 53, 53, 0.2); }
  footer#footer .logo {
    margin-bottom: 20px; }
  footer#footer #footermenu, footer#footer #legalmenu {
    text-align: left; }
    footer#footer #footermenu ul, footer#footer #legalmenu ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
    footer#footer #footermenu li, footer#footer #legalmenu li {
      list-style-type: none;
      margin: 0.5em 0; }
    footer#footer #footermenu a, footer#footer #legalmenu a {
      display: inline-block;
      text-decoration: none;
      transition: font-weight 0.2s ease; }
    footer#footer #footermenu .active a, footer#footer #legalmenu .active a {
      font-weight: 700; }
  footer#footer #footermenu {
    font-size: 0.8em; }
    footer#footer #footermenu a {
      color: #333535;
      transition: color 0.2s ease; }
      footer#footer #footermenu a:hover, footer#footer #footermenu a:active {
        color: #339a99; }
  footer#footer #legalmenu {
    margin: 22px 0 7px;
    font-size: 0.7em; }
    footer#footer #legalmenu a {
      color: #206160;
      text-transform: uppercase;
      transition: color 0.2s ease; }
      footer#footer #legalmenu a:hover, footer#footer #legalmenu a:active {
        color: #333535; }
  footer#footer .social p {
    margin-bottom: 0.6em; }
  footer#footer .social strong {
    text-transform: uppercase; }
  footer#footer .social ul {
    margin-bottom: 0.6em;
    list-style-type: none; }
    footer#footer .social ul li {
      display: inline-block; }
      footer#footer .social ul li:not(:first-child) {
        margin-left: 15px; }
      footer#footer .social ul li a {
        background-image: url("/images/sprite_social.png");
        background-repeat: no-repeat;
        width: 48px;
        height: 48px;
        display: block;
        white-space: nowrap;
        text-indent: 48px;
        overflow: hidden;
        transition: filter 0.2s ease; }
        footer#footer .social ul li a:hover, footer#footer .social ul li a:active {
          filter: brightness(90%); }
      footer#footer .social ul li.linkedin a {
        background-position: 0 0; }
      footer#footer .social ul li.facebook a {
        background-position: 0 -78px; }
  footer#footer .copyright, footer#footer .conception {
    font-size: 0.7em; }
  footer#footer .copyright {
    margin-bottom: 0.6em; }
  footer#footer .conception a {
    font-weight: bold;
    color: #339a99;
    transition: color 0.2s ease; }
    footer#footer .conception a:hover, footer#footer .conception a:active {
      color: #206160; }

/*** Styles de la page d'accueil **************************************************/
.home #main {
  position: relative;
  background-image: url("/images/bg_small.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 220px; }
  .home #main .title {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: -0.05em;
    color: #333535; }
    .home #main .title > div {
      flex-basis: 100%;
      max-width: 100%; }
    .home #main .title .number {
      display: inline;
      font-size: 1.6em; }
    .home #main .title .lines {
      display: inline; }
      .home #main .title .lines p {
        margin-bottom: 0.5em; }
        .home #main .title .lines p:first-of-type {
          display: inline; }
  .home #main .typeuser {
    padding-bottom: 240px;
    position: relative;
    z-index: 5; }
    .home #main .typeuser > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
    .home #main .typeuser .bubble {
      display: inline-block;
      flex-basis: 96%;
      max-width: 96%;
      flex-grow: 0;
      position: relative;
      margin-top: 1em;
      z-index: 10; }
      .home #main .typeuser .bubble.black .content {
        background-color: rgba(51, 53, 53, 0.8); }
        .home #main .typeuser .bubble.black .content:hover {
          background-color: #333535; }
      .home #main .typeuser .bubble.aqua .content {
        background-color: rgba(51, 154, 153, 0.8); }
        .home #main .typeuser .bubble.aqua .content:hover {
          background-color: #339a99; }
      .home #main .typeuser .bubble.purple .content {
        background-color: rgba(106, 90, 201, 0.8); }
        .home #main .typeuser .bubble.purple .content:hover {
          background-color: #6a5ac9; }
      .home #main .typeuser .bubble.green .content {
        background-color: rgba(90, 201, 148, 0.8); }
        .home #main .typeuser .bubble.green .content:hover {
          background-color: #5ac994; }
      .home #main .typeuser .bubble::before {
        content: "";
        display: none;
        margin-top: 100%; }
      .home #main .typeuser .bubble.disabled {
        opacity: 0.3; }
      .home #main .typeuser .bubble .icon {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10%;
        width: 72px;
        height: 59px;
        background-image: url("/images/sprite_icon.png");
        background-position: 0 -4771px; }
      .home #main .typeuser .bubble .content {
        border: 6px solid #fff;
        text-align: center;
        border-radius: 1em;
        transition: background-color 0.2s ease; }
        .home #main .typeuser .bubble .content span.noa, .home #main .typeuser .bubble .content a {
          display: block;
          color: white; }
          .home #main .typeuser .bubble .content span.noa, .home #main .typeuser .bubble .content span.noa:link, .home #main .typeuser .bubble .content span.noa:visited, .home #main .typeuser .bubble .content a, .home #main .typeuser .bubble .content a:link, .home #main .typeuser .bubble .content a:visited {
            text-decoration: none; }
          .home #main .typeuser .bubble .content span.noa .aContent, .home #main .typeuser .bubble .content a .aContent {
            display: block;
            padding: 1.4em 8% 3.2em 8%; }
            .home #main .typeuser .bubble .content span.noa .aContent > span, .home #main .typeuser .bubble .content a .aContent > span {
              display: block; }
        .home #main .typeuser .bubble .content .clickHere {
          text-decoration: underline;
          display: block;
          position: absolute;
          width: 125px;
          bottom: 15px;
          left: 50%;
          margin-left: -62.5px;
          text-align: center;
          line-height: 1.2;
          font-size: 1em;
          font-weight: bold;
          color: #ffffff; }
        .home #main .typeuser .bubble .content .typeTitle {
          line-height: 1.4em; }
          .home #main .typeuser .bubble .content .typeTitle .titleLine {
            display: block;
            white-space: nowrap; }
          .home #main .typeuser .bubble .content .typeTitle .fragment.xlarge {
            font-size: 2em;
            font-weight: 700; }
          .home #main .typeuser .bubble .content .typeTitle .fragment.large {
            font-size: 1.4em;
            font-weight: 700; }
          .home #main .typeuser .bubble .content .typeTitle .fragment.small {
            font-size: 0.8em; }
        .home #main .typeuser .bubble .content .typeSubtitle {
          line-height: 1.2em;
          font-size: 1.4em;
          font-style: italic; }
  .home #main .linkBar {
    position: absolute;
    z-index: 50;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333535;
    opacity: 0.9; }
    .home #main .linkBar .inner {
      padding: 0.7em 0;
      margin: 0 auto;
      width: 100%;
      max-width: 1300px;
      display: flex;
      flex-wrap: wrap; }
    .home #main .linkBar .text {
      display: block;
      width: 100%;
      text-align: center;
      padding: 5px 20px; }
      .home #main .linkBar .text h3 {
        color: #fff;
        margin: 0;
        font-size: 1em; }
    .home #main .linkBar .link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 100%;
      max-width: 100%; }
      .home #main .linkBar .link:first-of-type {
        position: relative;
        padding-bottom: 1em; }
        .home #main .linkBar .link:first-of-type a::after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          bottom: -1.5px;
          width: 3em;
          max-width: 90%;
          height: 0;
          border-top: 3px solid #fff;
          transform: translateX(-50%); }
      .home #main .linkBar .link:not(:first-of-type) {
        margin-top: 1em; }
      .home #main .linkBar .link a {
        display: inline-block;
        padding: 0.6em 1.6em;
        border: 1px solid #fff;
        border-radius: 0.5em;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        transition: background-color 0.2s ease, color 0.2s ease; }
        .home #main .linkBar .link a, .home #main .linkBar .link a:link, .home #main .linkBar .link a:visited {
          color: #fff; }
        .home #main .linkBar .link a:hover, .home #main .linkBar .link a:active {
          color: #333535;
          background-color: #fff; }

.home #videoBlock {
  padding: 9em 0 7em;
  overflow: hidden;
  background-color: #EBEBEB; }
  .home #videoBlock .wrap {
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap; }
    .home #videoBlock .wrap::after {
      content: '';
      position: absolute;
      z-index: -10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      transform: translate(0, -1.5em); }
  .home #videoBlock .videoCol {
    flex-basis: 100%;
    max-width: 100%;
    flex-grow: 1;
    align-items: top; }
    .home #videoBlock .videoCol .outerVideo {
      max-width: 600px; }
  .home #videoBlock .videoDescription {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 4%;
    padding-top: 1em; }
    .home #videoBlock .videoDescription h2 {
      font-size: 1.8em; }
      .home #videoBlock .videoDescription h2 > span {
        display: block;
        line-height: 1em; }
      .home #videoBlock .videoDescription h2 .mainTitle {
        font-size: 1.4em;
        font-weight: 800;
        font-style: italic;
        color: #339a99; }
      .home #videoBlock .videoDescription h2 .subtitle {
        font-style: italic; }
    .home #videoBlock .videoDescription .entryContent, .home #videoBlock .videoDescription .entry-content {
      padding-right: 2em;
      line-height: 1.4em;
      margin-bottom: 1em; }

.home #howitwork {
  padding: 5em 0; }
  .home #howitwork a {
    color: #339a99;
    text-decoration: none; }
    .home #howitwork a:hover {
      text-decoration: underline; }
  .home #howitwork header {
    display: flex;
    justify-content: center;
    align-items: center; }
  .home #howitwork h2 {
    font-size: 2.2em;
    font-weight: 800;
    font-style: italic;
    line-height: 1.3;
    display: inline-block;
    padding-right: 4px;
    margin: 0;
    padding-bottom: 0.3em;
    position: relative; }
    .home #howitwork h2 > span {
      display: block;
      padding-right: 0.2em;
      color: #339a99; }
    .home #howitwork h2 .indent {
      text-indent: 1em;
      margin-top: -0.9em; }
    .home #howitwork h2 strong {
      font-size: 1.4em; }
  .home #howitwork .intro {
    text-align: justify;
    margin-bottom: 1.5em;
    margin: 2em auto 0; }
    .home #howitwork .intro h3 {
      font-size: 1.5em; }
    .home #howitwork .intro p:not(:last-child) {
      margin-bottom: 1em; }
  .home #howitwork .steps .step {
    position: relative;
    margin-bottom: 25px; }
    .home #howitwork .steps .step .image {
      width: 100%; }
      .home #howitwork .steps .step .image img {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: auto; }
    .home #howitwork .steps .step .title {
      line-height: 1; }
      .home #howitwork .steps .step .title hr {
        max-width: 5em;
        border-width: 0 0 2px 0;
        border-style: solid;
        border-color: #EBEBEB;
        text-align: left;
        margin: 1em 0; }
      .home #howitwork .steps .step .title .thestep {
        font-size: 1.4em;
        font-weight: 400;
        font-style: italic; }
      .home #howitwork .steps .step .title .no {
        font-size: 4em;
        font-weight: 800;
        display: block; }
    .home #howitwork .steps .step .candidate, .home #howitwork .steps .step .employer {
      font-size: 0.9em;
      font-weight: 700; }
      .home #howitwork .steps .step .candidate strong, .home #howitwork .steps .step .employer strong {
        font-size: 1.4em;
        font-weight: 800;
        text-transform: uppercase;
        display: block; }
    .home #howitwork .steps .step .candidate strong {
      color: #339a99; }
    .home #howitwork .steps .step .image, .home #howitwork .steps .step .content {
      float: left; }
    .home #howitwork .steps .step .content {
      padding-right: 1em; }
      .home #howitwork .steps .step .content .title .no {
        color: #35a26e; }

.home #blog {
  background-color: #333535;
  color: white;
  padding: 3em 0; }
  .home #blog .wrap > header {
    line-height: 1;
    margin-bottom: 3em; }
    .home #blog .wrap > header hr {
      max-width: 5em;
      border-width: 0 0 6px 0;
      border-style: solid;
      border-color: #5ac994;
      text-align: left;
      margin: 1em 0; }
  .home #blog h2 {
    font-size: 3em;
    font-weight: 800;
    font-style: italic;
    color: #fff;
    line-height: 1.3;
    display: inline-block;
    padding-right: 0.2em;
    margin: 0; }
  .home #blog .blogpost:not(:first-child) {
    margin-top: 2em; }
  .home #blog .blogpost.postCategory-269 .content h4 {
    color: #339a99; }
    .home #blog .blogpost.postCategory-269 .content h4 a, .home #blog .blogpost.postCategory-269 .content h4 a:link, .home #blog .blogpost.postCategory-269 .content h4 a:visited {
      color: #339a99; }
    .home #blog .blogpost.postCategory-269 .content h4 a:hover, .home #blog .blogpost.postCategory-269 .content h4 a:active {
      color: #40c0bf; }
  .home #blog .blogpost.postCategory-269 .content .entryContent .excerpt-read-more, .home #blog .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:link, .home #blog .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:visited, .home #blog .blogpost.postCategory-269 .content .entry-content .excerpt-more, .home #blog .blogpost.postCategory-269 .content .entry-content .excerpt-more:link, .home #blog .blogpost.postCategory-269 .content .entry-content .excerpt-more:visited {
    color: #339a99; }
  .home #blog .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:hover, .home #blog .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:active, .home #blog .blogpost.postCategory-269 .content .entry-content .excerpt-more:hover, .home #blog .blogpost.postCategory-269 .content .entry-content .excerpt-more:active {
    color: #40c0bf; }
  .home #blog .blogpost.postCategory-270 .content h4 {
    color: #6a5ac9; }
    .home #blog .blogpost.postCategory-270 .content h4 a, .home #blog .blogpost.postCategory-270 .content h4 a:link, .home #blog .blogpost.postCategory-270 .content h4 a:visited {
      color: #6a5ac9; }
    .home #blog .blogpost.postCategory-270 .content h4 a:hover, .home #blog .blogpost.postCategory-270 .content h4 a:active {
      color: #8d80d6; }
  .home #blog .blogpost.postCategory-270 .content .entryContent .excerpt-read-more, .home #blog .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:link, .home #blog .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:visited, .home #blog .blogpost.postCategory-270 .content .entry-content .excerpt-read-more, .home #blog .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:link, .home #blog .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:visited {
    color: #6a5ac9; }
  .home #blog .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:hover, .home #blog .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:active, .home #blog .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:hover, .home #blog .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:active {
    color: #8d80d6; }
  .home #blog .blogpost .content .entryContent .excerpt-read-more, .home #blog .blogpost .content .entryContent .excerpt-read-more:link, .home #blog .blogpost .content .entryContent .excerpt-read-more:visited, .home #blog .blogpost .content .entry-content .excerpt-read-more, .home #blog .blogpost .content .entry-content .excerpt-read-more:link, .home #blog .blogpost .content .entry-content .excerpt-read-more:visited {
    color: rgba(255, 255, 255, 0.6); }
  .home #blog .blogpost .content .entryContent .excerpt-read-more:hover, .home #blog .blogpost .content .entryContent .excerpt-read-more:active, .home #blog .blogpost .content .entry-content .excerpt-read-more:hover, .home #blog .blogpost .content .entry-content .excerpt-read-more:active {
    color: #fff; }
  .home #blog .postCategories li.category-269 a, .home #blog .postCategories li.category-269 a:link, .home #blog .postCategories li.category-269 a:visited {
    color: #fff;
    background-color: #6a5ac9; }
  .home #blog .postCategories li.category-269 a:hover, .home #blog .postCategories li.category-269 a:active {
    background-color: #8d80d6; }
  .home #blog .postCategories li.category-270 a, .home #blog .postCategories li.category-270 a:link, .home #blog .postCategories li.category-270 a:visited {
    color: #fff;
    background-color: #6a5ac9; }
  .home #blog .postCategories li.category-270 a:hover, .home #blog .postCategories li.category-270 a:active {
    background-color: #8d80d6; }
  .home #blog .postCategories li a {
    transition: background-color 0.2s ease, color 0.2s ease; }
    .home #blog .postCategories li a, .home #blog .postCategories li a:link, .home #blog .postCategories li a:visited {
      background-color: #fff;
      color: #4b4b4b; }
    .home #blog .postCategories li a:hover, .home #blog .postCategories li a:active {
      background-color: rgba(255, 255, 255, 0.9);
      color: #333535; }
  .home #blog .image {
    display: block;
    margin-right: 0;
    margin-bottom: 1em; }
    .home #blog .image img {
      border: 1px solid #5e6161;
      max-width: 480px; }
  .home #blog time {
    font-size: 1.1em;
    font-weight: 700;
    font-style: italic;
    color: #fff;
    line-height: 1; }
  .home #blog h4 {
    font-size: 1.6em;
    font-weight: 700;
    font-style: italic;
    line-height: 1;
    margin: 0;
    margin-bottom: 0.2em; }
    .home #blog h4 a {
      color: rgba(255, 255, 255, 0.6);
      hyphens: auto;
      transition: color 0.2s ease; }
      .home #blog h4 a:hover, .home #blog h4 a:active {
        color: #fff; }
  .home #blog .excerpt-read-more {
    text-decoration: none; }
    .home #blog .excerpt-read-more:hover, .home #blog .excerpt-read-more:active {
      text-decoration: underline; }
  .home #blog .allposts {
    margin-top: 2em; }

.home #infos {
  padding: 5em 0; }
  .home #infos ul.infos {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap; }
    .home #infos ul.infos li {
      font-weight: 700;
      text-align: center;
      margin-bottom: 2em; }
      .home #infos ul.infos li:nth-child(odd) h5 {
        color: #339a99; }
      .home #infos ul.infos li:nth-child(even) h5 {
        color: #206160; }
      .home #infos ul.infos li a {
        text-decoration: none;
        color: #6a5ac9;
        font-style: italic; }
        .home #infos ul.infos li a:hover {
          text-decoration: underline; }
  .home #infos .icon {
    height: 130px;
    margin-bottom: 2em; }
    .home #infos .icon .theIcon {
      display: inline-block;
      height: 130px;
      width: 100%;
      background-image: url("/images/sprite_icon.png");
      background-repeat: no-repeat; }
    .home #infos .icon.communaute .theIcon {
      background-position: 0 0;
      width: 126px;
      height: 116px; }
    .home #infos .icon.securite .theIcon {
      background-position: 0 -431px;
      width: 98px;
      height: 126px; }
    .home #infos .icon.partout .theIcon {
      background-position: 0 -882px;
      width: 74px;
      height: 126px; }
  .home #infos h5 {
    font-size: 1.4em;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase; }

.emploiretraite-view-jobs-button {
  margin-top: 1em;
  text-align: center; }

/*** Styles des formulaires **************************************************/
.msg {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.2em;
  padding: 1em 1.5em 1.3em;
  font-size: 0.8em;
  font-weight: 700;
  border: 1px solid #333535;
  border-radius: 0.3em;
  width: 100%; }
  .msg.msgBig {
    font-size: 1em;
    font-weight: 400; }
  .msg.msg-error {
    border-color: #9a0000;
    color: #9a0000; }
    .msg.msg-error > .icon {
      background-position: 0 -806px; }
    .msg.msg-error a {
      transition: color 0.2s ease; }
      .msg.msg-error a, .msg.msg-error a:link, .msg.msg-error a:visited {
        color: #9a0000; }
      .msg.msg-error a:hover, .msg.msg-error a:active {
        color: #333535; }
  .msg.msg-notice {
    border-color: #dda946;
    color: #cb9225; }
    .msg.msg-notice > .icon {
      width: 32px;
      height: 28px;
      border-right: 4px solid transparent;
      background-position: 0 -706px; }
    .msg.msg-notice a {
      transition: color 0.2s ease; }
      .msg.msg-notice a, .msg.msg-notice a:link, .msg.msg-notice a:visited {
        color: #dda946; }
      .msg.msg-notice a:hover, .msg.msg-notice a:active {
        color: #333535; }
  .msg.msg-success {
    border-color: #5ac994;
    color: #3bb57b; }
    .msg.msg-success > .icon {
      background-position: 0 -406px; }
    .msg.msg-success a {
      transition: color 0.2s ease; }
      .msg.msg-success a, .msg.msg-success a:link, .msg.msg-success a:visited {
        color: #5ac994; }
      .msg.msg-success a:hover, .msg.msg-success a:active {
        color: #333535; }
  .msg > .icon {
    display: inline-block;
    margin-right: 1em;
    width: 28px;
    height: 28px;
    background-image: url("/images/sprite_form.png");
    background-repeat: no-repeat; }
  .msg ul {
    margin: 0;
    margin-top: 0.2em;
    padding: 0;
    list-style-type: disc; }
  .msg li {
    margin: 0 0 0 0;
    padding: 0;
    margin-left: 1em;
    list-style-type: disc;
    line-height: 1.4em; }
    .msg li:only-child {
      margin-left: 0;
      list-style-type: none; }
    .msg li:not(:last-of-type) {
      margin-bottom: 0.4em; }

#main, .mfp-wrap {
  /* Style des compteurs de caractères des textarea */
  /* Style des liens spéciaux de formulaire (Ajouter une expérience, supprimer une expérience) */
  /* Style des fieldset */
  /* Style des select */
  /* Styles des checkbox */
  /* Styles des radios */
  /* Styles des checkbox "Secteurs" */
  /*.single-dropzone {
		.dz-image-preview, .dz-file-preview {
			display: none;
		}
	}*/ }
  #main li.formRow, .mfp-wrap li.formRow {
    list-style-type: none; }
  #main .validForm input:-webkit-autofill, .mfp-wrap .validForm input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset; }
  #main .validForm .required:not(select), .mfp-wrap .validForm .required:not(select) {
    background: url(/images/sprite_form.png) right -989px no-repeat;
    padding-right: 45px !important; }
  #main .validForm select.required, .mfp-wrap .validForm select.required {
    background: url(/images/sprite_form.png) calc(100% - 29px) -989px no-repeat, url(/images/sprite_form.png) right -84px no-repeat;
    padding-right: 80px; }
  #main .validForm select.badSelect, .mfp-wrap .validForm select.badSelect {
    border: 1px solid #9a0000 !important;
    background: url(/images/sprite_form.png) calc(100% - 29px) -1430px no-repeat, url(/images/sprite_form.png) right -84px no-repeat; }
  #main .validForm select.correctSelect, .mfp-wrap .validForm select.correctSelect {
    background: url(/images/sprite_form.png) calc(100% - 29px) -1664px no-repeat, url(/images/sprite_form.png) right -84px no-repeat; }
  #main .validForm .bad input:-webkit-autofill, .mfp-wrap .validForm .bad input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffcdcd inset; }
  #main .validForm .bad .label, .mfp-wrap .validForm .bad .label {
    color: #9a0000 !important; }
  #main .validForm .bad input, #main .validForm .bad textarea, .mfp-wrap .validForm .bad input, .mfp-wrap .validForm .bad textarea {
    border: 1px solid #9a0000 !important; }
  #main .validForm .bad .required:not(select), .mfp-wrap .validForm .bad .required:not(select) {
    background: url(/images/sprite_form.png) right -1430px no-repeat; }
  #main .validForm .correct input:-webkit-autofill, .mfp-wrap .validForm .correct input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #a7e2c6 inset; }
  #main .validForm .correct .required:not(select), .mfp-wrap .validForm .correct .required:not(select) {
    background: url(/images/sprite_form.png) right -1664px no-repeat; }
  #main .formRow, .mfp-wrap .formRow {
    margin-bottom: 0.8em; }
    #main .formRow .radio, .mfp-wrap .formRow .radio {
      display: inline-block; }
      #main .formRow .radio ~ .radio, .mfp-wrap .formRow .radio ~ .radio {
        margin-left: 0.8em; }
    #main .formRow div.passwordStrength, .mfp-wrap .formRow div.passwordStrength {
      position: relative; }
      #main .formRow div.passwordStrength a.button_strength, .mfp-wrap .formRow div.passwordStrength a.button_strength {
        display: none; }
      #main .formRow div.passwordStrength div.strength_meter, .mfp-wrap .formRow div.passwordStrength div.strength_meter {
        position: absolute;
        top: 28px;
        right: 50px;
        color: #339a99; }
        #main .formRow div.passwordStrength div.strength_meter .veryweak, .mfp-wrap .formRow div.passwordStrength div.strength_meter .veryweak {
          color: #9a0000;
          font-weight: normal; }
        #main .formRow div.passwordStrength div.strength_meter .weak, .mfp-wrap .formRow div.passwordStrength div.strength_meter .weak {
          color: #9a0000;
          font-weight: bold; }
        #main .formRow div.passwordStrength div.strength_meter .medium, .mfp-wrap .formRow div.passwordStrength div.strength_meter .medium {
          color: #5ac994;
          font-weight: normal; }
        #main .formRow div.passwordStrength div.strength_meter .strong, .mfp-wrap .formRow div.passwordStrength div.strength_meter .strong {
          color: #5ac994;
          font-weight: bold; }
  #main .multiRow, .mfp-wrap .multiRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap; }
    #main .multiRow.threeRows > select:not(:last-child), #main .multiRow.threeRows > input:not(:last-child), .mfp-wrap .multiRow.threeRows > select:not(:last-child), .mfp-wrap .multiRow.threeRows > input:not(:last-child) {
      margin-bottom: 0.8em; }
    #main .multiRow.threeRows .separator, .mfp-wrap .multiRow.threeRows .separator {
      display: none; }
    #main .multiRow.fakeRowEnd::after, .mfp-wrap .multiRow.fakeRowEnd::after {
      content: '';
      display: block;
      flex-basis: 50%;
      max-width: 50%;
      align-self: stretch; }
    #main .multiRow.fakeRowEnd.threeRows::after, .mfp-wrap .multiRow.fakeRowEnd.threeRows::after {
      flex-basis: 33.333%;
      max-width: 33.333%; }
    #main .multiRow.fakeRowStart::before, .mfp-wrap .multiRow.fakeRowStart::before {
      content: '';
      display: block;
      flex-basis: 50%;
      max-width: 50%;
      align-self: stretch; }
    #main .multiRow.fakeRowStart.threeRows::after, .mfp-wrap .multiRow.fakeRowStart.threeRows::after {
      flex-basis: 33.333%;
      max-width: 33.333%; }
    #main .multiRow > *, .mfp-wrap .multiRow > * {
      flex-basis: 100%;
      max-width: 100%; }
    #main .multiRow .separator, .mfp-wrap .multiRow .separator {
      flex-shrink: 99;
      align-self: center; }
      #main .multiRow .separator span, .mfp-wrap .multiRow .separator span {
        display: inline-block;
        transform: scale(1.6); }
    #main .multiRow .multiRow, .mfp-wrap .multiRow .multiRow {
      flex-wrap: nowrap; }
      #main .multiRow .multiRow > *, .mfp-wrap .multiRow .multiRow > * {
        flex-basis: 50%;
        max-width: 50%; }
        #main .multiRow .multiRow > *:not(:last-child), .mfp-wrap .multiRow .multiRow > *:not(:last-child) {
          margin-right: 0.8em; }
  #main .formRowParent, .mfp-wrap .formRowParent {
    padding: 6px 30px 10px;
    border: 1px solid #339a99;
    border-radius: 5px;
    margin-bottom: 4px; }
    #main .formRowParent.checkbox > input[type="checkbox"] + label, .mfp-wrap .formRowParent.checkbox > input[type="checkbox"] + label {
      display: block; }
  #main .twoCols .rows, #main .twoCols fieldset, .mfp-wrap .twoCols .rows, .mfp-wrap .twoCols fieldset {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; }
    #main .twoCols .rows .formRowParent, #main .twoCols fieldset .formRowParent, .mfp-wrap .twoCols .rows .formRowParent, .mfp-wrap .twoCols fieldset .formRowParent {
      flex-basis: 100%;
      max-width: 100%; }
  #main .formRowChild, .mfp-wrap .formRowChild {
    padding-left: 2.3em; }
    #main .formRowChild .formRow, .mfp-wrap .formRowChild .formRow {
      margin-bottom: 0; }
  #main .title, .mfp-wrap .title {
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 1em;
    margin-bottom: 1em; }
  #main p.notice, .mfp-wrap p.notice {
    margin-bottom: 1em; }
  #main .label, .mfp-wrap .label {
    display: block;
    margin: 0;
    font-size: 0.9em;
    font-weight: 700;
    color: #339a99; }
  #main .label-inline, .mfp-wrap .label-inline {
    display: inline-block;
    margin-right: 1em; }
  #main .label-inline + input, .mfp-wrap .label-inline + input {
    max-width: 80% !important; }
  #main .or, #main .formRow .or, .mfp-wrap .or, .mfp-wrap .formRow .or {
    margin: 2em 0;
    border-width: 4px 0 0;
    border-style: solid;
    border-color: #5ac994;
    height: 0;
    max-width: 80%;
    color: #339a99;
    text-transform: uppercase; }
    #main .or strong, #main .formRow .or strong, .mfp-wrap .or strong, .mfp-wrap .formRow .or strong {
      background-color: white;
      display: inline-block;
      padding: 0 1em;
      font-size: 1.4em;
      font-weight: 800;
      font-style: italic;
      top: -0.9em;
      position: relative;
      margin-left: 1.5em; }
  #main .instruction, .mfp-wrap .instruction {
    font-size: 0.9em;
    font-weight: 400;
    font-style: italic; }
  #main small, .mfp-wrap small {
    font-size: 0.7em;
    font-weight: 400;
    font-style: italic;
    display: block;
    text-transform: none; }
  #main .fhp, .mfp-wrap .fhp {
    display: none; }
  #main input:not([type="submit"]):not([type="reset"]):not([type="button"]):disabled, #main textarea:disabled, #main select:disabled, .mfp-wrap input:not([type="submit"]):not([type="reset"]):not([type="button"]):disabled, .mfp-wrap textarea:disabled, .mfp-wrap select:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  #main input:not([type="submit"]):not([type="reset"]):not([type="button"]):read-only, #main textarea:read-only, .mfp-wrap input:not([type="submit"]):not([type="reset"]):not([type="button"]):read-only, .mfp-wrap textarea:read-only {
    opacity: 0.7;
    cursor: not-allowed; }
  #main input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]), #main textarea, #main select, .mfp-wrap input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]), .mfp-wrap textarea, .mfp-wrap select {
    border: 1px solid #339a99;
    font-family: "Open Sans", Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #333535;
    padding: 5px 15px;
    border-radius: 0;
    width: 100%;
    box-sizing: border-box;
    outline: none; }
  #main input[type=file], #main select, #main textarea, .mfp-wrap input[type=file], .mfp-wrap select, .mfp-wrap textarea {
    width: 100%; }
  #main textarea, .mfp-wrap textarea {
    min-height: 10em; }
  #main .formRowError input, #main .formRowError select, #main .formRowError textarea, #main .formRowError .checkbox label::before, #main .formRowError .radio label::before, .mfp-wrap .formRowError input, .mfp-wrap .formRowError select, .mfp-wrap .formRowError textarea, .mfp-wrap .formRowError .checkbox label::before, .mfp-wrap .formRowError .radio label::before {
    border: 1px solid #9a0000 !important; }
  #main .formRowError label, #main .formRowError .label, .mfp-wrap .formRowError label, .mfp-wrap .formRowError .label {
    color: #9a0000; }
  #main .wrapCharCounter, .mfp-wrap .wrapCharCounter {
    display: flex;
    justify-content: space-between; }
    #main .wrapCharCounter.tooLong .curChars, .mfp-wrap .wrapCharCounter.tooLong .curChars {
      color: #9a0000; }
    #main .wrapCharCounter .separator, #main .wrapCharCounter .maxChars, .mfp-wrap .wrapCharCounter .separator, .mfp-wrap .wrapCharCounter .maxChars {
      color: #339a99; }
    #main .wrapCharCounter .curChars, .mfp-wrap .wrapCharCounter .curChars {
      margin-right: 0.2em;
      font-size: 1.2em;
      color: #4b4b4b; }
  #main .addXP, #main .remove a, .mfp-wrap .addXP, .mfp-wrap .remove a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    transition: color 0.2s ease; }
    #main .addXP:hover, #main .addXP:active, #main .remove a:hover, #main .remove a:active, .mfp-wrap .addXP:hover, .mfp-wrap .addXP:active, .mfp-wrap .remove a:hover, .mfp-wrap .remove a:active {
      color: #206160; }
    #main .addXP .plus, #main .addXP .x, #main .remove a .plus, #main .remove a .x, .mfp-wrap .addXP .plus, .mfp-wrap .addXP .x, .mfp-wrap .remove a .plus, .mfp-wrap .remove a .x {
      font-size: 2em;
      margin-right: 0.2em; }
  #main .remove, .mfp-wrap .remove {
    display: block;
    text-align: right; }
    #main .remove a, #main .remove a:link, #main .remove a:visited, .mfp-wrap .remove a, .mfp-wrap .remove a:link, .mfp-wrap .remove a:visited {
      color: #9a0000; }
    #main .remove a:hover, #main .remove a:active, .mfp-wrap .remove a:hover, .mfp-wrap .remove a:active {
      color: #4e0000; }
  #main fieldset, .mfp-wrap fieldset {
    width: 100%;
    border: 0 none;
    padding: 15px 0;
    margin-bottom: 1.5em; }
    #main fieldset legend, .mfp-wrap fieldset legend {
      font-size: 1.1em;
      font-weight: 700;
      color: #333535;
      text-transform: uppercase; }
  #main select:not(.picker__select--year):not(.picker__select--month), .mfp-wrap select:not(.picker__select--year):not(.picker__select--month) {
    background: url(/images/sprite_form.png) right -84px no-repeat;
    border-radius: 0;
    min-height: 34px;
    appearance: none;
    padding: 4px 42px 4px 4px;
    cursor: pointer; }
    #main select:not(.picker__select--year):not(.picker__select--month)::-ms-expand, .mfp-wrap select:not(.picker__select--year):not(.picker__select--month)::-ms-expand {
      display: none; }
    #main select:not(.picker__select--year):not(.picker__select--month) option, .mfp-wrap select:not(.picker__select--year):not(.picker__select--month) option {
      line-height: 1.4em; }
  #main .buttons, .mfp-wrap .buttons {
    margin-top: 1.5em;
    margin-bottom: 3em;
    padding-top: 1.5em;
    border-top: 2px solid #EBEBEB;
    text-align: right; }
    #main .buttons.buttonsLeft, .mfp-wrap .buttons.buttonsLeft {
      text-align: left; }
    #main .buttons .button:not(:first-child), #main .buttons .buttonLink:not(:first-child), #main .buttons .buttonAlt:not(:first-child), .mfp-wrap .buttons .button:not(:first-child), .mfp-wrap .buttons .buttonLink:not(:first-child), .mfp-wrap .buttons .buttonAlt:not(:first-child) {
      margin-left: 1.5em; }
    #main .buttons .buttonLine:not(:last-of-type), .mfp-wrap .buttons .buttonLine:not(:last-of-type) {
      margin-bottom: 1em; }
    #main .buttons .buttonLine .connexionDroite, .mfp-wrap .buttons .buttonLine .connexionDroite {
      display: inline-block;
      margin-left: 1.5em;
      position: relative;
      top: 8px; }
      #main .buttons .buttonLine .connexionDroite .buttonLink, .mfp-wrap .buttons .buttonLine .connexionDroite .buttonLink {
        display: block;
        padding: 0;
        margin: 0;
        line-height: 1.2; }
    #main .buttons.formTop, .mfp-wrap .buttons.formTop {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      border-top: none;
      border-bottom: 2px solid #EBEBEB; }
  #main .buttonSave, .mfp-wrap .buttonSave {
    background-image: url(/images/sprite_form.png);
    background-position: 17px -156px;
    background-repeat: no-repeat;
    padding-left: 58px;
    margin-top: 8px; }
  #main .checkbox.withOtherField .otherField, .mfp-wrap .checkbox.withOtherField .otherField {
    margin-left: 20px;
    padding-left: 0.5em; }
  #main .checkbox > input[type=checkbox], .mfp-wrap .checkbox > input[type=checkbox] {
    display: none; }
  #main .checkbox > input[type=checkbox] + label, .mfp-wrap .checkbox > input[type=checkbox] + label {
    display: inline-block; }
    #main .checkbox > input[type=checkbox] + label:not(.long), .mfp-wrap .checkbox > input[type=checkbox] + label:not(.long) {
      line-height: 40px; }
    #main .checkbox > input[type=checkbox] + label:hover, .mfp-wrap .checkbox > input[type=checkbox] + label:hover {
      cursor: pointer; }
  #main .checkbox > input[type=checkbox] + label::before, .mfp-wrap .checkbox > input[type=checkbox] + label::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #333535;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 0px rgba(51, 53, 53, 0.5);
    display: inline-block;
    margin-right: 0.5em;
    position: relative;
    top: 5px; }
    #main .checkbox > input[type=checkbox] + label::before:hover, .mfp-wrap .checkbox > input[type=checkbox] + label::before:hover {
      cursor: pointer; }
  #main .checkbox > input[type=checkbox]:checked + label::before, .mfp-wrap .checkbox > input[type=checkbox]:checked + label::before {
    content: "";
    background: url(/images/sprite_form.png) 1px 1px no-repeat; }
  #main .radio > input[type="radio"], .mfp-wrap .radio > input[type="radio"] {
    display: none; }
  #main .radio > input[type="radio"] + label, .mfp-wrap .radio > input[type="radio"] + label {
    display: inline-block;
    line-height: 40px; }
    #main .radio > input[type="radio"] + label:hover, .mfp-wrap .radio > input[type="radio"] + label:hover {
      cursor: pointer; }
  #main .radio > input[type="radio"] + label::before, .mfp-wrap .radio > input[type="radio"] + label::before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid #333535;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 0px rgba(51, 53, 53, 0.5);
    display: inline-block;
    margin-right: 0.5em;
    position: relative;
    top: 4px; }
    #main .radio > input[type="radio"] + label::before:hover, .mfp-wrap .radio > input[type="radio"] + label::before:hover {
      cursor: pointer; }
  #main .radio > input[type="radio"]:checked + label::before, .mfp-wrap .radio > input[type="radio"]:checked + label::before {
    content: "";
    background: url(/images/sprite_form.png) 2px -33px no-repeat; }
  #main .sectors, .mfp-wrap .sectors {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
  #main .sectorCheckbox, #main .categoryCheckbox, .mfp-wrap .sectorCheckbox, .mfp-wrap .categoryCheckbox {
    flex-basis: 100%;
    max-width: 100%; }
    #main .sectorCheckbox > input[type="checkbox"], #main .categoryCheckbox > input[type="checkbox"], .mfp-wrap .sectorCheckbox > input[type="checkbox"], .mfp-wrap .categoryCheckbox > input[type="checkbox"] {
      display: none; }
    #main .sectorCheckbox > input[type="checkbox"] + label,
    #main .sectorCheckbox > input[type="checkbox"] + .label,
    #main .sectorCheckbox > label,
    #main .sectorCheckbox > .label, #main .categoryCheckbox > input[type="checkbox"] + label,
    #main .categoryCheckbox > input[type="checkbox"] + .label,
    #main .categoryCheckbox > label,
    #main .categoryCheckbox > .label, .mfp-wrap .sectorCheckbox > input[type="checkbox"] + label,
    .mfp-wrap .sectorCheckbox > input[type="checkbox"] + .label,
    .mfp-wrap .sectorCheckbox > label,
    .mfp-wrap .sectorCheckbox > .label, .mfp-wrap .categoryCheckbox > input[type="checkbox"] + label,
    .mfp-wrap .categoryCheckbox > input[type="checkbox"] + .label,
    .mfp-wrap .categoryCheckbox > label,
    .mfp-wrap .categoryCheckbox > .label {
      display: inline-block;
      border: 4px solid white;
      position: relative;
      cursor: pointer;
      transition: border-color 0.2s ease; }
      #main .sectorCheckbox > input[type="checkbox"] + label img,
      #main .sectorCheckbox > input[type="checkbox"] + .label img,
      #main .sectorCheckbox > label img,
      #main .sectorCheckbox > .label img, #main .categoryCheckbox > input[type="checkbox"] + label img,
      #main .categoryCheckbox > input[type="checkbox"] + .label img,
      #main .categoryCheckbox > label img,
      #main .categoryCheckbox > .label img, .mfp-wrap .sectorCheckbox > input[type="checkbox"] + label img,
      .mfp-wrap .sectorCheckbox > input[type="checkbox"] + .label img,
      .mfp-wrap .sectorCheckbox > label img,
      .mfp-wrap .sectorCheckbox > .label img, .mfp-wrap .categoryCheckbox > input[type="checkbox"] + label img,
      .mfp-wrap .categoryCheckbox > input[type="checkbox"] + .label img,
      .mfp-wrap .categoryCheckbox > label img,
      .mfp-wrap .categoryCheckbox > .label img {
        display: block;
        width: 100%;
        height: auto; }
      #main .sectorCheckbox > input[type="checkbox"] + label:hover, #main .sectorCheckbox > input[type="checkbox"] + label:active,
      #main .sectorCheckbox > input[type="checkbox"] + .label:hover,
      #main .sectorCheckbox > input[type="checkbox"] + .label:active,
      #main .sectorCheckbox > label:hover,
      #main .sectorCheckbox > label:active,
      #main .sectorCheckbox > .label:hover,
      #main .sectorCheckbox > .label:active, #main .categoryCheckbox > input[type="checkbox"] + label:hover, #main .categoryCheckbox > input[type="checkbox"] + label:active,
      #main .categoryCheckbox > input[type="checkbox"] + .label:hover,
      #main .categoryCheckbox > input[type="checkbox"] + .label:active,
      #main .categoryCheckbox > label:hover,
      #main .categoryCheckbox > label:active,
      #main .categoryCheckbox > .label:hover,
      #main .categoryCheckbox > .label:active, .mfp-wrap .sectorCheckbox > input[type="checkbox"] + label:hover, .mfp-wrap .sectorCheckbox > input[type="checkbox"] + label:active,
      .mfp-wrap .sectorCheckbox > input[type="checkbox"] + .label:hover,
      .mfp-wrap .sectorCheckbox > input[type="checkbox"] + .label:active,
      .mfp-wrap .sectorCheckbox > label:hover,
      .mfp-wrap .sectorCheckbox > label:active,
      .mfp-wrap .sectorCheckbox > .label:hover,
      .mfp-wrap .sectorCheckbox > .label:active, .mfp-wrap .categoryCheckbox > input[type="checkbox"] + label:hover, .mfp-wrap .categoryCheckbox > input[type="checkbox"] + label:active,
      .mfp-wrap .categoryCheckbox > input[type="checkbox"] + .label:hover,
      .mfp-wrap .categoryCheckbox > input[type="checkbox"] + .label:active,
      .mfp-wrap .categoryCheckbox > label:hover,
      .mfp-wrap .categoryCheckbox > label:active,
      .mfp-wrap .categoryCheckbox > .label:hover,
      .mfp-wrap .categoryCheckbox > .label:active {
        border-color: rgba(51, 154, 153, 0.7); }
    #main .sectorCheckbox label, #main .sectorCheckbox .label, #main .categoryCheckbox label, #main .categoryCheckbox .label, .mfp-wrap .sectorCheckbox label, .mfp-wrap .sectorCheckbox .label, .mfp-wrap .categoryCheckbox label, .mfp-wrap .categoryCheckbox .label {
      position: relative;
      width: 100%; }
      #main .sectorCheckbox label span, #main .sectorCheckbox .label span, #main .categoryCheckbox label span, #main .categoryCheckbox .label span, .mfp-wrap .sectorCheckbox label span, .mfp-wrap .sectorCheckbox .label span, .mfp-wrap .categoryCheckbox label span, .mfp-wrap .categoryCheckbox .label span {
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        min-height: 4.6em;
        display: block;
        padding: 0.8em 5px;
        font-size: 0.85em;
        font-weight: 700;
        color: #333535;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s ease, color 0.2s ease; }
    #main .sectorCheckbox > input[type="checkbox"]:checked + label, #main .sectorCheckbox > input[type="checkbox"]:checked + .label, #main .categoryCheckbox > input[type="checkbox"]:checked + label, #main .categoryCheckbox > input[type="checkbox"]:checked + .label, .mfp-wrap .sectorCheckbox > input[type="checkbox"]:checked + label, .mfp-wrap .sectorCheckbox > input[type="checkbox"]:checked + .label, .mfp-wrap .categoryCheckbox > input[type="checkbox"]:checked + label, .mfp-wrap .categoryCheckbox > input[type="checkbox"]:checked + .label {
      border-color: #339a99; }
      #main .sectorCheckbox > input[type="checkbox"]:checked + label span, #main .sectorCheckbox > input[type="checkbox"]:checked + .label span, #main .categoryCheckbox > input[type="checkbox"]:checked + label span, #main .categoryCheckbox > input[type="checkbox"]:checked + .label span, .mfp-wrap .sectorCheckbox > input[type="checkbox"]:checked + label span, .mfp-wrap .sectorCheckbox > input[type="checkbox"]:checked + .label span, .mfp-wrap .categoryCheckbox > input[type="checkbox"]:checked + label span, .mfp-wrap .categoryCheckbox > input[type="checkbox"]:checked + .label span {
        background-color: rgba(51, 154, 153, 0.7);
        color: white; }
      #main .sectorCheckbox > input[type="checkbox"]:checked + label .visual-checkbox .checkmark, #main .sectorCheckbox > input[type="checkbox"]:checked + .label .visual-checkbox .checkmark, #main .categoryCheckbox > input[type="checkbox"]:checked + label .visual-checkbox .checkmark, #main .categoryCheckbox > input[type="checkbox"]:checked + .label .visual-checkbox .checkmark, .mfp-wrap .sectorCheckbox > input[type="checkbox"]:checked + label .visual-checkbox .checkmark, .mfp-wrap .sectorCheckbox > input[type="checkbox"]:checked + .label .visual-checkbox .checkmark, .mfp-wrap .categoryCheckbox > input[type="checkbox"]:checked + label .visual-checkbox .checkmark, .mfp-wrap .categoryCheckbox > input[type="checkbox"]:checked + .label .visual-checkbox .checkmark {
        opacity: 1; }
    #main .sectorCheckbox .visual-checkbox, #main .categoryCheckbox .visual-checkbox, .mfp-wrap .sectorCheckbox .visual-checkbox, .mfp-wrap .categoryCheckbox .visual-checkbox {
      display: inline-block;
      vertical-align: top;
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 1px;
      border: 1px solid #333535;
      border-radius: 2px;
      box-shadow: 1px 1px 1px 0px rgba(51, 53, 53, 0.5);
      background-color: #fff; }
      #main .sectorCheckbox .visual-checkbox .checkmark, #main .categoryCheckbox .visual-checkbox .checkmark, .mfp-wrap .sectorCheckbox .visual-checkbox .checkmark, .mfp-wrap .categoryCheckbox .visual-checkbox .checkmark {
        position: relative;
        z-index: 10;
        display: block;
        opacity: 0;
        vertical-align: top;
        width: 18px;
        height: 18px;
        background-image: url("/images/sprite_form.png");
        background-repeat: no-repeat;
        background-position: 0 -1206px;
        transition: opacity 0.2s ease; }
  #main .notice, .mfp-wrap .notice {
    font-size: 0.9em;
    color: #339a99;
    font-style: italic; }
  #main .fbconnect, .mfp-wrap .fbconnect {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1em;
    padding: 0.8em 1.2em;
    text-align: left;
    font-size: 1em;
    font-weight: 700;
    text-decoration: none;
    border: 1px solid #254892;
    border-radius: 0.5em;
    color: white;
    background-color: #3d5a97; }
    #main .fbconnect:hover, #main .fbconnect:active, .mfp-wrap .fbconnect:hover, .mfp-wrap .fbconnect:active {
      background-color: #254892;
      cursor: pointer; }
    #main .fbconnect .inner, .mfp-wrap .fbconnect .inner {
      display: inline-flex; }
    #main .fbconnect .fbIcon, .mfp-wrap .fbconnect .fbIcon {
      margin-right: 1em; }
      #main .fbconnect .fbIcon .icon, .mfp-wrap .fbconnect .fbIcon .icon {
        display: block;
        width: 21px;
        height: 21px;
        text-indent: -99999px;
        background-image: url(/images/sprite_social.png);
        background-position: 0 -372px;
        background-repeat: no-repeat; }
    #main .fbconnect .text, .mfp-wrap .fbconnect .text {
      line-height: 1.2em; }
  #main .security, .mfp-wrap .security {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 3em;
    font-size: 0.9em;
    font-weight: 700; }
    #main .security > *, .mfp-wrap .security > * {
      flex-basis: 100%;
      max-width: 100%; }
    #main .security .padlock, .mfp-wrap .security .padlock {
      margin-bottom: 1.2em; }
      #main .security .padlock .icon, .mfp-wrap .security .padlock .icon {
        display: block;
        width: 98px;
        height: 126px;
        margin: auto;
        background-image: url(/images/sprite_icon.png);
        background-position: left -431px;
        background-repeat: no-repeat; }
    #main .security h5, .mfp-wrap .security h5 {
      margin-bottom: 0.2em;
      font-size: 1.2em;
      font-style: italic; }
    #main .security p:last-child, .mfp-wrap .security p:last-child {
      margin-bottom: 0; }
    #main .security a, .mfp-wrap .security a {
      font-style: italic;
      color: #339a99;
      text-decoration: none; }
      #main .security a:hover, .mfp-wrap .security a:hover {
        text-decoration: underline; }

body:not(.account) .leftCentered {
  margin: 0 auto;
  width: 100%;
  max-width: 575px; }
  body:not(.account) .leftCentered.thin {
    max-width: 325px; }
  body:not(.account) .leftCentered.mWide {
    max-width: 600px; }
  body:not(.account) .leftCentered.wide {
    max-width: 700px; }
  body:not(.account) .leftCentered.xWide {
    max-width: 800px; }
  body:not(.account) .leftCentered .or {
    max-width: 100% !important; }

/*** Styles spécifiques à certaines pages, templates / modèles de page ou étapes du formulaire d'inscription */
.register.step1 #main .inner, .register.step4b_2 #main .inner {
  max-width: 900px; }

.register.step2 #main .inner {
  max-width: 1000px; }

.register.step3 #main .sectors .visual-checkbox {
  display: none; }

.register.step3 #main .sectors .formRow.sectorCheckbox {
  flex-basis: 49%;
  max-width: 49%;
  margin-bottom: 0; }
  .register.step3 #main .sectors .formRow.sectorCheckbox label {
    cursor: auto;
    transition: none; }
    .register.step3 #main .sectors .formRow.sectorCheckbox label:hover {
      border-color: #fff; }
  .register.step3 #main .sectors .formRow.sectorCheckbox span {
    font-size: 0.55em; }

.register.step3 #main .inner, .register.step6 #main .inner {
  max-width: 900px; }

.register.step5 #main .inner {
  max-width: 800px; }

.register.step5_2 #main .inner, .register.step5_3 #main .inner, .register.step7 #main .inner {
  max-width: 800px; }

.register.summary .entryContent strong, .register.summary .entry-content strong {
  font-style: italic;
  font-weight: 700; }

.publishOffer.stepOffer #main .inner {
  max-width: 900px; }

.publishOffer.stepAccount #main .inner {
  max-width: 900px; }

/*** Styles des pages du template pricing (tarification / forfaits) **************/
/*
#main {
	.packages {
		.packageGroup {
			&:not(:last-of-type) {
				margin-bottom: 3em;
			}

			table {
				width: 100%;
				border-collapse: separate;

				&, thead, tbody, tr, td, th {
					display: block;
				}

				.extraInfo {
					display: inline-block;
					vertical-align: middle;

					.trigger {
						background-color: transparent;
						padding: 0;
						text-transform: none;
						border-radius: 0;

						&, * {
							display: inline-block;
							vertical-align: top;
						}

						&:hover, &:active {
							.icon {
								background-color: $darkturquoise;
							}
						}

						.icon {
							position: relative;
							width: 1.6em;
							padding-bottom: 100%;
							border-radius: 100%;
							color: $white;
							background-color: $turquoise;
							transition: background-color 0.2s ease;

							.btnContent {
								position: absolute;
								left: 0;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								text-align: center;
								//font-size: 0.9em;
								font-weight: 700;
							}
						}
					}
				}

				h2, h3 {
					display: inline-block;
					margin-right: 0.5em;
					vertical-align: middle;
				}

				thead {
				}

				th {
					padding: 0 0.4em;
					border-bottom: 2px solid $turquoise;
					font-size: 1em;
					text-align: left;

					&.title {
						padding-bottom: 0.2em;
						background-color: transparent;

						& > h2 {
							margin-bottom: 0.1em;
							margin-top: 0.2em;
							font-size: 2.2em;
							font-weight: 800;
						}

						.shortDescription {
							margin: 0;

							p {
								margin-bottom: 0;
								font-weight: 400;
							}
						}
					}

					&:not(.title) {
						position: relative;
						display: none;
						//De la place pour la ligne qui est un ::after
						padding-bottom: 1px;
						text-align: center;
						background-color: $turquoise;
						color: $white;

						&::after {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							display: block;
							border-top: 1px solid $white;
						}

						&::before {
							//Fix pour la border-right qui marche semi
							content: '';
							display: block;
							position: absolute;
							left: -2px;
							top: 0;
							bottom: -2px;
							border-right: 2px solid $white;
						}

						&:not(:last-of-type) {
							border-right: 2px solid $white;
						}

						.icon {
							display: inline-block;
							vertical-align: middle;
							width: 44px;
							height: 44px;
							margin-right: 0.5em;
							background-image: url('/images/sprite_icon.png');
						}

						.text {
							display: inline-block;
							vertical-align: middle;
							font-size: 1.4em;
						}
					}

					&.duration {
						.icon {
							background-position: 0 -1700px;
						}
					}

					&.cost {
						.icon {
							background-position: 0 -1500px;
						}
					}
				}

				tbody {
					tr {
						padding: 0.4em 0;
						background-color: $lightgray;

						&:nth-of-type(even) {
							background-color: darken($lightgray, 7%);
						}

						&:not(:last-of-type) {
							border-bottom: 2px solid $white;
						}
					}

					td {
						padding: 0 0.4em;

						&:not(:last-of-type) {
							margin-bottom: 0.2em;
						}

						&.title {
							font-size: 1em;
						}

						.mobileTitle {
							margin-bottom: 0;
							margin-right: 0.5em;

							&, & ~ * {
								display: inline-block;
							}

							&::after {
								content: ':';
								display: inline;

							}
						}

						&.cost {
							.mobileTitle {
								margin-right: 1em;
							}

							.content {
								font-size: 1.2em;
								font-weight: 800;
								letter-spacing: 0.02em;
								color: $turquoise;
							}

							.perUnit {
								font-size: 0.7em;
								letter-spacing: 0.04em;
							}
						}

						h3 {
							font-size: 1em;
							font-weight: bold;
							text-transform: none;
							color: $black;
						}
					}
				}
			}

			.buttons {
				margin: 0;
				padding: 0;
				border: 0;
				margin-top: 1em;
			}
		}
	}
}

.pageTemplate-pricing .innerPopup .entryContent, .pageTemplate-pricing .innerPopup .entry-content {
	h2, h3 {
		display: inline-block;
	}

	h2 {
		font-weight: 400;
		margin-right: 0.5em;
	}

	h3 {
		font-size: 1.4em;
		color: $turquoise;
	}
}
*/
/*** Styles des listings de forfaits (Tarification -> Entreprises/Particuliers) (Page de la comparaison des avantages) ************/
body.pricePlans #main .inner > div:not(:last-child) {
  margin-bottom: 3em; }
  body.pricePlans #main .inner > div:not(:last-child).intro {
    margin-bottom: 2em; }

body.pricePlans #main .inner div.yourPlan {
  margin-bottom: 1em; }

body.pricePlans #main .inner .planGroup.monthlyPlans table thead tr:last-of-type th {
  vertical-align: bottom; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table thead {
  display: none; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table td, body.pricePlans #main .inner .planGroup.aLaCartePlans table th {
  padding: 0;
  padding-bottom: 1em;
  vertical-align: middle; }
  body.pricePlans #main .inner .planGroup.aLaCartePlans table td, body.pricePlans #main .inner .planGroup.aLaCartePlans table td:not(:first-child), body.pricePlans #main .inner .planGroup.aLaCartePlans table th, body.pricePlans #main .inner .planGroup.aLaCartePlans table th:not(:first-child) {
    border: none; }
  body.pricePlans #main .inner .planGroup.aLaCartePlans table td:not(:last-child), body.pricePlans #main .inner .planGroup.aLaCartePlans table th:not(:last-child) {
    padding-right: 0.5em; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table h3 {
  font-size: 1.4em; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table .subtitle {
  font-style: italic;
  font-weight: 700;
  font-size: 0.9em; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table .price {
  font-size: 1.6em;
  font-weight: 700; }
  body.pricePlans #main .inner .planGroup.aLaCartePlans table .price .suffix {
    font-size: 0.6em;
    color: #339a99; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table .description {
  font-size: 0.9em; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table tbody:not(:last-of-type) {
  border-bottom: 2px solid #c5c5c5; }
  body.pricePlans #main .inner .planGroup.aLaCartePlans table tbody:not(:last-of-type) .button td {
    padding-bottom: 2em; }

body.pricePlans #main .inner .planGroup.aLaCartePlans table tbody:not(:first-of-type) tr:first-of-type td {
  padding-top: 2em; }

body.pricePlans #main .inner .planGroup .mobileAdvantages {
  margin-bottom: 2em; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages .plan:not(:last-of-type) {
    padding-bottom: 2em;
    margin-bottom: 2em;
    border-bottom: 2px solid #c5c5c5; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages .plan > header h3 {
    margin-bottom: 0.6em; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages .plan > header .subtitle p {
    margin-bottom: 0; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages .plan > header .subtitle strong {
    font-size: 1.4em; }
    body.pricePlans #main .inner .planGroup .mobileAdvantages .plan > header .subtitle strong sub {
      position: static;
      display: inline-block;
      font-size: 0.7em;
      vertical-align: baseline; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages .plan > header .extraInfo {
    margin-top: 0.4em; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages .plan .advantages {
    margin-top: 0.6em; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages .plan .advantage {
    display: flex;
    margin-bottom: 0.6em; }
    body.pricePlans #main .inner .planGroup .mobileAdvantages .plan .advantage .advantageCheckmark {
      flex-grow: 0;
      padding-right: 0.5em; }
      body.pricePlans #main .inner .planGroup .mobileAdvantages .plan .advantage .advantageCheckmark .checkmark {
        display: inline-block;
        width: 28px;
        height: 28px;
        text-indent: -99999px;
        background-image: url("/images/sprite_icon.png");
        background-repeat: no-repeat; }
        body.pricePlans #main .inner .planGroup .mobileAdvantages .plan .advantage .advantageCheckmark .checkmark.checked {
          background-position: 0 -4571px; }
        body.pricePlans #main .inner .planGroup .mobileAdvantages .plan .advantage .advantageCheckmark .checkmark.notChecked {
          background-position: 0 -4671px; }
    body.pricePlans #main .inner .planGroup .mobileAdvantages .plan .advantage .advantageText {
      padding-top: 2px;
      font-size: 0.95em; }

body.pricePlans #main .inner .planGroup .fullAdvantages {
  display: none; }

body.pricePlans #main .inner .planGroup .buttonWrapper.free {
  display: none; }

body.pricePlans #main .inner .planGroup .intro {
  margin-bottom: 2em; }

body.pricePlans #main .inner .planGroup table {
  position: relative;
  z-index: 10; }
  body.pricePlans #main .inner .planGroup table th, body.pricePlans #main .inner .planGroup table td {
    vertical-align: top;
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em; }
    body.pricePlans #main .inner .planGroup table th:not(:first-child), body.pricePlans #main .inner .planGroup table td:not(:first-child) {
      border-left: 2px solid #339a99; }
    body.pricePlans #main .inner .planGroup table th.highlighted, body.pricePlans #main .inner .planGroup table td.highlighted {
      position: relative;
      color: #fff;
      background-color: #339a99;
      border-bottom: none;
      border-left-color: #339a99;
      background-clip: border-box; }
      body.pricePlans #main .inner .planGroup table th.highlighted h3, body.pricePlans #main .inner .planGroup table td.highlighted h3 {
        color: #fff; }
      body.pricePlans #main .inner .planGroup table th.highlighted button, body.pricePlans #main .inner .planGroup table td.highlighted button {
        border-color: #fff;
        color: #fff; }
        body.pricePlans #main .inner .planGroup table th.highlighted button:hover, body.pricePlans #main .inner .planGroup table th.highlighted button:active, body.pricePlans #main .inner .planGroup table td.highlighted button:hover, body.pricePlans #main .inner .planGroup table td.highlighted button:active {
          background-color: #fff;
          color: #339a99; }
      body.pricePlans #main .inner .planGroup table th.highlighted .roundTrigger, body.pricePlans #main .inner .planGroup table td.highlighted .roundTrigger {
        background-color: #fff;
        color: #339a99; }
      body.pricePlans #main .inner .planGroup table th.highlighted .extraInfo, body.pricePlans #main .inner .planGroup table td.highlighted .extraInfo {
        display: inline-block;
        margin-top: 0.75em; }
        body.pricePlans #main .inner .planGroup table th.highlighted .extraInfo:hover, body.pricePlans #main .inner .planGroup table th.highlighted .extraInfo:active, body.pricePlans #main .inner .planGroup table td.highlighted .extraInfo:hover, body.pricePlans #main .inner .planGroup table td.highlighted .extraInfo:active {
          cursor: pointer; }
          body.pricePlans #main .inner .planGroup table th.highlighted .extraInfo:hover .roundTrigger, body.pricePlans #main .inner .planGroup table th.highlighted .extraInfo:active .roundTrigger, body.pricePlans #main .inner .planGroup table td.highlighted .extraInfo:hover .roundTrigger, body.pricePlans #main .inner .planGroup table td.highlighted .extraInfo:active .roundTrigger {
            background-color: #c5c5c5; }
    body.pricePlans #main .inner .planGroup table th.plan, body.pricePlans #main .inner .planGroup table td.plan {
      min-width: 20%; }
    body.pricePlans #main .inner .planGroup table th.featureText, body.pricePlans #main .inner .planGroup table td.featureText {
      font-weight: bold; }
    body.pricePlans #main .inner .planGroup table th .cellInner, body.pricePlans #main .inner .planGroup table td .cellInner {
      display: block; }
  body.pricePlans #main .inner .planGroup table thead tr {
    border-bottom: 0; }
    body.pricePlans #main .inner .planGroup table thead tr:first-of-type td, body.pricePlans #main .inner .planGroup table thead tr:first-of-type th {
      padding-bottom: 0;
      padding-top: 0.5em;
      border-top: 35px solid transparent; }
      body.pricePlans #main .inner .planGroup table thead tr:first-of-type td.highlighted, body.pricePlans #main .inner .planGroup table thead tr:first-of-type th.highlighted {
        border-top-color: #339a99; }
      body.pricePlans #main .inner .planGroup table thead tr:first-of-type td:not(.highlighted), body.pricePlans #main .inner .planGroup table thead tr:first-of-type th:not(.highlighted) {
        position: relative; }
        body.pricePlans #main .inner .planGroup table thead tr:first-of-type td:not(.highlighted)::before, body.pricePlans #main .inner .planGroup table thead tr:first-of-type th:not(.highlighted)::before {
          content: '';
          display: block;
          position: absolute;
          left: -5px;
          top: -50px;
          width: 10px;
          height: 36px;
          background-color: #fff; }
    body.pricePlans #main .inner .planGroup table thead tr:last-of-type th {
      border-bottom: 2px solid #339a99; }
      body.pricePlans #main .inner .planGroup table thead tr:last-of-type th.highlighted {
        border-bottom-color: #fff; }
  body.pricePlans #main .inner .planGroup table tbody td {
    border-bottom: 2px solid #c5c5c5; }
    body.pricePlans #main .inner .planGroup table tbody td.plan {
      text-align: center;
      vertical-align: middle; }
      body.pricePlans #main .inner .planGroup table tbody td.plan .icon {
        display: inline-block;
        margin-top: 0.2em;
        text-indent: -99999px;
        width: 35px;
        height: 25px;
        background-image: url("/images/sprite_icon.png");
        background-repeat: no-repeat;
        background-position: 0 -4471px; }
    body.pricePlans #main .inner .planGroup table tbody td.highlighted.plan .icon {
      background-position: 0 -4521px; }
    body.pricePlans #main .inner .planGroup table tbody td.highlighted::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -3px;
      right: 0;
      z-index: 30;
      height: 2px;
      background-color: #fff; }
  body.pricePlans #main .inner .planGroup table tfoot {
    /* button {
						text-transform: none;
						background-color: transparent;
						color: $black;
						text-align: center;
						border: 3px solid $turquoise;
						transition: all 0.2s ease;

						&:hover, &:active {
							border-color: $turquoise;
							background-color: $turquoise;
							color: $white;
						}

						.textNonBold, strong {
							display: block;
						}

						.textNonBold {
							font-weight: 400;
							font-style: italic;
						}

						strong {
							text-transform: uppercase;
							line-height: 1.2em;
						}
					} */ }
    body.pricePlans #main .inner .planGroup table tfoot tr:last-of-type td {
      padding-bottom: 0.5em;
      border-bottom: 35px solid transparent; }
      body.pricePlans #main .inner .planGroup table tfoot tr:last-of-type td.highlighted {
        border-bottom-color: #339a99; }
      body.pricePlans #main .inner .planGroup table tfoot tr:last-of-type td:not(.highlighted)::before {
        content: '';
        display: block;
        position: absolute;
        left: -5px;
        right: 0;
        bottom: -36px;
        top: 100%;
        background-color: #fff; }
    body.pricePlans #main .inner .planGroup table tfoot td {
      padding-left: 0.5em;
      padding-right: 0.5em;
      text-align: center; }
      body.pricePlans #main .inner .planGroup table tfoot td.highlighted::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -3px;
        right: 0;
        z-index: 30;
        height: 2px;
        background-color: #fff; }
  body.pricePlans #main .inner .planGroup table th h3 {
    line-height: 1.1em; }
  body.pricePlans #main .inner .planGroup table th .subtitle {
    margin-top: 0.4em;
    line-height: 1.3em;
    font-weight: 400; }
    body.pricePlans #main .inner .planGroup table th .subtitle p {
      margin-bottom: 0; }
    body.pricePlans #main .inner .planGroup table th .subtitle strong {
      font-size: 1.2em;
      line-height: 1.6em; }
      body.pricePlans #main .inner .planGroup table th .subtitle strong sub {
        position: static;
        display: inline-block;
        font-size: 0.7em;
        vertical-align: baseline; }
  body.pricePlans #main .inner .planGroup table th .extraInfo {
    margin-top: 0.8em; }
  body.pricePlans #main .inner .planGroup table td {
    padding-top: 1em; }

.durationSelect.durationTable, #main .durationSelect.durationTable, .durationTable.durationTable {
  margin-top: 2em; }
  .durationSelect.durationTable .footNotes, #main .durationSelect.durationTable .footNotes, .durationTable.durationTable .footNotes {
    padding-bottom: 2em; }
  .durationSelect.durationTable tbody tr:nth-of-type(even), #main .durationSelect.durationTable tbody tr:nth-of-type(even), .durationTable.durationTable tbody tr:nth-of-type(even) {
    background-color: rgba(51, 53, 53, 0.1); }

.durationSelect.durationSelect table tbody tr, #main .durationSelect.durationSelect table tbody tr, .durationTable.durationSelect table tbody tr {
  cursor: pointer; }

.durationSelect table, #main .durationSelect table, .durationTable table {
  width: 100%; }
  .durationSelect table:not(:last-child), #main .durationSelect table:not(:last-child), .durationTable table:not(:last-child) {
    margin-bottom: 2em; }
  .durationSelect table thead, #main .durationSelect table thead, .durationTable table thead {
    display: none; }
  .durationSelect table, .durationSelect table tbody, .durationSelect table tfoot, .durationSelect table tr, .durationSelect table td, .durationSelect table th, #main .durationSelect table, #main .durationSelect table tbody, #main .durationSelect table tfoot, #main .durationSelect table tr, #main .durationSelect table td, #main .durationSelect table th, .durationTable table, .durationTable table tbody, .durationTable table tfoot, .durationTable table tr, .durationTable table td, .durationTable table th {
    display: block; }
  .durationSelect table tr, #main .durationSelect table tr, .durationTable table tr {
    position: relative; }
  .durationSelect table td.radio, #main .durationSelect table td.radio, .durationTable table td.radio {
    position: absolute;
    display: inline-block;
    padding: 0;
    left: 0;
    top: 2px; }
  .durationSelect table td, .durationSelect table th, #main .durationSelect table td, #main .durationSelect table th, .durationTable table td, .durationTable table th {
    vertical-align: middle;
    padding: 0; }
    .durationSelect table td:not(:last-child):not(.radio), .durationSelect table th:not(:last-child):not(.radio), #main .durationSelect table td:not(:last-child):not(.radio), #main .durationSelect table th:not(:last-child):not(.radio), .durationTable table td:not(:last-child):not(.radio), .durationTable table th:not(:last-child):not(.radio) {
      margin-bottom: 1em; }
  .durationSelect table tr, #main .durationSelect table tr, .durationTable table tr {
    padding: 0.5em 0; }
    .durationSelect table tr:not(:last-child), #main .durationSelect table tr:not(:last-child), .durationTable table tr:not(:last-child) {
      margin-bottom: 2em; }
  .durationSelect table th, .durationSelect table .mobileTitle, #main .durationSelect table th, #main .durationSelect table .mobileTitle, .durationTable table th, .durationTable table .mobileTitle {
    text-align: left;
    font-size: 1.2em;
    font-weight: 400;
    color: #339a99;
    text-transform: uppercase; }
    .durationSelect table th a, .durationSelect table .mobileTitle a, #main .durationSelect table th a, #main .durationSelect table .mobileTitle a, .durationTable table th a, .durationTable table .mobileTitle a {
      transition: none; }
      .durationSelect table th a, .durationSelect table th a:link, .durationSelect table th a:visited, .durationSelect table .mobileTitle a, .durationSelect table .mobileTitle a:link, .durationSelect table .mobileTitle a:visited, #main .durationSelect table th a, #main .durationSelect table th a:link, #main .durationSelect table th a:visited, #main .durationSelect table .mobileTitle a, #main .durationSelect table .mobileTitle a:link, #main .durationSelect table .mobileTitle a:visited, .durationTable table th a, .durationTable table th a:link, .durationTable table th a:visited, .durationTable table .mobileTitle a, .durationTable table .mobileTitle a:link, .durationTable table .mobileTitle a:visited {
        color: #4b4b4b; }
      .durationSelect table th a:hover, .durationSelect table th a:active, .durationSelect table .mobileTitle a:hover, .durationSelect table .mobileTitle a:active, #main .durationSelect table th a:hover, #main .durationSelect table th a:active, #main .durationSelect table .mobileTitle a:hover, #main .durationSelect table .mobileTitle a:active, .durationTable table th a:hover, .durationTable table th a:active, .durationTable table .mobileTitle a:hover, .durationTable table .mobileTitle a:active {
        color: #333535;
        text-decoration: underline; }
  .durationSelect table .mobileTitle, #main .durationSelect table .mobileTitle, .durationTable table .mobileTitle {
    margin-bottom: 0.2em; }
  .durationSelect table a, #main .durationSelect table a, .durationTable table a {
    font-weight: 700;
    transition: color 0.2s ease;
    text-decoration: none; }
    .durationSelect table a, .durationSelect table a:link, .durationSelect table a:visited, #main .durationSelect table a, #main .durationSelect table a:link, #main .durationSelect table a:visited, .durationTable table a, .durationTable table a:link, .durationTable table a:visited {
      color: #339a99; }
    .durationSelect table a:hover, .durationSelect table a:active, #main .durationSelect table a:hover, #main .durationSelect table a:active, .durationTable table a:hover, .durationTable table a:active {
      color: #206160; }
  .durationSelect table .bigLabel, #main .durationSelect table .bigLabel, .durationTable table .bigLabel {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10; }
    .durationSelect table .bigLabel.above, #main .durationSelect table .bigLabel.above, .durationTable table .bigLabel.above {
      z-index: 30; }
      .durationSelect table .bigLabel.above:hover, .durationSelect table .bigLabel.above:active, #main .durationSelect table .bigLabel.above:hover, #main .durationSelect table .bigLabel.above:active, .durationTable table .bigLabel.above:hover, .durationTable table .bigLabel.above:active {
        cursor: pointer; }
        .durationSelect table .bigLabel.above:hover + .bigLabel, .durationSelect table .bigLabel.above:active + .bigLabel, #main .durationSelect table .bigLabel.above:hover + .bigLabel, #main .durationSelect table .bigLabel.above:active + .bigLabel, .durationTable table .bigLabel.above:hover + .bigLabel, .durationTable table .bigLabel.above:active + .bigLabel {
          background-color: rgba(51, 53, 53, 0.1); }
  .durationSelect table .textLabel, #main .durationSelect table .textLabel, .durationTable table .textLabel {
    position: relative;
    z-index: 20; }
  .durationSelect table label.textLabel:hover, #main .durationSelect table label.textLabel:hover, .durationTable table label.textLabel:hover {
    cursor: pointer; }
  .durationSelect table .radio, #main .durationSelect table .radio, .durationTable table .radio {
    width: 1px; }
    .durationSelect table .radio input[type="radio"]:checked ~ .bigLabel.below, #main .durationSelect table .radio input[type="radio"]:checked ~ .bigLabel.below, .durationTable table .radio input[type="radio"]:checked ~ .bigLabel.below {
      background-color: rgba(51, 154, 153, 0.2); }
    .durationSelect table .radio input[type="radio"] + label, #main .durationSelect table .radio input[type="radio"] + label, .durationTable table .radio input[type="radio"] + label {
      margin-right: 0; }
      .durationSelect table .radio input[type="radio"] + label::before, #main .durationSelect table .radio input[type="radio"] + label::before, .durationTable table .radio input[type="radio"] + label::before {
        top: 3px;
        margin-right: 0; }
  .durationSelect table .duration .textLabel, #main .durationSelect table .duration .textLabel, .durationTable table .duration .textLabel {
    font-size: 1.1em; }
  .durationSelect table .duration .subtitle, #main .durationSelect table .duration .subtitle, .durationTable table .duration .subtitle {
    display: block;
    font-size: 0.9em;
    font-style: italic;
    color: #339a99; }
  .durationSelect table .price .number, .durationSelect table .price .symbol, #main .durationSelect table .price .number, #main .durationSelect table .price .symbol, .durationTable table .price .number, .durationTable table .price .symbol {
    font-size: 1.4em;
    font-weight: bold; }
  .durationSelect table .price .suffix, #main .durationSelect table .price .suffix, .durationTable table .price .suffix {
    font-size: 0.9em; }
  .durationSelect table .savings:not(.savings-0) .number, #main .durationSelect table .savings:not(.savings-0) .number, .durationTable table .savings:not(.savings-0) .number {
    color: #339a99; }
  .durationSelect table .savings .textLabel, #main .durationSelect table .savings .textLabel, .durationTable table .savings .textLabel {
    font-size: 1.4em; }

.durationSelect .footNotes .footNote, #main .durationSelect .footNotes .footNote, .durationTable .footNotes .footNote {
  display: flex; }

.durationSelect .footNotes .theStar, #main .durationSelect .footNotes .theStar, .durationTable .footNotes .theStar {
  position: relative;
  bottom: 0.1em;
  margin-right: 0.3em;
  font-size: 1.5em;
  font-weight: bold;
  color: #339a99; }

.durationSelect .footNotes p, #main .durationSelect .footNotes p, .durationTable .footNotes p {
  flex-grow: 100;
  font-size: 0.9em;
  font-style: italic; }

.durationSelect .buttons > button:not(:first-child), .durationSelect .buttons > input:not(:first-child), .durationSelect .buttons > a:not(:first-child), #main .durationSelect .buttons > button:not(:first-child), #main .durationSelect .buttons > input:not(:first-child), #main .durationSelect .buttons > a:not(:first-child), .durationTable .buttons > button:not(:first-child), .durationTable .buttons > input:not(:first-child), .durationTable .buttons > a:not(:first-child) {
  margin-left: 0.7em; }

/*** Tableau de confirmation du prix lors de la commande d'un forfait **************/
.confirmTable table tbody tr, #main .confirmTable table tbody tr, .confirmTable table tbody tr {
  cursor: auto; }
  .confirmTable table tbody tr:hover, .confirmTable table tbody tr:active, #main .confirmTable table tbody tr:hover, #main .confirmTable table tbody tr:active, .confirmTable table tbody tr:hover, .confirmTable table tbody tr:active {
    background-color: inherit; }
  .confirmTable table tbody tr:nth-of-type(even), #main .confirmTable table tbody tr:nth-of-type(even), .confirmTable table tbody tr:nth-of-type(even) {
    background-color: transparent; }
  .confirmTable table tbody tr:nth-of-type(odd), #main .confirmTable table tbody tr:nth-of-type(odd), .confirmTable table tbody tr:nth-of-type(odd) {
    background-color: rgba(51, 53, 53, 0.1); }

.confirmTable table tfoot tr:nth-of-type(even), #main .confirmTable table tfoot tr:nth-of-type(even), .confirmTable table tfoot tr:nth-of-type(even) {
  background-color: rgba(51, 53, 53, 0.1); }

.confirmTable table tfoot tr.lastLine td, #main .confirmTable table tfoot tr.lastLine td, .confirmTable table tfoot tr.lastLine td {
  padding-bottom: 2em; }

.confirmTable table tfoot tr.subtotal, #main .confirmTable table tfoot tr.subtotal, .confirmTable table tfoot tr.subtotal {
  font-weight: bold; }

.confirmTable table tfoot tr.total, #main .confirmTable table tfoot tr.total, .confirmTable table tfoot tr.total {
  text-transform: uppercase;
  font-weight: bold;
  border-top: 2px solid #339a99;
  background-color: transparent; }
  .confirmTable table tfoot tr.total td, #main .confirmTable table tfoot tr.total td, .confirmTable table tfoot tr.total td {
    padding-top: 1em; }

/*** Styles de la page Thanks **************************************/
.thanks .entryContent time, .thanks .entry-content time {
  font-weight: bold; }

/*** Styles du template linkHub (pour Publier une offre) ***********/
.pageTemplate-linkHub #main .inner > .entryContent, .pageTemplate-linkHub #main .inner > .entry-content {
  margin-bottom: 2em; }
  .pageTemplate-linkHub #main .inner > .entryContent h2, .pageTemplate-linkHub #main .inner > .entry-content h2 {
    text-align: left;
    font-weight: 800;
    font-style: italic;
    color: #339a99; }

.pageTemplate-linkHub #main .pageSection {
  margin-bottom: 3em; }
  .pageTemplate-linkHub #main .pageSection:not(:last-of-type)::after {
    content: '';
    display: block;
    border-bottom: 1px solid #b8b8b8;
    margin-top: 2em;
    margin-bottom: 2em; }
  .pageTemplate-linkHub #main .pageSection.hasBubble .mainText {
    order: 2; }
  .pageTemplate-linkHub #main .pageSection.hasBubble .bubble {
    order: 1; }
  .pageTemplate-linkHub #main .pageSection.hasBubble:nth-of-type(odd) .mainText {
    padding-right: 0; }
  .pageTemplate-linkHub #main .pageSection .sectionInner {
    display: flex;
    flex-wrap: wrap; }
  .pageTemplate-linkHub #main .pageSection .mainText {
    flex-basis: 100%;
    max-width: 100%;
    flex-grow: 99;
    margin-bottom: 1em;
    text-align: center; }
    .pageTemplate-linkHub #main .pageSection .mainText > h2 {
      text-align: center;
      font-weight: 800;
      font-style: italic;
      color: #333535; }
      .pageTemplate-linkHub #main .pageSection .mainText > h2 a {
        text-decoration: none; }
        .pageTemplate-linkHub #main .pageSection .mainText > h2 a, .pageTemplate-linkHub #main .pageSection .mainText > h2 a:link, .pageTemplate-linkHub #main .pageSection .mainText > h2 a:visited {
          color: inherit; }
        .pageTemplate-linkHub #main .pageSection .mainText > h2 a:hover, .pageTemplate-linkHub #main .pageSection .mainText > h2 a:active {
          text-decoration: underline; }
    .pageTemplate-linkHub #main .pageSection .mainText .entryContent ul, .pageTemplate-linkHub #main .pageSection .mainText .entry-content ul {
      padding-top: 0;
      padding-bottom: 0; }
      .pageTemplate-linkHub #main .pageSection .mainText .entryContent ul li, .pageTemplate-linkHub #main .pageSection .mainText .entry-content ul li {
        padding-top: 0;
        padding-bottom: 0; }
        .pageTemplate-linkHub #main .pageSection .mainText .entryContent ul li:before, .pageTemplate-linkHub #main .pageSection .mainText .entry-content ul li:before {
          color: #333535; }
    .pageTemplate-linkHub #main .pageSection .mainText .entryContent h3, .pageTemplate-linkHub #main .pageSection .mainText .entry-content h3 {
      text-transform: none;
      font-weight: 700; }
      .pageTemplate-linkHub #main .pageSection .mainText .entryContent h3:not(:only-child), .pageTemplate-linkHub #main .pageSection .mainText .entry-content h3:not(:only-child) {
        margin-bottom: 0.6em; }
      .pageTemplate-linkHub #main .pageSection .mainText .entryContent h3:only-child, .pageTemplate-linkHub #main .pageSection .mainText .entry-content h3:only-child {
        margin-bottom: 0; }
      .pageTemplate-linkHub #main .pageSection .mainText .entryContent h3 a, .pageTemplate-linkHub #main .pageSection .mainText .entry-content h3 a {
        text-decoration: none; }
        .pageTemplate-linkHub #main .pageSection .mainText .entryContent h3 a:hover, .pageTemplate-linkHub #main .pageSection .mainText .entryContent h3 a:active, .pageTemplate-linkHub #main .pageSection .mainText .entry-content h3 a:hover, .pageTemplate-linkHub #main .pageSection .mainText .entry-content h3 a:active {
          text-decoration: underline; }
    .pageTemplate-linkHub #main .pageSection .mainText .entryContent .extraInfoBubble, .pageTemplate-linkHub #main .pageSection .mainText .entry-content .extraInfoBubble {
      display: inline-block; }
      .pageTemplate-linkHub #main .pageSection .mainText .entryContent .extraInfoBubble button, .pageTemplate-linkHub #main .pageSection .mainText .entry-content .extraInfoBubble button {
        padding: 0.5em 0.9em; }

.pageTemplate-linkHub #main div.bubblesContainer:not(:last-child) {
  margin-bottom: 3em; }

.pageTemplate-linkHub #main .bubble {
  flex-basis: 260px;
  max-width: 260px;
  flex-shrink: 0;
  display: block;
  margin: 0 auto 1em;
  max-width: 100%;
  color: #fff; }
  .pageTemplate-linkHub #main .bubble.transparent a, .pageTemplate-linkHub #main .bubble.transparent .noa {
    border: none;
    box-shadow: none;
    border-radius: 0;
    color: #333535; }
    .pageTemplate-linkHub #main .bubble.transparent a, .pageTemplate-linkHub #main .bubble.transparent a:link, .pageTemplate-linkHub #main .bubble.transparent a:visited, .pageTemplate-linkHub #main .bubble.transparent .noa, .pageTemplate-linkHub #main .bubble.transparent .noa:link, .pageTemplate-linkHub #main .bubble.transparent .noa:visited {
      color: #333535; }
    .pageTemplate-linkHub #main .bubble.transparent a:not(.noa):active, .pageTemplate-linkHub #main .bubble.transparent .noa:not(.noa):active {
      top: 0;
      box-shadow: none; }
    .pageTemplate-linkHub #main .bubble.transparent a::after, .pageTemplate-linkHub #main .bubble.transparent .noa::after {
      display: none; }
    .pageTemplate-linkHub #main .bubble.transparent a .aContent, .pageTemplate-linkHub #main .bubble.transparent .noa .aContent {
      position: static;
      transform: translateY(0);
      padding: 0; }
  .pageTemplate-linkHub #main .bubble.black a, .pageTemplate-linkHub #main .bubble.black .noa {
    background-color: rgba(51, 53, 53, 0.9); }
    .pageTemplate-linkHub #main .bubble.black a:not(.noa):hover, .pageTemplate-linkHub #main .bubble.black a:not(.noa):active, .pageTemplate-linkHub #main .bubble.black .noa:not(.noa):hover, .pageTemplate-linkHub #main .bubble.black .noa:not(.noa):active {
      background-color: #333535; }
  .pageTemplate-linkHub #main .bubble.purple a, .pageTemplate-linkHub #main .bubble.purple .noa {
    background-color: rgba(106, 90, 201, 0.9); }
    .pageTemplate-linkHub #main .bubble.purple a:not(.noa):hover, .pageTemplate-linkHub #main .bubble.purple a:not(.noa):active, .pageTemplate-linkHub #main .bubble.purple .noa:not(.noa):hover, .pageTemplate-linkHub #main .bubble.purple .noa:not(.noa):active {
      background-color: #6a5ac9; }
  .pageTemplate-linkHub #main .bubble.green a, .pageTemplate-linkHub #main .bubble.green .noa {
    background-color: rgba(90, 201, 148, 0.9); }
    .pageTemplate-linkHub #main .bubble.green a:not(.noa):hover, .pageTemplate-linkHub #main .bubble.green a:not(.noa):active, .pageTemplate-linkHub #main .bubble.green .noa:not(.noa):hover, .pageTemplate-linkHub #main .bubble.green .noa:not(.noa):active {
      background-color: #5ac994; }
  .pageTemplate-linkHub #main .bubble.aqua a, .pageTemplate-linkHub #main .bubble.aqua .noa {
    background-color: rgba(51, 154, 153, 0.9); }
    .pageTemplate-linkHub #main .bubble.aqua a:not(.noa):hover, .pageTemplate-linkHub #main .bubble.aqua a:not(.noa):active, .pageTemplate-linkHub #main .bubble.aqua .noa:not(.noa):hover, .pageTemplate-linkHub #main .bubble.aqua .noa:not(.noa):active {
      background-color: #339a99; }
  .pageTemplate-linkHub #main .bubble.textXlarge .titleLine:not(.titleLine-bigger) {
    font-size: 1.8em; }
  .pageTemplate-linkHub #main .bubble.textXlarge .titleLine.titleLine-bigger {
    font-size: 4em; }
  .pageTemplate-linkHub #main .bubble a, .pageTemplate-linkHub #main .bubble .noa {
    position: relative;
    top: 0;
    display: block;
    text-decoration: none;
    border-radius: 100%;
    border: 6px solid #fff;
    box-shadow: 0px 2px 5px 5px rgba(51, 53, 53, 0.2);
    transition: all 0.2s ease; }
    .pageTemplate-linkHub #main .bubble a, .pageTemplate-linkHub #main .bubble a:link, .pageTemplate-linkHub #main .bubble a:visited, .pageTemplate-linkHub #main .bubble .noa, .pageTemplate-linkHub #main .bubble .noa:link, .pageTemplate-linkHub #main .bubble .noa:visited {
      color: #fff; }
    .pageTemplate-linkHub #main .bubble a:not(.noa):active, .pageTemplate-linkHub #main .bubble .noa:not(.noa):active {
      top: 2px;
      box-shadow: 0px 0px 5px 5px rgba(51, 53, 53, 0.2); }
    .pageTemplate-linkHub #main .bubble a::after, .pageTemplate-linkHub #main .bubble .noa::after {
      content: '';
      display: block;
      padding-bottom: 100%; }
  .pageTemplate-linkHub #main .bubble .aContent {
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 20px; }
    .pageTemplate-linkHub #main .bubble .aContent img {
      display: block;
      width: 100%;
      max-width: 500px;
      height: auto; }
  .pageTemplate-linkHub #main .bubble .titleLine {
    display: block;
    text-align: center;
    line-height: 1em; }
    .pageTemplate-linkHub #main .bubble .titleLine:not(.titleLine-bigger) {
      font-style: italic;
      font-size: 1.2em; }
    .pageTemplate-linkHub #main .bubble .titleLine.titleLine-bigger {
      font-size: 2.2em; }
  .pageTemplate-linkHub #main .bubble .titleLine-2 {
    margin-top: 0.1em; }

/*** Styles des pubs **************************************************/
form .formContent {
  display: block; }

form .rows {
  flex-grow: 1; }

form .wrapBup {
  flex-basis: 100%;
  max-width: 100%;
  margin: auto;
  text-align: center; }
  form .wrapBup .bup {
    width: 100%;
    text-align: center; }
    form .wrapBup .bup img {
      display: inline-block;
      vertical-align: top;
      max-width: 300px;
      width: 100%;
      height: auto; }

.step1 form .wrapBup {
  text-align: left; }
  .step1 form .wrapBup .bup {
    text-align: left; }

/*** Styles de la page de connexion / login ****************************************/
.login #main input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]), .passwordReset #main input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]) {
  width: 15em;
  max-width: 100%; }

.passwordReset #main .intro {
  max-width: 900px; }

/*** Styles du compte utilisateur **************************************************/
.account.handyTools .introText {
  margin-top: 1em; }

.account.handyTools .blogposts {
  margin-top: 2em;
  padding-bottom: 2em; }
  .account.handyTools .blogposts .blogpostsTitle {
    margin-bottom: 2em; }

.account #main {
  width: 100%;
  padding: 4.6em 5% 1em;
  flex-grow: 1; }
  .account #main > .wrap {
    padding: 0; }
  .account #main h1 {
    font-size: 2.6em;
    line-height: 1.05em;
    font-style: italic;
    margin-bottom: 0.2em; }
    .account #main h1.withComma::after {
      content: ','; }
  .account #main p {
    margin-bottom: 0.8em; }
  .account #main .inner {
    /* a{
				color: $turquoise;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			} */ }
    .account #main .inner .headline {
      font-size: 1.3em; }

.account .headerText .headerText-inner {
  max-width: 100%; }

.account .headerText .external {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.account .headerText a {
  color: #339a99; }

.account #content {
  position: relative;
  display: flex;
  min-height: 1085px;
  justify-content: space-between;
  align-items: stretch; }

.account .cvs-display {
  flex-direction: column; }
  .account .cvs-display .stats-applied-by-link b {
    color: #339a99;
    font-size: 24px; }

.account .headline {
  margin-bottom: 0; }

.account .articles, .account .inner.entryContent, .account .inner.entry-content {
  margin-top: 1.4em; }

.account:not(.history) .jobOffers .jobOffer.applied .articleInner {
  opacity: 0.5; }

.account.history .jobOffers .jobOffer {
  padding-left: 40px; }

.emploiretraite-alerte-emploi {
  float: right; }
  .emploiretraite-alerte-emploi .linkList a {
    border-width: 0;
    background-color: #4caf50; }

#main .entryContent .emploiretraite-alerte-emploi ul li::before {
  content: ''; }

.jobOffers {
  margin-bottom: 2em; }
  .jobOffers .jobOffer {
    display: block;
    position: relative;
    justify-content: space-between;
    /*&.nbApply-0 {
            .wrapButton {
                .link .button {
                    color: $gray;
                    border-color: $gray;

                    &:hover, &:active {
                        background-color: transparent;
                        color: $gray;
                    }
                }
            }
        }*/ }
    .jobOffers .jobOffer:not(:last-of-type) {
      margin-bottom: 1em; }
    .jobOffers .jobOffer .link .button[disabled] {
      color: #bcbcbc;
      border-color: #bcbcbc; }
      .jobOffers .jobOffer .link .button[disabled]:hover, .jobOffers .jobOffer .link .button[disabled]:active {
        background-color: transparent;
        color: #bcbcbc; }
    .jobOffers .jobOffer .state {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -17.5px;
      margin-right: 5px; }
      .jobOffers .jobOffer .state .icon {
        display: none;
        margin: 0 auto;
        text-indent: -99999px;
        background-image: url("/images/sprite_user.png");
        background-repeat: no-repeat; }
      .jobOffers .jobOffer .state.state-trashed .icon, .jobOffers .jobOffer .state.state-trash .icon {
        display: block;
        width: 30px;
        height: 36px;
        background-position: 0 -751px; }
      .jobOffers .jobOffer .state.state-expired .icon {
        display: block;
        width: 35px;
        height: 35px;
        background-position: 0 -955px; }
      .jobOffers .jobOffer .state.state-applied .icon {
        display: block;
        width: 30px;
        height: 31px;
        background-position: 0 -1080px; }
      .jobOffers .jobOffer .state.state-future .icon {
        display: block;
        width: 35px;
        height: 34px;
        background-position: 0 -1881px; }
      .jobOffers .jobOffer .state.state-draft .icon, .jobOffers .jobOffer .state.state-auto-draft .icon, .jobOffers .jobOffer .state.state-pending .icon {
        display: block;
        width: 35px;
        height: 45px;
        background-position: 0 -2081px; }
      .jobOffers .jobOffer .state.state-private, .jobOffers .jobOffer .state.state-private + div {
        display: none; }
      .jobOffers .jobOffer .state .tooltip {
        display: none; }
    .jobOffers .jobOffer .articleInner {
      flex-grow: 1;
      border-left: 7px solid #339a99; }
    .jobOffers .jobOffer.company .articleInner {
      border-left: 7px solid #333535; }
      .jobOffers .jobOffer.company .articleInner h3 {
        color: #333535; }
    .jobOffers .jobOffer.private .articleInner {
      border-left: 7px solid #6a5ac9; }
      .jobOffers .jobOffer.private .articleInner h3 {
        color: #6a5ac9; }
    .jobOffers .jobOffer .articleInner-l2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0.5em 0 0.5em 0.7em;
      border-bottom: 2px solid #EBEBEB; }
    .jobOffers .jobOffer .cieLogo {
      flex-basis: 45px;
      max-width: 45px;
      flex-shrink: 0;
      padding-top: 0.3em;
      display: flex;
      align-items: flex-start;
      margin-right: 0.5em; }
      .jobOffers .jobOffer .cieLogo img {
        display: block;
        width: 100%;
        height: auto;
        border: 2px solid #339a99;
        border-radius: 100%;
        overflow: hidden; }
    .jobOffers .jobOffer .jobInfo {
      flex-basis: 72%;
      max-width: 72%;
      display: flex; }
      .jobOffers .jobOffer .jobInfo h3 {
        margin-bottom: 0.1em;
        font-weight: 700;
        color: #339a99; }
        .jobOffers .jobOffer .jobInfo h3 a {
          color: #339a99;
          text-decoration: none; }
          .jobOffers .jobOffer .jobInfo h3 a:hover {
            text-decoration: underline; }
      .jobOffers .jobOffer .jobInfo .regions {
        font-weight: 700; }
      .jobOffers .jobOffer .jobInfo .sectors {
        font-size: 0.85em;
        font-style: italic;
        color: #4b4b4b; }
      .jobOffers .jobOffer .jobInfo .dates {
        margin-top: 0.4em;
        font-size: 0.75em; }
        .jobOffers .jobOffer .jobInfo .dates time {
          font-size: 1.1em;
          color: #339a99; }
      .jobOffers .jobOffer .jobInfo .managementLinks {
        margin-top: 0.4em; }
        .jobOffers .jobOffer .jobInfo .managementLinks a {
          margin-right: 0.5em;
          text-decoration: underline;
          transition: opacity 0.2s ease;
          white-space: nowrap;
          text-decoration: none;
          opacity: 0.6; }
          .jobOffers .jobOffer .jobInfo .managementLinks a, .jobOffers .jobOffer .jobInfo .managementLinks a:link, .jobOffers .jobOffer .jobInfo .managementLinks a:visited {
            color: #333535; }
          .jobOffers .jobOffer .jobInfo .managementLinks a:hover, .jobOffers .jobOffer .jobInfo .managementLinks a:hover {
            opacity: 1; }
    .jobOffers .jobOffer .wrapButton {
      display: flex;
      margin-top: 1em;
      text-align: left;
      white-space: nowrap; }
      .jobOffers .jobOffer .wrapButton .wrapButton-inner {
        display: flex;
        flex-wrap: wrap; }
      .jobOffers .jobOffer .wrapButton .cieLogoSpace {
        flex-basis: 45px;
        max-width: 45px;
        flex-shrink: 0;
        margin-right: 0.5em; }
      .jobOffers .jobOffer .wrapButton .link {
        margin-right: 1em; }
        .jobOffers .jobOffer .wrapButton .link a {
          text-align: center;
          white-space: nowrap; }
      .jobOffers .jobOffer .wrapButton .wrapIndicators {
        margin-top: 0.5em;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center; }
      .jobOffers .jobOffer .wrapButton .indicator {
        border-radius: 0.5em; }
        .jobOffers .jobOffer .wrapButton .indicator:not(:last-of-type) {
          margin-bottom: 0;
          margin-right: 1em; }
        .jobOffers .jobOffer .wrapButton .indicator.nbRetired .wrapIcon {
          padding-top: 3px; }
        .jobOffers .jobOffer .wrapButton .indicator.nbRetired .icon {
          width: 30px;
          height: 31px;
          background-position: 0 -1080px; }
        .jobOffers .jobOffer .wrapButton .indicator.nbApply .icon {
          width: 30px;
          height: 22px;
          background-position: 0 -1279px; }
        .jobOffers .jobOffer .wrapButton .indicator .indicatorInner, .jobOffers .jobOffer .wrapButton .indicator .indicatorInner > a {
          display: flex;
          align-items: center; }
        .jobOffers .jobOffer .wrapButton .indicator .indicatorInner > a {
          text-decoration: none;
          opacity: 0.9;
          transition: opacity 0.2s ease; }
          .jobOffers .jobOffer .wrapButton .indicator .indicatorInner > a, .jobOffers .jobOffer .wrapButton .indicator .indicatorInner > a:link, .jobOffers .jobOffer .wrapButton .indicator .indicatorInner > a:visited {
            color: #339a99; }
          .jobOffers .jobOffer .wrapButton .indicator .indicatorInner > a:hover, .jobOffers .jobOffer .wrapButton .indicator .indicatorInner > a:active {
            opacity: 1; }
        .jobOffers .jobOffer .wrapButton .indicator .wrapIcon {
          margin-right: 0.6em; }
        .jobOffers .jobOffer .wrapButton .indicator .icon {
          display: block;
          font-size: 0;
          background-image: url("/images/sprite_user.png");
          background-repeat: no-repeat; }
        .jobOffers .jobOffer .wrapButton .indicator .above {
          display: block;
          padding-top: 0.2em;
          padding-bottom: 0.5em;
          line-height: 0;
          text-align: center;
          font-style: italic;
          font-weight: 700;
          text-transform: lowercase;
          color: #339a99; }
        .jobOffers .jobOffer .wrapButton .indicator .number {
          display: block;
          flex-grow: 1;
          font-size: 1.4em;
          text-align: left;
          color: #339a99; }
      .jobOffers .jobOffer .wrapButton .tooltip {
        display: none; }

/*** Styles des modules du tableau de bord / dashboard de l'accueil du compte utilisateur ***********************/
#main .dashboardModules {
  margin: 0 0 2em; }
  #main .dashboardModules .gutterSizer {
    width: 4%; }
  #main .dashboardModules .module {
    width: 100%;
    /* Couleurs des modules */ }
    #main .dashboardModules .module:not(:last-of-type) {
      margin-bottom: 3em; }
    #main .dashboardModules .module.moduleColor-aqua.threeCols .col .number, #main .dashboardModules .module.moduleColor-aqua.threeCols .col .number a, #main .dashboardModules .module.moduleColor-aqua.threeCols .col .number a:link, #main .dashboardModules .module.moduleColor-aqua.threeCols .col .number a:visited {
      color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua.threeCols .col .number a:hover, #main .dashboardModules .module.moduleColor-aqua.threeCols .col .number a:active {
      color: #206160; }
    #main .dashboardModules .module.moduleColor-aqua.threeCols .col .text a {
      text-decoration: none; }
      #main .dashboardModules .module.moduleColor-aqua.threeCols .col .text a, #main .dashboardModules .module.moduleColor-aqua.threeCols .col .text a:link, #main .dashboardModules .module.moduleColor-aqua.threeCols .col .text a:visited {
        color: #339a99; }
      #main .dashboardModules .module.moduleColor-aqua.threeCols .col .text a:hover, #main .dashboardModules .module.moduleColor-aqua.threeCols .col .text a:active {
        color: #206160; }
    #main .dashboardModules .module.moduleColor-aqua.offerList .offer h4, #main .dashboardModules .module.moduleColor-aqua.offerList .offer h4 a, #main .dashboardModules .module.moduleColor-aqua.offerList .offer h4 a:link, #main .dashboardModules .module.moduleColor-aqua.offerList .offer h4 a:visited {
      color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua.offerList .offer h4 a:hover, #main .dashboardModules .module.moduleColor-aqua.offerList .offer h4 a:visited {
      color: #206160; }
    #main .dashboardModules .module.moduleColor-aqua.largeButton .button {
      border-color: #339a99; }
      #main .dashboardModules .module.moduleColor-aqua.largeButton .button:hover {
        text-decoration: none; }
    #main .dashboardModules .module.moduleColor-aqua.largeButton .wrapText {
      color: white !important; }
    #main .dashboardModules .module.moduleColor-aqua.oneStat .theStat .statNumber {
      color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua.oneStat .links a, #main .dashboardModules .module.moduleColor-aqua.oneStat .links a:link, #main .dashboardModules .module.moduleColor-aqua.oneStat .links a:visited {
      color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua.oneStat .links a:hover, #main .dashboardModules .module.moduleColor-aqua.oneStat .links a:active {
      color: #206160; }
    #main .dashboardModules .module.moduleColor-aqua.textContent a, #main .dashboardModules .module.moduleColor-aqua.textContent a:link, #main .dashboardModules .module.moduleColor-aqua.textContent a:visited {
      color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua.textContent a:hover, #main .dashboardModules .module.moduleColor-aqua.textContent a:active {
      color: #206160; }
    #main .dashboardModules .module.moduleColor-aqua.planInfo .infoLine .theText h5 {
      color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua.planInfo .promoLinks a, #main .dashboardModules .module.moduleColor-aqua.planInfo .promoLinks a:link, #main .dashboardModules .module.moduleColor-aqua.planInfo .promoLinks a:visited {
      color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua.planInfo .promoLinks a:hover, #main .dashboardModules .module.moduleColor-aqua.planInfo .promoLinks a:active {
      color: #206160; }
    #main .dashboardModules .module.moduleColor-aqua > header {
      background-color: #339a99; }
      #main .dashboardModules .module.moduleColor-aqua > header .wrapIcon {
        background-color: #339a99; }
    #main .dashboardModules .module.moduleColor-aqua .button, #main .dashboardModules .module.moduleColor-aqua button, #main .dashboardModules .module.moduleColor-aqua input[type="button"], #main .dashboardModules .module.moduleColor-aqua input[type="submit"] {
      background-color: #339a99; }
      #main .dashboardModules .module.moduleColor-aqua .button:hover, #main .dashboardModules .module.moduleColor-aqua .button:active, #main .dashboardModules .module.moduleColor-aqua button:hover, #main .dashboardModules .module.moduleColor-aqua button:active, #main .dashboardModules .module.moduleColor-aqua input[type="button"]:hover, #main .dashboardModules .module.moduleColor-aqua input[type="button"]:active, #main .dashboardModules .module.moduleColor-aqua input[type="submit"]:hover, #main .dashboardModules .module.moduleColor-aqua input[type="submit"]:active {
        background-color: #206160; }
      #main .dashboardModules .module.moduleColor-aqua .button.outline, #main .dashboardModules .module.moduleColor-aqua button.outline, #main .dashboardModules .module.moduleColor-aqua input[type="button"].outline, #main .dashboardModules .module.moduleColor-aqua input[type="submit"].outline {
        border-color: #339a99; }
        #main .dashboardModules .module.moduleColor-aqua .button.outline:hover, #main .dashboardModules .module.moduleColor-aqua .button.outline:active, #main .dashboardModules .module.moduleColor-aqua button.outline:hover, #main .dashboardModules .module.moduleColor-aqua button.outline:active, #main .dashboardModules .module.moduleColor-aqua input[type="button"].outline:hover, #main .dashboardModules .module.moduleColor-aqua input[type="button"].outline:active, #main .dashboardModules .module.moduleColor-aqua input[type="submit"].outline:hover, #main .dashboardModules .module.moduleColor-aqua input[type="submit"].outline:active {
          border-color: #206160;
          background-color: #206160; }
    #main .dashboardModules .module.moduleColor-aqua .buttonLink {
      color: #339a99; }
      #main .dashboardModules .module.moduleColor-aqua .buttonLink:hover, #main .dashboardModules .module.moduleColor-aqua .buttonLink:active {
        color: #206160; }
    #main .dashboardModules .module.moduleColor-green.threeCols .col .number, #main .dashboardModules .module.moduleColor-green.threeCols .col .number a, #main .dashboardModules .module.moduleColor-green.threeCols .col .number a:link, #main .dashboardModules .module.moduleColor-green.threeCols .col .number a:visited {
      color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.threeCols .col .number a:hover, #main .dashboardModules .module.moduleColor-green.threeCols .col .number a:active {
      color: #35a26e; }
    #main .dashboardModules .module.moduleColor-green.threeCols .col .text a {
      text-decoration: none; }
      #main .dashboardModules .module.moduleColor-green.threeCols .col .text a, #main .dashboardModules .module.moduleColor-green.threeCols .col .text a:link, #main .dashboardModules .module.moduleColor-green.threeCols .col .text a:visited {
        color: #5ac994; }
      #main .dashboardModules .module.moduleColor-green.threeCols .col .text a:hover, #main .dashboardModules .module.moduleColor-green.threeCols .col .text a:active {
        color: #35a26e; }
    #main .dashboardModules .module.moduleColor-green.offerList .offer h4, #main .dashboardModules .module.moduleColor-green.offerList .offer h4 a, #main .dashboardModules .module.moduleColor-green.offerList .offer h4 a:link, #main .dashboardModules .module.moduleColor-green.offerList .offer h4 a:visited {
      color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.offerList .offer h4 a:hover, #main .dashboardModules .module.moduleColor-green.offerList .offer h4 a:visited {
      color: #35a26e; }
    #main .dashboardModules .module.moduleColor-green.largeButton .button {
      border-color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.oneStat .theStat .statNumber {
      color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.oneStat .links a, #main .dashboardModules .module.moduleColor-green.oneStat .links a:link, #main .dashboardModules .module.moduleColor-green.oneStat .links a:visited {
      color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.oneStat .links a:hover, #main .dashboardModules .module.moduleColor-green.oneStat .links a:active {
      color: #35a26e; }
    #main .dashboardModules .module.moduleColor-green.textContent a, #main .dashboardModules .module.moduleColor-green.textContent a:link, #main .dashboardModules .module.moduleColor-green.textContent a:visited {
      color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.textContent a:hover, #main .dashboardModules .module.moduleColor-green.textContent a:active {
      color: #35a26e; }
    #main .dashboardModules .module.moduleColor-green.planInfo .infoLine .theText h5 {
      color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.planInfo .promoLinks a, #main .dashboardModules .module.moduleColor-green.planInfo .promoLinks a:link, #main .dashboardModules .module.moduleColor-green.planInfo .promoLinks a:visited {
      color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green.planInfo .promoLinks a:hover, #main .dashboardModules .module.moduleColor-green.planInfo .promoLinks a:active {
      color: #35a26e; }
    #main .dashboardModules .module.moduleColor-green > header {
      background-color: #5ac994; }
      #main .dashboardModules .module.moduleColor-green > header .wrapIcon {
        background-color: #5ac994; }
    #main .dashboardModules .module.moduleColor-green .button, #main .dashboardModules .module.moduleColor-green button, #main .dashboardModules .module.moduleColor-green input[type="button"], #main .dashboardModules .module.moduleColor-green input[type="submit"] {
      background-color: #5ac994; }
      #main .dashboardModules .module.moduleColor-green .button:hover, #main .dashboardModules .module.moduleColor-green .button:active, #main .dashboardModules .module.moduleColor-green button:hover, #main .dashboardModules .module.moduleColor-green button:active, #main .dashboardModules .module.moduleColor-green input[type="button"]:hover, #main .dashboardModules .module.moduleColor-green input[type="button"]:active, #main .dashboardModules .module.moduleColor-green input[type="submit"]:hover, #main .dashboardModules .module.moduleColor-green input[type="submit"]:active {
        background-color: #35a26e; }
      #main .dashboardModules .module.moduleColor-green .button.outline, #main .dashboardModules .module.moduleColor-green button.outline, #main .dashboardModules .module.moduleColor-green input[type="button"].outline, #main .dashboardModules .module.moduleColor-green input[type="submit"].outline {
        border-color: #5ac994; }
        #main .dashboardModules .module.moduleColor-green .button.outline:hover, #main .dashboardModules .module.moduleColor-green .button.outline:active, #main .dashboardModules .module.moduleColor-green button.outline:hover, #main .dashboardModules .module.moduleColor-green button.outline:active, #main .dashboardModules .module.moduleColor-green input[type="button"].outline:hover, #main .dashboardModules .module.moduleColor-green input[type="button"].outline:active, #main .dashboardModules .module.moduleColor-green input[type="submit"].outline:hover, #main .dashboardModules .module.moduleColor-green input[type="submit"].outline:active {
          border-color: #35a26e;
          background-color: #35a26e; }
    #main .dashboardModules .module.moduleColor-green .buttonLink {
      color: #5ac994; }
      #main .dashboardModules .module.moduleColor-green .buttonLink:hover, #main .dashboardModules .module.moduleColor-green .buttonLink:active {
        color: #35a26e; }
    #main .dashboardModules .module.moduleColor-purple.threeCols .col .number, #main .dashboardModules .module.moduleColor-purple.threeCols .col .number a, #main .dashboardModules .module.moduleColor-purple.threeCols .col .number a:link, #main .dashboardModules .module.moduleColor-purple.threeCols .col .number a:visited {
      color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.threeCols .col .number a:hover, #main .dashboardModules .module.moduleColor-purple.threeCols .col .number a:active {
      color: #4535a2; }
    #main .dashboardModules .module.moduleColor-purple.threeCols .col .text a {
      text-decoration: none; }
      #main .dashboardModules .module.moduleColor-purple.threeCols .col .text a, #main .dashboardModules .module.moduleColor-purple.threeCols .col .text a:link, #main .dashboardModules .module.moduleColor-purple.threeCols .col .text a:visited {
        color: #6a5ac9; }
      #main .dashboardModules .module.moduleColor-purple.threeCols .col .text a:hover, #main .dashboardModules .module.moduleColor-purple.threeCols .col .text a:active {
        color: #4535a2; }
    #main .dashboardModules .module.moduleColor-purple.offerList .offer h4, #main .dashboardModules .module.moduleColor-purple.offerList .offer h4 a, #main .dashboardModules .module.moduleColor-purple.offerList .offer h4 a:link, #main .dashboardModules .module.moduleColor-purple.offerList .offer h4 a:visited {
      color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.offerList .offer h4 a:hover, #main .dashboardModules .module.moduleColor-purple.offerList .offer h4 a:visited {
      color: #4535a2; }
    #main .dashboardModules .module.moduleColor-purple.largeButton .button {
      border-color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.oneStat .theStat .statNumber {
      color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.oneStat .links a, #main .dashboardModules .module.moduleColor-purple.oneStat .links a:link, #main .dashboardModules .module.moduleColor-purple.oneStat .links a:visited {
      color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.oneStat .links a:hover, #main .dashboardModules .module.moduleColor-purple.oneStat .links a:active {
      color: #4535a2; }
    #main .dashboardModules .module.moduleColor-purple.textContent a, #main .dashboardModules .module.moduleColor-purple.textContent a:link, #main .dashboardModules .module.moduleColor-purple.textContent a:visited {
      color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.textContent a:hover, #main .dashboardModules .module.moduleColor-purple.textContent a:active {
      color: #4535a2; }
    #main .dashboardModules .module.moduleColor-purple.planInfo .infoLine .theText h5 {
      color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.planInfo .promoLinks a, #main .dashboardModules .module.moduleColor-purple.planInfo .promoLinks a:link, #main .dashboardModules .module.moduleColor-purple.planInfo .promoLinks a:visited {
      color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple.planInfo .promoLinks a:hover, #main .dashboardModules .module.moduleColor-purple.planInfo .promoLinks a:active {
      color: #4535a2; }
    #main .dashboardModules .module.moduleColor-purple > header {
      background-color: #6a5ac9; }
      #main .dashboardModules .module.moduleColor-purple > header .wrapIcon {
        background-color: #6a5ac9; }
    #main .dashboardModules .module.moduleColor-purple .button, #main .dashboardModules .module.moduleColor-purple button, #main .dashboardModules .module.moduleColor-purple input[type="button"], #main .dashboardModules .module.moduleColor-purple input[type="submit"] {
      background-color: #6a5ac9; }
      #main .dashboardModules .module.moduleColor-purple .button:hover, #main .dashboardModules .module.moduleColor-purple .button:active, #main .dashboardModules .module.moduleColor-purple button:hover, #main .dashboardModules .module.moduleColor-purple button:active, #main .dashboardModules .module.moduleColor-purple input[type="button"]:hover, #main .dashboardModules .module.moduleColor-purple input[type="button"]:active, #main .dashboardModules .module.moduleColor-purple input[type="submit"]:hover, #main .dashboardModules .module.moduleColor-purple input[type="submit"]:active {
        background-color: #4535a2; }
      #main .dashboardModules .module.moduleColor-purple .button.outline, #main .dashboardModules .module.moduleColor-purple button.outline, #main .dashboardModules .module.moduleColor-purple input[type="button"].outline, #main .dashboardModules .module.moduleColor-purple input[type="submit"].outline {
        border-color: #6a5ac9; }
        #main .dashboardModules .module.moduleColor-purple .button.outline:hover, #main .dashboardModules .module.moduleColor-purple .button.outline:active, #main .dashboardModules .module.moduleColor-purple button.outline:hover, #main .dashboardModules .module.moduleColor-purple button.outline:active, #main .dashboardModules .module.moduleColor-purple input[type="button"].outline:hover, #main .dashboardModules .module.moduleColor-purple input[type="button"].outline:active, #main .dashboardModules .module.moduleColor-purple input[type="submit"].outline:hover, #main .dashboardModules .module.moduleColor-purple input[type="submit"].outline:active {
          border-color: #4535a2;
          background-color: #4535a2; }
    #main .dashboardModules .module.moduleColor-purple .buttonLink {
      color: #6a5ac9; }
      #main .dashboardModules .module.moduleColor-purple .buttonLink:hover, #main .dashboardModules .module.moduleColor-purple .buttonLink:active {
        color: #4535a2; }
    #main .dashboardModules .module.moduleIcon-pieChart > header .wrapIcon .icon {
      width: 40px;
      height: 40px;
      background-position: 0 -3320px; }
    #main .dashboardModules .module.moduleIcon-medalFirst > header .wrapIcon .icon {
      width: 28px;
      height: 36px;
      background-position: 0 -3420px; }
    #main .dashboardModules .module.moduleIcon-sheetCheck > header .wrapIcon .icon {
      margin-left: 6px;
      width: 35px;
      height: 37px;
      background-position: 0 -3520px; }
    #main .dashboardModules .module.moduleIcon-eye > header .wrapIcon .icon {
      width: 35px;
      height: 21px;
      background-position: 0 -3620px; }
    #main .dashboardModules .module.moduleIcon-tag > header .wrapIcon .icon {
      width: 32px;
      height: 25px;
      background-position: 0 -4171px; }
    #main .dashboardModules .module.moduleIcon-wrenchScrewdriver > header .wrapIcon .icon {
      width: 32px;
      height: 32px;
      background-position: 0 -3720px; }
    #main .dashboardModules .module.padded .contentInner {
      padding: 1.2em 1.4em; }
    #main .dashboardModules .module.threeCols .contentInner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    #main .dashboardModules .module.threeCols .col {
      flex-basis: 100%;
      max-width: 100%;
      flex-grow: 10;
      padding: 0.8em 1.4em; }
      #main .dashboardModules .module.threeCols .col:not(:last-of-type) {
        border-bottom: 2px solid #e6e6e6; }
      #main .dashboardModules .module.threeCols .col:nth-of-type(odd) {
        background-color: #f0f0f0; }
      #main .dashboardModules .module.threeCols .col > .number {
        font-size: 3em;
        font-weight: 700;
        color: #525252; }
        #main .dashboardModules .module.threeCols .col > .number .small {
          font-size: 0.5em; }
        #main .dashboardModules .module.threeCols .col > .number a {
          text-decoration: none;
          transition: color 0.2s ease; }
          #main .dashboardModules .module.threeCols .col > .number a, #main .dashboardModules .module.threeCols .col > .number a:link, #main .dashboardModules .module.threeCols .col > .number a:visited {
            color: #525252; }
          #main .dashboardModules .module.threeCols .col > .number a:hover, #main .dashboardModules .module.threeCols .col > .number a:active {
            color: #2c2c2c; }
      #main .dashboardModules .module.threeCols .col > .text {
        font-size: 0.9em;
        font-weight: 700; }
        #main .dashboardModules .module.threeCols .col > .text a {
          transition: color 0.2s ease; }
          #main .dashboardModules .module.threeCols .col > .text a, #main .dashboardModules .module.threeCols .col > .text a:link, #main .dashboardModules .module.threeCols .col > .text a:visited {
            color: #525252; }
          #main .dashboardModules .module.threeCols .col > .text a:hover, #main .dashboardModules .module.threeCols .col > .text a:active {
            color: #2c2c2c; }
    #main .dashboardModules .module.offerList.withIndicators .offer {
      flex-wrap: wrap; }
    #main .dashboardModules .module.offerList .offer {
      margin-bottom: 1.4em; }
      #main .dashboardModules .module.offerList .offer h4 {
        margin: 0;
        font-weight: 700;
        color: #717171; }
        #main .dashboardModules .module.offerList .offer h4 a {
          text-decoration: none;
          transition: color 0.2s ease; }
          #main .dashboardModules .module.offerList .offer h4 a, #main .dashboardModules .module.offerList .offer h4 a:link, #main .dashboardModules .module.offerList .offer h4 a:visited {
            color: #717171; }
          #main .dashboardModules .module.offerList .offer h4 a:hover, #main .dashboardModules .module.offerList .offer h4 a:active {
            color: #4b4b4b; }
      #main .dashboardModules .module.offerList .offer .region {
        margin-bottom: 0.2em;
        font-size: 0.9em;
        font-weight: 700;
        color: #333535; }
      #main .dashboardModules .module.offerList .offer .sector {
        font-size: 0.8em;
        color: #ababab; }
    #main .dashboardModules .module.offerList .buttons {
      margin: 0;
      padding: 0;
      border-top: none;
      text-align: left; }
      #main .dashboardModules .module.offerList .buttons .wrapButton {
        display: block;
        text-align: center; }
      #main .dashboardModules .module.offerList .buttons .button:not(:first-child), #main .dashboardModules .module.offerList .buttons button:not(:first-child), #main .dashboardModules .module.offerList .buttons input[type="button"]:not(:first-child), #main .dashboardModules .module.offerList .buttons input[type="submit"]:not(:first-child), #main .dashboardModules .module.offerList .buttons .buttonLink:not(:first-child) {
        margin-left: 0; }
    #main .dashboardModules .module.largeButton.moduleIcon-rankStars .icon {
      width: 64px;
      height: 61px;
      background-position: 0 -3871px; }
    #main .dashboardModules .module.largeButton.moduleIcon-ribbonSeal .icon {
      width: 49px;
      height: 63px;
      background-position: 0 -4071px; }
    #main .dashboardModules .module.largeButton .button {
      position: relative;
      display: block;
      padding: 1.6em 2em;
      border: 2px solid #4b4b4b;
      background-color: #fff;
      overflow: hidden; }
      #main .dashboardModules .module.largeButton .button:hover::before, #main .dashboardModules .module.largeButton .button:active::before {
        opacity: 1; }
      #main .dashboardModules .module.largeButton .button::before {
        content: '';
        position: absolute;
        z-index: 5;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(115deg, #339a99 0%, #339a99 20%, #5ac994 60%, #5ac994 100%);
        opacity: 0.9;
        transition: opacity 0.2s linear; }
      #main .dashboardModules .module.largeButton .button .buttonContent {
        position: relative;
        z-index: 10;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center; }
        #main .dashboardModules .module.largeButton .button .buttonContent .wrapIcon {
          flex-basis: 100%;
          max-width: 100%;
          margin-bottom: 0.5em;
          text-align: center; }
          #main .dashboardModules .module.largeButton .button .buttonContent .wrapIcon .icon {
            display: inline-block;
            background-image: url("/images/sprite_icon.png");
            background-repeat: no-repeat; }
        #main .dashboardModules .module.largeButton .button .buttonContent .wrapText {
          text-align: center; }
          #main .dashboardModules .module.largeButton .button .buttonContent .wrapText > span {
            display: block;
            line-height: 1.2em; }
          #main .dashboardModules .module.largeButton .button .buttonContent .wrapText .firstLine {
            font-size: 1.2em;
            text-transform: none;
            font-style: italic;
            font-weight: 400; }
          #main .dashboardModules .module.largeButton .button .buttonContent .wrapText .emphasis {
            font-size: 1.4em;
            letter-spacing: 0.02em; }
    #main .dashboardModules .module.buttonList .wrapButton {
      position: relative; }
      #main .dashboardModules .module.buttonList .wrapButton button, #main .dashboardModules .module.buttonList .wrapButton .button, #main .dashboardModules .module.buttonList .wrapButton input[type="button"], #main .dashboardModules .module.buttonList .wrapButton input[type="submit"] {
        margin: 0 0 0.4em 37px; }
      #main .dashboardModules .module.buttonList .wrapButton .linkTrigger {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -18px; }
        #main .dashboardModules .module.buttonList .wrapButton .linkTrigger button {
          margin-bottom: 0;
          margin: 0; }
    #main .dashboardModules .module.oneStat .theStat .statNumber {
      margin-right: 0.4em;
      color: #717171; }
    #main .dashboardModules .module.oneStat .theStat .number {
      font-size: 3em;
      line-height: 1em;
      font-weight: 700; }
    #main .dashboardModules .module.oneStat .theStat .symbol {
      font-size: 1.7em;
      line-height: 1em; }
    #main .dashboardModules .module.oneStat .theStat .label {
      display: inline;
      font-weight: 700;
      color: #333535; }
    #main .dashboardModules .module.oneStat .links {
      margin-top: 0.6em; }
      #main .dashboardModules .module.oneStat .links a {
        font-style: italic;
        text-decoration: none;
        transition: color 0.2s ease; }
        #main .dashboardModules .module.oneStat .links a, #main .dashboardModules .module.oneStat .links a:link, #main .dashboardModules .module.oneStat .links a:visited {
          color: #717171; }
        #main .dashboardModules .module.oneStat .links a:hover, #main .dashboardModules .module.oneStat .links a:active {
          color: #4b4b4b; }
    #main .dashboardModules .module.textContent a {
      text-decoration: none;
      font-weight: 700;
      transition: color 0.2s ease; }
      #main .dashboardModules .module.textContent a, #main .dashboardModules .module.textContent a:link, #main .dashboardModules .module.textContent a:visited {
        color: #717171; }
      #main .dashboardModules .module.textContent a:hover, #main .dashboardModules .module.textContent a:active {
        color: #4b4b4b; }
    #main .dashboardModules .module.textContent .textLinkList:not(:last-child) {
      margin-bottom: 1em; }
    #main .dashboardModules .module.textContent .textLinkList ul {
      padding-left: 1.6em;
      font-size: 0.9em;
      list-style-type: disc; }
    #main .dashboardModules .module.textContent .textLinkList li:not(:last-of-type) {
      margin-bottom: 0.4em; }
    #main .dashboardModules .module.textContent .textLinkList .theLink a {
      font-size: 0.9em;
      text-decoration: none;
      font-style: italic; }
    #main .dashboardModules .module.planInfo .contentInner > div:not(:last-child) {
      margin-bottom: 1em; }
    #main .dashboardModules .module.planInfo .infoLine {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      align-items: center; }
      #main .dashboardModules .module.planInfo .infoLine .theText {
        margin-right: 2em; }
        #main .dashboardModules .module.planInfo .infoLine .theText h5 {
          margin-bottom: 0;
          font-size: 0.7em;
          text-transform: uppercase;
          color: #989898; }
        #main .dashboardModules .module.planInfo .infoLine .theText .mainText {
          display: block;
          font-size: 1.8em;
          margin-bottom: 0.4em;
          line-height: 1.2em;
          color: #4b4b4b; }
      #main .dashboardModules .module.planInfo .infoLine .buttons {
        margin: 0;
        padding: 0;
        border: 0; }
    #main .dashboardModules .module.planInfo .promoLinks {
      margin-top: 2em; }
      #main .dashboardModules .module.planInfo .promoLinks > div:not(:last-child) {
        margin-bottom: 0.6em; }
      #main .dashboardModules .module.planInfo .promoLinks a {
        text-decoration: none;
        font-style: italic;
        transition: color 0.2s ease; }
        #main .dashboardModules .module.planInfo .promoLinks a, #main .dashboardModules .module.planInfo .promoLinks a:link, #main .dashboardModules .module.planInfo .promoLinks a:visited {
          color: #717171; }
        #main .dashboardModules .module.planInfo .promoLinks a:hover, #main .dashboardModules .module.planInfo .promoLinks a:active {
          color: #4b4b4b; }
    #main .dashboardModules .module > header {
      position: relative;
      padding: 0.6em 1.4em;
      padding-right: 5.6em;
      text-transform: uppercase;
      background-color: #525252;
      color: #fff;
      border-radius: 0.3em 0.3em 0 0; }
      #main .dashboardModules .module > header h3 {
        margin: 0;
        font-size: 1.05em;
        line-height: 1.2em;
        font-weight: 800;
        letter-spacing: 0.02em; }
      #main .dashboardModules .module > header .wrapIcon {
        position: absolute;
        right: 1.4em;
        top: -0.7em;
        height: 3.8em;
        width: 3.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: #525252; }
        #main .dashboardModules .module > header .wrapIcon .icon {
          display: inline-block;
          text-indent: -99999px;
          background-image: url("/images/sprite_icon.png");
          background-repeat: no-repeat; }
    #main .dashboardModules .module .contentInner {
      border: 2px solid #e6e6e6;
      border-top: none; }
    #main .dashboardModules .module button, #main .dashboardModules .module input[type="button"], #main .dashboardModules .module input[type="submit"], #main .dashboardModules .module .button {
      background-color: #717171;
      transition: all 0.2s ease; }
      #main .dashboardModules .module button:hover, #main .dashboardModules .module button:active, #main .dashboardModules .module input[type="button"]:hover, #main .dashboardModules .module input[type="button"]:active, #main .dashboardModules .module input[type="submit"]:hover, #main .dashboardModules .module input[type="submit"]:active, #main .dashboardModules .module .button:hover, #main .dashboardModules .module .button:active {
        background-color: #4b4b4b; }
      #main .dashboardModules .module button.outline, #main .dashboardModules .module input[type="button"].outline, #main .dashboardModules .module input[type="submit"].outline, #main .dashboardModules .module .button.outline {
        border: 3px solid #717171;
        background-color: transparent;
        color: #333535; }
        #main .dashboardModules .module button.outline:hover, #main .dashboardModules .module button.outline:active, #main .dashboardModules .module input[type="button"].outline:hover, #main .dashboardModules .module input[type="button"].outline:active, #main .dashboardModules .module input[type="submit"].outline:hover, #main .dashboardModules .module input[type="submit"].outline:active, #main .dashboardModules .module .button.outline:hover, #main .dashboardModules .module .button.outline:active {
          border-color: #4b4b4b;
          background-color: #4b4b4b;
          color: #fff; }
    #main .dashboardModules .module .buttonLink {
      color: #717171; }
      #main .dashboardModules .module .buttonLink:hover, #main .dashboardModules .module .buttonLink:active {
        color: #4b4b4b; }

/*** Styles des tooltips Smallipop ****************************************/
.pageTemplate-pricing div.smallipop-theme-emploiretraite {
  max-width: 200px; }

div.smallipop-theme-emploiretraite {
  background-color: #fff;
  color: #333535;
  border-color: #339a99;
  font-size: 0.9em;
  border-width: 3px;
  border-radius: 0.5em;
  text-shadow: 0;
  letter-spacing: -0.03em;
  z-index: 11000;
  filter: drop-shadow(1px 3px 3px rgba(51, 53, 53, 0.7)); }
  div.smallipop-theme-emploiretraite.smallipop-align-left::before, div.smallipop-theme-emploiretraite.smallipop-top.smallipop-align-left::before {
    transform: translate(6px, 12px); }
  div.smallipop-theme-emploiretraite::before, div.smallipop-theme-emploiretraite.smallipop-top::before {
    border-width: 16px;
    transform: translate(-6px, 12px);
    border-color: #339a99 transparent transparent transparent; }
  div.smallipop-theme-emploiretraite::after, div.smallipop-theme-emploiretraite.smallipop-top::after {
    border-color: #fff transparent transparent transparent; }
  div.smallipop-theme-emploiretraite.smallipop-right::before {
    border-color: transparent #339a99 transparent transparent; }
  div.smallipop-theme-emploiretraite.smallipop-right::after {
    border-color: transparent #fff transparent transparent; }
  div.smallipop-theme-emploiretraite.smallipop-bottom.smallipop-align-left::before {
    transform: translate(6px, -12px); }
  div.smallipop-theme-emploiretraite.smallipop-bottom::before {
    transform: translate(-6px, -12px);
    border-color: transparent transparent #339a99 transparent; }
  div.smallipop-theme-emploiretraite.smallipop-bottom::after {
    border-color: transparent transparent #fff transparent; }
  div.smallipop-theme-emploiretraite.smallipop-left::before {
    border-color: transparent transparent transparent #339a99; }
  div.smallipop-theme-emploiretraite.smallipop-left::after {
    border-color: transparent transparent transparent #fff; }
  div.smallipop-theme-emploiretraite p:last-child, div.smallipop-theme-emploiretraite ul:last-child, div.smallipop-theme-emploiretraite ol:last-child, div.smallipop-theme-emploiretraite dl:last-child, div.smallipop-theme-emploiretraite h1:last-child, div.smallipop-theme-emploiretraite h2:last-child, div.smallipop-theme-emploiretraite h3:last-child, div.smallipop-theme-emploiretraite h4:last-child, div.smallipop-theme-emploiretraite h5:last-child, div.smallipop-theme-emploiretraite h6:last-child {
    margin-bottom: 0; }
  div.smallipop-theme-emploiretraite strong {
    white-space: nowrap;
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0;
    color: #339a99; }
  div.smallipop-theme-emploiretraite .number {
    font-weight: 700;
    color: #339a99; }
  div.smallipop-theme-emploiretraite .mfp-hide {
    display: block !important; }

/*** Popup AJAX : Popup emploi, popup liste des CV, popup CV **************/
/*** Single emploi, liste de CV *********************************/
.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  height: 100%;
  width: 100%;
  max-width: 1050px;
  background-color: transparent; }
  .mfp-inline-holder .mfp-content .innerPopup, .mfp-ajax-holder .mfp-content .innerPopup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .mfp-inline-holder .mfp-content .innerPopup.extraBubble p, .mfp-ajax-holder .mfp-content .innerPopup.extraBubble p {
      margin: 5px 0; }
    .mfp-inline-holder .mfp-content .innerPopup.extraBubble ol, .mfp-ajax-holder .mfp-content .innerPopup.extraBubble ol {
      margin: 5px 0;
      padding-left: 30px;
      list-style-type: disc; }
      .mfp-inline-holder .mfp-content .innerPopup.extraBubble ol li, .mfp-ajax-holder .mfp-content .innerPopup.extraBubble ol li {
        max-width: 95%; }
  .mfp-inline-holder .mfp-content .innerPopup-l2, .mfp-ajax-holder .mfp-content .innerPopup-l2 {
    position: absolute;
    top: 5%;
    right: 2%;
    bottom: 5%;
    left: 2%;
    padding: 0.5em 0.5em 0 0.5em;
    background-color: #fff;
    border: 4px solid #339a99;
    overflow-y: scroll; }
  .mfp-inline-holder .mfp-content #content, .mfp-ajax-holder .mfp-content #content {
    padding-bottom: 0.5em; }
  .mfp-inline-holder .mfp-content .closePopup, .mfp-ajax-holder .mfp-content .closePopup {
    display: block;
    position: absolute;
    z-index: 2000;
    right: 2%;
    top: 5%;
    margin-right: -13px;
    margin-top: -13px;
    background-color: #339a99;
    border-color: #fff;
    transition: transform 0.1s linear; }
    .mfp-inline-holder .mfp-content .closePopup:hover, .mfp-inline-holder .mfp-content .closePopup:active, .mfp-ajax-holder .mfp-content .closePopup:hover, .mfp-ajax-holder .mfp-content .closePopup:active {
      transform: scale(1.1); }

#singleHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;
  position: relative; }
  #singleHeader .cieLogo {
    margin-right: 1em;
    flex-basis: 70px;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: center; }
    #singleHeader .cieLogo img {
      border: 4px solid #339a99;
      border-radius: 100%;
      overflow: hidden; }
  #singleHeader .headerText {
    flex-basis: 100%;
    max-width: 100%;
    max-width: calc(100% - 230px);
    word-wrap: break-word; }
  #singleHeader .info {
    flex-basis: 300px;
    flex-shrink: 100;
    margin-top: 0.5em;
    font-weight: 700;
    font-style: italic;
    color: #339a99; }
  #singleHeader form {
    justify-content: flex-end; }
  #singleHeader h1 {
    margin-bottom: 0.2em;
    font-size: 2em;
    font-style: normal;
    color: #339a99; }
  #singleHeader .regions {
    margin-bottom: 0.2em;
    font-size: 1.2em;
    font-weight: 700; }
  #singleHeader .sectors {
    margin-bottom: 0;
    font-style: italic;
    color: #4b4b4b; }
  #singleHeader form, #singleHeader .contactCandidates {
    margin-top: 1em; }
    #singleHeader form .button, #singleHeader .contactCandidates .button {
      margin-bottom: 0.6em; }
  #singleHeader + .introText {
    margin-top: -1em;
    margin-bottom: 2em; }

.mainContent {
  width: 100%; }
  .mainContent.jobEntreprise .fieldset.accordion.open > header {
    background-color: #2d8786; }
  .mainContent.jobEntreprise .fieldset.accordion > header {
    background-color: #339a99; }
    .mainContent.jobEntreprise .fieldset.accordion > header:hover, .mainContent.jobEntreprise .fieldset.accordion > header:active {
      background-color: #2d8786; }
  .mainContent.jobParticulier .fieldset.accordion.open > header {
    background-color: #5947c3; }
  .mainContent.jobParticulier .fieldset.accordion > header {
    background-color: #6a5ac9; }
    .mainContent.jobParticulier .fieldset.accordion > header:hover, .mainContent.jobParticulier .fieldset.accordion > header:active {
      background-color: #5947c3; }
  .mainContent .closePopup {
    display: none; }

#sidebar {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 375px;
  min-height: 100%;
  padding-bottom: 1em;
  border-right: 6px solid #339a99;
  background-color: #2c2c2c;
  color: #fff;
  transform: translateX(-100%);
  transition: transform 0.3s ease; }
  #sidebar.open {
    transform: translateX(0);
    overflow: hidden; }
  #sidebar #btnOpenSidebar {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    right: -225px;
    top: 14px;
    padding: 10px 10px;
    border-radius: 0 10px 10px 0;
    background-color: #6a5ac9; }
    #sidebar #btnOpenSidebar .roundCorner {
      display: inline-block;
      position: absolute;
      left: 0;
      width: 10px;
      height: 10px; }
      #sidebar #btnOpenSidebar .roundCorner::after, #sidebar #btnOpenSidebar .roundCorner::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: 20px 20px;
        transition: opacity 0.2s ease; }
      #sidebar #btnOpenSidebar .roundCorner:nth-of-type(1) {
        top: -10px; }
        #sidebar #btnOpenSidebar .roundCorner:nth-of-type(1)::before, #sidebar #btnOpenSidebar .roundCorner:nth-of-type(1)::after {
          background-position: bottom left; }
      #sidebar #btnOpenSidebar .roundCorner:nth-of-type(2) {
        bottom: -10px; }
      #sidebar #btnOpenSidebar .roundCorner::before {
        background-image: radial-gradient(circle farthest-side at center, rgba(106, 90, 201, 0) 0%, rgba(106, 90, 201, 0) 99.9%, #6a5ac9 100%); }
      #sidebar #btnOpenSidebar .roundCorner::after {
        background-image: radial-gradient(circle farthest-side at center, rgba(69, 53, 162, 0) 0%, rgba(69, 53, 162, 0) 99.9%, #4535a2 100%);
        opacity: 0; }
    #sidebar #btnOpenSidebar:hover, #sidebar #btnOpenSidebar:active {
      background-color: #4535a2; }
      #sidebar #btnOpenSidebar:hover .roundCorner::before, #sidebar #btnOpenSidebar:active .roundCorner::before {
        opacity: 0; }
      #sidebar #btnOpenSidebar:hover .roundCorner::after, #sidebar #btnOpenSidebar:active .roundCorner::after {
        opacity: 1; }
    #sidebar #btnOpenSidebar .icon {
      display: inline-block;
      vertical-align: middle;
      width: 200px;
      height: 36px;
      background-image: url(/images/sprite_user.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      text-indent: 42px;
      white-space: nowrap;
      padding-top: 7px;
      color: #fff; }
  #sidebar #btnCloseSidebar {
    position: absolute;
    top: 0.6em;
    left: 0.6em;
    display: inline-block;
    padding: 0;
    height: 30px;
    width: 30px;
    border-radius: 0;
    background-color: transparent;
    transition: transform 0.15s linear; }
    #sidebar #btnCloseSidebar:hover, #sidebar #btnCloseSidebar:active {
      transform: scale(1.4); }
    #sidebar #btnCloseSidebar span {
      display: inline-block; }
    #sidebar #btnCloseSidebar .btnName {
      position: absolute;
      overflow: hidden;
      text-indent: -99999px; }
    #sidebar #btnCloseSidebar .bar {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 3px;
      width: 100%;
      transform: translate(-50%, -50%);
      background-color: #fff; }
      #sidebar #btnCloseSidebar .bar:nth-of-type(1) {
        transform: translate(-50%, -50%) rotate(-45deg); }
      #sidebar #btnCloseSidebar .bar:nth-of-type(2) {
        transform: translate(-50%, -50%) rotate(45deg); }
  #sidebar .profileCompletion {
    display: flex;
    align-items: center;
    background-image: linear-gradient(to left, #339a99, #5ac994);
    padding: 2.8em 0.4em 1em 0.6em; }
    #sidebar .profileCompletion > div {
      padding: 0 0.8em; }
    #sidebar .profileCompletion + .userinfos {
      padding-top: 1.6em; }
    #sidebar .profileCompletion .wrapProgress {
      flex-basis: 75%;
      max-width: 75%;
      font-size: 0.85em;
      font-weight: 700; }
      #sidebar .profileCompletion .wrapProgress p {
        margin-top: 0.5em;
        margin-bottom: 0; }
        #sidebar .profileCompletion .wrapProgress p span {
          vertical-align: middle; }
        #sidebar .profileCompletion .wrapProgress p .completionNumber, #sidebar .profileCompletion .wrapProgress p .percentSign {
          font-size: 1.4em;
          font-weight: 800; }
    #sidebar .profileCompletion .button {
      display: block;
      padding: 0.9em 1em;
      border: 2px solid #fff;
      border-radius: 0;
      background-color: transparent;
      font-size: 0.8em;
      text-transform: none;
      transition: background-color 0.2s ease, color 0.2s ease; }
      #sidebar .profileCompletion .button:hover, #sidebar .profileCompletion .button:active {
        background-color: #fff;
        color: #339a99; }
  #sidebar .userinfos {
    padding: 3em 0 1.6em;
    text-align: center; }
    #sidebar .userinfos .avatar {
      display: inline-block;
      border: 3px solid #fff;
      border-radius: 100%;
      overflow: hidden; }
    #sidebar .userinfos .name {
      margin-bottom: 0.4em;
      font-size: 1.4em;
      font-style: italic; }
    #sidebar .userinfos .button {
      padding: 0.4em 1em 0.5em;
      font-weight: bold; }
      #sidebar .userinfos .button:hover, #sidebar .userinfos .button:active {
        background-color: #37a5a4; }
  #sidebar .account-menu {
    background-color: #242424; }
    #sidebar .account-menu > ul > li {
      border-top: 1px solid #191919;
      border-right: 1px solid #191919; }
      #sidebar .account-menu > ul > li:last-of-type {
        border-bottom: 1px solid #191919; }
      #sidebar .account-menu > ul > li.active > a {
        background-color: #339a99;
        border-right: none; }
        #sidebar .account-menu > ul > li.active > a:hover {
          background-color: rgba(255, 255, 255, 0.06); }
        #sidebar .account-menu > ul > li.active > a:active {
          background-color: rgba(255, 255, 255, 0.12); }
      #sidebar .account-menu > ul > li.active ul {
        display: block; }
      #sidebar .account-menu > ul > li.dashboard > a::before {
        background-position: 0 -360px; }
      #sidebar .account-menu > ul > li.statistics > a::before {
        background-position: 0 -50px; }
      #sidebar .account-menu > ul > li.profil > a::before {
        background-position: 0 0; }
      #sidebar .account-menu > ul > li.emplois > a::before {
        background-position: 0 -103px; }
      #sidebar .account-menu > ul > li.historique > a::before {
        background-position: 0 -203px; }
      #sidebar .account-menu > ul > li.outils > a::before {
        background-position: 0 -308px; }
      #sidebar .account-menu > ul > li.communaute > a::before {
        background-position: 0 -409px; }
      #sidebar .account-menu > ul > li.deconnexion > a::before {
        background-position: 0 -2281px; }
      #sidebar .account-menu > ul > li.publish > a::before {
        background-position: 0 -255px; }
      #sidebar .account-menu > ul > li.forfait > a::before {
        background-image: url(/images/sprite_icon.png);
        background-position: 0 -4165px; }
      #sidebar .account-menu > ul > li > a {
        display: block;
        padding: 0.4em 0 0.4em 10%;
        border-left: 5px solid #fff;
        transition: background-color 0.2s ease; }
        #sidebar .account-menu > ul > li > a::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 36px;
          height: 36px;
          background-image: url("/images/sprite_user.png");
          background-repeat: no-repeat; }
        #sidebar .account-menu > ul > li > a, #sidebar .account-menu > ul > li > a:link, #sidebar .account-menu > ul > li > a:visited {
          text-decoration: none;
          color: #fff; }
        #sidebar .account-menu > ul > li > a:hover {
          background-color: rgba(255, 255, 255, 0.03); }
        #sidebar .account-menu > ul > li > a:active {
          background-color: rgba(255, 255, 255, 0.06); }
        #sidebar .account-menu > ul > li > a span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5%; }
    #sidebar .account-menu > ul ul {
      background-color: #313131;
      padding: 0.4em 0.4em 0.4em 20%;
      display: none; }
      #sidebar .account-menu > ul ul li {
        list-style-type: disc;
        color: #fff; }
      #sidebar .account-menu > ul ul li.active a {
        font-weight: 700; }
      #sidebar .account-menu > ul ul a {
        font-size: 0.8em;
        color: #fff;
        text-decoration: none; }
        #sidebar .account-menu > ul ul a:hover {
          color: #339a99; }
  #sidebar .bup {
    padding: 0 10%;
    margin-top: 2em;
    text-align: center; }
    #sidebar .bup a {
      display: block; }
    #sidebar .bup img {
      display: inline-block;
      width: 100%;
      max-width: 298px;
      height: auto; }
  #sidebar .social {
    margin-top: 1.4em;
    padding: 0 10%;
    font-size: 0.9em;
    line-height: 1.2em;
    text-transform: uppercase; }
    #sidebar .social p {
      margin-bottom: 0.6em; }
    #sidebar .social ul .icon {
      display: inline-block;
      width: 44px;
      height: 44px;
      text-indent: -99999px;
      overflow: hidden;
      background-image: url("/images/sprite_social.png");
      background-repeat: no-repeat; }
    #sidebar .social ul li {
      display: inline-block;
      vertical-align: top; }
      #sidebar .social ul li a {
        display: block; }
      #sidebar .social ul li:not(:first-of-type) {
        margin-left: 0.6em; }
      #sidebar .social ul li.facebook .icon {
        background-position: 0 -182px; }
      #sidebar .social ul li.linkedin .icon {
        background-position: 0 -472px; }
      #sidebar .social ul li.youtube .icon {
        background-position: 0 -290px; }

/* Menu en onglets (n'apparaît pas en onglets en mobile */
/*#sidebar .userinfos .avatar {
	img { width: 100px; height: 100px; }
	.dz-preview { display: none; }
}
.dropzone-module {
	.single-dropzone {
		margin-top: 1em;
		padding: 2em;
		border: 5px dashed $turquoise;
		cursor: pointer;

		.text {
			font-size: 1.4em;
			font-weight: 700;
			color: $turquoise;
		}

		&.dz-drag-hover { border-color: $green;
			.text { color: $green; }
		}
	}

	.avatar {
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 0.5em;
		display: none;

		.dz-error-message, .dz-success-mark, .dz-error-mark { display: none; }

		img {
			max-width: 250px;
			height: auto;
			border-radius: 100%;
			border: 3px solid $turquoise;
		}
	}
}*/
#avatarUpload {
  margin: 1em 0;
  width: 200px;
  height: 200px;
  position: relative;
  /* or fixed or absolute */ }
  #avatarUpload .avatarUpload_imgUploadForm {
    height: 0;
    overflow: hidden; }
  #avatarUpload img {
    border: 2px solid #339a99;
    border-radius: 100%; }
    #avatarUpload img.notUploaded {
      width: 100%;
      height: auto; }
  #avatarUpload .cropControls {
    right: -45px; }
    #avatarUpload .cropControls.cropControlsUpload {
      display: none; }

/* Croppic */
.cropImgWrapper img, #croppicModal img {
  max-width: none !important; }

.cropImgWrapper {
  cursor: -webkit-grab;
  cursor: grab; }
  .cropImgWrapper:active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }

.cropImgUpload {
  z-index: 2;
  position: absolute;
  height: 28px;
  display: block;
  top: -30px;
  right: -2px;
  font-family: sans-serif;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #FFF; }

.cropControls {
  z-index: 2;
  position: absolute;
  height: 30px;
  display: block;
  /* top: -31px; */
  top: -1px;
  font-family: sans-serif;
  background-color: rgba(0, 0, 0, 0.35); }
  .cropControls i {
    display: block;
    float: left;
    margin: 0;
    cursor: pointer;
    background-image: url("/images/cropperIcons.png");
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 20px;
    color: #FFF;
    font-size: 13px;
    font-weight: bold;
    font-style: normal; }
    .cropControls i .cropTips {
      position: absolute;
      top: -22px;
      left: 0;
      opacity: 0;
      font-size: 1.2em; }
    .cropControls i:hover {
      background-color: rgba(0, 0, 0, 0.7); }
      .cropControls i:hover .cropTips {
        opacity: 1; }
    .cropControls i.cropControlZoomMuchIn {
      background-position: 0px 0px; }
    .cropControls i.cropControlZoomIn {
      background-position: -30px 0px; }
    .cropControls i.cropControlZoomOut {
      background-position: -60px 0px; }
    .cropControls i.cropControlZoomMuchOut {
      background-position: -90px 0px; }
    .cropControls i.cropControlRotateLeft {
      background-position: -210px 0px; }
    .cropControls i.cropControlRotateRight {
      background-position: -240px 0px; }
    .cropControls i.cropControlCrop {
      background-position: -120px 0px; }
    .cropControls i.cropControlUpload {
      background-position: -150px 0px; }
    .cropControls i.cropControlReset {
      background-position: -180px 0px; }
    .cropControls i.cropControlRemoveCroppedImage {
      background-position: -180px 0px; }
    .cropControls i::last-child {
      margin-right: none; }

#croppicModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000; }
  #croppicModal .cropImgWrapper {
    border-radius: 100%; }
  #croppicModal .cropControls {
    top: -30px;
    right: 8px; }

/*
*		PRELOADER
*		With courtesy of : http://cssload.net/
*/
.bubblingG {
  text-align: center;
  width: 80px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px auto auto -40px;
  z-index: 2; }
  .bubblingG span {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin: 25px auto;
    background: #FFF;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-border-radius: 50px;
    -moz-animation: bubblingG 1s infinite alternate;
    -webkit-border-radius: 50px;
    -webkit-animation: bubblingG 1s infinite alternate;
    -ms-border-radius: 50px;
    -ms-animation: bubblingG 1s infinite alternate;
    -o-border-radius: 50px;
    -o-animation: bubblingG 1s infinite alternate;
    border-radius: 50px;
    animation: bubblingG 1s infinite alternate; }

#bubblingG_1 {
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s; }

#bubblingG_2 {
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  animation-delay: 0.3s; }

#bubblingG_3 {
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s; }

@-moz-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -moz-transform: translateY(0); }
  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -moz-transform: translateY(-21px); } }

@-webkit-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -webkit-transform: translateY(0); }
  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -webkit-transform: translateY(-21px); } }

@-ms-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -ms-transform: translateY(0); }
  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -ms-transform: translateY(-21px); } }

@-o-keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    -o-transform: translateY(0); }
  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    -o-transform: translateY(-21px); } }

@keyframes bubblingG {
  0% {
    width: 10px;
    height: 10px;
    background-color: #FFF;
    transform: translateY(0); }
  100% {
    width: 24px;
    height: 24px;
    background-color: #FFF;
    transform: translateY(-21px); } }

ul.links {
  list-style-type: disc;
  padding-left: 20px; }
  ul.links .tab {
    margin-bottom: 10px; }
    ul.links .tab a {
      color: #339a99;
      text-decoration: none;
      font-weight: bold; }
      ul.links .tab a:hover {
        text-decoration: underline; }

.tabs {
  margin-top: 15px; }

.tabMenu {
  display: inline-block;
  position: relative;
  margin-top: 1em;
  margin-bottom: 2em; }
  .tabMenu .menuTitle {
    font-style: italic;
    color: #339a99;
    background-color: #fff; }
    .tabMenu .menuTitle > span {
      font-size: 1.4em; }
  .tabMenu li:not(:last-of-type) {
    border-bottom: 1px solid #fff; }
  .tabMenu li:first-of-type, .tabMenu li:first-of-type a {
    border-radius: 0.5em 0 0 0; }
  .tabMenu li:last-of-type, .tabMenu li:last-of-type a {
    border-radius: 0 0 0 0.5em; }
  .tabMenu li.active a, .tabMenu li.active a:link, .tabMenu li.active a:visited {
    background-color: #339a99;
    color: #fff; }
    .tabMenu li.active a::after, .tabMenu li.active a:link::after, .tabMenu li.active a:visited::after {
      border-left-color: #339a99; }
  .tabMenu li.active a:hover, .tabMenu li.active a:active {
    background-color: #2d8786; }
    .tabMenu li.active a:hover::after, .tabMenu li.active a:active::after {
      border-left-color: #2d8786; }
  .tabMenu li a {
    position: relative;
    display: block;
    text-decoration: none;
    padding: 0.6em 1em 0.7em;
    background-color: #EBEBEB;
    transition: background-color 0.2s ease; }
    .tabMenu li a, .tabMenu li a:link, .tabMenu li a:visited {
      color: #333535; }
    .tabMenu li a:hover, .tabMenu li a:active {
      background-color: #dedede; }
      .tabMenu li a:hover::after, .tabMenu li a:active::after {
        border-left-color: #dedede; }
    .tabMenu li a::after {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 0;
      width: 0;
      border: 1.26em solid transparent;
      border-left-color: #EBEBEB;
      transition: border-left-color 0.2s ease;
      pointer-events: none; }
    .tabMenu li a > span {
      font-size: 0.9em; }
    .tabMenu li a .full {
      display: block; }
    .tabMenu li a .short {
      display: none; }

/*
.tabMenu {
	display: inline-block;
	position: relative;
	margin-top: 1em;
	margin-bottom: 2em;
	padding: 1em;

	.menuTitle {
		font-style: italic;
		color: $turquoise;
		background-color: $white;

		&>span {
			font-size: 1.4em;
		}
	}

	ul {
		background-color: $sidebargray;
		border-radius: 0.5em;
	}

	li {
		&:not(:last-of-type) {
			border-bottom: 1px solid $darkblack;
		}

		a {
			display: block;
			text-decoration: none;
			padding: 0.6em 1.6em 0.7em;
			transition: color 0.2s ease;

			&, &:link, &:visited {
				color: $white;
			}

			&:hover, &:active {
				background-color: rgba($white, 0.03);
			}

			.full {
				display: block;
			}

			.short {
				display: none;
			}
		}
	}
}
*/
/* Styles des listes d'affichage d'informations (incluant accordéons / accordeons / accordions) */
.site #main .fieldset > header * {
  color: #fff; }

.site #main .fieldset.lightTheme > header:hover *, .site #main .fieldset.lightTheme > header:active * {
  color: #000000; }

.site #main .fieldset.lightTheme > header * {
  color: #333535; }

.site #main .fieldset.lightTheme > header h2, .site #main .fieldset.lightTheme > header h3, .site #main .fieldset.lightTheme > header h4 {
  font-weight: 400; }

.fieldset {
  position: relative;
  margin-bottom: 0.2em;
  display: flex;
  flex-wrap: wrap; }
  .fieldset.lightTheme {
    margin-bottom: 0; }
    .fieldset.lightTheme.accordion {
      border-bottom: 1px solid #339a99; }
      .fieldset.lightTheme.accordion:nth-of-type(2n) {
        background-color: #EBEBEB; }
      .fieldset.lightTheme.accordion.open > header {
        background-color: transparent; }
      .fieldset.lightTheme.accordion > header:hover, .fieldset.lightTheme.accordion > header:active {
        border-bottom-color: #c5c5c5;
        background-color: transparent; }
      .fieldset.lightTheme.accordion > header .btnRollUnroll {
        display: inline-block; }
    .fieldset.lightTheme > header {
      background-color: transparent;
      padding-left: 0.8em;
      transition: background-color 0.2s ease, border-bottom-color 0.2s ease; }
      .fieldset.lightTheme > header:hover, .fieldset.lightTheme > header:active {
        border-bottom-color: #b8b8b8;
        background-color: transparent; }
        .fieldset.lightTheme > header:hover .btnRollUnroll::after, .fieldset.lightTheme > header:active .btnRollUnroll::after {
          color: #206160; }
      .fieldset.lightTheme > header, .fieldset.lightTheme > header * {
        color: #333535; }
      .fieldset.lightTheme > header h2, .fieldset.lightTheme > header h3, .fieldset.lightTheme > header h4 {
        font-size: 1.1em;
        line-height: 1.2em;
        font-weight: 400;
        color: #339a99;
        transition: color 0.2s ease; }
      .fieldset.lightTheme > header .btnRollUnroll::after {
        color: #339a99;
        font-weight: 400;
        font-size: 1.1em;
        transition: transform 0.2s linear, color 0.2s ease; }
    .fieldset.lightTheme .accordionContent {
      border-left-width: 0.8em;
      border-right-width: 0.8em; }
  .fieldset div.formRow.checkbox {
    flex-basis: 16%;
    max-width: 16%;
    align-items: stretch;
    background-color: #bcbcbc;
    display: flex;
    align-items: center;
    float: left;
    padding: 0.5em;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0; }
    .fieldset div.formRow.checkbox label {
      width: 23px;
      height: 23px;
      margin: auto; }
      .fieldset div.formRow.checkbox label::before {
        background-color: #fff;
        border: 1px solid #fff !important;
        top: -4px;
        margin-right: 50px; }
    .fieldset div.formRow.checkbox + header {
      flex-basis: 84%;
      max-width: 84%; }
  .fieldset > header {
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 0.8em;
    background-color: #2c2c2c;
    color: #fff;
    user-select: none;
    transition: background-color 0.2s ease; }
    .fieldset > header.hasDetails h2, .fieldset > header.hasDetails h3, .fieldset > header.hasDetails h4 {
      font-size: 1em;
      margin-bottom: 0.4em; }
    .fieldset > header.hasDetails .extraDetails {
      font-size: 0.85em; }
    .fieldset > header h2, .fieldset > header h3, .fieldset > header h4 {
      margin-bottom: 0;
      font-size: 0.8em; }
    .fieldset > header .btnRollUnroll {
      align-self: stretch;
      padding: 0 0.5em;
      background-color: transparent;
      border-radius: 0; }
      .fieldset > header .btnRollUnroll::after {
        content: '+';
        display: block;
        font-weight: 700;
        font-size: 1.3em;
        transition: transform 0.2s linear, color 0.2s ease; }
    .fieldset > header .headerText {
      padding: 1em; }
    .fieldset > header .extraDetails {
      margin-top: 0.2em;
      opacity: 0.9; }
      .fieldset > header .extraDetails > div {
        display: inline-flex;
        align-items: center; }
        .fieldset > header .extraDetails > div:not(:last-of-type) {
          margin-bottom: 0.2em;
          margin-right: 1em; }
        .fieldset > header .extraDetails > div .wrapIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 20px;
          margin-right: 0.4em;
          text-align: center; }
        .fieldset > header .extraDetails > div .icon {
          display: inline-block;
          text-indent: -99999px;
          background-image: url("/images/sprite_user.png");
          background-repeat: no-repeat; }
    .fieldset > header .mainDiploma .icon {
      width: 17px;
      height: 17px;
      background-position: 0 -1680px; }
    .fieldset > header .lastEmployer .icon {
      width: 20px;
      height: 17px;
      background-position: 0 -1480px; }
    .fieldset > header .testimonials .icon {
      width: 19px;
      height: 19px;
      background-position: 0 -1576px; }
  .fieldset.accordion > header:hover {
    cursor: pointer; }
  .fieldset.accordion > header:hover, .fieldset.accordion > header:active {
    background-color: #242424; }
  .fieldset.accordion > header .btnRollUnroll {
    display: inline-block; }
  .fieldset.accordion.open > header {
    background-color: #242424; }
    .fieldset.accordion.open > header .btnRollUnroll {
      min-width: 53px; }
      .fieldset.accordion.open > header .btnRollUnroll::after {
        content: "-";
        position: relative;
        top: -2px;
        font-size: 1.5em;
        left: -2px; }
  .fieldset.accordion.open .accordionContent {
    opacity: 1;
    overflow: visible; }
  .fieldset.accordion .accordionContent {
    width: 100%;
    overflow: hidden;
    display: none;
    opacity: 0;
    transition: opacity 0.3s linear; }
  .fieldset.twoCol .accordionContent > dl {
    display: flex; }
    .fieldset.twoCol .accordionContent > dl .colLeft {
      flex-basis: 30%;
      max-width: 30%; }
      .fieldset.twoCol .accordionContent > dl .colLeft img {
        display: block;
        width: 100%;
        max-width: 300px;
        height: auto; }
    .fieldset.twoCol .accordionContent > dl .colRight {
      flex-grow: 1;
      padding-left: 1em; }
  .fieldset.longText .text > *:last-child {
    margin-bottom: 0; }
  .fieldset:not(:last-of-type) .accordionContent {
    margin-bottom: 2em; }
  .fieldset .accordionContent {
    padding-top: 1em;
    border-right: 0.8em solid transparent;
    border-left: 0.8em solid transparent; }
    .fieldset .accordionContent .xps {
      padding-top: 1em; }
      .fieldset .accordionContent .xps, .fieldset .accordionContent .xps > .xp {
        list-style-type: none; }
      .fieldset .accordionContent .xps > .xp {
        margin-left: 0;
        text-indent: 0; }
        .fieldset .accordionContent .xps > .xp:not(:last-of-type) {
          padding-bottom: 2em;
          margin-bottom: 2em;
          border-bottom: 1px solid #bcbcbc; }
        .fieldset .accordionContent .xps > .xp dl > div:last-of-type dd,
        .fieldset .accordionContent .xps > .xp dl > dd:last-of-type {
          padding-bottom: 0; }
        .fieldset .accordionContent .xps > .xp .dates {
          margin-bottom: 1em;
          font-size: 0.8em;
          text-transform: uppercase;
          font-weight: 700;
          color: #898989; }
    .fieldset .accordionContent > dl {
      margin: 0; }
      .fieldset .accordionContent > dl dt {
        line-height: 1.1; }
      .fieldset .accordionContent > dl > div:not(.col):not(:last-of-type),
      .fieldset .accordionContent > dl .col > div:not(:last-of-type) {
        margin-bottom: 1em; }
      .fieldset .accordionContent > dl > dt, .fieldset .accordionContent > dl > div > dt {
        font-size: 1.2em;
        font-style: italic;
        font-weight: bold;
        color: #339a99;
        opacity: 0.7;
        border-bottom: 1px solid #339a99; }
      .fieldset .accordionContent > dl > dd, .fieldset .accordionContent > dl > div > dd {
        padding: 0.4em 0.4em 0 0.4em; }
        .fieldset .accordionContent > dl > dd ul, .fieldset .accordionContent > dl > div > dd ul {
          margin: 0;
          padding-left: 0; }
        .fieldset .accordionContent > dl > dd dl > dt, .fieldset .accordionContent > dl > dd dl > div > dt, .fieldset .accordionContent > dl > div > dd dl > dt, .fieldset .accordionContent > dl > div > dd dl > div > dt {
          padding: 0;
          font-weight: bold;
          font-style: italic; }
          .fieldset .accordionContent > dl > dd dl > dt::after, .fieldset .accordionContent > dl > dd dl > div > dt::after, .fieldset .accordionContent > dl > div > dd dl > dt::after, .fieldset .accordionContent > dl > div > dd dl > div > dt::after {
            content: ':';
            margin: 0 0.2em; }

/*** Styles des sliders / carousels / carrousels de logos ****************************************/
.logoSlider {
  padding-top: 2em;
  padding-bottom: 2em; }
  .logoSlider .sliderTitle {
    margin-bottom: 1.4em;
    text-align: center;
    text-transform: uppercase;
    color: #333535; }
    .logoSlider .sliderTitle.sliderTitle-aqua {
      color: #339a99; }
    .logoSlider .sliderTitle.sliderTitle-green {
      color: #5ac994; }
    .logoSlider .sliderTitle.sliderTitle-purple {
      color: #6a5ac9; }
    .logoSlider .sliderTitle::after {
      content: '';
      display: block;
      margin: auto;
      margin-top: 0.4em;
      width: 100%;
      max-width: 4em;
      border-bottom: 2px solid #d2d2d2; }
    .logoSlider .sliderTitle h3 {
      font-weight: 800; }
  .logoSlider .owl-carousel .owl-stage {
    display: flex; }
  .logoSlider .owl-item {
    float: none;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .logoSlider .elementsOuter {
    padding: 0 5%; }
  .logoSlider .sliderElement {
    text-align: center; }
    .logoSlider .sliderElement img {
      width: auto;
      height: auto;
      max-height: 100px;
      max-width: 230px; }
    .logoSlider .sliderElement a, .logoSlider .sliderElement a:link, .logoSlider .sliderElement a:visited {
      color: #333535;
      text-decoration: none; }
    .logoSlider .sliderElement a, .logoSlider .sliderElement .noa {
      color: #333535;
      opacity: 0.9;
      transition: opacity 0.2s ease; }
      .logoSlider .sliderElement a:hover, .logoSlider .sliderElement a:active, .logoSlider .sliderElement .noa:hover, .logoSlider .sliderElement .noa:active {
        opacity: 1; }
    .logoSlider .sliderElement .noImg {
      font-size: 1.6em;
      line-height: 1em;
      font-weight: 800;
      font-style: italic;
      color: #339a99; }
  .logoSlider .owl-prev, .logoSlider .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.556%;
    font-size: 3.4em;
    color: #333535;
    transition: color 0.2s ease; }
    .logoSlider .owl-prev:hover, .logoSlider .owl-prev:active, .logoSlider .owl-next:hover, .logoSlider .owl-next:active {
      color: #339a99; }
    .logoSlider .owl-prev span, .logoSlider .owl-next span {
      transform: scaleX(0.5); }
  .logoSlider .owl-prev {
    right: 103.5%; }
  .logoSlider .owl-next {
    left: 103.5%; }

/*** Styles des alertes **************************************************************************/
body.home #site .wrapAlerts > .inner, body.home .mfp-wrap .wrapAlerts > .inner {
  width: 90%; }

#site .wrapAlerts, .mfp-wrap .wrapAlerts {
  margin: 2em 0; }
  #site .wrapAlerts > .inner, .mfp-wrap .wrapAlerts > .inner {
    margin: 0 auto;
    width: 96%;
    max-width: 900px; }

#site .alerts, .mfp-wrap .alerts {
  font-size: 0.9em; }
  #site .alerts .alertInner, .mfp-wrap .alerts .alertInner {
    text-align: center; }

#site .alert, .mfp-wrap .alert {
  position: relative;
  margin-bottom: 1em;
  border: 2px solid #5ac994;
  color: #fff;
  opacity: 1;
  transition: opacity 0.2s ease-in; }
  #site .alert.vanishing, .mfp-wrap .alert.vanishing {
    opacity: 0; }
  #site .alert.alertImage-background .alertInner, .mfp-wrap .alert.alertImage-background .alertInner {
    background-color: rgba(51, 154, 153, 0.8); }
  #site .alert.alertImage-top .alertThumbnail, .mfp-wrap .alert.alertImage-top .alertThumbnail {
    margin-bottom: 0.6em; }
  #site .alert.alertImage-bottom .alertThumbnail, .mfp-wrap .alert.alertImage-bottom .alertThumbnail {
    margin-top: 0.6em; }
  #site .alert.alertImage-left .alertThumbnail, .mfp-wrap .alert.alertImage-left .alertThumbnail {
    margin-right: 0.6em; }
  #site .alert.alertImage-right .alertThumbnail, .mfp-wrap .alert.alertImage-right .alertThumbnail {
    margin-left: 0.6em; }
  #site .alert.alertImage-top .alertThumbnail, #site .alert.alertImage-bottom .alertThumbnail, #site .alert.alertImage-left .alertThumbnail, #site .alert.alertImage-right .alertThumbnail, .mfp-wrap .alert.alertImage-top .alertThumbnail, .mfp-wrap .alert.alertImage-bottom .alertThumbnail, .mfp-wrap .alert.alertImage-left .alertThumbnail, .mfp-wrap .alert.alertImage-right .alertThumbnail {
    padding-left: 0.2em; }
    #site .alert.alertImage-top .alertThumbnail img, #site .alert.alertImage-bottom .alertThumbnail img, #site .alert.alertImage-left .alertThumbnail img, #site .alert.alertImage-right .alertThumbnail img, .mfp-wrap .alert.alertImage-top .alertThumbnail img, .mfp-wrap .alert.alertImage-bottom .alertThumbnail img, .mfp-wrap .alert.alertImage-left .alertThumbnail img, .mfp-wrap .alert.alertImage-right .alertThumbnail img {
      width: auto;
      height: auto;
      max-height: 50px; }
  #site .alert.buttonDisplay-horizontal_bottom .alertInner, #site .alert.buttonDisplay-horizontal_top .alertInner, .mfp-wrap .alert.buttonDisplay-horizontal_bottom .alertInner, .mfp-wrap .alert.buttonDisplay-horizontal_top .alertInner {
    flex-wrap: wrap; }
  #site .alert.buttonDisplay-horizontal_bottom .wrapContent, #site .alert.buttonDisplay-horizontal_bottom .wrapButtons, #site .alert.buttonDisplay-horizontal_top .wrapContent, #site .alert.buttonDisplay-horizontal_top .wrapButtons, .mfp-wrap .alert.buttonDisplay-horizontal_bottom .wrapContent, .mfp-wrap .alert.buttonDisplay-horizontal_bottom .wrapButtons, .mfp-wrap .alert.buttonDisplay-horizontal_top .wrapContent, .mfp-wrap .alert.buttonDisplay-horizontal_top .wrapButtons {
    flex-basis: 100%;
    max-width: 100%; }
  #site .alert.buttonDisplay-horizontal_bottom .wrapButtons, #site .alert.buttonDisplay-horizontal_top .wrapButtons, .mfp-wrap .alert.buttonDisplay-horizontal_bottom .wrapButtons, .mfp-wrap .alert.buttonDisplay-horizontal_top .wrapButtons {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0; }
  #site .alert.buttonDisplay-horizontal_bottom .wrapButton:not(:last-of-type), #site .alert.buttonDisplay-horizontal_top .wrapButton:not(:last-of-type), .mfp-wrap .alert.buttonDisplay-horizontal_bottom .wrapButton:not(:last-of-type), .mfp-wrap .alert.buttonDisplay-horizontal_top .wrapButton:not(:last-of-type) {
    margin-bottom: 0.6em;
    margin-right: 0.6em; }
  #site .alert.buttonDisplay-horizontal_bottom .wrapButtons, .mfp-wrap .alert.buttonDisplay-horizontal_bottom .wrapButtons {
    margin-top: 0.6em; }
  #site .alert.buttonDisplay-horizontal_top .wrapButtons, .mfp-wrap .alert.buttonDisplay-horizontal_top .wrapButtons {
    margin-bottom: 0.6em; }
  #site .alert.buttonDisplay-vertical_right .alertInner, #site .alert.buttonDisplay-vertical_left .alertInner, .mfp-wrap .alert.buttonDisplay-vertical_right .alertInner, .mfp-wrap .alert.buttonDisplay-vertical_left .alertInner {
    flex-wrap: wrap; }
  #site .alert.buttonDisplay-vertical_right .wrapContent, #site .alert.buttonDisplay-vertical_right .wrapButtons, #site .alert.buttonDisplay-vertical_left .wrapContent, #site .alert.buttonDisplay-vertical_left .wrapButtons, .mfp-wrap .alert.buttonDisplay-vertical_right .wrapContent, .mfp-wrap .alert.buttonDisplay-vertical_right .wrapButtons, .mfp-wrap .alert.buttonDisplay-vertical_left .wrapContent, .mfp-wrap .alert.buttonDisplay-vertical_left .wrapButtons {
    flex-basis: 100%;
    max-width: 100%; }
  #site .alert.buttonDisplay-vertical_right .wrapButtons, .mfp-wrap .alert.buttonDisplay-vertical_right .wrapButtons {
    margin-top: 0.6em; }
  #site .alert.buttonDisplay-vertical_left .wrapButtons, .mfp-wrap .alert.buttonDisplay-vertical_left .wrapButtons {
    margin-bottom: 0.6em; }
  #site .alert .wrapContent, .mfp-wrap .alert .wrapContent {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap; }
  #site .alert .contentInner, #site .alert .alertThumbnail, .mfp-wrap .alert .contentInner, .mfp-wrap .alert .alertThumbnail {
    flex-basis: 100%;
    max-width: 100%; }
  #site .alert .alertThumbnail img[src$=".jpg"], .mfp-wrap .alert .alertThumbnail img[src$=".jpg"] {
    padding: 2px;
    border: 2px solid #fff;
    background-clip: padding-box;
    border-radius: 100%; }
  #site .alert .fakeBackgroundImage, .mfp-wrap .alert .fakeBackgroundImage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    filter: grayscale(100%);
    opacity: 0.9;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  #site .alert .dismissAlert, .mfp-wrap .alert .dismissAlert {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 20;
    transition: border-color 0.2s linear, transform 0.05s linear, opacity 0.05s linear; }
    #site .alert .dismissAlert:active, .mfp-wrap .alert .dismissAlert:active {
      transform: scale(1.1); }
    #site .alert .dismissAlert.vanishing, .mfp-wrap .alert .dismissAlert.vanishing {
      transform: scale(0);
      opacity: 0; }
    #site .alert .dismissAlert .icon, .mfp-wrap .alert .dismissAlert .icon {
      transition: opacity 0.2s linear; }
      #site .alert .dismissAlert .icon:not(:first-of-type), .mfp-wrap .alert .dismissAlert .icon:not(:first-of-type) {
        position: absolute;
        left: 10px;
        top: 9px;
        opacity: 0; }
  #site .alert .alertInner, .mfp-wrap .alert .alertInner {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    padding: 0.6em 1.2em;
    border: 1px solid #fff;
    background-color: #339a99; }
  #site .alert h4, .mfp-wrap .alert h4 {
    color: #fff;
    display: inline;
    text-transform: uppercase; }
    #site .alert h4::after, .mfp-wrap .alert h4::after {
      content: ' -- '; }
  #site .alert .entryContent, #site .alert .entry-content, .mfp-wrap .alert .entryContent, .mfp-wrap .alert .entry-content {
    display: inline; }
    #site .alert .entryContent p, #site .alert .entry-content p, .mfp-wrap .alert .entryContent p, .mfp-wrap .alert .entry-content p {
      margin-bottom: 0; }
      #site .alert .entryContent p:first-of-type, #site .alert .entry-content p:first-of-type, .mfp-wrap .alert .entryContent p:first-of-type, .mfp-wrap .alert .entry-content p:first-of-type {
        display: inline; }
      #site .alert .entryContent p:not(:first-of-type), #site .alert .entry-content p:not(:first-of-type), .mfp-wrap .alert .entryContent p:not(:first-of-type), .mfp-wrap .alert .entry-content p:not(:first-of-type) {
        margin-top: 1em; }
    #site .alert .entryContent a:not(.button), #site .alert .entryContent a:not(.button):link, #site .alert .entryContent a:not(.button):visited, #site .alert .entry-content a:not(.button), #site .alert .entry-content a:not(.button):link, #site .alert .entry-content a:not(.button):visited, .mfp-wrap .alert .entryContent a:not(.button), .mfp-wrap .alert .entryContent a:not(.button):link, .mfp-wrap .alert .entryContent a:not(.button):visited, .mfp-wrap .alert .entry-content a:not(.button), .mfp-wrap .alert .entry-content a:not(.button):link, .mfp-wrap .alert .entry-content a:not(.button):visited {
      color: inherit; }
  #site .alert .wrapButtons, .mfp-wrap .alert .wrapButtons {
    white-space: nowrap; }
  #site .alert .wrapButton:not(:last-of-type), .mfp-wrap .alert .wrapButton:not(:last-of-type) {
    margin-bottom: 0.4em; }
  #site .alert .wrapButton.buttonStyle-link .button, .mfp-wrap .alert .wrapButton.buttonStyle-link .button {
    color: #fff;
    background-color: transparent; }
    #site .alert .wrapButton.buttonStyle-link .button:hover, #site .alert .wrapButton.buttonStyle-link .button:active, .mfp-wrap .alert .wrapButton.buttonStyle-link .button:hover, .mfp-wrap .alert .wrapButton.buttonStyle-link .button:active {
      border-color: transparent;
      outline-color: transparent;
      text-decoration: underline; }
  #site .alert .wrapButton.buttonStyle-outline .button, .mfp-wrap .alert .wrapButton.buttonStyle-outline .button {
    background-clip: padding-box;
    background-color: transparent;
    border-color: #fff;
    color: #fff; }
    #site .alert .wrapButton.buttonStyle-outline .button:hover, #site .alert .wrapButton.buttonStyle-outline .button:active, .mfp-wrap .alert .wrapButton.buttonStyle-outline .button:hover, .mfp-wrap .alert .wrapButton.buttonStyle-outline .button:active {
      border-color: transparent;
      background-color: #fff;
      color: #333535; }
  #site .alert .button, .mfp-wrap .alert .button {
    padding: 0.6em 1.2em;
    border-radius: 0;
    text-transform: none;
    background-color: #fff;
    background-clip: padding-box;
    color: #333535;
    border: 1px solid #fff;
    outline: 2px solid transparent;
    transition: all 0.2s ease; }
    #site .alert .button:hover, #site .alert .button:active, .mfp-wrap .alert .button:hover, .mfp-wrap .alert .button:active {
      border-color: transparent;
      outline-color: #fff; }

#site .alert.alertLevel-notice {
  border: 1px dashed #339a99;
  color: #333535; }
  #site .alert.alertLevel-notice.alertImage-background .alertInner {
    background-color: rgba(255, 255, 255, 0.8); }
  #site .alert.alertLevel-notice .alertThumbnail img[src$=".jpg"] {
    border-color: #339a99; }
  #site .alert.alertLevel-notice .dismissAlert {
    background-color: #fff;
    border-color: #5ac994; }
    #site .alert.alertLevel-notice .dismissAlert:hover, #site .alert.alertLevel-notice .dismissAlert:active {
      border-color: #339a99; }
      #site .alert.alertLevel-notice .dismissAlert:hover .icon:first-of-type, #site .alert.alertLevel-notice .dismissAlert:active .icon:first-of-type {
        opacity: 0; }
      #site .alert.alertLevel-notice .dismissAlert:hover .icon:not(:first-of-type), #site .alert.alertLevel-notice .dismissAlert:active .icon:not(:first-of-type) {
        opacity: 1; }
    #site .alert.alertLevel-notice .dismissAlert .icon:first-of-type {
      background-position: 0 -2561px; }
    #site .alert.alertLevel-notice .dismissAlert .icon:not(:first-of-type) {
      background-position: 0 -2481px; }
  #site .alert.alertLevel-notice .alertInner {
    background-color: #fff; }
  #site .alert.alertLevel-notice .wrapButton.buttonStyle-outline .button {
    border-color: #339a99;
    color: #339a99; }
    #site .alert.alertLevel-notice .wrapButton.buttonStyle-outline .button:hover, #site .alert.alertLevel-notice .wrapButton.buttonStyle-outline .button:active {
      color: #fff;
      background-color: #339a99;
      border-color: transparent;
      outline-color: #339a99; }
  #site .alert.alertLevel-notice .wrapButton.buttonStyle-link .button {
    color: #339a99; }
  #site .alert.alertLevel-notice .button {
    color: #fff;
    border-color: #339a99;
    background-color: #339a99; }
    #site .alert.alertLevel-notice .button:hover, #site .alert.alertLevel-notice .button:active {
      border-color: transparent;
      outline-color: #339a99; }
  #site .alert.alertLevel-notice h4 {
    color: #339a99; }

#site .alert.alertLevel-success {
  border-color: #333535; }
  #site .alert.alertLevel-success.alertImage-background .alertInner {
    background-color: rgba(90, 201, 148, 0.8); }
  #site .alert.alertLevel-success .alertInner {
    background-color: #5ac994; }
  #site .alert.alertLevel-success .dismissAlert {
    background-color: #333535; }

#site .alert.alertLevel-warning {
  color: #333535;
  border-color: #333535; }
  #site .alert.alertLevel-warning.alertImage-background .alertInner {
    background-color: rgba(221, 169, 70, 0.8); }
  #site .alert.alertLevel-warning .alertInner {
    background-color: #dda946; }
  #site .alert.alertLevel-warning .dismissAlert {
    background-color: #333535; }
  #site .alert.alertLevel-warning h4 {
    color: #333535; }
  #site .alert.alertLevel-warning .wrapButton.buttonStyle-outline .button {
    border-color: #333535;
    color: #333535; }
    #site .alert.alertLevel-warning .wrapButton.buttonStyle-outline .button:hover, #site .alert.alertLevel-warning .wrapButton.buttonStyle-outline .button:active {
      color: #fff;
      background-color: #333535;
      border-color: transparent;
      outline-color: #333535; }
  #site .alert.alertLevel-warning .wrapButton.buttonStyle-link .button {
    color: #333535; }
  #site .alert.alertLevel-warning .button {
    color: #fff;
    background-color: #333535;
    border-color: #333535; }
    #site .alert.alertLevel-warning .button:hover, #site .alert.alertLevel-warning .button:active {
      outline-color: #333535;
      border-color: transparent; }

#site .alert.alertLevel-error {
  border-color: #333535; }
  #site .alert.alertLevel-error.alertImage-background .alertInner {
    background-color: rgba(154, 0, 0, 0.8); }
  #site .alert.alertLevel-error .alertInner {
    background-color: #9a0000; }
  #site .alert.alertLevel-error .dismissAlert {
    background-color: #333535; }

/*** Styles de la liste des billets de blogue/posts/articles (archive) **************************/
.blogposts .blogpost:not(:last-of-type) {
  margin-bottom: 2em; }

.blogposts .blogpost.postCategory-269 .content h4 {
  color: #339a99; }
  .blogposts .blogpost.postCategory-269 .content h4 a, .blogposts .blogpost.postCategory-269 .content h4 a:link, .blogposts .blogpost.postCategory-269 .content h4 a:visited {
    color: #339a99; }
  .blogposts .blogpost.postCategory-269 .content h4 a:hover, .blogposts .blogpost.postCategory-269 .content h4 a:active {
    color: #206160; }

.blogposts .blogpost.postCategory-269 .content .entryContent .excerpt-read-more, .blogposts .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:link, .blogposts .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:visited, .blogposts .blogpost.postCategory-269 .content .entry-content .excerpt-read-more, .blogposts .blogpost.postCategory-269 .content .entry-content .excerpt-read-more:link, .blogposts .blogpost.postCategory-269 .content .entry-content .excerpt-read-more:visited {
  color: #339a99; }

.blogposts .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:hover, .blogposts .blogpost.postCategory-269 .content .entryContent .excerpt-read-more:active, .blogposts .blogpost.postCategory-269 .content .entry-content .excerpt-read-more:hover, .blogposts .blogpost.postCategory-269 .content .entry-content .excerpt-read-more:active {
  color: #206160; }

.blogposts .blogpost.postCategory-270 .content h4 {
  color: #6a5ac9; }
  .blogposts .blogpost.postCategory-270 .content h4 a, .blogposts .blogpost.postCategory-270 .content h4 a:link, .blogposts .blogpost.postCategory-270 .content h4 a:visited {
    color: #6a5ac9; }
  .blogposts .blogpost.postCategory-270 .content h4 a:hover, .blogposts .blogpost.postCategory-270 .content h4 a:active {
    color: #4535a2; }

.blogposts .blogpost.postCategory-270 .content .entryContent .excerpt-read-more, .blogposts .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:link, .blogposts .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:visited, .blogposts .blogpost.postCategory-270 .content .entry-content .excerpt-read-more, .blogposts .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:link, .blogposts .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:visited {
  color: #6a5ac9; }

.blogposts .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:hover, .blogposts .blogpost.postCategory-270 .content .entryContent .excerpt-read-more:active, .blogposts .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:hover, .blogposts .blogpost.postCategory-270 .content .entry-content .excerpt-read-more:active {
  color: #4535a2; }

.blogposts .blogpost .image {
  flex-grow: 1; }
  .blogposts .blogpost .image a {
    display: block; }
  .blogposts .blogpost .image img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 275px; }

.blogposts .blogpost .content {
  flex-shrink: 99;
  flex-grow: 1; }
  .blogposts .blogpost .content > header {
    margin-top: 0.6em; }
    .blogposts .blogpost .content > header .postCategories {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.6em;
      margin-bottom: 0; }
      .blogposts .blogpost .content > header .postCategories li:not(:last-of-type) {
        margin-right: 0.4em;
        margin-bottom: 0.2em; }
      .blogposts .blogpost .content > header .postCategories li.category-269 a, .blogposts .blogpost .content > header .postCategories li.category-269 a:link, .blogposts .blogpost .content > header .postCategories li.category-269 a:visited {
        background-color: #339a99; }
      .blogposts .blogpost .content > header .postCategories li.category-269 a:hover, .blogposts .blogpost .content > header .postCategories li.category-269 a:active {
        background-color: #206160; }
      .blogposts .blogpost .content > header .postCategories li.category-270 a, .blogposts .blogpost .content > header .postCategories li.category-270 a:link, .blogposts .blogpost .content > header .postCategories li.category-270 a:visited {
        background-color: #6a5ac9; }
      .blogposts .blogpost .content > header .postCategories li.category-270 a:hover, .blogposts .blogpost .content > header .postCategories li.category-270 a:active {
        background-color: #4535a2; }
      .blogposts .blogpost .content > header .postCategories li a {
        position: relative;
        bottom: 0.2em;
        display: block;
        padding: 0.2em 0.6em;
        text-decoration: none;
        transition: background-color 0.2s ease; }
        .blogposts .blogpost .content > header .postCategories li a, .blogposts .blogpost .content > header .postCategories li a:link, .blogposts .blogpost .content > header .postCategories li a:visited {
          font-weight: 700;
          background-color: #4b4b4b;
          color: #fff; }
        .blogposts .blogpost .content > header .postCategories li a:hover, .blogposts .blogpost .content > header .postCategories li a:active {
          background-color: #333535; }
        .blogposts .blogpost .content > header .postCategories li a span {
          font-size: 0.8em; }
    .blogposts .blogpost .content > header time {
      margin-right: 0.8em;
      font-size: 1.1em;
      font-weight: 800;
      font-style: italic;
      color: #4b4b4b; }
    .blogposts .blogpost .content > header h4 {
      margin-bottom: 0.2em;
      font-size: 1.7em;
      font-weight: 700;
      font-style: italic;
      color: #4b4b4b; }
      .blogposts .blogpost .content > header h4 a {
        text-decoration: none;
        transition: color 0.2s ease; }
        .blogposts .blogpost .content > header h4 a, .blogposts .blogpost .content > header h4 a:link, .blogposts .blogpost .content > header h4 a:visited {
          color: #4b4b4b; }
        .blogposts .blogpost .content > header h4 a:hover, .blogposts .blogpost .content > header h4 a:active {
          color: #333535; }
  .blogposts .blogpost .content .entryContent, .blogposts .blogpost .content .entry-content {
    font-size: 0.9em; }
    .blogposts .blogpost .content .entryContent .excerpt-read-more, .blogposts .blogpost .content .entry-content .excerpt-read-more {
      text-decoration: none; }
      .blogposts .blogpost .content .entryContent .excerpt-read-more, .blogposts .blogpost .content .entryContent .excerpt-read-more:link, .blogposts .blogpost .content .entryContent .excerpt-read-more:visited, .blogposts .blogpost .content .entry-content .excerpt-read-more, .blogposts .blogpost .content .entry-content .excerpt-read-more:link, .blogposts .blogpost .content .entry-content .excerpt-read-more:visited {
        color: #4b4b4b; }
      .blogposts .blogpost .content .entryContent .excerpt-read-more:hover, .blogposts .blogpost .content .entryContent .excerpt-read-more:active, .blogposts .blogpost .content .entry-content .excerpt-read-more:hover, .blogposts .blogpost .content .entry-content .excerpt-read-more:active {
        text-decoration: underline;
        color: #333535; }

.blogControls {
  margin-bottom: 2em; }
  .blogControls > div:not(:last-of-type) {
    margin-bottom: 1em; }
  .blogControls .linkList li {
    display: inline-block;
    font-size: 0.9em; }

/*** Styles du single (un seul article de blogue) *****************************************/
#main .blogSingle .singleHeader {
  margin-bottom: 2em; }
  #main .blogSingle .singleHeader::after {
    content: '';
    display: block;
    margin-top: 2em;
    width: 100%;
    max-width: 160px;
    border-bottom: 2px solid #339a99; }
  #main .blogSingle .singleHeader .innerHeader {
    display: flex;
    flex-wrap: wrap; }
    #main .blogSingle .singleHeader .innerHeader > div {
      flex-basis: 100%;
      max-width: 100%; }
  #main .blogSingle .singleHeader .postInfo {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1em; }
    #main .blogSingle .singleHeader .postInfo time {
      display: flex;
      align-items: baseline;
      margin-right: 1em;
      font-style: italic;
      color: #4b4b4b; }
      #main .blogSingle .singleHeader .postInfo time .date {
        font-size: 1.1em;
        margin-right: 0.5em; }
    #main .blogSingle .singleHeader .postInfo .comments, #main .blogSingle .singleHeader .postInfo .comments a {
      display: inline-flex;
      align-items: center; }
    #main .blogSingle .singleHeader .postInfo .comments a {
      text-decoration: none; }
      #main .blogSingle .singleHeader .postInfo .comments a, #main .blogSingle .singleHeader .postInfo .comments a:link, #main .blogSingle .singleHeader .postInfo .comments a:visited {
        color: #333535; }
      #main .blogSingle .singleHeader .postInfo .comments a:hover .icon, #main .blogSingle .singleHeader .postInfo .comments a:active .icon {
        filter: none; }
      #main .blogSingle .singleHeader .postInfo .comments a:hover .number, #main .blogSingle .singleHeader .postInfo .comments a:active .number {
        color: #339a99; }
    #main .blogSingle .singleHeader .postInfo .comments .icon {
      display: inline-block;
      width: 21px;
      height: 21px;
      margin-right: 0.2em;
      background-image: url("/images/sprite_icon.png");
      background-repeat: no-repeat;
      background-position: 0 -3070px;
      text-indent: -99999px;
      overflow: hidden;
      filter: grayscale(100%) brightness(140%);
      transition: filter 0.2s ease; }
    #main .blogSingle .singleHeader .postInfo .comments .number {
      color: #aaaaaa;
      transition: color 0.2s ease; }
  #main .blogSingle .singleHeader .share > .button {
    padding: 0.5em;
    border-radius: 0.25em; }
    #main .blogSingle .singleHeader .share > .button .buttonInner {
      display: flex;
      align-items: center; }
    #main .blogSingle .singleHeader .share > .button .icon {
      display: inline-block;
      width: 21px;
      height: 21px;
      margin-right: 0.5em;
      background-image: url("/images/sprite_icon.png");
      background-repeat: no-repeat;
      background-position: 0 -3120px; }
    #main .blogSingle .singleHeader .share > .button .label {
      font-weight: 400;
      text-transform: none;
      color: #fff; }

#main .blogSingle .singleFooter {
  margin-top: 2em;
  margin-bottom: 2em; }
  #main .blogSingle .singleFooter::after {
    content: '';
    display: block;
    margin-top: 2em;
    width: 100%;
    max-width: 67px;
    border-bottom: 2px solid #339a99; }

#main .blogSingle .singleComments > header {
  margin-bottom: 2em;
  /*
                        &::after {
                            content: '';
                            display: block;
                            margin-top: 1em;
                            width: 100%;
                            max-width: 220px;
                            border-bottom: 2px solid darken($lightgray, 10%);
                        }
             */ }
  #main .blogSingle .singleComments > header .label {
    display: inline; }

#main .blogSingle .singleComments .newComment, #main .blogSingle .singleComments .replyToComment {
  margin-bottom: 2em; }
  #main .blogSingle .singleComments .newComment form, #main .blogSingle .singleComments .replyToComment form {
    display: flex; }
    #main .blogSingle .singleComments .newComment form .avatar, #main .blogSingle .singleComments .replyToComment form .avatar {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 0.5em; }
      #main .blogSingle .singleComments .newComment form .avatar img, #main .blogSingle .singleComments .replyToComment form .avatar img {
        width: 40px;
        height: auto;
        border-radius: 100%;
        border: 3px solid #339a99; }
    #main .blogSingle .singleComments .newComment form .theForm, #main .blogSingle .singleComments .newComment form .notLoggedIn, #main .blogSingle .singleComments .replyToComment form .theForm, #main .blogSingle .singleComments .replyToComment form .notLoggedIn {
      flex-grow: 1; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn p, #main .blogSingle .singleComments .newComment form .theForm p, #main .blogSingle .singleComments .replyToComment form .notLoggedIn p, #main .blogSingle .singleComments .replyToComment form .theForm p {
      margin-bottom: 0.4em;
      font-size: 1.05em;
      color: #656969; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn footer, #main .blogSingle .singleComments .newComment form .theForm footer, #main .blogSingle .singleComments .replyToComment form .notLoggedIn footer, #main .blogSingle .singleComments .replyToComment form .theForm footer {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a, #main .blogSingle .singleComments .newComment form .theForm .replyTo a, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: color 0.2s ease; }
      #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a, #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:link, #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:visited, #main .blogSingle .singleComments .newComment form .theForm .replyTo a, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:link, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:visited, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:link, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:visited, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:link, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:visited {
        color: #4c4f4f; }
      #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:hover, #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:active, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:hover, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:active, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:hover, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:active, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:hover, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:active {
        color: #333535; }
        #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:hover .icon, #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:active .icon, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:hover .icon, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:active .icon, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:hover .icon, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:active .icon, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:hover .icon, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:active .icon {
          filter: brightness(90%); }
        #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:hover .username, #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo a:active .username, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:hover .username, #main .blogSingle .singleComments .newComment form .theForm .replyTo a:active .username, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:hover .username, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo a:active .username, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:hover .username, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo a:active .username {
          color: #206160; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo .icon, #main .blogSingle .singleComments .newComment form .theForm .replyTo .icon, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo .icon, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo .icon {
      display: inline-block;
      width: 20px;
      height: 18px;
      margin-right: 0.5em;
      background-image: url("/images/sprite_icon.png");
      background-repeat: no-repeat;
      background-position: 0 -3170px;
      transition: filter 0.2s ease; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo .label, #main .blogSingle .singleComments .newComment form .theForm .replyTo .label, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo .label, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo .label {
      word-break: break-all;
      color: inherit;
      font-weight: 400; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn .replyTo .username, #main .blogSingle .singleComments .newComment form .theForm .replyTo .username, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .replyTo .username, #main .blogSingle .singleComments .replyToComment form .theForm .replyTo .username {
      font-weight: 700;
      color: #339a99;
      transition: color 0.2s ease; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn .buttons, #main .blogSingle .singleComments .newComment form .theForm .buttons, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .buttons, #main .blogSingle .singleComments .replyToComment form .theForm .buttons {
      margin: 0;
      padding: 0;
      border: 0;
      text-align: left; }
    #main .blogSingle .singleComments .newComment form .notLoggedIn button, #main .blogSingle .singleComments .newComment form .notLoggedIn .button, #main .blogSingle .singleComments .newComment form .theForm button, #main .blogSingle .singleComments .newComment form .theForm .button, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button, #main .blogSingle .singleComments .replyToComment form .theForm button, #main .blogSingle .singleComments .replyToComment form .theForm .button {
      padding: 0.3em 0.8em;
      font-size: 0.9em;
      font-weight: 400;
      text-transform: none;
      border-radius: 0.25em;
      border: 2px solid #339a99;
      transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease; }
      #main .blogSingle .singleComments .newComment form .notLoggedIn button:hover, #main .blogSingle .singleComments .newComment form .notLoggedIn button:active, #main .blogSingle .singleComments .newComment form .notLoggedIn .button:hover, #main .blogSingle .singleComments .newComment form .notLoggedIn .button:active, #main .blogSingle .singleComments .newComment form .theForm button:hover, #main .blogSingle .singleComments .newComment form .theForm button:active, #main .blogSingle .singleComments .newComment form .theForm .button:hover, #main .blogSingle .singleComments .newComment form .theForm .button:active, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button:hover, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button:active, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button:hover, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button:active, #main .blogSingle .singleComments .replyToComment form .theForm button:hover, #main .blogSingle .singleComments .replyToComment form .theForm button:active, #main .blogSingle .singleComments .replyToComment form .theForm .button:hover, #main .blogSingle .singleComments .replyToComment form .theForm .button:active {
        border-color: #206160; }
      #main .blogSingle .singleComments .newComment form .notLoggedIn button:not(:first-child), #main .blogSingle .singleComments .newComment form .notLoggedIn .button:not(:first-child), #main .blogSingle .singleComments .newComment form .theForm button:not(:first-child), #main .blogSingle .singleComments .newComment form .theForm .button:not(:first-child), #main .blogSingle .singleComments .replyToComment form .notLoggedIn button:not(:first-child), #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button:not(:first-child), #main .blogSingle .singleComments .replyToComment form .theForm button:not(:first-child), #main .blogSingle .singleComments .replyToComment form .theForm .button:not(:first-child) {
        margin-left: 0; }
      #main .blogSingle .singleComments .newComment form .notLoggedIn button:not(:last-child), #main .blogSingle .singleComments .newComment form .notLoggedIn .button:not(:last-child), #main .blogSingle .singleComments .newComment form .theForm button:not(:last-child), #main .blogSingle .singleComments .newComment form .theForm .button:not(:last-child), #main .blogSingle .singleComments .replyToComment form .notLoggedIn button:not(:last-child), #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button:not(:last-child), #main .blogSingle .singleComments .replyToComment form .theForm button:not(:last-child), #main .blogSingle .singleComments .replyToComment form .theForm .button:not(:last-child) {
        margin-right: 1em;
        margin-bottom: 0.5em; }
      #main .blogSingle .singleComments .newComment form .notLoggedIn button.buttonLink, #main .blogSingle .singleComments .newComment form .notLoggedIn .button.buttonLink, #main .blogSingle .singleComments .newComment form .theForm button.buttonLink, #main .blogSingle .singleComments .newComment form .theForm .button.buttonLink, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button.buttonLink, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button.buttonLink, #main .blogSingle .singleComments .replyToComment form .theForm button.buttonLink, #main .blogSingle .singleComments .replyToComment form .theForm .button.buttonLink {
        border-color: transparent; }
        #main .blogSingle .singleComments .newComment form .notLoggedIn button.buttonLink:hover, #main .blogSingle .singleComments .newComment form .notLoggedIn button.buttonLink:active, #main .blogSingle .singleComments .newComment form .notLoggedIn .button.buttonLink:hover, #main .blogSingle .singleComments .newComment form .notLoggedIn .button.buttonLink:active, #main .blogSingle .singleComments .newComment form .theForm button.buttonLink:hover, #main .blogSingle .singleComments .newComment form .theForm button.buttonLink:active, #main .blogSingle .singleComments .newComment form .theForm .button.buttonLink:hover, #main .blogSingle .singleComments .newComment form .theForm .button.buttonLink:active, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button.buttonLink:hover, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button.buttonLink:active, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button.buttonLink:hover, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button.buttonLink:active, #main .blogSingle .singleComments .replyToComment form .theForm button.buttonLink:hover, #main .blogSingle .singleComments .replyToComment form .theForm button.buttonLink:active, #main .blogSingle .singleComments .replyToComment form .theForm .button.buttonLink:hover, #main .blogSingle .singleComments .replyToComment form .theForm .button.buttonLink:active {
          border-color: transparent; }
      #main .blogSingle .singleComments .newComment form .notLoggedIn button.register, #main .blogSingle .singleComments .newComment form .notLoggedIn .button.register, #main .blogSingle .singleComments .newComment form .theForm button.register, #main .blogSingle .singleComments .newComment form .theForm .button.register, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button.register, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button.register, #main .blogSingle .singleComments .replyToComment form .theForm button.register, #main .blogSingle .singleComments .replyToComment form .theForm .button.register {
        color: #339a99;
        background-color: transparent; }
        #main .blogSingle .singleComments .newComment form .notLoggedIn button.register:hover, #main .blogSingle .singleComments .newComment form .notLoggedIn button.register:active, #main .blogSingle .singleComments .newComment form .notLoggedIn .button.register:hover, #main .blogSingle .singleComments .newComment form .notLoggedIn .button.register:active, #main .blogSingle .singleComments .newComment form .theForm button.register:hover, #main .blogSingle .singleComments .newComment form .theForm button.register:active, #main .blogSingle .singleComments .newComment form .theForm .button.register:hover, #main .blogSingle .singleComments .newComment form .theForm .button.register:active, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button.register:hover, #main .blogSingle .singleComments .replyToComment form .notLoggedIn button.register:active, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button.register:hover, #main .blogSingle .singleComments .replyToComment form .notLoggedIn .button.register:active, #main .blogSingle .singleComments .replyToComment form .theForm button.register:hover, #main .blogSingle .singleComments .replyToComment form .theForm button.register:active, #main .blogSingle .singleComments .replyToComment form .theForm .button.register:hover, #main .blogSingle .singleComments .replyToComment form .theForm .button.register:active {
          border-color: #206160;
          color: #206160; }
    #main .blogSingle .singleComments .newComment form .theForm textarea, #main .blogSingle .singleComments .replyToComment form .theForm textarea {
      min-height: 3em;
      width: 100%;
      resize: vertical; }
    #main .blogSingle .singleComments .newComment form .theForm .buttons, #main .blogSingle .singleComments .replyToComment form .theForm .buttons {
      margin-top: 0.5em;
      text-align: right; }
      #main .blogSingle .singleComments .newComment form .theForm .buttons button, #main .blogSingle .singleComments .newComment form .theForm .buttons .button, #main .blogSingle .singleComments .replyToComment form .theForm .buttons button, #main .blogSingle .singleComments .replyToComment form .theForm .buttons .button {
        font-size: 1em; }

#main .blogSingle .singleComments .commentList .comment {
  margin-bottom: 2em; }
  #main .blogSingle .singleComments .commentList .comment.child {
    margin-left: 1em; }
  #main .blogSingle .singleComments .commentList .comment .commentInner {
    display: flex; }
  #main .blogSingle .singleComments .commentList .comment .avatar {
    flex-shrink: 0;
    margin-right: 1em; }
    #main .blogSingle .singleComments .commentList .comment .avatar img {
      display: block;
      width: 40px;
      height: auto;
      border-radius: 100%;
      border: 3px solid #339a99; }
  #main .blogSingle .singleComments .commentList .comment .theComment {
    flex-grow: 99; }
    #main .blogSingle .singleComments .commentList .comment .theComment header {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      #main .blogSingle .singleComments .commentList .comment .theComment header .isReplyTo {
        display: inline-block;
        margin-right: 0.5em; }
        #main .blogSingle .singleComments .commentList .comment .theComment header .isReplyTo a {
          display: flex;
          align-items: center;
          text-decoration: none; }
          #main .blogSingle .singleComments .commentList .comment .theComment header .isReplyTo a:hover .icon, #main .blogSingle .singleComments .commentList .comment .theComment header .isReplyTo a:active .icon {
            filter: brightness(90%); }
        #main .blogSingle .singleComments .commentList .comment .theComment header .isReplyTo .icon {
          display: inline-block;
          width: 20px;
          height: 18px;
          background-image: url("/images/sprite_icon.png");
          background-repeat: no-repeat;
          background-position: 0 -3170px;
          text-indent: -99999px;
          transition: filter 0.2s ease; }
      #main .blogSingle .singleComments .commentList .comment .theComment header .authorName {
        margin-right: 0.3em;
        color: #339a99;
        font-weight: 700; }
      #main .blogSingle .singleComments .commentList .comment .theComment header .separator, #main .blogSingle .singleComments .commentList .comment .theComment header time {
        color: #9f9f9f; }
      #main .blogSingle .singleComments .commentList .comment .theComment header time .time {
        font-size: 0.9em; }
      #main .blogSingle .singleComments .commentList .comment .theComment header .separator {
        display: none;
        margin-right: 0.3em; }
    #main .blogSingle .singleComments .commentList .comment .theComment .commentBody {
      margin-bottom: 0.2em; }
    #main .blogSingle .singleComments .commentList .comment .theComment footer .links {
      display: flex;
      align-items: baseline; }
      #main .blogSingle .singleComments .commentList .comment .theComment footer .links::before {
        content: '';
        display: inline-block;
        align-self: center;
        width: 1.6em;
        margin-right: 0.4em;
        border-bottom: 2px solid #b8b8b8; }
      #main .blogSingle .singleComments .commentList .comment .theComment footer .links a {
        font-size: 0.9em;
        font-style: italic;
        text-decoration: none;
        transition: color 0.2s ease; }
        #main .blogSingle .singleComments .commentList .comment .theComment footer .links a, #main .blogSingle .singleComments .commentList .comment .theComment footer .links a:link, #main .blogSingle .singleComments .commentList .comment .theComment footer .links a:visited {
          color: #9f9f9f; }
        #main .blogSingle .singleComments .commentList .comment .theComment footer .links a:hover, #main .blogSingle .singleComments .commentList .comment .theComment footer .links a:active {
          color: #333535; }
        #main .blogSingle .singleComments .commentList .comment .theComment footer .links a:not(:last-child) {
          margin-right: 0.5em; }
  #main .blogSingle .singleComments .commentList .comment .replyContainer {
    display: none;
    opacity: 0;
    margin-left: 1em;
    margin-top: 1em;
    transition: opacity 0.2s ease; }
    #main .blogSingle .singleComments .commentList .comment .replyContainer.appearing {
      opacity: 1; }
    #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm footer {
      flex-wrap: wrap; }
      #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm footer .replyTo {
        flex-basis: 100%;
        max-width: 100%;
        flex-grow: 1;
        margin-top: 0.4em; }
    #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm .buttons .button, #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm .buttons button {
      font-size: 0.9em; }
      #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm .buttons .button:not(:last-child), #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm .buttons button:not(:last-child) {
        margin-right: 0.5em; }

#main .blogSingle .singleComments .commentList .tmpReplyContainer {
  display: none; }

.search.form form,
#main.hasSidebar .innerMain > aside .sidebarInner > .search form {
  display: flex; }
  .search.form form input,
  #main.hasSidebar .innerMain > aside .sidebarInner > .search form input {
    margin-right: 0.5em; }
  .search.form form button,
  #main.hasSidebar .innerMain > aside .sidebarInner > .search form button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.2em 0.4em;
    background-color: transparent;
    border-radius: 0; }
    .search.form form button:hover .icon:not(.hover), .search.form form button:active .icon:not(.hover),
    #main.hasSidebar .innerMain > aside .sidebarInner > .search form button:hover .icon:not(.hover),
    #main.hasSidebar .innerMain > aside .sidebarInner > .search form button:active .icon:not(.hover) {
      opacity: 0; }
    .search.form form button:hover .icon.hover, .search.form form button:active .icon.hover,
    #main.hasSidebar .innerMain > aside .sidebarInner > .search form button:hover .icon.hover,
    #main.hasSidebar .innerMain > aside .sidebarInner > .search form button:active .icon.hover {
      opacity: 1; }
    .search.form form button .icon,
    #main.hasSidebar .innerMain > aside .sidebarInner > .search form button .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      text-indent: -99999px;
      background-image: url("/images/sprite_icon.png");
      background-repeat: no-repeat;
      background-position: 0 -2500px;
      transition: opacity 0.2s linear; }
      .search.form form button .icon.hover,
      #main.hasSidebar .innerMain > aside .sidebarInner > .search form button .icon.hover {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        background-position: 0 -2550px;
        opacity: 0; }

.linkList.categories li.active.category-269 a {
  background-color: transparent; }
  .linkList.categories li.active.category-269 a, .linkList.categories li.active.category-269 a:link, .linkList.categories li.active.category-269 a:visited {
    color: #339a99; }
  .linkList.categories li.active.category-269 a:hover, .linkList.categories li.active.category-269 a:active {
    background-color: transparent;
    color: #206160;
    border-color: #206160; }

.linkList.categories li.active.category-270 a {
  background-color: transparent; }
  .linkList.categories li.active.category-270 a, .linkList.categories li.active.category-270 a:link, .linkList.categories li.active.category-270 a:visited {
    color: #6a5ac9; }
  .linkList.categories li.active.category-270 a:hover, .linkList.categories li.active.category-270 a:active {
    background-color: transparent;
    color: #4535a2;
    border-color: #4535a2; }

.linkList.categories li.active a {
  background-color: transparent; }
  .linkList.categories li.active a, .linkList.categories li.active a:link, .linkList.categories li.active a:visited {
    color: #4b4b4b; }
  .linkList.categories li.active a:hover, .linkList.categories li.active a:active {
    background-color: transparent;
    color: #333535;
    border-color: #333535; }

.linkList.categories li.category-269 a {
  background-color: #339a99;
  border-color: #339a99; }
  .linkList.categories li.category-269 a:hover, .linkList.categories li.category-269 a:active {
    border-color: #206160;
    background-color: #206160; }

.linkList.categories li.category-270 a {
  background-color: #6a5ac9;
  border-color: #6a5ac9; }
  .linkList.categories li.category-270 a:hover, .linkList.categories li.category-270 a:active {
    border-color: #4535a2;
    background-color: #4535a2; }

.linkList.categories li a {
  background-color: #4b4b4b;
  border-color: #4b4b4b; }
  .linkList.categories li a:hover, .linkList.categories li a:active {
    border-color: #333535;
    background-color: #333535; }

.linkList li:not(:last-of-type) {
  margin-bottom: 0.4em; }

.linkList a {
  display: inline-block;
  padding: 0.4em 1em;
  font-weight: 700;
  text-decoration: none;
  border: 3px solid #4b4b4b;
  background-color: #4b4b4b;
  transition: all 0.2s ease; }
  .linkList a, .linkList a:link, .linkList a:visited {
    color: #fff; }
  .linkList a:hover, .linkList a:active {
    background-color: #333535;
    border-color: #333535; }

.pagination ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  font-size: 0.9em; }
  .pagination ul > li {
    /* 			&.number.active {
                            a {
                                position: relative;
                                display: flex;
                                align-items: center;
                                height: 0;
                                width: 2.4em;
                                padding-top: 0;
                                //Ratio d'aspect 1:1
                                padding-bottom: 100%;
                                background-color: $turquoise;
                                border-radius: 100%;

                                &, &:link, &:visited {
                                    color: $white;
                                }

                                &:hover, &:active {
                                    color: $white;
                                    background-color: $darkturquoise;
                                }

                                span {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    font-size: 1.1em;
                                }
                            }
                        } */ }
    .pagination ul > li:not(:last-of-type) {
      margin-right: 0.5em; }
    .pagination ul > li.ellipsis span {
      position: relative;
      bottom: 0.15em;
      line-height: 0;
      font-size: 1.4em;
      letter-spacing: 0.04em;
      color: rgba(51, 53, 53, 0.3); }
    .pagination ul > li.button a {
      padding: 0 0.2em; }
    .pagination ul > li.first a, .pagination ul > li.last a {
      padding-top: 0.5em;
      padding-bottom: 0.5em; }
    .pagination ul > li.first {
      margin-right: 0.9em; }
      .pagination ul > li.first .icon {
        width: 20px;
        height: 13px;
        background-position: 0 -2920px; }
    .pagination ul > li.last {
      margin-left: 0.4em; }
      .pagination ul > li.last .icon {
        width: 20px;
        height: 13px;
        background-position: 0 -2820px; }
    .pagination ul > li.previous {
      margin-right: 2.1em; }
      .pagination ul > li.previous .icon {
        width: 28px;
        height: 28px;
        background-position: 0 -3020px; }
    .pagination ul > li.next {
      margin-left: 1.6em; }
      .pagination ul > li.next .icon {
        width: 28px;
        height: 28px;
        background-position: 0 -2770px; }
    .pagination ul > li.number a {
      min-width: 2.6em;
      padding-left: 0.6em;
      padding-right: 0.6em;
      text-align: center;
      background-color: rgba(51, 53, 53, 0.15);
      padding: 0.4em; }
      .pagination ul > li.number a, .pagination ul > li.number a:link, .pagination ul > li.number a:visited {
        color: #333535; }
      .pagination ul > li.number a:hover, .pagination ul > li.number a:active {
        color: #333535;
        background-color: rgba(51, 53, 53, 0.4); }
    .pagination ul > li.number.active a {
      background-color: #339a99; }
      .pagination ul > li.number.active a, .pagination ul > li.number.active a:link, .pagination ul > li.number.active a:visited {
        color: #fff; }
      .pagination ul > li.number.active a:hover, .pagination ul > li.number.active a:active {
        color: #fff;
        background-color: #206160; }
    .pagination ul > li a {
      position: relative;
      display: block;
      text-decoration: underline;
      transition: color 0.2s ease, background-color 0.2s ease; }
      .pagination ul > li a, .pagination ul > li a:link, .pagination ul > li a:visited {
        color: #333535; }
      .pagination ul > li a:hover, .pagination ul > li a:active {
        color: #339a99; }
        .pagination ul > li a:hover .icon, .pagination ul > li a:active .icon {
          filter: brightness(85%); }
    .pagination ul > li .icon {
      display: block;
      text-indent: -99999px;
      overflow: hidden;
      background-image: url("/images/sprite_icon.png");
      background-repeat: no-repeat;
      transition: filter 0.2s ease; }

.single .headerText .postCategories {
  font-size: 0.8em; }

.cvRegister {
  position: absolute;
  right: 10px;
  top: 10px;
  max-width: calc(100% - 198px); }

#home-search {
  border-radius: 10px;
  background-color: rgba(51, 154, 153, 0.8);
  width: 95%;
  margin: auto;
  padding: 1em; }
  #home-search button {
    background-color: rgba(106, 90, 201, 0.8); }
  #home-search label {
    color: #fff !important; }
  #home-search input, #home-search select {
    width: 100%; }
  #home-search ul {
    position: static !important; }

#home-search-simplified {
  border-radius: 10px;
  width: 28%;
  margin: auto;
  padding: 1em;
  text-align: center;
  z-index: 10; }
  #home-search-simplified button {
    background-color: rgba(106, 90, 201, 0.8); }
  #home-search-simplified label {
    color: #6a6a6a !important;
    font-weight: bold;
    font-size: 1.4rem; }
  #home-search-simplified input, #home-search-simplified select {
    width: 100%; }
  #home-search-simplified ul {
    position: static !important; }
  #home-search-simplified .animated-arrow {
    position: absolute;
    top: 90%;
    right: 37%;
    width: 100px;
    height: 80px;
    background-image: url("/images/arrow-homepage.svg");
    background-size: contain;
    background-repeat: no-repeat;
    animation: arrowAnimation 3000ms forwards;
    opacity: 0;
    transition: opacity 100ms ease; }

@keyframes arrowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

#searchfilters {
  min-width: 100px;
  max-width: 300px !important; }
  #searchfilters form#filter .filter-container {
    margin: auto; }

span.select2-selection__arrow {
  background-color: rgba(106, 90, 201, 0.8); }
  span.select2-selection__arrow b {
    border-color: white transparent transparent transparent !important; }

body #main .select2 input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]):not([type=button]) {
  border: 0; }

body #main .select2-container {
  width: 100% !important; }

.select2-selection__arrow {
  display: none !important; }

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border-color: #479a99; }

.select2-container .select2-selection--single {
  height: 36px;
  padding-top: 4px; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 400px; }

#triCityDiv > div > span {
  border: 0; }

#filter > div.formRow.filter-container > div:nth-child(1) > div > span {
  border: 0; }

#filter > div.formRow.filter-container > div:nth-child(2) > div > span {
  border: 0; }

#filter > div.formRow.filter-container > div:nth-child(3) > div > span {
  border: 0; }

#filter > div.formRow.filter-container > div:nth-child(4) > div > span {
  border: 0; }

#filter > div.formRow.filter-container > div:nth-child(5) > div > span {
  border: 0; }

/*** Importation du partial pour les librairies **************************************************/
.manager .jobOffers .jobOffer .jobInfo {
  -ms-flex-preferred-size: 65%;
  flex-basis: 65%;
  max-width: 65%; }

.manager .confirmTable {
  font-size: 0.75em;
  width: 100%; }
  .manager .confirmTable td, .manager .confirmTable th {
    padding: 5px 10px; }

.manager .filters {
  margin-bottom: 1em; }
  .manager .filters ul li {
    display: inline-block;
    margin-left: 0.5em; }
    .manager .filters ul li:first-child {
      margin-left: 0; }
  .manager .filters a {
    color: #339a99; }
  .manager .filters .active, .manager .filters a.active {
    font-weight: 700; }

.manager div.formRefuse {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 1.5em;
  border-radius: 10px;
  z-index: 99;
  box-shadow: 0 0 5px 5px rgba(51, 53, 53, 0.2); }
  .manager div.formRefuse .minimsg {
    font-size: 0.8em;
    font-style: italic;
    color: #9a0000; }

.manager div.formNote {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 1.5em;
  border-radius: 10px;
  z-index: 99;
  box-shadow: 0 0 5px 5px rgba(51, 53, 53, 0.2); }

.manager .addNote {
  text-decoration: none; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  background-color: #000;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
			 * Remove all paddings around the image on small screen
			 */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*** Importation du partial pour le responsive ***************************************************/
/******************************************
*              HEADER-WRAPPER             *
*******************************************/
@media (max-width: 399px) {
  #header-wrapper {
    flex-direction: column; }
    #header-wrapper #home-search-simplified {
      display: flex;
      justify-content: center;
      order: 2;
      min-width: 100%;
      margin: auto;
      padding-top: 5px;
      padding-bottom: 0px; }
      #header-wrapper #home-search-simplified form {
        display: flex;
        flex-direction: column;
        align-items: center; }
      #header-wrapper #home-search-simplified form label {
        display: block;
        min-width: 100px;
        font-size: initial; }
      #header-wrapper #home-search-simplified .animated-arrow {
        display: none; }
      #header-wrapper #home-search-simplified button {
        margin-top: 5px;
        width: 80%; }
      #header-wrapper #home-search-simplified #select-button {
        display: flex;
        flex-direction: column; }
        #header-wrapper #home-search-simplified #select-button .select2 {
          width: 100% !important; }
        #header-wrapper #home-search-simplified #select-button button {
          margin-top: 5px; }
    #header-wrapper #menus {
      order: 1; } }

@media (min-width: 400px) and (max-width: 599px) {
  #header-wrapper {
    flex-direction: column; }
    #header-wrapper #home-search-simplified {
      order: 2;
      min-width: 300px;
      margin: auto;
      padding-top: 5px;
      padding-bottom: 0px; }
      #header-wrapper #home-search-simplified form {
        display: flex;
        flex-direction: column;
        align-items: center; }
      #header-wrapper #home-search-simplified form label {
        display: block;
        min-width: 100px;
        font-size: initial; }
      #header-wrapper #home-search-simplified .animated-arrow {
        display: none; }
      #header-wrapper #home-search-simplified button {
        margin-top: 5px;
        width: 80%; }
    #header-wrapper #menus {
      order: 1; } }

@media (min-width: 600px) and (max-width: 749px) {
  #header-wrapper {
    flex-direction: column; }
    #header-wrapper #home-search-simplified {
      order: 2;
      width: 50%;
      margin: auto;
      padding-top: 5px;
      padding-bottom: 0px; }
      #header-wrapper #home-search-simplified form {
        display: flex;
        flex-direction: column;
        align-items: center; }
      #header-wrapper #home-search-simplified form label {
        display: block;
        min-width: 200px;
        font-size: initial; }
      #header-wrapper #home-search-simplified .animated-arrow {
        display: none; }
      #header-wrapper #home-search-simplified button {
        margin-top: 5px;
        width: 80%; }
    #header-wrapper #menus {
      order: 1; } }

@media (min-width: 750px) and (max-width: 1099px) {
  #home-search-simplified {
    order: 2;
    width: 50%;
    margin: -25px auto auto auto;
    padding-top: 5px;
    padding-bottom: 0px; }
    #home-search-simplified form label {
      font-size: initial; }
    #home-search-simplified .animated-arrow {
      display: none; } }

@media (min-width: 749px) and (max-width: 1218px) {
  #header-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    #header-wrapper #home-search-simplified form label {
      display: block;
      min-width: 200px;
      font-size: initial; }
    #header-wrapper #home-search-simplified .animated-arrow {
      display: none; }
    #header-wrapper #home-search-simplified #select-button {
      min-width: 300px; }
    #header-wrapper #menus {
      order: 1; } }

@media (min-width: 1219px) and (max-width: 1400px) {
  #header-wrapper.signed-in {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    #header-wrapper.signed-in #home-search-simplified {
      position: absolute;
      top: 30%;
      left: 43%; }
      #header-wrapper.signed-in #home-search-simplified form label {
        display: block;
        min-width: 200px; }
      #header-wrapper.signed-in #home-search-simplified #select-button {
        min-width: 300px; }
    #header-wrapper.signed-in #menus {
      order: 1; }
  #header-wrapper.not-signed-in {
    flex-direction: row;
    flex-wrap: wrap; }
    #header-wrapper.not-signed-in #home-search-simplified #select-button {
      min-width: 300px; }
    #header-wrapper.not-signed-in #home-search-simplified form {
      display: flex;
      flex-direction: column;
      align-items: center; }
    #header-wrapper.not-signed-in #home-search-simplified form label {
      font-size: initial; }
    #header-wrapper.not-signed-in #home-search-simplified button {
      width: 80%;
      margin-top: 5px; }
    #header-wrapper.not-signed-in #menus {
      order: 1; } }

@media (max-width: 600px) {
  footer#footer .left, footer#footer .right, #newsletter .left, #newsletter .right {
    float: none; }
  .emploiretraite-view-jobs-button {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 400px) {
  header#header #mainmenu > ul > li.parent ul {
    left: initial;
    right: 0; }
  header#header .top {
    top: 48px; }
  #main .buttons .buttonLink:last-of-type, .mfp-wrap .buttons .buttonLink:last-of-type {
    margin-left: 0.5em; }
  .fieldset > header .btnRollUnroll {
    padding: 0 1.2em; }
  button.btnWithIcon, .button.btnWithIcon, input.btnWithIcon {
    padding-left: 1em;
    padding-right: 1em; }
    button.btnWithIcon.gradientOutline .buttonInner, .button.btnWithIcon.gradientOutline .buttonInner, input.btnWithIcon.gradientOutline .buttonInner {
      padding-left: 1.6em;
      padding-right: 1.6em; }
  .home #main .typeuser {
    padding-bottom: 200px; }
  .register.step3 #main .sectors .formRow.sectorCheckbox span {
    font-size: 0.7em; }
  .account .mainContent #singleHeader .headerText {
    display: flex; } }

@media (min-width: 420px) {
  .home #howitwork h2 {
    font-size: 3em; } }

@media (min-width: 465px) {
  header#header .top {
    top: 66px;
    right: 44px; }
    header#header .top .lang ul li {
      display: inline-block; } }

@media (min-width: 500px) {
  header#header #logo {
    max-width: 70%; }
  header#header .top {
    bottom: 23px;
    right: 20px; }
  .account .jobOffers .jobOffer .articleInner-l2 {
    padding: 1em 0 1em 1.4em; }
  .account .jobOffers .jobOffer .cieLogo, .account .jobOffers .jobOffer .wrapButton .cieLogoSpace {
    flex-basis: 70px;
    max-width: 70px;
    margin-right: 1em; }
  .home #blog h2 {
    font-size: 4em; }
  div.formRow label.triggerLabel {
    max-width: none; }
  .register.step3 #main .sectors .formRow.sectorCheckbox {
    flex-basis: 41%;
    max-width: 41%; }
  #main .multiRow:not(.threeRows), .mfp-wrap .multiRow:not(.threeRows) {
    flex-wrap: nowrap; }
    #main .multiRow:not(.threeRows) > *, .mfp-wrap .multiRow:not(.threeRows) > * {
      flex-basis: 50%;
      max-width: 50%; }
      #main .multiRow:not(.threeRows) > *:not(:last-child), .mfp-wrap .multiRow:not(.threeRows) > *:not(:last-child) {
        margin-right: 0.8em; }
    #main .multiRow:not(.threeRows).fakeRowEnd > *, .mfp-wrap .multiRow:not(.threeRows).fakeRowEnd > * {
      margin-right: 0.8em; }
    #main .multiRow:not(.threeRows).fakeRowStart::before, .mfp-wrap .multiRow:not(.threeRows).fakeRowStart::before {
      margin-right: 0.8em; }
  #main .security, .mfp-wrap .security {
    flex-wrap: nowrap; }
    #main .security > *, .mfp-wrap .security > * {
      flex-basis: auto;
      max-width: 100%; }
    #main .security .padlock, .mfp-wrap .security .padlock {
      margin-bottom: 0;
      margin-right: 1em; }
  .fieldset div.formRow.checkbox {
    flex-basis: 11%;
    max-width: 11%; }
    .fieldset div.formRow.checkbox + header {
      flex-basis: 89%;
      max-width: 89%; }
  .pageTemplate-pricing div.smallipop-theme-emploiretraite {
    width: 800px;
    max-width: 90%; }
    .pageTemplate-pricing div.smallipop-theme-emploiretraite.smallipop-align-right, .pageTemplate-pricing div.smallipop-theme-emploiretraite.smallipop-align-left {
      width: auto;
      max-width: 400px; }
    .pageTemplate-pricing div.smallipop-theme-emploiretraite .smallipop-content {
      text-align: left; }
      .pageTemplate-pricing div.smallipop-theme-emploiretraite .smallipop-content h2, .pageTemplate-pricing div.smallipop-theme-emploiretraite .smallipop-content h3 {
        display: inline-block; }
      .pageTemplate-pricing div.smallipop-theme-emploiretraite .smallipop-content h2 {
        margin-right: 1em; }
  #main .blogSingle .singleHeader .innerHeader {
    flex-wrap: nowrap;
    justify-content: space-between; }
    #main .blogSingle .singleHeader .innerHeader > div {
      flex-basis: auto;
      max-width: 100%; }
  #main .blogSingle .singleComments .newComment form .avatar {
    margin-right: 1em; }
    #main .blogSingle .singleComments .newComment form .avatar img {
      width: 65px; }
  #main .blogSingle .singleComments .newComment form .notLoggedIn p, #main .blogSingle .singleComments .newComment form .theForm p {
    font-size: 1.2em; }
  #main .blogSingle .singleComments .newComment form .notLoggedIn .button, #main .blogSingle .singleComments .newComment form .notLoggedIn button, #main .blogSingle .singleComments .newComment form .theForm .button, #main .blogSingle .singleComments .newComment form .theForm button {
    font-size: 1em; }
  #main .blogSingle .singleComments .commentList .comment .avatar img {
    width: 65px; }
  #main .blogSingle .singleComments .commentList .comment .theComment header .separator {
    display: inline-block; }
  #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm footer {
    flex-wrap: nowrap; }
    #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm footer .replyTo {
      flex-basis: auto;
      max-width: 100%; }
      #main .blogSingle .singleComments .commentList .comment .replyContainer form .theForm footer .replyTo .label {
        word-break: normal; } }

@media (min-width: 550px) {
  header#header #mainmenu > ul > li.parent ul {
    right: initial;
    left: 0; }
  #home-search {
    width: 50%; }
  .entryContent table, .entry-content table, .formFooter table, label table, dd table, #main .entryContent table, #main .entry-content table {
    font-size: 0.9em; }
    .entryContent table tr th, .entry-content table tr th, .formFooter table tr th, label table tr th, dd table tr th, #main .entryContent table tr th, #main .entry-content table tr th {
      padding: 10px 20px; }
    .entryContent table tr td, .entry-content table tr td, .formFooter table tr td, label table tr td, dd table tr td, #main .entryContent table tr td, #main .entry-content table tr td {
      padding: 8px 20px; }
  #main .sectorCheckbox, #main .categoryCheckbox {
    flex-basis: 49%;
    max-width: 49%; }
    #main .sectorCheckbox:nth-of-type(2n), #main .categoryCheckbox:nth-of-type(2n) {
      margin-left: 2%; }
  .page #main > header.gradient h1 {
    font-size: 3em; }
  .page #main > header h1 {
    font-size: 2.4em; }
  #site .alert.buttonDisplay-vertical_right .alertInner, #site .alert.buttonDisplay-vertical_left .alertInner {
    flex-wrap: nowrap; }
  #site .alert.buttonDisplay-vertical_right .wrapContent, #site .alert.buttonDisplay-vertical_right .wrapButtons, #site .alert.buttonDisplay-vertical_left .wrapContent, #site .alert.buttonDisplay-vertical_left .wrapButtons {
    flex-basis: auto;
    max-width: 100%; }
  #site .alert.buttonDisplay-vertical_right .wrapButtons {
    margin-top: 0;
    margin-left: 0.6em; }
  #site .alert.buttonDisplay-vertical_left .wrapButtons {
    margin-bottom: 0;
    margin-right: 0.6em; }
  #site .alert.alertImage-left .wrapContent, #site .alert.alertImage-right .wrapContent {
    flex-wrap: nowrap;
    align-items: center; }
  #site .alert.alertImage-left .contentInner, #site .alert.alertImage-left .alertThumbnail, #site .alert.alertImage-right .contentInner, #site .alert.alertImage-right .alertThumbnail {
    flex-basis: auto;
    max-width: 100%; }
  #site .alert.alertImage-left .alertThumbnail img, #site .alert.alertImage-right .alertThumbnail img {
    width: auto;
    height: auto;
    max-width: 50px; }
  .home #main {
    background-size: contain; }
    .home #main .typeuser ul {
      justify-content: space-around;
      flex-wrap: wrap; }
      .home #main .typeuser ul .bubble {
        margin-top: 0.5em;
        max-width: 300px; }
        .home #main .typeuser ul .bubble::before {
          display: block; }
        .home #main .typeuser ul .bubble .content {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 100%; }
          .home #main .typeuser ul .bubble .content span.noa, .home #main .typeuser ul .bubble .content a {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0; }
            .home #main .typeuser ul .bubble .content span.noa .aContent, .home #main .typeuser ul .bubble .content a .aContent {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 100%;
              padding: 0 8%; }
          .home #main .typeuser ul .bubble .content .clickHere {
            bottom: 25px; }
          .home #main .typeuser ul .bubble .content .typeTitle {
            font-size: 1.2em; }
          .home #main .typeuser ul .bubble .content .typeSubtitle {
            font-size: 1.6em; }
  .register.step3 #main .sectors .formRow.sectorCheckbox {
    flex-basis: 41%;
    max-width: 41%; }
  .mfp-inline-holder .mfp-content .innerPopup-l2, .mfp-ajax-holder .mfp-content .innerPopup-l2 {
    padding: 2.5em 2.5em 0 2.5em; }
  .mfp-inline-holder .mfp-content #content, .mfp-ajax-holder .mfp-content #content {
    padding-bottom: 2.5em; }
  .fieldset > header {
    padding-left: 1.8em; }
  .fieldset .accordionContent {
    border-right-width: 1.8em;
    border-left-width: 1.8em; } }

@media (min-width: 601px) {
  .containForm #main > header #progressbar {
    width: 50%;
    position: relative;
    margin-left: auto;
    margin-right: auto; }
  .account .jobOffers .jobOffer .cieLogoSpace {
    display: none; }
  #main, .mfp-wrap {
    /* Style des fieldset */ }
    #main fieldset, .mfp-wrap fieldset {
      width: 100%;
      border: 2px solid #dedede;
      border-radius: 5px;
      padding: 15px 30px;
      margin-bottom: 1.5em; }
      #main fieldset legend, .mfp-wrap fieldset legend {
        font-size: 1.1em;
        font-weight: 700;
        color: #333535;
        padding: 0 10px;
        margin-left: -11px; }
    #main input[type=file], #main select, .mfp-wrap input[type=file], .mfp-wrap select {
      width: 100%; }
    #main textarea, .mfp-wrap textarea {
      width: 100%; }
    #main .leftCentered textarea, .mfp-wrap .leftCentered textarea {
      min-width: 100%; }
    #main textarea, .mfp-wrap textarea {
      min-width: 75%; }
    #main .twoCols .formContent, .mfp-wrap .twoCols .formContent {
      display: block; }
      #main .twoCols .formContent .rows, #main .twoCols .formContent fieldset, .mfp-wrap .twoCols .formContent .rows, .mfp-wrap .twoCols .formContent fieldset {
        display: block; }
        #main .twoCols .formContent .rows .formRowParent, #main .twoCols .formContent fieldset .formRowParent, .mfp-wrap .twoCols .formContent .rows .formRowParent, .mfp-wrap .twoCols .formContent fieldset .formRowParent {
          display: block;
          width: 49%;
          float: left; }
          #main .twoCols .formContent .rows .formRowParent:nth-of-type(2n-1), #main .twoCols .formContent fieldset .formRowParent:nth-of-type(2n-1), .mfp-wrap .twoCols .formContent .rows .formRowParent:nth-of-type(2n-1), .mfp-wrap .twoCols .formContent fieldset .formRowParent:nth-of-type(2n-1) {
            margin-right: 2%; }
    #main .multiRow.threeRows, .mfp-wrap .multiRow.threeRows {
      flex-wrap: nowrap; }
      #main .multiRow.threeRows > *, .mfp-wrap .multiRow.threeRows > * {
        flex-basis: 33.333%;
        max-width: 33.333%; }
        #main .multiRow.threeRows > *:not(:last-child), .mfp-wrap .multiRow.threeRows > *:not(:last-child) {
          margin-right: 0.8em; }
      #main .multiRow.threeRows > select:not(:last-child), #main .multiRow.threeRows > input:not(:last-child), .mfp-wrap .multiRow.threeRows > select:not(:last-child), .mfp-wrap .multiRow.threeRows > input:not(:last-child) {
        margin-bottom: 0; }
      #main .multiRow.threeRows .separator, .mfp-wrap .multiRow.threeRows .separator {
        display: block; }
    #main .checkboxes.manyCheckboxes, .mfp-wrap .checkboxes.manyCheckboxes {
      columns: 2; }
  .home #main .typeuser {
    padding-bottom: 130px; }
  .home #main .linkBar {
    flex-wrap: nowrap; }
    .home #main .linkBar .link {
      flex-basis: 50%;
      max-width: 50%;
      margin-bottom: 0; }
      .home #main .linkBar .link:first-of-type {
        padding-bottom: 0; }
        .home #main .linkBar .link:first-of-type a::after {
          left: auto;
          right: -1.5px;
          bottom: auto;
          top: 50%;
          width: 0;
          height: 80%;
          border-top: none;
          border-left: 3px solid #fff;
          transform: translateY(-50%); }
      .home #main .linkBar .link:not(:first-of-type) {
        margin-top: 0; }
      .home #main .linkBar .link:nth-of-type(2n) {
        justify-content: flex-start;
        padding-left: 10%; }
      .home #main .linkBar .link:nth-of-type(2n-1) {
        justify-content: flex-end;
        padding-right: 10%; }
  .home #videoBlock .videoDescription h2 {
    font-size: 2.667em; }
    .home #videoBlock .videoDescription h2 .mainTitle {
      font-size: 1.6em; }
  .home #howitwork .steps .step .title .no {
    font-size: 5.6em; }
  .register.step3 #main .sectors .formRow.sectorCheckbox {
    flex-basis: 37%;
    max-width: 37%; }
  div#newsletter > .inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    /* align vertical */ }
    div#newsletter > .inner .left, div#newsletter > .inner .right {
      float: none;
      flex-grow: 1;
      width: 46%; }
    div#newsletter > .inner .left {
      text-align: right;
      border-right: 2px solid #3db9b7;
      padding-right: calc( 4%);
      margin-right: 4%; }
  .account.listJobs .jobOffers .jobOffer .articleInner, .account.history .jobOffers .jobOffer .articleInner {
    flex-wrap: nowrap;
    align-items: flex-start; }
  .account.listJobs .jobOffers .jobOffer .articleInner-l2, .account.history .jobOffers .jobOffer .articleInner-l2 {
    flex-wrap: nowrap; }
  .account.listJobs .jobOffers .jobOffer .jobInfo, .account.history .jobOffers .jobOffer .jobInfo {
    flex-basis: auto;
    max-width: 100%;
    flex-grow: 1;
    padding-right: 1.4em; }
  .account.listJobs .jobOffers .jobOffer .cieLogo, .account.history .jobOffers .jobOffer .cieLogo {
    align-items: center; }
  .account.listJobs .jobOffers .jobOffer .wrapButton, .account.history .jobOffers .jobOffer .wrapButton {
    margin-top: 0;
    text-align: right; }
    .account.listJobs .jobOffers .jobOffer .wrapButton .wrapButton-inner, .account.history .jobOffers .jobOffer .wrapButton .wrapButton-inner {
      align-items: flex-start;
      justify-content: flex-end; }
    .account.listJobs .jobOffers .jobOffer .wrapButton .link, .account.history .jobOffers .jobOffer .wrapButton .link {
      margin-right: 0; }
    .account.listJobs .jobOffers .jobOffer .wrapButton .wrapIndicators, .account.history .jobOffers .jobOffer .wrapButton .wrapIndicators {
      margin-top: 0;
      flex-basis: 100%;
      max-width: 100%;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-end; }
  .account .mainContent #singleHeader {
    flex-wrap: nowrap; }
    .account .mainContent #singleHeader .headerText {
      flex-basis: auto;
      max-width: 65%; }
    .account .mainContent #singleHeader form, .account .mainContent #singleHeader .contactCandidates {
      margin-top: 0;
      padding-left: 1em; }
  #main .blogSingle .singleComments .commentList .comment.child {
    margin-left: 2em; }
  #main .blogSingle .singleComments .commentList .comment .replyContainer {
    margin-left: 2em; }
  footer#footer .right {
    margin-top: -70px; }
  footer#footer #legalmenu {
    margin-top: 5px; }
  #main .dashboardModules .module.threeCols .col {
    flex-basis: 50%;
    max-width: 100%; }
  #main .dashboardModules .module.offerList .buttons .wrapButton {
    display: inline-block;
    text-align: left; }
    #main .dashboardModules .module.offerList .buttons .wrapButton:not(:last-child) {
      margin-right: 0.5em; }
  #main .dashboardModules .module.largeButton .button .buttonContent {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    #main .dashboardModules .module.largeButton .button .buttonContent .wrapIcon {
      flex-basis: auto;
      max-width: 100%;
      margin-bottom: 0;
      margin-right: 1em; }
    #main .dashboardModules .module.largeButton .button .buttonContent .wrapText {
      text-align: left; } }

@media (min-width: 650px) {
  body.pricePlans #main .inner .planGroup .fullAdvantages {
    display: block; }
  body.pricePlans #main .inner .planGroup .buttonWrapper.free {
    display: block;
    visibility: hidden; }
  body.pricePlans #main .inner .planGroup .mobileAdvantages {
    display: none; }
  .home #main {
    background-size: cover;
    background-image: url("/images/bg_medium_2023.jpg"); }
  .register.step3 #main .sectors .formRow.sectorCheckbox {
    flex-basis: 35%;
    max-width: 35%; } }

@media (min-width: 700px) {
  .pageTemplate-linkHub #main .pageSection.hasBubble .mainText > h2 {
    text-align: left; }
  .page #main > header h1 {
    font-size: 3.4em; }
  #main .or, #main .formRow .or {
    max-width: 50%; }
  #main .security {
    max-width: 70%; }
  .home #main {
    padding-top: 135px; }
  .home #howitwork h2 {
    font-size: 5em; }
  .home #howitwork .steps .step:nth-child(odd) .image, .home #howitwork .steps .step:nth-child(odd) .content {
    float: left; }
  .home #howitwork .steps .step:nth-child(odd) .image {
    padding-right: 5%; }
  .home #howitwork .steps .step:nth-child(odd) .content {
    padding-right: 1em; }
    .home #howitwork .steps .step:nth-child(odd) .content .title .no {
      color: #206160; }
  .home #howitwork .steps .step:nth-child(even) .image, .home #howitwork .steps .step:nth-child(even) .content {
    float: right;
    text-align: right; }
  .home #howitwork .steps .step:nth-child(even) .image {
    padding-left: 5%;
    padding-right: 0; }
  .home #howitwork .steps .step:nth-child(even) .content {
    text-align: left; }
    .home #howitwork .steps .step:nth-child(even) .content .title .no {
      color: #339a99; }
  .home #howitwork .steps .step:nth-child(even) .content-inner {
    padding-left: 1em; }
  .home #howitwork .steps .step .content {
    width: 32%; }
  .home #howitwork .steps .step .image {
    width: 68%; }
  .home #infos ul.infos li {
    flex-basis: 46%;
    max-width: 46%; }
  .home #blog .image {
    margin-right: 1.5em; }
    .home #blog .image img {
      max-width: 275px; }
  .containForm #main > header .wrap {
    padding: 30px 20px; }
  .containForm #main > header h1 {
    font-size: 1.6em;
    letter-spacing: -0.06em;
    margin-top: 0.4em;
    margin-bottom: 0.2em; }
  .containForm #main > header h2  {
    font-size: 1.8em;
    font-weight: 700 !important; }
  .durationSelect.durationSelect tbody tr:hover, .durationSelect.durationSelect tbody tr:active, #main .durationSelect.durationSelect tbody tr:hover, #main .durationSelect.durationSelect tbody tr:active, .durationTable.durationSelect tbody tr:hover, .durationTable.durationSelect tbody tr:active {
    background-color: rgba(51, 53, 53, 0.1); }
    .durationSelect.durationSelect tbody tr:hover.checked, .durationSelect.durationSelect tbody tr:active.checked, #main .durationSelect.durationSelect tbody tr:hover.checked, #main .durationSelect.durationSelect tbody tr:active.checked, .durationTable.durationSelect tbody tr:hover.checked, .durationTable.durationSelect tbody tr:active.checked {
      background-color: #2d8786; }
  .durationSelect table, #main .durationSelect table, .durationTable table {
    display: table; }
    .durationSelect table thead, #main .durationSelect table thead, .durationTable table thead {
      display: table-header-group; }
    .durationSelect table tbody, #main .durationSelect table tbody, .durationTable table tbody {
      display: table-row-group; }
    .durationSelect table tfoot, #main .durationSelect table tfoot, .durationTable table tfoot {
      display: table-footer-group; }
    .durationSelect table tr, #main .durationSelect table tr, .durationTable table tr {
      display: table-row;
      margin: 0; }
    .durationSelect table tbody tr.checked, #main .durationSelect table tbody tr.checked, .durationTable table tbody tr.checked {
      background-color: #339a99;
      color: #fff; }
      .durationSelect table tbody tr.checked input[type="radio"] + label::before, #main .durationSelect table tbody tr.checked input[type="radio"] + label::before, .durationTable table tbody tr.checked input[type="radio"] + label::before {
        border-color: #fff;
        filter: brightness(1000%); }
      .durationSelect table tbody tr.checked .duration .subtitle, #main .durationSelect table tbody tr.checked .duration .subtitle, .durationTable table tbody tr.checked .duration .subtitle {
        color: #fff; }
      .durationSelect table tbody tr.checked .savings:not(.savings-0) .number, #main .durationSelect table tbody tr.checked .savings:not(.savings-0) .number, .durationTable table tbody tr.checked .savings:not(.savings-0) .number {
        color: #fff; }
    .durationSelect table td, .durationSelect table th, #main .durationSelect table td, #main .durationSelect table th, .durationTable table td, .durationTable table th {
      display: table-cell;
      padding: 0.5em; }
      .durationSelect table td:not(:last-child):not(.radio), .durationSelect table th:not(:last-child):not(.radio), #main .durationSelect table td:not(:last-child):not(.radio), #main .durationSelect table th:not(:last-child):not(.radio), .durationTable table td:not(:last-child):not(.radio), .durationTable table th:not(:last-child):not(.radio) {
        margin-bottom: 0; }
    .durationSelect table td.radio, #main .durationSelect table td.radio, .durationTable table td.radio {
      display: table-cell;
      position: relative;
      top: 0;
      padding: 0.5em; }
    .durationSelect table td:not(.radio), #main .durationSelect table td:not(.radio), .durationTable table td:not(.radio) {
      padding-left: 0.5em; }
    .durationSelect table .mobileTitle, #main .durationSelect table .mobileTitle, .durationTable table .mobileTitle {
      display: none; }
    .durationSelect table .duration .textLabel, #main .durationSelect table .duration .textLabel, .durationTable table .duration .textLabel {
      font-size: 1em; }
  .confirmTable thead tr td:not(:first-of-type), .confirmTable thead tr th:not(:first-of-type), .confirmTable tbody tr td:not(:first-of-type), .confirmTable tbody tr th:not(:first-of-type), .confirmTable tfoot tr td:not(:first-of-type), .confirmTable tfoot tr th:not(:first-of-type), #main .confirmTable thead tr td:not(:first-of-type), #main .confirmTable thead tr th:not(:first-of-type), #main .confirmTable tbody tr td:not(:first-of-type), #main .confirmTable tbody tr th:not(:first-of-type), #main .confirmTable tfoot tr td:not(:first-of-type), #main .confirmTable tfoot tr th:not(:first-of-type), .confirmTable thead tr td:not(:first-of-type), .confirmTable thead tr th:not(:first-of-type), .confirmTable tbody tr td:not(:first-of-type), .confirmTable tbody tr th:not(:first-of-type), .confirmTable tfoot tr td:not(:first-of-type), .confirmTable tfoot tr th:not(:first-of-type) {
    text-align: right; }
  .fieldset div.formRow.checkbox {
    flex-basis: 8%;
    max-width: 8%; }
    .fieldset div.formRow.checkbox + header {
      flex-basis: 92%;
      max-width: 92%; } }

@media (min-width: 750px) {
  header#header #logo {
    position: relative;
    top: 0.45em;
    float: left;
    margin-top: -6px; }
  header#header #menus {
    max-width: calc(100% - 367px);
    /* La grosseur du logo */
    float: right;
    margin-top: -6px;
    text-align: right; }
  header#header .top {
    position: relative;
    top: 0;
    left: 0; }
  header#header #topmenu {
    display: block;
    text-align: right; }
  header#header #mainmenu {
    text-align: right;
    padding-bottom: 1em; }
  #home-search {
    width: 30%; }
  .entryContent table, .entry-content table, .formFooter table, label table, dd table, #main .entryContent table, #main .entry-content table {
    font-size: 1em; }
  .account .tabs .tabContent form .formRowParent {
    float: left;
    width: 49%;
    flex-basis: auto;
    max-width: none; }
    .account .tabs .tabContent form .formRowParent:nth-of-type(2n-1) {
      margin-right: 2%; }
  .account #main .twoCols .rows .formRowParent {
    float: left;
    width: 49%;
    flex-basis: auto;
    max-width: none; }
    .account #main .twoCols .rows .formRowParent:nth-of-type(2n-1) {
      margin-right: 2%; }
  .home #main .typeuser {
    min-height: calc(100vh - 135px); }
    .home #main .typeuser ul {
      position: absolute;
      top: 0;
      bottom: 130px;
      left: 0;
      right: 0; }
      .home #main .typeuser ul .bubble {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        margin-top: 0; }
        .home #main .typeuser ul .bubble:first-of-type {
          left: 0; }
        .home #main .typeuser ul .bubble:last-of-type {
          right: 0; }
        .home #main .typeuser ul .bubble .content a::after {
          bottom: 30px; }
        .home #main .typeuser ul .bubble .content .typeTitle {
          font-size: 1em; }
        .home #main .typeuser ul .bubble .content .typeSubtitle {
          font-size: 1.3em; }
  .emploiretraite-view-jobs-button {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 70px;
    margin-top: 0; }
  .register.step3 #main .sectors .formRow.sectorCheckbox {
    flex-basis: 30%;
    max-width: 30%; }
  .step1 form div.formContent > .rows {
    width: 67%;
    float: left; }
  .step1 form .wrapBup {
    width: 33%;
    float: right; }
  .fieldset.pointList .accordionContent dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .fieldset.pointList .accordionContent dl > div {
      flex-basis: 45%;
      max-width: 45%; } }

@media (min-width: 820px) {
  header#header #mainmenu > ul > li.parent ul {
    left: initial;
    right: 0; } }

@media (min-width: 900px) {
  .pageTemplate-linkHub #main .pageSection.hasBubble .mainText > h2 {
    text-align: center; }
  #singleHeader .cieLogo {
    flex-basis: 100px; }
  .home #main .title {
    flex-wrap: nowrap; }
    .home #main .title > div {
      flex-basis: 100%;
      max-width: 100%; }
  .home #main .typeuser {
    padding-bottom: 76px; }
    .home #main .typeuser ul {
      justify-content: space-between;
      bottom: 76px; }
      .home #main .typeuser ul .bubble {
        flex-basis: 35%;
        max-width: 35%; }
        .home #main .typeuser ul .bubble .content .typeTitle {
          font-size: 1.2em; }
        .home #main .typeuser ul .bubble .content .typeSubtitle {
          font-size: 1.5em; }
  .home #main .linkBar .link:nth-of-type(2n) {
    padding-left: 15%; }
  .home #main .linkBar .link:nth-of-type(2n-1) {
    padding-right: 15%; }
  .home #blog h2 {
    font-size: 3.5em; }
  .home #blog .inner {
    display: flex; }
    .home #blog .inner > header {
      flex-basis: 30%;
      max-width: 30%;
      padding-right: 0.5em; }
    .home #blog .inner .blogposts {
      flex-basis: 70%;
      max-width: 70%; }
  .home #blog .image {
    display: inline-block; }
  body:not(.pageTemplate-pricing):not(.pricePlans) #main.hasSidebar .innerMain {
    flex-wrap: nowrap;
    justify-content: space-between; }
    body:not(.pageTemplate-pricing):not(.pricePlans) #main.hasSidebar .innerMain > *:not(aside) {
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding-right: 4%; }
      body:not(.pageTemplate-pricing):not(.pricePlans) #main.hasSidebar .innerMain > *:not(aside) .wrap {
        padding-right: 0; }
    body:not(.pageTemplate-pricing):not(.pricePlans) #main.hasSidebar .innerMain > aside {
      flex-basis: 300px;
      max-width: 300px;
      flex-shrink: 0;
      width: auto;
      margin: 0; }
      body:not(.pageTemplate-pricing):not(.pricePlans) #main.hasSidebar .innerMain > aside .sidebarInner > .bup > a {
        margin: 0; }
  .register.step3 #main .sectors {
    position: absolute;
    bottom: 0;
    left: 0; }
    .register.step3 #main .sectors .visual-checkbox {
      display: none; }
    .register.step3 #main .sectors .formRow.sectorCheckbox {
      flex-basis: 49%;
      max-width: 49%; }
      .register.step3 #main .sectors .formRow.sectorCheckbox span {
        font-size: 0.73em; }
  #main .packages .packageGroup table {
    display: table; }
    #main .packages .packageGroup table thead {
      display: table-header-group; }
    #main .packages .packageGroup table tbody {
      display: table-row-group; }
    #main .packages .packageGroup table tfoot {
      display: table-footer-group; }
    #main .packages .packageGroup table tr {
      display: table-row; }
    #main .packages .packageGroup table td, #main .packages .packageGroup table th {
      display: table-cell; }
    #main .packages .packageGroup table tr td:first-of-type, #main .packages .packageGroup table tr th:first-of-type {
      padding-left: 2em; }
    #main .packages .packageGroup table th.title {
      position: relative; }
      #main .packages .packageGroup table th.title::after {
        content: '';
        position: absolute;
        z-index: 10;
        bottom: -2px;
        left: 0;
        right: -1px;
        display: block;
        border-bottom: 2px solid #339a99; }
    #main .packages .packageGroup table th:not(.title) {
      display: table-cell;
      min-width: 220px; }
    #main .packages .packageGroup table tbody tr {
      padding: 0; }
    #main .packages .packageGroup table tbody td {
      padding: 0.8em 0.4em; }
      #main .packages .packageGroup table tbody td:not(:last-of-type) {
        margin-bottom: 0;
        border-right: 2px solid #fff; }
      #main .packages .packageGroup table tbody td:not(.title) {
        text-align: center; }
      #main .packages .packageGroup table tbody td .mobileTitle {
        display: none; }
  #main .buttons.formTop {
    border-bottom: none;
    position: absolute;
    top: 4.6em;
    right: 5%; }
  body.account.containForm #main h1 {
    max-width: 78%; }
  form .formContent {
    display: flex;
    align-items: space-between;
    flex-wrap: nowrap; }
  form .wrapBup {
    flex-basis: auto;
    max-width: 100%; }
  body.register form .formContent {
    display: block; }
  .fieldset div.formRow.checkbox {
    flex-basis: 6%;
    max-width: 6%; }
    .fieldset div.formRow.checkbox + header {
      flex-basis: 94%;
      max-width: 94%; }
  .blogControls {
    display: none; }
  #main.hasSidebar .innerMain > aside.blogSidebar .search, #main.hasSidebar .innerMain > aside.blogSidebar .categories {
    display: block; }
  .tabMenu ul {
    display: flex;
    flex-wrap: wrap; }
  .tabMenu li {
    margin-bottom: 1em; }
    .tabMenu li:not(:last-of-type) {
      border-bottom: none; }
      .tabMenu li:not(:last-of-type) a {
        padding-right: 0.8em; }
    .tabMenu li:not(:first-of-type) a {
      padding-left: 2.4em; }
    .tabMenu li:first-of-type, .tabMenu li:first-of-type a {
      border-radius: 0.5em 0 0 0.5em; }
    .tabMenu li:last-of-type, .tabMenu li:last-of-type a {
      border-radius: 0 0.5em 0.5em 0; }
    .tabMenu li:last-of-type a::after, .tabMenu li:last-of-type a::before {
      display: none; }
    .tabMenu li a::before {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 0;
      width: 0;
      border: 1.85em solid transparent;
      border-left-color: #fff;
      transition: border-left-color 0.2s ease;
      pointer-events: none; }
    .tabMenu li a::after, .tabMenu li a::before {
      z-index: 10; }
    .tabMenu li a .full {
      display: none; }
    .tabMenu li a .short {
      display: block; } }

@media (min-width: 1014px) {
  header#header .userinfos {
    display: inline-block; } }

@media (min-width: 1020px) {
  .account #sidebar {
    position: relative;
    width: auto;
    flex-basis: 375px;
    max-width: 375px;
    flex-shrink: 0;
    transform: translateX(0); }
    .account #sidebar #btnOpenSidebar, .account #sidebar #btnCloseSidebar {
      display: none; }
    .account #sidebar .profileCompletion {
      padding-top: 1em; }
    .account #sidebar .userinfos {
      padding-top: 1.6em; }
  .account #main {
    padding-top: 3em; }
    .account #main .twoCols .rows .formRowParent {
      float: none;
      width: 100%; }
      .account #main .twoCols .rows .formRowParent:nth-of-type(2n-1) {
        margin-right: 0; }
  .account #content {
    min-height: auto; }
  .account .tabs .tabContent form .formRowParent {
    float: none;
    width: 100%; }
    .account .tabs .tabContent form .formRowParent:nth-of-type(2n-1) {
      margin-right: 0; }
  .home #main .typeuser ul .bubble .content .clickHere {
    bottom: 35px; }
  .home #main .typeuser ul .bubble .content .typeTitle {
    font-size: 1.4em; }
  .home #main .typeuser ul .bubble .content .typeSubtitle {
    font-size: 1.7em; }
  body.account.containForm #main h1 {
    max-width: 68%; }
  #main .buttons.formTop {
    top: 3em; } }

@media (min-width: 1035px) {
  #main .sectorCheckbox, #main .categoryCheckbox {
    flex-basis: 32%;
    max-width: 32%; }
    #main .sectorCheckbox:nth-of-type(2n), #main .categoryCheckbox:nth-of-type(2n) {
      margin-left: 0; }
    #main .sectorCheckbox:nth-of-type(3n), #main .sectorCheckbox:nth-of-type(3n-1), #main .categoryCheckbox:nth-of-type(3n), #main .categoryCheckbox:nth-of-type(3n-1) {
      margin-left: 2%; }
  .home #main {
    background-image: url("/images/bg_large_2023.jpg"); } }

@media (min-width: 1100px) {
  body.pricePlans #main.hasSidebar .innerMain {
    flex-wrap: nowrap;
    justify-content: space-between; }
    body.pricePlans #main.hasSidebar .innerMain > *:not(aside) {
      flex-grow: 1;
      padding-right: 4%; }
      body.pricePlans #main.hasSidebar .innerMain > *:not(aside) .wrap {
        padding-right: 0; }
    body.pricePlans #main.hasSidebar .innerMain > aside {
      flex-basis: 300px;
      max-width: 300px;
      flex-shrink: 0;
      width: auto;
      margin: 0; }
      body.pricePlans #main.hasSidebar .innerMain > aside .sidebarInner > .bup > a {
        margin: 0; }
  .home #blog h2 {
    font-size: 4.5em; } }

@media (min-width: 1300px) {
  body.account.containForm #main h1 {
    max-width: 78%; } }

@media (min-width: 1400px) {
  .signed-in #home-search-simplified {
    position: absolute;
    top: 30%;
    left: 40%; } }

@media (min-width: 1550px) {
  header#header #mainmenu > ul > li.parent ul {
    right: initial;
    left: 0; } }

@media (min-width: 1206px) {
  .pageTemplate-linkHub #main .pageSection.hasBubble .mainText > h2 {
    text-align: left; }
  .account .tabs .tabContent form .formRowParent {
    float: left;
    width: 49%; }
    .account .tabs .tabContent form .formRowParent:nth-of-type(2n-1) {
      margin-right: 2%; }
  .account #main {
    padding-top: 3em; }
    .account #main .twoCols .rows .formRowParent {
      float: left;
      width: 49%; }
      .account #main .twoCols .rows .formRowParent:nth-of-type(2n-1) {
        margin-right: 2%; }
  .home #blog h2 {
    font-size: 5em; }
  .home #main {
    background-size: contain; }
    .home #main::before, .home #main::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0; }
    .home #main::before {
      left: 0;
      right: 0;
      background-image: linear-gradient(to right, #fff 0%, #fff 20%, transparent 25%, transparent 75%, #fff 80%, #fff 100%); }
    .home #main .typeuser ul .bubble {
      max-width: 370px; }
      .home #main .typeuser ul .bubble .icon {
        display: block; }
      .home #main .typeuser ul .bubble .content .clickHere {
        bottom: 50px; }
      .home #main .typeuser ul .bubble .content span.noa .typeTitle, .home #main .typeuser ul .bubble .content a .typeTitle {
        font-size: 1.6em;
        line-height: 1.1em; }
      .home #main .typeuser ul .bubble .content span.noa .typeSubtitle, .home #main .typeuser ul .bubble .content a .typeSubtitle {
        font-size: 1.8em;
        line-height: 1.1; }
  .home #videoBlock .wrap {
    flex-wrap: nowrap; }
    .home #videoBlock .wrap::after {
      transform: translate(10%, -3.8em); }
  .home #videoBlock .videoCol {
    flex-basis: auto;
    max-width: 100%; }
  .home #videoBlock .videoDescription {
    flex-basis: 45%;
    max-width: 45%; }
  .home #howitwork .steps .step:nth-child(odd) .content {
    right: 0; }
  .home #howitwork .steps .step:nth-child(even) .content {
    left: 0; }
  .home #howitwork .steps .step .content {
    position: absolute;
    top: 0;
    bottom: 0; }
  .home #howitwork .steps .step .content-inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%); }
  .home #infos ul.infos li {
    flex-basis: 30%;
    max-width: 30%; }
  .home div#newsletter h3 {
    font-size: 2.4em; }
    .home div#newsletter h3 strong {
      display: block;
      margin-top: -0.3em; }
  .home #main #blog .blogpost {
    display: flex; }
  .home #main #blog .image {
    flex-shrink: 0; }
  .account #main #blog .blogpost .image {
    margin-right: 1.5em; }
  body.pageTemplate-pricing #main.hasSidebar .innerMain {
    flex-wrap: nowrap;
    justify-content: space-between; }
    body.pageTemplate-pricing #main.hasSidebar .innerMain > *:not(aside) {
      flex-grow: 1;
      padding-right: 4%; }
      body.pageTemplate-pricing #main.hasSidebar .innerMain > *:not(aside) .wrap {
        padding-right: 0; }
    body.pageTemplate-pricing #main.hasSidebar .innerMain > aside {
      flex-basis: 300px;
      max-width: 300px;
      flex-shrink: 0;
      width: auto;
      margin: 0; }
      body.pageTemplate-pricing #main.hasSidebar .innerMain > aside .sidebarInner > .bup > a {
        margin: 0; }
  #main .sectorCheckbox, #main .categoryCheckbox {
    flex-basis: 23.5%;
    max-width: 23.5%; }
    #main .sectorCheckbox:nth-of-type(3n), #main .sectorCheckbox:nth-of-type(3n-1), #main .categoryCheckbox:nth-of-type(3n), #main .categoryCheckbox:nth-of-type(3n-1) {
      margin-left: 0; }
    #main .sectorCheckbox:nth-of-type(4n), #main .sectorCheckbox:nth-of-type(4n-1), #main .sectorCheckbox:nth-of-type(4n-2), #main .categoryCheckbox:nth-of-type(4n), #main .categoryCheckbox:nth-of-type(4n-1), #main .categoryCheckbox:nth-of-type(4n-2) {
      margin-left: 2%; }
  body footer#footer #footermenu ul li, body footer#footer #legalmenu ul li {
    display: inline-block;
    margin-right: 15px; }
  #main .dashboardModules .module.threeCols .contentInner {
    flex-wrap: nowrap; }
  #main .dashboardModules .module.threeCols .col {
    flex-basis: 33.333%;
    max-width: 100%; } }

@media (min-width: 1206px) and (max-width: 1550px) {
  .home #site #main {
    background-size: cover; } }

@media (min-width: 850px) and (max-width: 1399px) {
  .wrap {
    padding: 0 50px; } }

@media (min-width: 1400px) {
  .wrap {
    padding: 0;
    margin: 0 auto; }
  .home #main .typeuser .bubble {
    max-width: none; }
    .home #main .typeuser .bubble a .typeTitle {
      font-size: 1.8em; }
    .home #main .typeuser .bubble .content .typeSubtitle {
      font-size: 1.2em; }
  #main .dashboardModules .module:not(.fullWidth) {
    width: 48%; } }

/*** Queries « trouées » **************************************************/
@media (min-width: 700px) and (max-width: 899px), (min-width: 1206px) {
  .pageTemplate-linkHub #main .pageSection {
    margin-bottom: 0; }
    .pageTemplate-linkHub #main .pageSection .sectionInner {
      flex-wrap: nowrap;
      justify-content: space-between; }
    .pageTemplate-linkHub #main .pageSection.hasBubble:nth-of-type(odd) .bubble {
      order: 1; }
    .pageTemplate-linkHub #main .pageSection.hasBubble:nth-of-type(odd) .mainText {
      order: 2;
      padding-right: 0;
      padding-left: 1.4em; }
    .pageTemplate-linkHub #main .pageSection.hasBubble:nth-of-type(even) .bubble {
      order: 2; }
    .pageTemplate-linkHub #main .pageSection.hasBubble:nth-of-type(even) .mainText {
      order: 1;
      padding-right: 1.4em;
      padding-left: 0; }
    .pageTemplate-linkHub #main .pageSection.hasBubble.valign-center .sectionInner {
      align-items: center; }
    .pageTemplate-linkHub #main .pageSection.hasBubble.valign-center .mainText {
      padding-top: 0; }
    .pageTemplate-linkHub #main .pageSection.hasBubble .mainText {
      flex-basis: auto;
      max-width: 100%;
      padding-top: 2em;
      padding-right: 1em;
      margin-bottom: 0em;
      text-align: left; }
    .pageTemplate-linkHub #main .pageSection:not(.hasBubble):not(:first-child) {
      margin-top: 2em; }
    .pageTemplate-linkHub #main .pageSection .bubble {
      flex-basis: 300px;
      max-width: 300px;
      margin-bottom: 0; }
  .blogList .blogposts .blogpost, .home .blogposts .blogpost {
    display: flex;
    align-items: flex-start; }
    .blogList .blogposts .blogpost .image, .home .blogposts .blogpost .image {
      margin-right: 1.8em; }
    .blogList .blogposts .blogpost .content > header, .home .blogposts .blogpost .content > header {
      margin-top: 0; }
      .blogList .blogposts .blogpost .content > header .subHeader, .home .blogposts .blogpost .content > header .subHeader {
        display: flex;
        align-items: baseline;
        margin-bottom: 0em;
        line-height: 1em; }
      .blogList .blogposts .blogpost .content > header .postCategories, .home .blogposts .blogpost .content > header .postCategories {
        margin-top: 0; } }

@media (min-width: 615px) and (max-width: 1020px), (min-width: 1180px) and (max-width: 1399px), (min-width: 1825px) {
  .account.accountHome .jobOffers .jobOffer .articleInner {
    flex-wrap: nowrap;
    align-items: flex-start; }
  .account.accountHome .jobOffers .jobOffer .articleInner-l2 {
    flex-wrap: nowrap; }
  .account.accountHome .jobOffers .jobOffer .jobInfo {
    flex-basis: auto;
    max-width: 100%;
    flex-grow: 1;
    padding-right: 1.4em; }
  .account.accountHome .jobOffers .jobOffer .wrapButton {
    margin-top: 0;
    text-align: right; }
    .account.accountHome .jobOffers .jobOffer .wrapButton .wrapIndicators {
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: flex-end; } }

@media (min-width: 750px) and (max-width: 1019px), (min-width: 1206px) {
  .account #main #blog .blogpost {
    display: flex; }
  .account #main #blog .content > header {
    margin-top: 0; }
    .account #main #blog .content > header .subHeader {
      display: flex;
      align-items: baseline;
      margin-bottom: 0em;
      line-height: 1em; }
    .account #main #blog .content > header .postCategories {
      margin-top: 0; }
  .account #main #blog .image {
    flex-shrink: 0;
    margin-right: 1.5em; } }

/***David*******/
/****END David******/
@media screen and (max-width: 611px) {
  #singleHeader .headerText {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    word-wrap: break-word; } }

/*** Queries de hauteur **************************************************/
@media print {
  /******************************************************************
Site Name:
Author:

Stylesheet: Print Stylesheet

This is the print stylesheet. There's probably not a lot
of reasons to edit this stylesheet. If you want to
though, go for it.

******************************************************************/
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    box-sizing: content-box !important;
    float: none !important; }
  a, a:visited {
    color: #444 !important;
    text-decoration: underline; }
    a:after, a:visited:after {
      content: none; }
    a abbr[title]:after, a:visited abbr[title]:after {
      content: none; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  .hidePrint {
    display: none; }
  .sidebar, .page-navigation, .wp-prev-next, .respond-form, nav {
    display: none; }
  .mfp-wrap {
    position: relative !important;
    bottom: 0;
    left: 0; }
    .mfp-wrap .mfp-content {
      max-width: 100%; }
    .mfp-wrap .mfp-container, .mfp-wrap .innerPopup, .mfp-wrap .innerPopup-l2 {
      position: relative !important;
      top: auto !important;
      bottom: auto !important;
      left: auto !important;
      right: auto !important; }
    .mfp-wrap .innerPopup-l2 {
      border: none !important;
      overflow-y: visible; }
    .mfp-wrap + #site {
      display: none; }
    .mfp-wrap #content {
      display: block; }
  .cvsList #singleHeader .headerText {
    width: 100% !important; }
  .cvsList div {
    float: none !important; }
  .cvsList .fieldset div.formRow.checkbox + header {
    flex-basis: auto;
    max-width: 100%; }
  .cvsList .fieldset {
    page-break-after: always !important; }
  .cvsList #employerCVS .checkbox {
    display: none; }
  .cvsList .fieldset {
    display: block; }
  .cvsList .fieldset .accordionContent {
    display: block !important;
    opacity: 1 !important;
    border-left: 0 none;
    border-right: 0 none; }
  .cvsList .fieldset > header {
    padding-left: 0; }
    .cvsList .fieldset > header .headerText {
      padding: 0; }
    .cvsList .fieldset > header h2 {
      font-size: 2em; }
    .cvsList .fieldset > header .btnRollUnroll::after {
      display: none; } }

/**********************************************************************************
Fonts Stack
**********************************************************************************/
/* Times New Roman-based stack */
$times: Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;

/* Modern Georgia-based serif stack */
$georgia: Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;

/* Traditional Garamond-based serif stack */
$garamond: "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;

/* Helvetica/Arial-based sans serif stack */
$helvetica: Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;

/* Verdana-based sans serif stack */
$verdana: Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;

/* Trebuchet-based sans serif stack */
$trebuchet: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;

/* Impact-based sans serif stack */
$impact: Impact, "HelveticaNeue-CondensedBlack", Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", sans-serif;

/* Monospace stack */
$monospace: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;


/**********************************************************************************
Mixin tableReset
DESC : Remet à un <table> et ses enfants les propriétés display appropriées, lorsque le tableau a été mis en display: block; pour le mobile.
$img : Mettre le nom de l'image (ex: bg.png)
$pos : Permet de positionner le background ( défaut: left top )
$repeat : Défini la répétition du bacground ( défaut : no-repeat )
$color : Définir la couleur de background ( défaut : transparent )
**********************************************************************************/
@mixin tableReset {
	display: table;

	thead {
		display: table-header-group;
	}

	tbody {
		display: table-row-group;
	}

	tfoot {
		display: table-footer-group;
	}

	tr {
		display: table-row;
	}

	td, th {
		display: table-cell;
	}
}

/**********************************************************************************
Fonction bg
DESC : Fait l'appel d'un background plus rapidement
$img : Mettre le nom de l'image (ex: bg.png)
$pos : Permet de positionner le background ( défaut: left top )
$repeat : Défini la répétition du bacground ( défaut : no-repeat )
$color : Définir la couleur de background ( défaut : transparent )
**********************************************************************************/
@mixin bg( $img, $pos: left top, $repeat: no-repeat, $color: transparent ) {
	background: $color url('/images/'+$img) $pos $repeat;
}

/**********************************************************************************
Fonction transition
DESC : Faire la transition entre 2 états
$delai : Mettre le délai de la transition
**********************************************************************************/
@mixin transition( $delai ) {
	transition-duration: $delai;
	-webkit-transition-duration: $delai;
}

/**********************************************************************************
Fonction rounded
DESC : Permet d'ajouter des coin rond à un élément
$radius : Définir le rayon des coins ronds ( défaut : 10px )
**********************************************************************************/
@mixin rounded( $radius: 10px ) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

/**********************************************************************************
Fonction absBox
DESC : Permet de mettre un élément en absolue
$top : Définir la distance par rapport au haut
$left : Définir la distance par rapport à la gauche
**********************************************************************************/
@mixin absBox( $top, $left ) {
	position: absolute;
	top: $top;
	left: $left;
}

/**********************************************************************************
Fonction init_font
DESC : Permet d'initier une nouvelle police
$fontName : Nom de la police servant servant aux appel
$fontFileName : Nom du fichier de la police
**********************************************************************************/
@mixin init_font( $fontName, $fontFileName, $weight: normal, $style: normal ) {
	font-family: $fontName;
	src: url('/fonts/' + $fontFileName + '.woff') format('woff'),
		 url('/fonts/' + $fontFileName + '.ttf') format('truetype');
	font-weight: $weight;
	font-style: $style;
}

@mixin boxsizing( $value: border-box ) {
	-webkit-box-sizing: $value; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: $value;    /* Firefox, other Gecko */
	box-sizing: $value;         /* Opera/IE 8+ */
}

/**********************************************************************************
Fonction textoverflow
DESC : Permet de contrôler la coupure des mots
$value : Valeur à donner à la propriété (default: ellipsis)
**********************************************************************************/
@mixin textoverflow( $value: ellipsis ) {
	overflow: hidden;
	-o-text-overflow: $value; /* pour Opera 9 */
	text-overflow: $value; /* pour le reste du monde */
}

/**********************************************************************************
Fonction cesure
DESC : Permet de contrôler la césure des mots
$value : Valeur à donner à la propriété (default: auto)
**********************************************************************************/
@mixin cesure( $value: auto ) {
	-webkit-hyphens: $value;
	-moz-hyphens: $value;
	-ms-hyphens: $value;
	-o-hyphens: $value;
	hyphens: $value;
}
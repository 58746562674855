.manager {
	.jobOffers .jobOffer .jobInfo {
		-ms-flex-preferred-size: 65%;
		flex-basis: 65%;
		max-width: 65%;
	}

	.confirmTable {
		font-size:0.75em;
		width: 100%;
		td, th { padding: 5px 10px; }
	}

	.filters {
		margin-bottom: 1em;

		ul {
			li {
				display: inline-block;
				margin-left: 0.5em;

				&:first-child { margin-left: 0; }
			}
		}

		a {
			color: $turquoise;
		}
		.active, a.active { font-weight: 700 ; }
	}

	div.formRefuse {
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: white;
		padding: 1.5em;
		border-radius: 10px;
		z-index: 99;
		box-shadow: 0 0 5px 5px rgba($black, 0.2);

		.minimsg {
			font-size: 0.8em;
			font-style: italic;
			color: $red;
		}
	}

	div.formNote {
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: white;
		padding: 1.5em;
		border-radius: 10px;
		z-index: 99;
		box-shadow: 0 0 5px 5px rgba($black, 0.2);
	}

	.addNote {
		text-decoration: none;
	}
}

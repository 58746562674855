/* ==========================================================================
	$BASE-PICKER
========================================================================== */

/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {
	font-size: 16px;
	text-align: left;
	line-height: 1.2;
	color: #000000;
	position: absolute;
	z-index: 10000;
	-webkit-user-select: none;
		 -moz-user-select: none;
			-ms-user-select: none;
					user-select: none;
}
/**
 * The picker input element.
 */
.picker__input {
	cursor: default;
}
/**
 * When the picker is opened, the input element is “activated”.
 */
.picker__input.picker__input--active {
	border-color: #0089ec;
}
/**
 * The holder is the only “scrollable” top-level container element.
 */
.picker__holder {
	width: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

/*!
 * Default mobile-first, responsive styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
/**
 * Make the holder and frame fullscreen.
 */
.picker__holder,
.picker__frame {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	-webkit-transform: translateY(100%);
			-ms-transform: translateY(100%);
					transform: translateY(100%);
}
/**
 * The holder should overlay the entire screen.
 */
.picker__holder {
	position: fixed;
	transition: background 0.15s ease-out, -webkit-transform 0s 0.15s;
	transition: background 0.15s ease-out, transform 0s 0.15s;
	-webkit-backface-visibility: hidden;
}
/**
 * The frame that bounds the box contents of the picker.
 */
.picker__frame {
	position: absolute;
	margin: 0 auto;
	min-width: 256px;
	max-width: 666px;
	width: 100%;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	opacity: 0;
	transition: all 0.15s ease-out;
}
@media (min-height: 33.875em) {
	.picker__frame {
		overflow: visible;
		top: auto;
		bottom: -100%;
		max-height: 80%;
	}
}
@media (min-height: 40.125em) {
	.picker__frame {
		margin-bottom: 7.5%;
	}
}
/**
 * The wrapper sets the stage to vertically align the box contents.
 */
.picker__wrap {
	display: table;
	width: 100%;
	height: 100%;
}
@media (min-height: 33.875em) {
	.picker__wrap {
		display: block;
	}
}
/**
 * The box contains all the picker contents.
 */
.picker__box {
	background: #ffffff;
	display: table-cell;
	vertical-align: middle;
}
@media (min-height: 26.5em) {
	.picker__box {
		font-size: 1.25em;
	}
}
@media (min-height: 33.875em) {
	.picker__box {
		display: block;
		font-size: 1.33em;
		border: 1px solid #777777;
		border-top-color: #898989;
		border-bottom-width: 0;
		border-radius: 5px 5px 0 0;
		box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
	}
}
@media (min-height: 40.125em) {
	.picker__box {
		font-size: 1.5em;
		border-bottom-width: 1px;
		border-radius: 5px;
	}
}
/**
 * When the picker opens...
 */
.picker--opened .picker__holder {
	-webkit-transform: translateY(0);
			-ms-transform: translateY(0);
					transform: translateY(0);
	background: transparent;
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)";
	zoom: 1;
	background: rgba(0, 0, 0, 0.32);
	transition: background 0.15s ease-out;
}
.picker--opened .picker__frame {
	-webkit-transform: translateY(0);
			-ms-transform: translateY(0);
					transform: translateY(0);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	-moz-opacity: 1;
	opacity: 1;
}
@media (min-height: 33.875em) {
	.picker--opened .picker__frame {
		top: auto;
		bottom: 0;
	}
}

/* ==========================================================================
	$BASE-DATE-PICKER
========================================================================== */
/**
 * The picker box.
 */
.picker__box {
	padding: 0 1em;
}
/**
 * The header containing the month and year stuff.
 */
.picker__header {
	text-align: center;
	position: relative;
	margin-top: .75em;
}
/**
 * The month and year labels.
 */
.picker__month,
.picker__year {
	font-weight: 500;
	display: inline-block;
	margin-left: .25em;
	margin-right: .25em;
}
.picker__year {
	color: #999999;
	font-size: .8em;
	font-style: italic;
}
/**
 * The month and year selectors.
 */
.picker__select--month,
.picker__select--year {
	border: 1px solid #b7b7b7;
	height: 2em;
	padding: .5em;
	margin-left: .25em;
	margin-right: .25em;
}
@media (min-width: 24.5em) {
	.picker__select--month,
	.picker__select--year {
		margin-top: -0.5em;
	}
}
.picker__select--month {
	width: 35% !important;
}
.picker__select--year {
	width: 22.5% !important;
}
.picker__select--month:focus,
.picker__select--year:focus {
	border-color: #0089ec;
}
/**
 * The month navigation buttons.
 */
.picker__nav--prev,
.picker__nav--next {
	position: absolute;
	padding: .5em 1.25em;
	width: 1em;
	height: 1em;
	box-sizing: content-box;
	top: -0.25em;
}
@media (min-width: 24.5em) {
	.picker__nav--prev,
	.picker__nav--next {
		top: -0.33em;
	}
}
.picker__nav--prev {
	left: -1em;
	padding-right: 1.25em;
}
@media (min-width: 24.5em) {
	.picker__nav--prev {
		padding-right: 1.5em;
	}
}
.picker__nav--next {
	right: -1em;
	padding-left: 1.25em;
}
@media (min-width: 24.5em) {
	.picker__nav--next {
		padding-left: 1.5em;
	}
}
.picker__nav--prev:before,
.picker__nav--next:before {
	content: " ";
	border-top: .5em solid transparent;
	border-bottom: .5em solid transparent;
	border-right: 0.75em solid #000000;
	width: 0;
	height: 0;
	display: block;
	margin: 0 auto;
}
.picker__nav--next:before {
	border-right: 0;
	border-left: 0.75em solid #000000;
}
.picker__nav--prev:hover,
.picker__nav--next:hover {
	cursor: pointer;
	color: #000000;
	background: #b1dcfb;
}
.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
	cursor: default;
	background: none;
	border-right-color: #f5f5f5;
	border-left-color: #f5f5f5;
}
/**
 * The calendar table of dates
 */
.picker__table {
	text-align: center;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	font-size: inherit;
	width: 100%;
	margin-top: .75em;
	margin-bottom: .5em;
}
@media (min-height: 33.875em) {
	.picker__table {
		margin-bottom: .75em;
	}
}
.picker__table td {
	margin: 0;
	padding: 0;
}
/**
 * The weekday labels
 */
.picker__weekday {
	width: 14.285714286%;
	font-size: .75em;
	padding-bottom: .25em;
	color: #999999;
	font-weight: 500;
	/* Increase the spacing a tad */
}
@media (min-height: 33.875em) {
	.picker__weekday {
		padding-bottom: .5em;
	}
}
/**
 * The days on the calendar
 */
.picker__day {
	padding: .3125em 0;
	font-weight: 200;
	border: 1px solid transparent;
}
.picker__day--today {
	position: relative;
}
.picker__day--today:before {
	content: " ";
	position: absolute;
	top: 2px;
	right: 2px;
	width: 0;
	height: 0;
	border-top: 0.5em solid #0059bc;
	border-left: .5em solid transparent;
}
.picker__day--disabled:before {
	border-top-color: #aaaaaa;
}
.picker__day--outfocus {
	color: #dddddd;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
	cursor: pointer;
	color: #000000;
	background: #b1dcfb;
}
.picker__day--highlighted {
	border-color: #0089ec;
}
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
	cursor: pointer;
	color: #000000;
	background: #b1dcfb;
}
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
	background: #0089ec;
	color: #ffffff;
}
.picker__day--disabled,
.picker__day--disabled:hover,
.picker--focused .picker__day--disabled {
	background: #f5f5f5;
	border-color: #f5f5f5;
	color: #dddddd;
	cursor: default;
}
.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
	background: #bbbbbb;
}
/**
 * The footer containing the "today", "clear", and "close" buttons.
 */
.picker__footer {
	text-align: center;
}
.picker__button--today,
.picker__button--clear,
.picker__button--close {
	border: 1px solid #ffffff;
	background: #ffffff;
	font-size: .8em;
	padding: .66em 0;
	font-weight: bold;
	width: 33%;
	display: inline-block;
	vertical-align: bottom;
}
.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover {
	cursor: pointer;
	color: #000000;
	background: #b1dcfb;
	border-bottom-color: #b1dcfb;
}
.picker__button--today:focus,
.picker__button--clear:focus,
.picker__button--close:focus {
	background: #b1dcfb;
	border-color: #0089ec;
	outline: none;
}
.picker__button--today:before,
.picker__button--clear:before,
.picker__button--close:before {
	position: relative;
	display: inline-block;
	height: 0;
}
.picker__button--today:before,
.picker__button--clear:before {
	content: " ";
	margin-right: .45em;
}
.picker__button--today:before {
	top: -0.05em;
	width: 0;
	border-top: 0.66em solid #0059bc;
	border-left: .66em solid transparent;
}
.picker__button--clear:before {
	top: -0.25em;
	width: .66em;
	border-top: 3px solid #ee2200;
}
.picker__button--close:before {
	content: "\D7";
	top: -0.1em;
	vertical-align: top;
	font-size: 1.1em;
	margin-right: .35em;
	color: #777777;
}
.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
	background: #f5f5f5;
	border-color: #f5f5f5;
	color: #dddddd;
	cursor: default;
}
.picker__button--today[disabled]:before {
	border-top-color: #aaaaaa;
}

/* ==========================================================================
	$DEFAULT-DATE-PICKER
========================================================================== */
/******************************************
*              HEADER-WRAPPER             *
*******************************************/

@media (max-width: 399px) {
	#header-wrapper {
		flex-direction: column;

		#home-search-simplified {
			display: flex;
			justify-content: center;
			order: 2;
			min-width: 100%;
			margin: auto;
			padding-top: 5px;
			padding-bottom: 0px;

			form {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			form label {
				display: block;
				min-width: 100px;
				font-size: initial;
			}

			.animated-arrow {
				display: none;
			}

			button {
				margin-top: 5px;
				width: 80%;
			}

			#select-button {
				display: flex;
				flex-direction: column;

				.select2 {
					width: 100% !important;
				}

				button {
					margin-top: 5px;
				}
			}
		}

		#menus {
			order: 1;
		}
	}
}

@media (min-width: 400px) and (max-width: 599px) {
	#header-wrapper {
		flex-direction: column;

		#home-search-simplified {
			order: 2;
			min-width: 300px;
			margin: auto;
			padding-top: 5px;
			padding-bottom: 0px;

			form {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			form label {
				display: block;
				min-width: 100px;
				font-size: initial;
			}

			.animated-arrow {
				display: none;
			}

			button {
				margin-top: 5px;
				width: 80%;
			}
		}

		#menus {
			order: 1;
		}
	}
}

@media (min-width: 600px) and (max-width: 749px) {
	#header-wrapper {
		flex-direction: column;

		#home-search-simplified {
			order: 2;
			width: 50%;
			margin: auto;
			padding-top: 5px;
			padding-bottom: 0px;

			form {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			form label {
				display: block;
				min-width: 200px;
				font-size: initial;
			}

			.animated-arrow {
				display: none;
			}

			button {
				margin-top: 5px;
				width: 80%;
			}
		}
		#menus {
			order: 1;
		}
	}
}

@media (min-width: 750px) and (max-width: 1099px) {
	#home-search-simplified {
		order: 2;
		width: 50%;
		margin: -25px auto auto auto;
		padding-top: 5px;
		padding-bottom: 0px;

		form label {
			font-size: initial;
		}

		.animated-arrow {
			display: none;
		}
	}
}

@media (min-width: 749px) and (max-width: 1218px) {
	#header-wrapper {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		#home-search-simplified {
			form label {
				display: block;
				min-width: 200px;
				font-size: initial;
			}
			
			.animated-arrow {
				display: none;
			}

			#select-button {
				min-width: 300px;
			}
		}

		#menus {
			order: 1;
		}
	}
}

@media (min-width: 1219px) and (max-width: 1400px) {
	#header-wrapper {
		&.signed-in {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			#home-search-simplified {
				position: absolute;
				top: 30%;
				left: 43%;

				form label {
					display: block;
					min-width: 200px;
				}

				#select-button {
					min-width: 300px;
				}
			}

			#menus {
				order: 1;
			}
		}

		&.not-signed-in {
			flex-direction: row;
			flex-wrap: wrap;

			#home-search-simplified {
				#select-button {
					min-width: 300px;
				}

				form {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				form label {
					font-size: initial;
				}

				button {
					width: 80%;
					margin-top: 5px;
				}
			}

			#menus {
				order: 1;
			}
		}
	}
}

@media (max-width: 600px) {
	#main {
		.sectorCheckbox:not(:nth-child(2n+1)), .categoryCheckbox:not(:nth-child(2n+1)) {

		}
	}

	footer#footer, #newsletter {
		.left, .right { float: none; }
	}
	.emploiretraite-view-jobs-button {
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 400px) {
	header#header{
		#mainmenu{
			& > ul{
				& > li.parent{
					ul{
						left: initial;
						right: 0;
					}
				}
			}
		}

		.top{
			top: 48px;
		}
	}

	#main, .mfp-wrap{
		.buttons{
			.buttonLink{
				&:last-of-type{
					margin-left: 0.5em;
				}
			}
		}
	}

	.fieldset > header{
		.btnRollUnroll{
			padding: 0 1.2em;
		}
	}

	button.btnWithIcon, .button.btnWithIcon, input.btnWithIcon {
		padding-left: 1em;
		padding-right: 1em;

		&.gradientOutline {
			.buttonInner {
				padding-left: 1.6em;
				padding-right: 1.6em;
			}
		}
	}

	.home #main .typeuser {
		padding-bottom: 200px;
	}

	.register.step3{
		#main{
			.sectors{
				.formRow.sectorCheckbox{
					span{
						font-size: 0.7em;
					}
				}
			}
		}
	}

	.account .mainContent #singleHeader {
		.headerText {
			display: flex;
		}
	}
}

@media (min-width: 420px) {
	.home #howitwork {
		h2 {
			font-size: 3em;
		}
	}
}

@media (min-width: 465px) {
	header#header{
		.top{
			top: 66px;
			right: 44px;
			.lang ul li{
				display: inline-block;
			}
		}
	}
}

@media (min-width: 500px) {
	header#header {
		#logo {
			max-width: 70%;
		}
		.top{
			bottom: 23px;
			right: 20px;
		}
	}

	.account .jobOffers .jobOffer {
		.articleInner-l2 {
			padding: 1em 0 1em 1.4em;
		}

		.cieLogo, .wrapButton .cieLogoSpace {
			flex-basis: 70px;
			max-width: 70px;
			margin-right: 1em;
		}
	}

	.home #blog h2 {
		font-size: 4em;
	}

	// Rétablir l'espace à droite des worktype (première étape d'inscription retraité)
	div.formRow label.triggerLabel{
		max-width: none;
	}

	.register.step3{
		#main{
			.sectors{
				.formRow.sectorCheckbox{
					flex-basis: 41%;
					max-width: 41%;
				}
			}
		}
	}

	#main, .mfp-wrap {
		.multiRow {
			&:not(.threeRows) {
				flex-wrap: nowrap;

				& > * {
					flex-basis: 50%;
					max-width: 50%;

					&:not(:last-child) {
						margin-right: 0.8em;
					}
				}

				&.fakeRowEnd {
					& > * {
						margin-right: 0.8em;
					}
				}

				&.fakeRowStart {
					&::before {
						margin-right: 0.8em;
					}
				}
			}
		}

		.security {
			flex-wrap: nowrap;

			& > * {
				flex-basis: auto;
				max-width: 100%;
			}

			.padlock {
				margin-bottom: 0;
				margin-right: 1em;
			}
		}
	}

	.fieldset{
		div.formRow.checkbox{
			flex-basis: 11%;
			max-width: 11%;
			& + header{
				flex-basis: 89%;
				max-width: 89%;
			}
		}
	}

	.pageTemplate-pricing div.smallipop-theme-emploiretraite {
		width: 800px;
		max-width: 90%;

		&.smallipop-align-right, &.smallipop-align-left {
			width: auto;
			max-width: 400px;
		}

		.smallipop-content {
			text-align: left;

			h2, h3 {
				display: inline-block;
			}

			h2 {
				margin-right: 1em;
			}
		}
	}



	#main .blogSingle .singleHeader {

		.innerHeader {
			flex-wrap: nowrap;
			justify-content: space-between;

			& > div {
				flex-basis: auto;
				max-width: 100%;
			}
		}
	}

	#main .blogSingle .singleComments {
		.newComment {
			form .avatar {
				margin-right: 1em;

				img {
					width: 65px;
				}
			}

			form {
				.notLoggedIn, .theForm {
					p {
						font-size: 1.2em;
					}

					.button, button {
						font-size: 1em;
					}
				}
			}
		}

		.commentList .comment {
			.avatar {
				img {
					width: 65px;
				}
			}

			.theComment {
				header .separator {
					display: inline-block;
				}
			}

			.replyContainer {
				form .theForm {
					footer {
						flex-wrap: nowrap;

						.replyTo {
							flex-basis: auto;
							max-width: 100%;

							.label {
								word-break: normal;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 550px) {
	header#header {
		#mainmenu{
			& > ul{
				& > li.parent{
					ul{
						right: initial;
						left: 0;
					}
				}
			}
		}
	}

	#home-search {
		width: 50%;
	}

	.entryContent, .entry-content, .formFooter, label, dd, #main .entryContent, #main .entry-content{
		table {
			font-size: 0.9em;

			tr {
				th{
					padding: 10px 20px;
				}
				td{
					padding: 8px 20px;
				}
			}
		}
	}

	#main {
		.sectorCheckbox, .categoryCheckbox {
			flex-basis: 49%;
			max-width: 49%;

			&:nth-of-type(2n) {
				margin-left: 2%;
			}
		}
	}

	.page #main > header {
		&.gradient {
			h1 {
				font-size: 3em;
			}
		}

		h1 {
			font-size: 2.4em;
		}
	}

	#site {
		.alert {
			&.buttonDisplay-vertical_right, &.buttonDisplay-vertical_left {
				.alertInner {
					flex-wrap: nowrap;
				}

				.wrapContent, .wrapButtons {
					flex-basis: auto;
					max-width: 100%;
				}
			}

			&.buttonDisplay-vertical_right {
				.wrapButtons {
					margin-top: 0;
					margin-left: 0.6em;
				}
			}
			&.buttonDisplay-vertical_left {
				.wrapButtons {
					margin-bottom: 0;
					margin-right: 0.6em;
				}
			}

			&.alertImage-left, &.alertImage-right {
				.wrapContent {
					flex-wrap: nowrap;
					align-items: center;
				}

				.contentInner, .alertThumbnail {
					flex-basis: auto;
					max-width: 100%;
				}

				.alertThumbnail {
					img {
						width: auto;
						height: auto;
						max-width: 50px;
					}
				}
			}
		}
	}

	.home #main {
		background-size: contain;

		.typeuser ul {
			justify-content: space-around;
			flex-wrap: wrap;

			.bubble {
				margin-top: 0.5em;
				max-width: 300px;

				&::before {
					display: block;
				}

				.content {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					border-radius: 100%;

					span.noa, a {
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;

						.aContent {
							position: absolute;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							width: 100%;
							padding: 0 8%;
						}
					}

					.clickHere{
						bottom: 25px;
					}

					.typeTitle {
						font-size: 1.2em;
					}

					.typeSubtitle {
						font-size: 1.6em;
					}
				}
			}
		}
	}

	.register.step3{
		#main{
			.sectors{
				.formRow.sectorCheckbox{
					flex-basis: 41%;
					max-width: 41%;
				}
			}
		}
	}

	.mfp-inline-holder, .mfp-ajax-holder {
		.mfp-content {
			.innerPopup-l2 {
				padding: 2.5em 2.5em 0 2.5em;
			}

			#content {
				padding-bottom: 2.5em;
			}
		}
	}

	.fieldset {
		&> header {
			padding-left: 1.8em;
		}

		.accordionContent {
			border-right-width: 1.8em;
			border-left-width: 1.8em;
		}
	}

}

@media (min-width: 601px) {
	.containForm #main {
		> header {
			#progressbar {
				width: 50%;
				position: relative;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.account .jobOffers .jobOffer {

		.cieLogoSpace {
			display: none;
		}
	}

	#main, .mfp-wrap {
		/* Style des fieldset */
		fieldset {
			width: 100%;
			border: 2px solid darken($lightgray, 5%);
			border-radius: 5px;
			padding: 15px 30px;
			margin-bottom: 1.5em;

			legend {
				font-size: 1.1em;
				font-weight: 700;
				color: $black;
				padding: 0 10px;
				margin-left: -11px;
			}
		}

		input[type=file], select {
			width: 100%;
		}

		textarea {
			width: 100%;
		}

		.leftCentered {
			textarea {
				min-width: 100%;
			}
		}

		textarea {
			min-width: 75%;
		}

		.twoCols {
			.formContent{
				display: block;
				.rows, fieldset {
					display: block;
					.formRowParent {
						display: block;
						width: 49%;
						float: left;
						&:nth-of-type(2n-1){
							margin-right: 2%;
						}
					}
				}
			}
		}

		.multiRow {
			&.threeRows {
				flex-wrap: nowrap;

				& > * {
					flex-basis: 33.333%;
					max-width: 33.333%;

					&:not(:last-child) {
						margin-right: 0.8em;
					}
				}

				& > select, & > input {
					&:not(:last-child) {
						margin-bottom: 0;
					}
				}

				.separator {
					display: block;
				}
			}
		}

		.checkboxes.manyCheckboxes {
			columns: 2;
		}
	}

	.home #main {
		.typeuser {
			padding-bottom: 130px;
		}

		.linkBar {
			flex-wrap: nowrap;

			.link {
				flex-basis: 50%;
				max-width: 50%;

				margin-bottom: 0;

				&:first-of-type {
					padding-bottom: 0;

					a::after {
						left: auto;
						right: -1.5px;
						bottom: auto;
						top: 50%;
						width: 0;
						height: 80%;
						border-top: none;
						border-left: 3px solid $white;
						transform: translateY(-50%);
					}
				}

				&:not(:first-of-type) {
					margin-top: 0;
				}

				//Aligner depuis le centre
				&:nth-of-type(2n) {
					justify-content: flex-start;
					padding-left: 10%;
				}

				&:nth-of-type(2n-1) {
					justify-content: flex-end;
					padding-right: 10%;
				}
			}
		}
	}

	.home #videoBlock .videoDescription {
		h2 {
			font-size: 2.667em;

			.mainTitle {
				font-size: 1.6em;
			}
		}
	}

	.home #howitwork .steps .step .title .no {
		font-size: 5.6em;
	}

	.register.step3{
		#main{
			.sectors{
				.formRow.sectorCheckbox{
					flex-basis: 37%;
					max-width: 37%;
				}
			}
		}
	}

	div#newsletter > .inner {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center; /* align vertical */

		.left, .right {
			float: none;
			flex-grow: 1;
			width: 46%;
		}

		.left {
			text-align: right;
			border-right: 2px solid lighten( $turquoise, 8% );
			padding-right: calc( 4% );
			margin-right: 4%;
		}
	}

	.account {
		&.listJobs, &.history {
			.jobOffers {
				.jobOffer {
					.articleInner {
						flex-wrap: nowrap;
						align-items: flex-start;
					}

					.articleInner-l2 {
						flex-wrap: nowrap;
					}

					.jobInfo {
						flex-basis: auto;
						max-width: 100%;
						flex-grow: 1;
						padding-right: 1.4em;
					}

					.cieLogo {
						align-items: center;
					}

					.wrapButton {
						margin-top: 0;
						text-align: right;

						.wrapButton-inner {
							align-items: flex-start;
							justify-content: flex-end;
						}

						.link {
							margin-right: 0;
						}

						.wrapIndicators {
							margin-top: 0;
							flex-basis: 100%;
							max-width: 100%;
							flex-wrap: nowrap;
							align-items: flex-start;
							justify-content: flex-end;
						}
					}
				}
			}
		}

		.mainContent {
			#singleHeader {
				flex-wrap: nowrap;

				.headerText {
					flex-basis: auto;
					max-width: 65%;
				}

				form, .contactCandidates {
					margin-top: 0;
					padding-left: 1em;
				}
			}
		}
	}

	#main .blogSingle .singleComments {
		.commentList .comment {
			&.child {
				margin-left: 2em;
			}

			.replyContainer {
				margin-left: 2em;
			}
		}
	}

	footer#footer {
		.right { margin-top: -70px; }
		#legalmenu { margin-top: 5px; }
	}

	#main .dashboardModules .module {
		&.threeCols {
			.col {
				flex-basis: 50%;
				max-width: 100%;
			}
		}

		&.offerList .buttons .wrapButton {
			display: inline-block;
			text-align: left;

			&:not(:last-child) {
				margin-right: 0.5em;
			}
		}

		&.largeButton .button {
			.buttonContent {
				flex-wrap: nowrap;
				justify-content: flex-start;

				.wrapIcon {
					flex-basis: auto;
					max-width: 100%;
					margin-bottom: 0;
					margin-right: 1em;
				}

				.wrapText {
					text-align: left;
				}
			}
		}
	}
}

@media (min-width: 650px) {
	body.pricePlans #main .inner .planGroup {
		.fullAdvantages {
			display: block;
		}

		.buttonWrapper.free{
			display: block;
			visibility: hidden;
		}

		.mobileAdvantages {
			display: none;
		}
	}

	.home #main {
		background-size: cover;
		background-image: url('/images/bg_medium_2023.jpg');
	}

	.register.step3{
		#main{
			.sectors{
				.formRow.sectorCheckbox{
					flex-basis: 35%;
					max-width: 35%;
				}
			}
		}
	}
}

@media (min-width: 700px) {
	.pageTemplate-linkHub{
		#main{
			.pageSection{
				&.hasBubble {
					.mainText > h2 {
						text-align: left;
					}
				}
			}
		}
	}

	.page #main > header h1 {
		font-size: 3.4em;
	}

	#main {
		.or, .formRow .or {
			max-width: 50%;
		}

		.security {
			max-width: 70%;
		}
	}

	.home #main {
		padding-top: 135px;
	}

	.home #howitwork {
		h2 {
			font-size: 5em;
		}

		.steps {
			.step {
				&:nth-child(odd) {
					.image, .content {
						float: left;
					}

					.image {
						padding-right: 5%;
					}

					.content {
						padding-right: 1em;

						.title .no {
							color: $darkturquoise;
						}
					}
				}

				&:nth-child(even) {
					.image, .content {
						float: right;
						text-align: right;
					}

					.image {
						padding-left: 5%;
						padding-right: 0;
					}

					.content {
						text-align: left;
						.title .no {
							color: $turquoise;
						}
					}

					.content-inner {
						padding-left: 1em;
					}
				}

				.content {
					width: 32%;
				}

				.content-inner {
				}

				.image {
					width: 68%;
				}
			}
		}
	}

	.home #infos {
		ul.infos li {
			flex-basis: 46%;
			max-width: 46%;
		}
	}

	.home #blog .image{
		margin-right: 1.5em;
		img{
			max-width: 275px;
		}
	}

	.containForm #main {
		> header {
			.wrap {
				padding: 30px 20px;
			}

			h1 {
				font-size: 1.6em;
				letter-spacing: -0.06em;
				margin-top: 0.4em;
				margin-bottom: 0.2em;
			}

			h2 {
				font-size: 1.8em;
				font-weight: 700 !important;
			}
		}
	}

	.durationSelect, #main .durationSelect, .durationTable {
		&.durationSelect {
			tbody tr {
				&:hover, &:active {
					background-color: rgba($black, 0.1);

					&.checked {
						background-color: darken($turquoise, 5%);
					}
				}
			}
		}

		table {
			display: table;

			thead {
				display: table-header-group;
			}

			tbody {
				display: table-row-group;
			}

			tfoot {
				display: table-footer-group;
			}

			tr {
				display: table-row;
				margin: 0;
			}

			tbody tr {
				&.checked {
					background-color: $turquoise;
					color: $white;

					input[type="radio"] + label::before {
						border-color: $white;
						filter: brightness(1000%);
					}

					.duration .subtitle {
						color: $white;
					}

					.savings:not(.savings-0) .number {
						color: $white;
					}
				}
			}

			td, th {
				display: table-cell;
				padding: 0.5em;

				&:not(:last-child):not(.radio) {
					margin-bottom: 0;
				}
			}

			td.radio {
				display: table-cell;
				position: relative;
				top: 0;
				padding: 0.5em;
			}

			td:not(.radio) {
				padding-left: 0.5em;
			}

			.mobileTitle {
				display: none;
			}

			.duration .textLabel {
				font-size: 1em;
			}
		}
	}

	.confirmTable, #main .confirmTable, .confirmTable {
		thead, tbody, tfoot {
			tr td, tr th {
				&:not(:first-of-type) {
					text-align: right;
				}
			}
		}
	}

	.fieldset{
		div.formRow.checkbox{
			flex-basis: 8%;
			max-width: 8%;
			& + header{
				flex-basis: 92%;
				max-width: 92%;
			}
		}
	}
}

@media (min-width: 750px) {
	header#header {
		#logo {
			position: relative;
			top: 0.45em;
			float: left;
			margin-top: -6px;
		}
		#menus {
			max-width: calc(100% - 367px);/* La grosseur du logo */
			float: right;
			margin-top: -6px;
			text-align: right;
		}

		.top{
			position: relative;
			top: 0;
			left: 0;
		}

		#topmenu { display: block; text-align: right; }
		#mainmenu {
			text-align: right;
			padding-bottom: 1em;
		}
	}
	#home-search {
		width: 30%;
	}

	.entryContent, .entry-content, .formFooter, label, dd, #main .entryContent, #main .entry-content{
		table {
			font-size: 1em;
		}
	}

	.account{
		.tabs{
			.tabContent{
				form{
					.formRowParent{
						float: left;
						width: 49%;
						flex-basis: auto;
						max-width: none;
						&:nth-of-type(2n-1){
							margin-right: 2%;
						}
					}
				}
			}
		}

		#main{
			.twoCols{
				.rows{
					.formRowParent{
						float: left;
						width: 49%;
						flex-basis: auto;
						max-width: none;
						&:nth-of-type(2n-1){
							margin-right: 2%;
						}
					}
				}
			}
		}
	}

	.home #main .typeuser {
		min-height: calc(100vh - 135px);

		ul {
			position: absolute;
			top: 0;
			bottom: 130px;
			left: 0;
			right: 0;

			.bubble {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 35%;
				margin-top: 0;

				&:first-of-type {
					left: 0;
				}

				&:last-of-type {
					right: 0;
				}

				.content{
					a::after{
						bottom: 30px;
					}

					.typeTitle {
						font-size: 1em;
					}

					.typeSubtitle {
						font-size: 1.3em;
					}
				}
			}
		}
	}

	.emploiretraite-view-jobs-button {
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 70px;
		margin-top: 0;
	}

	.register.step3{
		#main{
			.sectors{
				.formRow.sectorCheckbox{
					flex-basis: 30%;
					max-width: 30%;
				}
			}
		}
	}

	.step1{
		form{
			div.formContent > .rows{
				width: 67%;
				float: left;
			}

			.wrapBup {
				width: 33%;
				float: right;
			}
		}
	}

	.fieldset {
		&.pointList {
			.accordionContent {
				dl{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					& > div{
						flex-basis: 45%;
						max-width: 45%;
					}
				}
			}
		}
	}
}

@media (min-width: 820px) {
	header#header {
		#mainmenu {
			& > ul{
				& > li.parent{
					ul{
						left: initial;
						right: 0;
					}
				}
			}
		}
	}
}

@media (min-width: 900px) {
	.pageTemplate-linkHub{
		#main{
			.pageSection{
				&.hasBubble {
					.mainText > h2 {
						text-align: center;
					}
				}
			}
		}
	}

	#singleHeader .cieLogo {
		flex-basis: 100px;
	}

	.home {
		#main {
			.title {
				flex-wrap: nowrap;

				& > div {
					flex-basis: 100%;
					max-width: 100%;
				}
			}

			.typeuser {
				padding-bottom: 76px;

				ul {
					justify-content: space-between;
					bottom: 76px;

					.bubble {
						flex-basis: 35%;
						max-width: 35%;

						.content{
							.typeTitle {
								font-size: 1.2em;
							}

							.typeSubtitle {
								font-size: 1.5em;
							}
						}
					}
				}
			}

			.linkBar {
				.link {
					&:nth-of-type(2n) {
						padding-left: 15%;
					}
					&:nth-of-type(2n-1) {
						padding-right: 15%;
					}
				}
			}
		}

		#blog {

			h2 {
				font-size: 3.5em;
			}

			.inner {
				display: flex;

				& > header {
					flex-basis: 30%;
					max-width: 30%;
					padding-right: 0.5em;
				}

				.blogposts {
					flex-basis: 70%;
					max-width: 70%;
				}
			}

			.image {
				display: inline-block;
			}
		}
	}

	//La sidebar apparaît à 1206px sur le template pricing et à 1100px sur la page 1 de la sélection de forfait (pricePlans)
	body:not(.pageTemplate-pricing):not(.pricePlans) {
		#main {
			&.hasSidebar {
				.innerMain {
					flex-wrap: nowrap;
					justify-content: space-between;

					& > *:not(aside) {
						-ms-flex-positive: 1;
						flex-grow: 1;
						padding-right: 4%;

						.wrap {
							padding-right: 0;
						}
					}

					& > aside {
						flex-basis: 300px;
						max-width: 300px;
						flex-shrink: 0;
						width: auto;
						margin: 0;

						.sidebarInner > .bup > a {
							margin: 0;
						}
					}
				}
			}
		}
	}

	.register.step3{
		#main{
			.sectors{
				position: absolute;
				bottom: 0;
				left: 0;
				.visual-checkbox{
					display: none;
				}
				.formRow.sectorCheckbox{
					flex-basis: 49%;
					max-width: 49%;
					span{
						font-size: 0.73em;
					}
				}
			}
		}
	}

	#main {
		.packages .packageGroup {
			table {
				@include tableReset;

				tr {

					td, th {
						&:first-of-type {
							padding-left: 2em;
						}
					}
				}

				th {
					&.title {
						position: relative;

						&::after {
							//Fix pour le border-bottom qui marche semi ici
							content: '';
							position: absolute;
							z-index: 10;
							bottom: -2px;
							left: 0;
							right: -1px;
							display: block;
							border-bottom: 2px solid $turquoise;
						}
					}

					&:not(.title) {
						display: table-cell;
						min-width: 220px;
					}
				}

				tbody {
					tr {
						padding: 0;
					}

					td {
						padding: 0.8em 0.4em;

						&:not(:last-of-type) {
							margin-bottom: 0;
							border-right: 2px solid $white;
						}

						&:not(.title) {
							text-align: center;
						}

						.mobileTitle {
							display: none;
						}
					}
				}
			}
		}

		.buttons.formTop{
			border-bottom: none;
			position: absolute;
			top: 4.6em;
			right: 5%;
		}
	}

	body.account.containForm #main h1{
		max-width: 78%;
	}

	form {
		.formContent {
			display: flex;
			align-items: space-between;
			flex-wrap: nowrap;
		}

		.wrapBup {
			flex-basis: auto;
			max-width: 100%;
		}
	}

	body.register{
		form{
			.formContent{
				display: block;
			}
		}
	}

	.fieldset {
		div.formRow.checkbox{
			flex-basis: 6%;
			max-width: 6%;
			& + header{
				flex-basis: 94%;
				max-width: 94%;
			}
		}
	}

	.blogControls {
		display: none;
	}

	#main.hasSidebar .innerMain > aside.blogSidebar {
		.search, .categories {
			display: block;
		}
	}

//Version 2 (en forme de flèches ______>______>)
	.tabMenu {
		ul {
			display: flex;
			flex-wrap: wrap;
		}

		li {
			margin-bottom: 1em;

			&:not(:last-of-type) {
				border-bottom: none;

				a {
					padding-right: 0.8em;
				}
			}

			&:not(:first-of-type) {
				a {
					padding-left: 2.4em;
				}
			}

			&:first-of-type {
				&, a {
					border-radius: 0.5em 0 0 0.5em;
				}
			}

			&:last-of-type {
				&, a {
					border-radius: 0 0.5em 0.5em 0;
				}

				a {
					&::after, &::before {
						display: none;
					}
				}
			}

			a {
				//Autre flèche blanche pour cacher l' « onglet » suivant. Ne fonctionne que sur fond blanc.
				&::before {
					content: '';
					position: absolute;
					left: 100%;
					top: 50%;
					transform: translateY(-50%);
					height: 0;
					width: 0;
					border: 1.85em solid transparent;
					border-left-color: $white;
					transition: border-left-color 0.2s ease;
					pointer-events: none;
				}

				&::after, &::before {
					z-index: 10;
				}

				.full {
					display: none;
				}

				.short {
					display: block;
				}
			}
		}
	}
}

@media (min-width: 1014px) {
	header#header {
		.userinfos {
			display: inline-block;
		}
	}
}

//Inclut iPad couché
@media (min-width: 1020px) {
	.account {
		#sidebar {
			position: relative;
			width: auto;
			flex-basis: 375px;
			max-width: 375px;
			flex-shrink: 0;
			transform: translateX(0);

			#btnOpenSidebar, #btnCloseSidebar {
				display: none;
			}

			.profileCompletion {
				padding-top: 1em;
			}

			.userinfos {
				padding-top: 1.6em;
			}
		}

		#main {
			padding-top: 3em;
			.twoCols{
				.rows{
					.formRowParent{
						float: none;
						width: 100%;
						&:nth-of-type(2n-1){
							margin-right: 0;
						}
					}
				}
			}
		}

		#content{
			min-height: auto;
		}

		.tabs{
			.tabContent{
				form{
					.formRowParent{
						float: none;
						width: 100%;
						&:nth-of-type(2n-1){
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.home #main{
		.typeuser ul{
			.bubble{
				.content{

					.clickHere{
						bottom: 35px;
					}

					.typeTitle {
						font-size: 1.4em;
					}

					.typeSubtitle {
						font-size: 1.7em;
					}
				}
			}
		}
	}

	body.account.containForm #main h1{
		max-width: 68%;
	}

	#main .buttons.formTop{
		top: 3em;
	}
}

@media (min-width: 1035px) {
	#main {
		.sectorCheckbox, .categoryCheckbox {
			flex-basis: 32%;
			max-width: 32%;

			&:nth-of-type(2n) {
				margin-left: 0;
			}

			&:nth-of-type(3n), &:nth-of-type(3n-1) {
				margin-left: 2%;
			}
		}
	}

	.home #main {
		background-image: url('/images/bg_large_2023.jpg');
	}
}

@media (min-width: 1100px) {
	body.pricePlans {
		#main {
			&.hasSidebar {
				.innerMain {
					flex-wrap: nowrap;
					justify-content: space-between;

					& > *:not(aside) {
						flex-grow: 1;
						padding-right: 4%;

						.wrap {
							padding-right: 0;
						}
					}

					& > aside {
						flex-basis: 300px;
						max-width: 300px;
						flex-shrink: 0;
						width: auto;
						margin: 0;

						.sidebarInner > .bup > a {
							margin: 0;
						}
					}
				}
			}
		}
	}

	.home{
		#blog{
			h2{
				font-size: 4.5em;
			}
		}
	}
}

@media (min-width: 1300px){
	body.account.containForm #main h1{
		max-width: 78%;
	}
}

@media (min-width: 1400px) {
	.signed-in #home-search-simplified {
		position: absolute;
		top: 30%;
		left: 40%;
	}
}

@media (min-width: 1550px) {
	header#header {
		#mainmenu {
			& > ul{
				& > li.parent{
					ul{
						right: initial;
						left: 0;
					}
				}
			}
		}
	}
}

@media (min-width: 1206px) {
	.pageTemplate-linkHub{
		#main{
			.pageSection{
				&.hasBubble {
					.mainText > h2 {
						text-align: left;
					}
				}
			}
		}
	}

	.account{
		.tabs{
			.tabContent{
				form{
					.formRowParent{
						float: left;
						width: 49%;
						&:nth-of-type(2n-1){
							margin-right: 2%;
						}
					}
				}
			}
		}

		#main {
			padding-top: 3em;
			.twoCols{
				.rows{
					.formRowParent{
						float: left;
						width: 49%;
						&:nth-of-type(2n-1){
							margin-right: 2%;
						}
					}
				}
			}
		}
	}
	.home {
		#blog{
			h2{
				font-size: 5em;
			}
		}

		#main {
			background-size: contain;

			&::before, &::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
			}

			&::before {
				left: 0;
				right: 0;
				background-image: linear-gradient(to right, $white 0%, $white 20%, transparent 25%, transparent 75%, $white 80%, $white 100%);
			}

			.typeuser ul .bubble {
				max-width: 370px;

				.icon {
					display: block;
				}

				.content {
					.clickHere{
						bottom: 50px;
					}

					span.noa, a {
						.typeTitle {
							font-size: 1.6em;
							line-height: 1.1em;
						}

						.typeSubtitle {
							font-size: 1.8em;
							line-height: 1.1;
						}
					}
				}
			}

		}

		#videoBlock {
			.wrap {
				flex-wrap: nowrap;

				&::after {
					transform: translate(10%, -3.8em);
				}
			}

			.videoCol {
				flex-basis: auto;
				max-width: 100%;
			}

			.videoDescription {
				flex-basis: 45%;
				max-width: 45%;
			}
		}

		#howitwork {
			.steps {
				.step {
					&:nth-child(odd) {
						.content {
							right: 0;
						}
					}

					&:nth-child(even) {
						.content {
							left: 0;
						}
					}

					.content {
						position: absolute;
						top: 0;
						bottom: 0;
					}

					.content-inner {
						position: absolute;
						top: 50%;
						left: 0;
						width: 100%;
						transform: translateY(-50%);
					}
				}
			}
		}

		#infos {
			ul.infos li {
				flex-basis: 30%;
				max-width: 30%;
			}
		}

		div#newsletter {
			h3 {
				font-size: 2.4em;

				strong {
					display: block;
					margin-top: -0.3em;
				}
			}
		}
	}

	.home #main {
		#blog {
			.blogpost {
				display: flex;
			}

			.image {
				flex-shrink: 0;
			}
		}
	}

	.account #main #blog {
		.blogpost .image {
			margin-right: 1.5em;
		}
	}

	body.pageTemplate-pricing {
		#main {
			&.hasSidebar {
				.innerMain {
					flex-wrap: nowrap;
					justify-content: space-between;

					& > *:not(aside) {
						flex-grow: 1;
						padding-right: 4%;

						.wrap {
							padding-right: 0;
						}
					}

					& > aside {
						flex-basis: 300px;
						max-width: 300px;
						flex-shrink: 0;
						width: auto;
						margin: 0;

						.sidebarInner > .bup > a {
							margin: 0;
						}
					}
				}
			}
		}
	}

	#main {
		.sectorCheckbox, .categoryCheckbox {
			flex-basis: 23.5%;
			max-width: 23.5%;

			&:nth-of-type(3n), &:nth-of-type(3n-1) {
				margin-left: 0;
			}

			&:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2) {
				margin-left: 2%;
			}
		}
	}

	body {
		footer#footer {
			#footermenu, #legalmenu {
				ul li {
					display: inline-block;
					margin-right: 15px;

				}
			}
		}
	}

	#main .dashboardModules .module {
		&.threeCols {
			.contentInner {
				flex-wrap: nowrap;
			}

			.col {
				flex-basis: 33.333%;
				max-width: 100%;
			}
		}
	}
}

//Quand l'image de l'accueil serait en background-size: contain; mais à cause de la faible largeur et la grande hauteur de l'écran, ça fait voir du blanc dessous.
@media (min-width: 1206px) and (max-width: 1550px) {
	.home #site #main {
		background-size: cover;
	}
}

@media (min-width: 850px) and (max-width: 1399px) {
	.wrap { padding: 0 50px; }
}

@media (min-width: 1400px) {
	.wrap {
		padding: 0;
		margin: 0 auto;
	}

	.home #main .typeuser .bubble {
		max-width: none;

		a .typeTitle {
			font-size: 1.8em;
		}

		.content .typeSubtitle {
			font-size: 1.2em;
		}
	}

	#main .dashboardModules .module {
		&:not(.fullWidth) {
			width: 48%;
		}
	}
}

/*** Queries « trouées » **************************************************/

//Media queries qui reviennent en mode "mobile" au début de l'affichage de la sidebar

@media (min-width: 700px) and (max-width: 899px),
(min-width: 1206px) {
	.pageTemplate-linkHub #main {
		.pageSection {
			margin-bottom: 0;

			.sectionInner {
				flex-wrap: nowrap;
				justify-content: space-between;
			}

			&.hasBubble {
				&:nth-of-type(odd) {
					.bubble {
						order: 1;
					}

					.mainText {
						order: 2;
						padding-right: 0;
						padding-left: 1.4em;
					}
				}

				&:nth-of-type(even) {
					.bubble {
						order: 2;
					}

					.mainText {
						order: 1;
						padding-right: 1.4em;
						padding-left: 0;
					}
				}

				&.valign-center {
					.sectionInner {
						align-items: center;
					}

					.mainText {
						padding-top: 0;
					}
				}

				.mainText {
					flex-basis: auto;
					max-width: 100%;
					padding-top: 2em;
					padding-right: 1em;
					margin-bottom: 0em;
					text-align: left;
				}
			}

			&:not(.hasBubble) {
				&:not(:first-child) {
					margin-top: 2em;
				}
			}

			.bubble {
				flex-basis: 300px;
				max-width: 300px;
				margin-bottom: 0;
			}
		}
	}

	.blogList, .home {
		.blogposts {
			.blogpost {
				display: flex;
				align-items: flex-start;

				.image {
					margin-right: 1.8em;
				}

				.content {
					&>header {
						margin-top: 0;

						.subHeader {
							display: flex;
							align-items: baseline;
							margin-bottom: 0em;
							line-height: 1em;
						}

						.postCategories {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
}

//Quand c'Est assez large pour afficher les indicateurs et le bouton à droite des offres dans le dashboard. Il y a un trou quand la sidebar arrive et amincit l'espace disponible, et un autre trou quand on vient de tomber en 2 colonnes de modules

@media (min-width: 615px) and (max-width: 1020px),
(min-width: 1180px) and (max-width: 1399px),
(min-width: 1825px) {
	.account.accountHome {
		.jobOffers {
			.jobOffer {
				.articleInner {
					flex-wrap: nowrap;
					align-items: flex-start;
				}

				.articleInner-l2 {
					flex-wrap: nowrap;
				}

				.jobInfo {
					flex-basis: auto;
					max-width: 100%;
					flex-grow: 1;
					padding-right: 1.4em;
				}

				.wrapButton {
					margin-top: 0;
					text-align: right;

					.wrapIndicators {
						flex-wrap: nowrap;
						align-items: flex-end;
						justify-content: flex-end;
					}
				}
			}
		}
	}
}


//Quand c'est assez large pour afficher les posts de blogue tout en large dans les outils pratiques du compte. Ça fait un trou quand la sidebar apparaît.
@media (min-width: 750px) and (max-width: 1019px),
(min-width: 1206px) {
	.account #main {
		#blog {
			.blogpost {
				display: flex;
			}

			.content {
				&>header {
					margin-top: 0;

					.subHeader {
						display: flex;
						align-items: baseline;
						margin-bottom: 0em;
						line-height: 1em;
					}

					.postCategories {
						margin-top: 0;
					}
				}
			}

			.image {
				flex-shrink: 0;
				margin-right: 1.5em;
			}
		}
	}
}

/***David*******/

/****END David******/
@media screen and (max-width: 611px) {
#singleHeader .headerText {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    word-wrap: break-word;
  }
}
/*** Queries de hauteur **************************************************/



// Styles pour les imprimantes
@media print {
	@import "print";
}
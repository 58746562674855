/*** Initialisation des polices ******************************************************************/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic,800,800italic);
//@font-face { @include init_font( 'RalewayThin', 'raleway_thin' ); }// Ajoute la font standard
//@font-face { @include init_font( 'RalewayThin', 'raleway_thin', bold ); }// Ajoute la font en bold
//@font-face { @include init_font( 'RalewayThin', 'raleway_thin', normal, italic ); }// Ajoute la font en italic

/*** Initialisation des variables ****************************************************************/
/* Polices (Voir dans _function.scss pour les fallback) */
$fontBase: 'Open Sans', $helvetica;

/* Couleurs du site */
$white: #fff;
$black: #333535;
$darkblack: #191919;
$trueblack: #000000;
$mauve: #6a5ac9;
$darkmauve: darken($mauve, 15%);
$turquoise: #339a99;
$darkturquoise: darken($turquoise, 15%);
$gray: #222929;
$lightgreen: #5ac993;
$green: #5ac994;
$darkgreen: darken($green, 15%);
$sidebargray: #2c2c2c;
$sidebardarkgray: #242424;
$orange: #dda946;

/* Couleurs des élément standard */
/*$menu: white;
$black: #05172b;*/

/* Couleurs qui reviennent toujours */
$red: #9a0000;
$green: #5ac994;
$gray : #bcbcbc;
$lightgray: #EBEBEB;
$midgray: #4b4b4b;
$darkgray: #1b1b1b;

/* Slicknav */
/*$slickButton: $blue;
$slickButtonText: white;
$slickMenuBackground: $blue;
$slickMenuText: white;*/

/* Formulaire */
$bad: $red;
$correct: $green;
$borderInput: $black;
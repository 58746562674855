/******************************************************************
Site Name:
Author:

Stylesheet: Print Stylesheet

This is the print stylesheet. There's probably not a lot
of reasons to edit this stylesheet. If you want to
though, go for it.

******************************************************************/

* {
	background: transparent !important;
	color: black !important;
	text-shadow: none !important;
	filter:none !important;
	-ms-filter: none !important;
	overflow: visible!important;
	overflow-x: visible!important;
	overflow-y: visible!important;
	box-sizing: content-box!important;
	float: none!important;
}

a, a:visited {
	color: #444 !important;
	text-decoration: underline;

	// show links on printed pages
	&:after {
		content: none;
	}

	// show title too
	abbr[title]:after {
		content: none;
	}
}

.ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
	content: "";
}

pre, blockquote {
	border: 1px solid #999;
	page-break-inside: avoid;
}

thead {
	display: table-header-group;
}

tr, img {
	page-break-inside: avoid;
}

img {
	max-width: 100% !important;
}

@page {
	margin: 0.5cm;
}

p, h2, h3 {
	orphans: 3;
	widows: 3;
}

h2, h3 {
	page-break-after: avoid;
}

.hidePrint { display: none; }

// hide content people who print don't need to see
.sidebar, .page-navigation, .wp-prev-next, .respond-form, nav {
	display: none;
}

.mfp-wrap{
	position: relative!important;
	bottom: 0;
	left: 0;

	.mfp-content {
		max-width: 100%;
	}
	.mfp-container, .innerPopup, .innerPopup-l2 {
		position: relative!important;
		top: auto!important;
		bottom: auto!important;
		left: auto!important;
		right: auto!important;
	}

	.innerPopup-l2{
		border: none!important;
		overflow-y: visible;
	}

	& + #site {
		display: none;
	}

	#content {
		display: block;

	}
}

.cvsList {
	#singleHeader .headerText { width: 100% !important; }

	div { float:none !important; }

	.fieldset div.formRow.checkbox + header {
		flex-basis: auto;
		max-width: 100%;
	}

	.fieldset {
		page-break-after: always !important;
	}

	#employerCVS {
		.checkbox { display: none; }
	}

	.fieldset { display: block; }

	.fieldset .accordionContent{
		display: block!important;
		opacity: 1!important;

		border-left: 0 none;
		border-right: 0 none;
	}

	.fieldset > header {
		padding-left: 0;
		.headerText { padding: 0; }

		h2 { font-size: 2em; }

		.btnRollUnroll::after { display: none; }
	}
}
